import * as React from 'react'
import Lottie from 'lottie-react'
import loader from './loader.json'

const PreLoader = () => {
  const styles = {
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    height: '15vh',
    width: '15vw'
  }
  return (
    <div style={styles}>
      <Lottie animationData={loader} loop />
    </div>
  )
}

export default PreLoader
