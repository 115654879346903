import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

function ContentPolicy () {
  const classes = styles()

  return (
    <div className={classes.mainContainer}>
      <Typography variant="h2">
        Content Policy
      </Typography>
      <Typography variant="body1" style={{ marginTop: '20px' }}>
        Nearcast content policy is simple. Post about things that unite, and which bring hope, joy, and happiness to the neighborhood.
        <br />
        We think neighborhoods work better together when neighbors focus what unites. Therefore, Nearcast's mission is to drive social engagement around things that spread joy and happiness. We welcome video content that is non-controversial, and which is family friendly. Therefore, all content on Nearcast is centrally moderated to ensure we highlight what makes us united. We don't allow videos of suspected crime. We don't allow videos of divisive politics. However, we welcome posts on hope, joy, and inspiration. We welcome neighbors helping neighbors and the ethos of supporting our neighbors and local businesses.
      </Typography>
    </div>
  )
}

const styles = makeStyles(({ breakpoints, palette }) => ({
  flex: {
    display: 'flex',
    alignItems: 'center'
  },
  flexGrow: {
    flex: '1'
  },
  mainContainer: {
    padding: '40px',
    paddingBottom: '60px'
  }
}))

export default ContentPolicy
