import React, { useState, useContext, useEffect, useRef, useCallback } from 'react'
import { Divider, Typography, Stack, FormControl, FormHelperText } from '@mui/material'
import dayjs from 'dayjs'
import { useSnackbar } from 'notistack'

// importing components
import { UserContext } from '../../../context/UserContext'
import FeatureCard from './FeatureCard'

// importing ui
import WideModal, { WideModalButtonProps } from 'ui/Modals/WideModal'
import CustomTextArea from 'ui/TextInputs/TextAreaInput'

// importing apis
import { postBugsReport, getBugsReports } from 'api/bugs/report'

// importing images
const Bug = require('../../../assets/bug.png')

export const status = {
  0: 'New',
  1: 'Under Review',
  2: 'Approved',
  3: 'Closed - Duplicate',
  4: 'Closed - Out of Scope'
}

export interface ReportBugProps {
  isOpen: boolean;
  onClose: () => {};
}

const ReportBug = ({ isOpen, onClose }) => {
  const { personal, jwt_token, active_neighbourhood } = useContext(UserContext)

  const [step, setStep] = useState(0)
  const initialReportsState = {
    page: 0,
    hasMore: true,
    reports: []
  }
  const [reportsState, setReportsState] = useState(initialReportsState)
  const [selectedReq, setSelectedReq] = useState<any>({})

  // form state
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const initialInputError = {
    title: null,
    description: null
  }
  const [inputError, setInputError] = useState(initialInputError)
  const [processing, setProcessing] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const observer: any = useRef()
  const lastReportRef = useCallback(
    (node) => {
      if (observer.current) {
        observer.current.disconnect()
      }
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && reportsState.hasMore) {
          fetchAllBugsReportWithPage(reportsState.page + 1)
        }
      })
      if (node) {
        observer.current.observe(node)
      }
    },
    [reportsState]
  )

  const validateNewReportForm = () => {
    const newInputError = initialInputError

    if (title.length === 0) {
      newInputError.title = 'Please provide a report title!'
    }

    if (description.length === 0) {
      newInputError.description = 'Please provide a description title!'
    }

    setInputError(newInputError)
    if (Object.values(newInputError).find((e) => e !== null)) {
      return false
    } else {
      return true
    }
  }

  const onPressCreateReport = () => {
    if (!validateNewReportForm()) {
      return
    }

    if (processing) {
      return
    }
    setProcessing(true)
    postBugsReport(title, description, personal[active_neighbourhood].ID, jwt_token)
      .then(() => {
        setTitle('')
        setDescription('')
        setInputError(initialInputError)
        setStep(0)
        fetchAllBugsReportWithPage(1, true)
        enqueueSnackbar('New bug report created!', { variant: 'success' })
      })
      .catch((err) => console.log(err.message))
      .finally(() => setProcessing(false))
  }

  const fetchAllBugsReportWithPage = (p = 1, shouldRefresh = false) => {
    // if should shouldRefresh is true then refresh reports from page one else further checks
    if (!shouldRefresh) {
      if (reportsState.page === p || reportsState.hasMore === false) {
        return
      }
    }

    getBugsReports(p, personal[active_neighbourhood].ID, jwt_token)
      .then((data) => {
        if (!data.bugs) {
          setReportsState({
            ...reportsState,
            hasMore: false,
            page: p
          })
        } else {
          setReportsState({
            ...reportsState,
            reports: data.bugs,
            page: p
          })
        }
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    fetchAllBugsReportWithPage(1)
  }, [])

  const renderModalContent = () => {
    if (step === 0) {
      if (reportsState.reports.length === 0) {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              padding: '0px 5%',
              height: '100%'
            }}
          >
            <img
              src={Bug}
              alt="No features"
              width={235}
              style={{ marginBottom: '20px' }}
            />
            <Typography textAlign="center" variant="h4">
              Report a bug and earn upto 5000 Nearcoins
            </Typography>
          </div>
        )
      }

      return (
        <>
          {reportsState.reports.map((req, index) => (
            <div key={req.id} ref={reportsState.reports.length - 1 === index ? lastReportRef : null}>
              <FeatureCard
                req={req}
                setStep={setStep}
                setSelectedReq={setSelectedReq}
              />
            </div>
          ))}
        </>
      )
    }

    if (step === 1) {
      return (
        <>
          <FormControl fullWidth>
            <CustomTextArea
              rows={1}
              label="Title"
              value={title}
              onChange={(e) => {
                if (e.target.value.length > 128) {
                  return
                }
                setTitle(e.target.value)
              }}
              error={Boolean(inputError.title)}
              placeholder="Title of the report"
            />
            {inputError.title && <FormHelperText error>{inputError.title}</FormHelperText>}
          </FormControl>
          <FormControl
            fullWidth
            style={{ marginTop: '10px', marginBottom: '20px' }}
          >
            <CustomTextArea
              rows={3}
              label="Description"
              value={description}
              onChange={(e) => {
                if (e.target.value > 512) {
                  return
                }
                setDescription(e.target.value)
              }}
              error={Boolean(inputError.description)}
              placeholder="Describe the bug in detail so that our team can fix it ASAP."
            />
            {inputError.description && <FormHelperText error>{inputError.description}</FormHelperText>}
          </FormControl>
        </>
      )
    }

    // step === 2
    return (
      <Stack direction="column" justifyContent="space-between" sx={{ height: '100%' }}>
        <div>
          <Typography variant="h3">
            {selectedReq.title}
          </Typography>
          <Typography variant="body1" sx={{ marginTop: 1 }}>
            {selectedReq.description}
          </Typography>
          {selectedReq.response && (
            <>
              <Typography variant="h5" sx={{ marginTop: 1 }}>
                Response
              </Typography>
              <Typography variant="h6" style={{ margin: '10px 0' }}>
                {selectedReq.response}
              </Typography>
            </>
          )}
        </div>
        <div>
          <Typography variant="h6" style={{ marginTop: '32px' }}>
            Status:{' '}
            <span style={{ color: '#00a7ff' }}>
              {status[selectedReq.status]}
            </span>
          </Typography>
          <Typography variant="h6">
            Submitted On: {dayjs(selectedReq.created_at).format('DD MMM YYYY')}
          </Typography>
          <Typography variant="h6">
            It will take atleast a few days to review a suggestion
            and reply to it.
          </Typography>
        </div>
      </Stack>
    )
  }

  const renderModalButton = (): WideModalButtonProps[] => {
    if (step === 0) {
      return [
        {
          title: 'Report a Bug',
          onPress: () => setStep(1),
          color: 'primary'
        }
      ]
    }

    if (step === 1) {
      return [
        {
          title: 'Create Report',
          onPress: onPressCreateReport,
          color: 'primary'
        }
      ]
    }

    return [
      {
        title: 'Back',
        onPress: () => setStep(0),
        color: 'ghost'
      }
    ]
  }

  return (
    <WideModal
      isOpen={isOpen}
      onClose={onClose}
      title="Report a Bug"
      isTitleBack={step !== 0}
      onPressTitleBack={() => setStep(0)}
      buttons={renderModalButton()}
      buttonsAlign="center"
    >
      <Divider style={{ marginBottom: 12 }} />
      <Stack direction="column" style={{ height: '55vh' }}>
        {renderModalContent()}
      </Stack>
    </WideModal>
  )
}

export default ReportBug
