import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import AgoraRTC from 'agora-rtc-sdk-ng'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

if (process.env.REACT_APP_DEPLOYMENT !== 'dev') {
  // If not in development env then set the log level for agora to 4, hence no logs
  AgoraRTC.setLogLevel(4)

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0, // for finer control adjust this value or use tracesSampler, set to 1 for 100% tracking
    environment: process.env.REACT_APP_DEPLOYMENT
  })
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
