import { FormControl, Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { useContext, useState } from 'react'
import StandardModal from 'ui/Modals/StandardModal'
import { TextLabel } from 'ui/TextInputs/TextInput'
import CustomTextArea from 'ui/TextInputs/TextAreaInput'
import DateFieldInput from 'ui/TextInputs/DateFieldInput'
import { UserContext } from '../../context/UserContext'
import { useSnackbar } from 'notistack'
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined'
import AddStickers from './AddStickers'
import StickerButton from 'ui/Buttons/StickerButton/StickerButton'
import { groupAnnouncementCreate } from 'api/group/announcements'

function CreateAnnouncement ({ open, onClose, groupId, setAnnouncements }) {
  const classes = styles()
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [expiry, setExpiry] = useState()
  const [stickers, setStickers] = useState([])
  const [loading, setLoading] = useState(false)

  const [stickerModal, setStickerModal] = useState(false)

  const { personal, active_neighbourhood, jwt_token } = useContext(UserContext)

  const { enqueueSnackbar } = useSnackbar()

  const clearModal = () => {
    setTitle('')
    setDescription('')
    setExpiry()
    setStickers([])
  }

  const handleRemove = (sticker) => {
    const index = stickers.findIndex((item) => item.type === sticker.type)

    const newArr = [...stickers]
    newArr.splice(index, 1)

    setStickers(newArr)
  }

  const createAnnouncement = async () => {
    if (title.trim().length === 0 || description.trim().length === 0 || !expiry) {
      enqueueSnackbar('All fields are mandatory 🛑', {
        variant: 'error',
        autoHideDuration: 5000
      })
      return
    }

    setLoading(true)
    const data = {
      title,
      description,
      valid_till: new Date(expiry).toISOString(),
      group_id: groupId,
      stickers: stickers
    }

    try {
      await groupAnnouncementCreate(
        title,
        description,
        new Date(expiry).toISOString(),
        groupId,
        stickers,
        personal[active_neighbourhood].ID,
        jwt_token
      )
      setAnnouncements((prev) => [...prev, data])
      onClose()
      clearModal()
    } catch (error) {
      // console.log(error);
      enqueueSnackbar('There was some error creating this announcement.', { variant: 'error', autoHideDuration: 5000 })
    }

    setLoading(false)
  }

  return (
        <StandardModal
			isOpen={open}
			onClose={onClose}
			title="Create Announcement"
			buttons={[
			  {
			    title: 'Create',
			    color: 'primary',
			    onPress: () => {
			      if (!loading) {
			        createAnnouncement()
			      }
			    }
			  }
			]}
		>
			<div style={{ marginTop: 20, width: '90%' }}>
				<FormControl fullWidth margin="none">
					<CustomTextArea rows={1} label="Title" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Enter announcement title" />
				</FormControl>
				<FormControl fullWidth margin="none" style={{ marginTop: 5 }}>
					<CustomTextArea
						rows={3}
						label="Description"
						placeholder="Enter announcement description"
						value={description}
						onChange={(e) => setDescription(e.target.value)}
					/>
				</FormControl>
				<FormControl fullWidth margin="none" style={{ marginTop: 5 }}>
					<DateFieldInput label="Expiry Date" value={expiry} onChange={(e) => setExpiry(e.target.value)} />
				</FormControl>
				<FormControl fullWidth margin="none" style={{ marginTop: 15 }}>
					<TextLabel
						htmlFor="addSticker"
						style={{
						  marginLeft: 6,
						  fontSize: 14,
						  fontWeight: 600
						}}
					>
						Stickers
					</TextLabel>
					<Button
						variant="contained"
						name="addSticker"
						className={classes.addStickerBtn}
						disableElevation
						color="secondary"
						onClick={() => setStickerModal(true)}
					>
						<NoteAddOutlinedIcon style={{ marginRight: 5 }} /> Add Sticker
					</Button>
				</FormControl>
				{stickers.length > 0 && (
					<FormControl fullWidth margin="none" style={{ marginTop: 15 }}>
						<TextLabel
							style={{
							  marginLeft: 6,
							  fontSize: 14,
							  fontWeight: 600
							}}
						>
							Added Stickers
						</TextLabel>

						<div
							style={{
							  display: 'flex',
							  flexWrap: 'wrap'
							}}
						>
							{stickers.map((sticker, i) => (
								<StickerButton
									key={`announcementSticker${i}`}
									sticker={sticker}
									overRideOnClick={() => {
									  handleRemove(sticker)
									}}
								/>
							))}
						</div>
					</FormControl>
				)}
			</div>
			<AddStickers
				open={stickerModal}
				onClose={() => setStickerModal(false)}
				setStickers={setStickers}
				currentStickers={stickers}
			/>
		</StandardModal>
  )
}

export default CreateAnnouncement

const styles = makeStyles(() => ({
  addStickerBtn: {
    marginTop: 10,
    width: 150,
    padding: '10px 0',
    '& .MuiButton-label': {
      // padding: "0 10px",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '0.9rem',

      fontWeight: 600
    }
  },
  stickerButton: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    padding: '5px 10px',
    borderRadius: 5,
    cursor: 'pointer',
    marginRight: '10px',
    color: 'white',
    whiteSpace: 'nowrap',
    marginTop: 10
  }
}))
