import axios from 'axios'

/**
 * @function gatheringNeighbourhood Used to retrieve the gatherings in the neighbourhood
 * @param {number} page - the paginated page number data to retrieve
 * @param {number} distance - the current user's uid
 * @param {string} nrctUID - user's current neighborhood id
 * @param {string} jwt - user's jwt token
 * @returns {Promise} Promise object represents gatherings array
 */
export const gatheringNeighbourhood = (page = 1, distance = 30, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/gatherings/neighbourhood`, {
      params: {
        page: page,
        distance: distance
      },
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(res.data.gatherings))
      .catch((err) => reject(err))
  })
}
