import Header from '../../components/Header/Header'
import CreateGathering from '../../components/UserDash/Gathering/CreateGathering'

function HostGathering (props) {
  return (
    <div>
      <Header />
      <CreateGathering />
    </div>
  )
}

export default HostGathering
