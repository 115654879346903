// Imports: Dependencies
import { combineReducers } from 'redux'

// importing reducers
import postReducer from './postReducer'
import activityReducer from './activityReducer'
import userReducer from './userReducer'

const defaultRootReducer = combineReducers({
  postsState: postReducer,
  activityState: activityReducer,
  userState: userReducer
})

// Redux: Root Reducer
const rootReducer = combineReducers({
  root: defaultRootReducer
})

export default rootReducer
