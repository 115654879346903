import React, { useMemo, useRef, useState } from 'react'
import StandardModal from 'ui/Modals/StandardModal'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import PriceFieldsInput from 'ui/TextInputs/PriceFieldsInput'
import { Button, FormControl, Typography, IconButton, MenuList, MenuItem } from '@mui/material'
import { CloseRounded } from '@mui/icons-material'
import { MapContainer, Marker, useMapEvents } from 'react-leaflet'
import icon from 'leaflet/dist/images/marker-icon.png'
import L from 'leaflet'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'
import axios from 'axios'
import { sTypes } from '../AddStickers'
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer'
import { useSnackbar } from 'notistack'

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconAnchor: [10, 41]
})
L.Marker.prototype.options.icon = DefaultIcon

function LocationModal ({ open, onClose, handleStickerAdd }) {
  const [map, setMap] = useState(null)
  const [address, setAddress] = useState('')
  const [formError, setFormError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [center, setCenter] = useState([39.8283, -98.5795])
  const [position, setPosition] = useState({ lat: 39.8283, lng: -98.5795 })
  const [key, setKey] = useState(0)
  const markerRef = useRef(null)
  const { enqueueSnackbar } = useSnackbar()

  const selectPlace = (add) => {
    setAddress(add)
    geocodeByAddress(add)
      .then((results) => {
        return getLatLng(results[0])
      })
      .then((latLng) => {
        setPosition(latLng)
        setCenter([latLng.lat, latLng.lng])
        if (map) map.flyTo([latLng.lat, latLng.lng], 14, { duration: 1 })
      })
  }

  const getAddress = async ({ lat, lng }) => {
    const url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`

    try {
      const res = await axios.get(url)
      setAddress(res.data.display_name)
    } catch (error) {
      // console.log(error);
    }
  }

  const handleMapClick = (e) => {
    setPosition(e.latlng)
    getAddress(e.latlng)
  }

  const eventHandlers = useMemo(
    () => ({
      dragend () {
        const marker = markerRef.current
        if (marker != null) {
          setPosition(marker.getLatLng())
          getAddress(marker.getLatLng())
        }
      }
    }),
    []
  )

  const handleClick = () => {
	  if (!address) {
      enqueueSnackbar('Select a Location 📍', {
        variant: 'error',
        autoHideDuration: 2000
      })
	  return
	  } if (address) {
      enqueueSnackbar('Your sticker has been added ✅', {
        variant: 'success',
        autoHideDuration: 2000
	   })
	  }
    handleStickerAdd({
      type: sTypes.location,
      location_name: address,
      location_lat: position.lat,
      location_lon: position.lng
    })
    onClose()
  }

  return (
		<StandardModal
			isOpen={open}
			onClose={onClose}
			title="Add Location"
			buttons={[
			  {
			    title: 'Add Sticker',
			    color: 'primary',
			    onPress: handleClick
			  }
			]}
		>
			<div style={{ marginTop: 20, marginBottom: 20, width: '100%' }}>
				<PlacesAutocomplete
					value={address}
					onChange={(e) => {
					  setAddress(e)
					  setFormError(false)
					}}
					onSelect={selectPlace}
				>
					{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
						<FormControl fullWidth>
							<PriceFieldsInput
								label="Address*"
								id="street"
								variant="outlined"
								value={address}
								style={{
								  border: formError ? '2px solid #FD2821' : '2px solid transparent',
								  borderRadius: '10px'
								}}
								size="small"
								inputProps={{
								  ...getInputProps({
								    placeholder: 'Search Places ...',
								    className: 'location-search-input'
								  })
								}}
							/>
							{loading || suggestions.length > 0 ? (
								<div>
									<MenuList
										style={{
										  position: 'absolute',
										  zIndex: 1000002,
										  maxHeight: 100,
										  width: '94%',
										  background: 'white',
										  border: '1px solid #d3d3d3',
										  borderRadius: 3,
										  overflow: 'auto'
										}}
									>
										{loading && <MenuItem value="">Loading...</MenuItem>}
										{suggestions.map((suggestion) => {
										  // inline style for demonstration purpose
										  const style = {
										    backgroundColor: suggestion.active
										      ? '#efefef'
										      : '#ffffff',
										    cursor: 'pointer',
										    padding: '2px 12px',
										    fontSize: '15px',
										    textOverflow: 'ellipsis',
										    overflow: 'hidden',
										    display: 'list-item'
										  }
										  return (
												<MenuItem key={suggestion.placeId}
													{...getSuggestionItemProps(suggestion, {
													  style
													})}
												>
													<span>{suggestion.description}</span>
												</MenuItem>
										  )
										})}
									</MenuList>
								</div>
							) : (
								<></>
							)}
						</FormControl>
					)}
				</PlacesAutocomplete>
				<div
					style={{
					  width: '100%',
					  height: 300,
					  maxWidth: '100%',
					  margin: 'auto',
					  marginTop: '16px',
					  opacity: loading ? 0.5 : 1,
					  cursor: loading ? 'pointer' : 'grab'
					}}
					onClick={(e) => {
					  if (loading) e.stopPropagation()
					}}
				>
					<MapContainer center={center} zoom={13} key={key} whenCreated={setMap}>
						<ReactLeafletGoogleLayer apiKey="AIzaSyD65D3CME73uC7YqwJSRNkyZJ_eXLlSE1Y" />
						<Marker
							position={position}
							draggable
							ref={markerRef}
							eventHandlers={eventHandlers}
						// onClick={handleMapClick}
						/>
						<ClickDetector handleMapClick={handleMapClick} />
					</MapContainer>
				</div>
			</div>
		</StandardModal>
  )
}

export default LocationModal

function ClickDetector ({ handleMapClick }) {
  const map = useMapEvents({
    click: (e) => {
      handleMapClick(e)
    }
  })
  return null
}
