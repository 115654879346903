import React from 'react'

// importing base component
import TextInput, { TextLabel } from './TextInput'

export interface DateFieldInputProps {
  label: string;
  name: string;
  placeholder: string;
  value: string;
  onChange: () => void;
}

const DateFieldInput: React.FC<DateFieldInputProps> = ({
  label,
  name,
  placeholder,
  value,
  onChange,
  ...props
}) => {
  return (
    <>
      <TextLabel
        style={{
          marginLeft: 6,
          fontSize: 14,
          fontWeight: 600
        }}
      >
        {label}
      </TextLabel>
      <TextInput
        placeholder={placeholder}
        value={value}
        type="date"
        onChange={onChange}
        name={name}
        color="#8aa6de"
        {...props}
      />
    </>
  )
}

export default DateFieldInput
