import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

function ThirdPartyTerms () {
  const classes = styles()

  return (
        <div className={classes.mainContainer}>
            <Typography variant="h2">
                Third Party Terms and Disclosures
            </Typography>
            <Typography variant="h3" style={{ marginTop: '20px', fontWeight: 'bolder' }}>
                Using Nearcast for Business Purposes
            </Typography>
            <Typography variant="body1" style={{ marginTop: '20px' }}>
                You are not permitted to sublicense, resell, or otherwise disseminate any data obtained from our third-party licensors; it is only for your internal, personal use. Third-party licensors may include, but are not limited to, businesses who supply Nearcast with information about their business pages, maps and addresses, neighborhoods, contact information, and pictures or videos. Additionally, they may include information from partners, news organizations, and GADM that assist Nearcast in creating Content.
                <br /><br />Nearcast.com obtains certain neighborhood names and borders from public and private sources. You may not sell, distribute, reverse engineer, or sublicense any Nearcast border or neighborhood material (or any portion thereof) or use such content in any way other than in connection with our services. This material is protected by copyright, and any unauthorized use is strictly forbidden.
                <br /><br />Certain map data, Business Page data, and contact data are licensed from other parties, including Google, Inc. through its Maps API. Google Maps and Places data are supplied subject to Google's Terms of Service, which include the Google Maps/Google Earth APIs Terms of Service, and its privacy statement.
                <br /><br />Apple requires that users of our iOS application adhere to the following terms: Apple disclaims any warranties with regard to the Nearcast iPhone app to the extent allowed by applicable law. Apple is not liable for any claims made by you or any third party relating to the Nearcast iPhone app or your possession or use of it, including, but not limited to: (i) product liability claims; (ii) claims that the Nearcast iPhone app does not comply with any applicable legal or regulatory requirement; (iii) claims arising under consumer protection or similar legislation; and (iv) claims relating to any infringement of a third party's intellectual property rights. Apple, its subsidiaries, and our other providers are third-party beneficiaries of this agreement to the extent allowed by law, but no one else is. Without limiting the above, Apple has the right (and will be considered to have accepted the right) (1) to enforce this agreement against you as a third-party beneficiary, and (2) Apple is not a party to this agreement and is not responsible for the Nearcast iPhone app or its contents.
            </Typography>
        </div>
  )
}

const styles = makeStyles(({ breakpoints, palette }) => ({
  flex: {
    display: 'flex',
    alignItems: 'center'
  },
  flexGrow: {
    flex: '1'
  },
  mainContainer: {
    padding: '40px',
    paddingBottom: '60px'
  }
}))

export default ThirdPartyTerms
