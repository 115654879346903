import React, { useState } from 'react'
import { Avatar, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Favorite, PlayArrowRounded, Chat } from '@mui/icons-material'
import { NIL as uuidNil } from 'uuid'

export interface NearcastCardUserProps {
  post: any;
  onClick: () => void;
  onPlay?: () => void;
  isSelected?: boolean;
}

const NearcastCardUser: React.FC<NearcastCardUserProps> = ({
  post,
  onClick,
  onPlay,
  isSelected
}) => {
  const classes = style()
  const [loaded, setLoaded] = useState(1)

  const username = (post.post_id !== uuidNil ? post.username : post.business_name)
  const avatar = (post.post_id !== uuidNil ? post.profile_avatar : post.business_avatar)

  return (
    <div
      style={{
        borderStyle: isSelected ? 'inset' : 'hidden'
      }}
      onClick={(e) => {
        e.preventDefault()
        onClick()
      }}
      className={classes.nearcastCard}
    >
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          borderRadius: '12px',
          zIndex: loaded === 1 ? '1' : '-1'
        }}
        className="shimmer"
      />
      {loaded !== 0 && (
        <img
          src={`${post.post_image_token}`}
          alt="post"
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            borderRadius: '12px',
            objectFit: 'cover'
          }}
          onLoad={() => {
            setLoaded(2)
          }}
          onError={() => {
            setLoaded(0)
          }}
        />
      )}
      {onPlay && (
        <div
          style={{
            alignSelf: 'center',
            justifySelf: 'center',
            marginBlock: 'auto'
          }}
        >
          <IconButton onClick={onPlay} size="large">
            <PlayArrowRounded htmlColor="#FFFFFF" style={{ fontSize: 56 }} />
          </IconButton>
        </div>
      )}
      <div className={'hoverDiv ' + classes.hoverDiv}>
        <div
          style={{
            display: 'flex',
            height: '100%',
            width: '100%',
            flexDirection: 'column'
          }}
        >
          <div style={{ width: '100%', marginBlock: 'auto' }}>
            <div className={classes.userDetailsContainer}>
              <div>
                <Avatar
                  aria-controls="user-controls"
                  alt={username}
                  className={classes.userPic}
                  src={avatar}
                />
              </div>
              <div className={classes.userDetails}>
                <div className={classes.userDetailsName}>{username}</div>
                <div style={{ fontSize: '12px' }}>
                  {post.post_caption.substring(0, 48)}
                  {post.post_caption.length > 48 ? '...' : ''}
                </div>
              </div>
            </div>
            {post.post_id !== uuidNil && (
              <div className={classes.postDetails}>
                <div className={classes.postDetailItem}>
                  <Favorite />
                  <div style={{ marginLeft: '10px' }}>{post.likes_count}</div>
                </div>
                <div className={classes.postDetailItem}>
                  <Chat />
                  <div style={{ marginLeft: '10px' }}>{post.comments_count}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const style = makeStyles(({ breakpoints }) => ({
  nearcastCard: {
    position: 'relative',
    backgroundColor: '#F2F2F2',
    height: '350px',
    width: '235px',
    borderRadius: '12px',
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    borderWidth: '2px',
    borderColor: 'green',
    cursor: 'pointer',
    [breakpoints.down('sm')]: {
      height: '247px',
      width: '140px',
      borderRadius: '12px'
    },
    '&:hover .hoverDiv': {
      display: 'block'
    }
  },
  hoverDiv: {
    display: 'none',
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    borderRadius: '12px',
    backgroundColor: '#00000082',
    color: 'white'
  },
  userDetailsContainer: {
    marginTop: 'auto',
    marginBottom: '15px',
    marginInline: '15px',
    display: 'flex',
    [breakpoints.down('sm')]: {
      marginBottom: '5px',
      marginInline: '5px'
    }
  },
  userDetails: {
    paddingTop: '2px',
    marginLeft: '10px'
  },
  userDetailsName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'hidden',
    fontWeight: 'bold',
    fontSize: '18px',
    [breakpoints.down('sm')]: {
      width: '60px',
      fontSize: '12px'
    }
  },
  userPic: {
    width: '30px',
    height: '30px'
  },
  postDetails: {
    display: 'flex',
    marginTop: '5px',
    justifyContent: 'space-evenly',
    fontSize: '18px',
    [breakpoints.down('sm')]: {
      fontSize: '12px'
    }
  },
  postDetailItem: {
    marginTop: '10px',
    display: 'flex',
    alignItems: 'center'
  }
}))

NearcastCardUser.defaultProps = {
  onClick: () => {}
}

export default NearcastCardUser
