import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { useState, useContext } from 'react'
import PostView from '../Post/PostView'
import NearcastCard from 'ui/Cards/NearcastCardUser'
import { NIL as uuidNil } from 'uuid'

// importing context
import { UserContext } from '../../context/UserContext'

// importing helpers
import { recordGaEvent } from 'helpers/ga'

// importing redux helpers
import { useAppDispatch } from '../../utils/redux/store'
import { refreshPostRedux } from '../../utils/redux/actions/postActions'

const style = makeStyles(({ breakpoints, palette }) => {
  return {
    videoContainer: {
      display: 'flex',
      position: 'relative',
      paddingTop: '16px',
      marginBottom: '16px',
      width: '100%',
      [breakpoints.down('sm')]: {
        paddingInline: '0px'
      }
    },
    videosGrid: {
      [breakpoints.down('sm')]: {
        justifyContent: 'center'
      }
    },
    placeholderVideoCard: {
      height: '350px',
      width: '235px',
      [breakpoints.down('sm')]: {
        height: '247px',
        width: '140px',
        borderRadius: '12px'
      }
    }
  }
})

// localRefresher is used to update posts in profile screen, where the users posts aren't coming from redux
// but are loaded in component state
function NearcastGrid ({ videos, loading, emptyMessage, playable, observer, localRefresher = () => {} }) {
  // redux app dispatcher
  const dispatch = useAppDispatch()
  // user context
  const { personal, jwt_token, active_neighbourhood } = useContext(UserContext)
  const [playing, setPlaying] = useState(-1)
  const classes = style()

  // is loading is true don't render the main video array
  if (loading) {
    return (
      <div className={classes.videoContainer}>
        <Grid container spacing={2} className={classes.videosGrid}>
          {Array(5)
            .fill('1')
            .map((_, i) => (
              <Grid item key={`homeVideo${i}`}>
                <div
                  className={`${classes.placeholderVideoCard} shimmer`}
                  style={{
                    backgroundColor: '#F2F2F2',
                    borderRadius: '12px',
                    backgroundSize: 'cover',
                    display: 'flex',
                    flexDirection: 'column',
                    borderWidth: '2px',
                    borderColor: 'green'
                  }}
                ></div>
              </Grid>
            ))}
        </Grid>
      </div>
    )
  }
  return (
    <div className={classes.videoContainer}>
      <Grid container spacing={2} className={classes.videosGrid}>
        {videos !== null && videos.length !== 0
          ? videos.map((post, index) =>
            videos.length - 1 === index
              ? (
                <Grid item key={`homeVideo${post.post_playback_id}`} ref={observer}>
                  <NearcastCard
                    post={post}
                    onClick={() => {
                      setPlaying(index)

                      // analytics
                      recordGaEvent('nearcastGrid-nearcast-clicked', {
                        personalAccountId: personal[active_neighbourhood].ID,
                        nearcast: post
                      })
                    }}
                  />
                </Grid>
                )
              : (
                <Grid item key={`homeVideo${post.post_playback_id}`}>
                  <NearcastCard
                    post={post}
                    onClick={() => {
                      setPlaying(index)

                      // analytics
                      recordGaEvent('nearcastGrid-nearcast-clicked', {
                        personalAccountId: personal[active_neighbourhood].ID,
                        nearcast: post
                      })
                    }}
                  />
                </Grid>
                )
          )
          : !loading &&
            (typeof emptyMessage === 'string'
              ? (
              <div
                style={{
                  width: '100%',
                  paddingTop: '20px',
                  placeSelf: 'center',
                  textAlign: 'center'
                }}
              >
                {emptyMessage}
              </div>
                )
              : (
                  emptyMessage
                ))}
      </Grid>
      {playing !== -1 && playable && (
        <PostView
          key={videos[playing].post_playback_id}
          index={playing}
          post={videos[playing]}
          open={playing !== -1}
          onClose={() => {
            setPlaying(-1)
            localRefresher() // for profile screen
            // only refresh post if it has post_id
            if (videos[playing].post_id !== uuidNil) {
              dispatch(refreshPostRedux(videos[playing], personal[active_neighbourhood].ID, jwt_token))
            }
          }}
          handleCycle={(dir) => {
            setPlaying((oldPlaying) => {
              const newPlaying = oldPlaying + dir
              if (newPlaying >= videos.length) {
                return 0
              }
              if (newPlaying <= -1) {
                return videos.length - 1
              } else {
                return newPlaying
              }
            })
          }}
          localRefresher={localRefresher} // for profile screen
        />
      )}
    </div>
  )
}

export default NearcastGrid
