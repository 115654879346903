import React, { useEffect, useState, useContext } from 'react'
import {
  Button,
  FormControl,
  MenuItem,
  MenuList
} from '@mui/material'
import { MapContainer, Polygon } from 'react-leaflet'
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete'
import { useSnackbar } from 'notistack'
import PriceFieldsInput from 'ui/TextInputs/PriceFieldsInput'
import StandardModal from 'ui/Modals/StandardModal'

// importing context
import { UserContext } from '../../context/UserContext'

// importing apis
import { getGeoJson } from 'api/get/geojson'
import { getLocation } from 'api/get/location'
import { userPersonalAccountAdd } from 'api/user/personalAccount'
import { personalAccount } from 'api/personal_account/index'

export default function AddNeighbourhood (props) {
  const [address, setAddress] = useState('')
  const [formError, setFormError] = useState(false)
  const [continueDisabled, setContinueDisabled] = useState(true)
  const [latlon, setLatlon] = useState({})
  const [neighborhood, setNeigh] = useState('')
  const [key, setKey] = useState(0)
  const [loading, setLoading] = useState(true)
  const [center, setCenter] = useState([39.8283, -98.5795])
  const [geoJSON, setGeoJSON] = useState([[[0, 0]]])
  const [map, setMap] = useState(false)
  const [token, setToken] = useState('')
  const [geoDetails, setGeoDetails] = useState({})
  const {
    jwt_token,
    personal,
    active_neighbourhood,
    login,
    getProfile,
    userProfiles
  } = useContext(UserContext)
  const { enqueueSnackbar } = useSnackbar()

  const getGeoData = async (id) => {
    getGeoJson(id)
      .then((res) => {
        let x = 0
        let y = 0
        const coor = [[[0, 0]]]
        for (let i = 0; i < res.data.coordinates[0][0].length; i++) {
          x = x + res.data.coordinates[0][0][i][0]
          y = y + res.data.coordinates[0][0][i][1]
          coor[0][0][i] = [
            res.data.coordinates[0][0][i][1],
            res.data.coordinates[0][0][i][0]
          ]
        }
        x = x / res.data.coordinates[0][0].length
        y = y / res.data.coordinates[0][0].length
        setGeoJSON(coor)
        setCenter([y, x])
        setKey((prev) => prev + 1)
        setMap(true)
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: 'error'
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getBoundary = async () => {
    setLoading(true)
    if (!latlon.lat || !latlon.lng) {
      setLoading(false)
      return
    }
    getLocation(latlon.lng, latlon.lat)
      .then((resData) => {
        setGeoDetails(resData.data[0])
        setNeigh(resData.data[0].neighbourhood)
        getGeoData(resData.data[0].obj_id)
      })
      .catch((err) => {
        let errorMsg = err.message
        if (err.response.data.msg) {
          errorMsg = err.response.data.err
        }
        enqueueSnackbar(errorMsg, {
          variant: 'error'
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const selectPlace = (add, pId, sugg) => {
    setAddress(add)
    setContinueDisabled(false)
    geocodeByAddress(add)
      .then((results) => {
        return getLatLng(results[0])
      })
      .then((latLng) => {
        setLatlon(latLng)
      })
      .catch((error) => console.error('Error', error))
  }

  useEffect(() => {
    setMap(true)
    return () => {
      setMap(false)
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (token !== '') {
      setLoading(false)
    }
  }, [token])

  useEffect(() => {
    if (latlon) {
      getBoundary()
    }
    // eslint-disable-next-line
  }, [latlon]);

  const getAllProperUserDetails = async (dataBody) => {
    /**
     * We retrieve personal account details again
     * because the personal_accounts key in login response has a lot
     * of data which is missing, primarily the Neighborhood details
     * which are required in home page right after login
     */
    return new Promise((resolve, reject) => {
      let p = []
      personalAccount(
        personal[active_neighbourhood].ID,
        jwt_token
      )
        .then((resData) => {
          p = resData.pa
          return Promise.all(
            resData.pa.map(
              async (account, _) =>
                await getProfile(account.ID, jwt_token)
            )
          )
        })
        .then((userProfiles) => resolve({
          pa: p,
          userProfiles
        }))
        .catch((err) => reject(err))
    })
  }

  const handleSubmit = () => {
    userPersonalAccountAdd(
      {
        address: geoDetails.neighbourhood_slug,
        city: geoDetails.city_slug,
        state: geoDetails.state_slug,
        country: geoDetails.country_slug,
        lat: latlon.lat,
        lon: latlon.lng
      },
      personal[active_neighbourhood].ID,
      jwt_token
    )
      .then(async (resData) => {
        const properUserDetails = await getAllProperUserDetails(resData)
        login({
          jwt_token: resData.body.jwtToken,
          ref_token: resData.body.refreshToken,
          accounts: properUserDetails.pa,
          user: resData.body.user,
          userProfiles: properUserDetails.userProfiles
        })
        props.onClose()
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: 'error'
        })
      })
  }

  return (
    <StandardModal
      isOpen={props.open}
      onClose={props.onClose}
      title="Add Neighbourhood"
    >
      <PlacesAutocomplete
        value={address}
        onChange={(e) => {
          setAddress(e)
          setFormError(false)
        }}
        onSelect={selectPlace}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <FormControl fullWidth>
            <PriceFieldsInput
              label="Address*"
              id="street"
              variant="outlined"
              value={address}
              style={{
                border: formError
                  ? '2px solid #FD2821'
                  : '2px solid transparent',
                borderRadius: '10px'
              }}
              size="small"
              inputProps={{
                ...getInputProps({
                  placeholder: neighborhood || 'Search Places ...',
                  className: 'location-search-input'
                })
              }}
            />
            {loading || suggestions.length > 0 ? (
              <div>
                <MenuList
                  style={{
                    position: 'absolute',
                    zIndex: 1000002,
                    maxHeight: 100,
                    width: '94%',
                    background: 'white',
                    border: '1px solid #d3d3d3',
                    borderRadius: 3,
                    overflow: 'auto'
                  }}
                >
                  {loading && <MenuItem value="">Loading...</MenuItem>}
                  {suggestions.map((suggestion) => {
                    // inline style for demonstration purpose
                    const style = {
                      backgroundColor: suggestion.active
                        ? '#efefef'
                        : '#ffffff',
                      cursor: 'pointer',
                      padding: '2px 12px',
                      fontSize: '15px',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      display: 'list-item'
                    }
                    return (
                      <MenuItem key={suggestion.placeId}
                        {...getSuggestionItemProps(suggestion, {
                          style
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </MenuItem>
                    )
                  })}
                </MenuList>
              </div>
            ) : (
              <></>
            )}
          </FormControl>
        )}
      </PlacesAutocomplete>
      <div
        style={{
          width: '100%',
          height: 200,
          maxWidth: '100%',
          margin: 'auto',
          marginTop: '16px',
          opacity: loading ? 0.5 : 1,
          cursor: loading ? 'pointer' : 'grab'
        }}
        onClick={(e) => {
          if (loading) e.stopPropagation()
        }}
      >
        <MapContainer center={center} zoom={13} key={key}>
          <ReactLeafletGoogleLayer apiKey="AIzaSyD65D3CME73uC7YqwJSRNkyZJ_eXLlSE1Y" />
          <Polygon positions={geoJSON} />
        </MapContainer>
      </div>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        style={{
          paddingInline: '40px',
          marginTop: '10px',
          width: '100%'
        }}
        disabled={loading || continueDisabled}
        onClick={handleSubmit}
      >
        Continue
      </Button>
    </StandardModal>
  )
}
