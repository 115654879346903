import axios from 'axios'

/**
 * @function groupMy Used to get all the user's groups
 * @param {string} nrctUID - user's current neighborhood id
 * @param {string} jwt - user's jwt token
 * @returns {Promise} Promise object represents array of user's groups
 */
export const groupMy = (nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/group/my`, {
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(res.data.groups))
      .catch((err) => reject(err))
  })
}
