import VideoIcon from '../FeatureIcons/videos-icon.svg'
import ShareIcon from '../FeatureIcons/share-icon.svg'
import PersonIcon from '../FeatureIcons/person-icon.svg'
import SellIcon from '../FeatureIcons/neighbours-icon.svg'
import GraphIcon from '../FeatureIcons/graph-icon.svg'
import StarIcon from '../FeatureIcons/star-icon.svg'

const features = [
  {
    iconSrc: ShareIcon,
    head: 'Share local news and information with residents.',
    headHighlight: '',
    description:
			'Simulcast content from your city channel across your city on Nearcast and keep residents involved and engaged in city happenings.'
  },
  {
    iconSrc: VideoIcon,
    head: 'Host community town halls.',
    headHighlight: '',
    description:
			'Allow your residents to tune in and interact directly with city government  on key issues affecting your city.'
  },
  {
    iconSrc: StarIcon,
    head: 'Highlight what makes your city amazing.',
    headHighlight: '',
    description:
			'Embed short-videos created by residents onto the  city website and social media.'
  },
  // {
  //   iconSrc: SellIcon,
  //   head: 'Fund public services and community art.',
  //   headHighlight: '',
  //   description:
	// 		'By partnering with Nearcast, your city will collect mining revenue from Nearcoin transactions happening between residents and local businesses in your city.'
  // },
  {
    iconSrc: PersonIcon,
    head: 'Recognize amazing residents.',
    headHighlight: '',
    description:
			'Share videos of award ceremonies recognizing outstanding residents and give them badges to display on their Nearcast profiles.'
  },
  {
    iconSrc: GraphIcon,
    head: 'Increase community engagement.',
    headHighlight: '',
    description:
			'Start a citywide competition to enable residents to engage in a community activity such as  a citywide dance contest, a citywide art contest, or a city wide neigbhorhood music concert.'
  }
]

export default features
