import React, { useEffect, useState } from 'react'
import styles from './FeaturesStyle'
import { Button, Fade, Grid, Typography, Hidden } from '@mui/material'
import FeatureCard from './FeatureCard'
import { Controller, Scene } from 'react-scrollmagic'

function Features ({
  features,
  featureBanners,
  scrollEnabled = true,
  title,
  onActionClick
}) {
  const classes = styles()
  const [activeCard, setActiveCard] = useState(0)
  const [showBtn, setShowBtn] = useState(false)
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    // Calcute the active card based on the scroll progress
    const steps = features.length + 1
    const perCard = Math.round(100 / steps)
    const cardNum = Math.round((progress * 100) / perCard)

    // Min because last card should be active when on the show btn step
    setActiveCard(Math.min(cardNum, features.length - 1))

    if (cardNum === features.length) {
      setShowBtn(true)
    }
  }, [progress, setShowBtn, features])

  return (
		<div>
			<Controller>
				<Scene
					duration={scrollEnabled ? 3000 : 0}
					pin
					triggerHook={0}
					enabled={scrollEnabled}
				>
					{(_progress) => {
					  setProgress(_progress)

					  return (
							<div className={classes.root}>
								{title && (
									<Typography
										variant="h2"
										color="primary"
										style={{
										  textAlign: 'center',
										  marginBottom: 20
										}}
									>
										{title}
									</Typography>
								)}
								<Grid
									container
									spacing={3}
									className={classes.featureListContainer}
								>
									<Grid item xs={12} md={6}>
										{features.map((feature, i) => (
											<FeatureCard
												key={i}
												iconSrc={feature.iconSrc}
												active={
													i === activeCard ||
													!scrollEnabled
												}
												onClick={() => {
												  if (scrollEnabled) { setActiveCard(i) }
												}}
											>
												<div
													className={
														classes.featureText
													}
												>
													{feature.head}{' '}
													<span
														className={
															classes.highlight
														}
													>
														{feature.headHighlight}
													</span>
												</div>
												<div
													className={
														classes.featureDesc
													}
												>
													{feature.description}
												</div>
											</FeatureCard>
										))}
									</Grid>
									<Hidden smDown>
										<Grid
											item
											xs={12}
											md={6}
											className={classes.bannerContainer}
										>
											<img
												src={featureBanners[activeCard]}
												alt=""
												className={classes.bannerImg}
											/>
										</Grid>
									</Hidden>
									<div className={classes.getStartedContainer}>
										<Fade in={showBtn || !scrollEnabled}>
											<Button
												variant="contained"
												color="secondary"
												style={{
												  width: '70%',
												  maxWidth: '350px',
												  fontWeight: 600,
												  fontSize: '1.2rem'
												}}
												onClick={() => {
												  onActionClick()
												}}
											>
												Get Started
											</Button>
										</Fade>
									</div>
								</Grid>

							</div>
					  )
					}}
				</Scene>
			</Controller>
		</div>
  )
}

export default Features
