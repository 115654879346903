import { IconButton, TextField, useTheme, Stack, Box } from '@mui/material'
import { CallEnd, FilterNone, InfoOutlined } from '@mui/icons-material'
import React from 'react'
import Nearcast from 'ui/Icons/Nearcast'

function GatheringHeader ({
  name,
  link,
  media,
  setInfo,
  setCopyFailed,
  leave,
  setCopySuccess,
  record
}) {
  const { palette } = useTheme()
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      flexWrap="wrap"
      sx={{
        background: '#FFF',
        color: '#0A0A0A',
        width: '100%',
        height: {
          xs: 40,
          md: 64
        },
        padding: {
          xs: '0px 10px',
          md: '0px 30px'
        }
      }}
    >
      <Stack
        direction={{
          xs: 'column',
          md: 'row'
        }}
        alignItems="center"
        justifyContent="center"
      >
        <Box
          sx={{
            height: {
              xs: '20px',
              md: '35px'
            },
            marginRight: {
              xs: 0,
              md: '16px'
            }
          }}
        >
          <Nearcast
            style={{ display: 'block', height: '100%' }}
            htmlColor={palette.primary.main}
          />
        </Box>
        <Box
          sx={{
            fontSize: '1.3rem',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            width: 'auto',
            display: {
              xs: 'none',
              md: 'flex'
            }
          }}
        >
          {name}
        </Box>
      </Stack>
      <IconButton
        size="small"
        style={{ height: 30 }}
        onClick={() => {
          setInfo(true)
        }}
      >
        <InfoOutlined color="primary" />
      </IconButton>
      <div
        style={{
          height: 30,
          display: 'flex',
          alignItems: 'center',
          marginLeft: 5
        }}
      >
        {/* <div
          style={{
            padding: '4px 10px',
            borderRadius: 4,
            background: record ? palette.error.main : palette.primary.main,
            color: 'white',
            fontSize: 14,
            lineHeight: 1
          }}
        >
          &#x25CF; {record ? 'Rec' : 'Live'}
        </div> */}
      </div>
      {media.small
        ? (
        <></>
          )
        : (
            <TextField
              size="small"
              value={link}
              disabled={true}
              style={{
                marginLeft: 'auto'
              }}
              InputProps={{
                style: {
                  background: '#c4c4c44c',
                  width: 240,
                  borderRadius: 5,
                  color: 'black',
                  fontSize: 14
                },
                endAdornment: (
                  <IconButton
                    size="small"
                    style={{ marginLeft: 5 }}
                    onClick={() => {
                      if (window.navigator.clipboard) {
                        window.navigator.clipboard
                          .writeText(link)
                          .then(() => {
                            setCopySuccess()
                          })
                          .catch(() => {
                            setCopyFailed()
                          })
                      }
                    }}
                  >
                    <FilterNone style={{ fontSize: 16 }} />
                  </IconButton>
                )
              }}
            />
          )}
      <IconButton
        onClick={() => leave(true)}
        sx={{
          backgroundColor: '#FF2821',
          color: '#FFFFFF',
          borderRadius: '24px',
          width: '60px',
          marginLeft: media.small ? 'auto' : '10px',
          height: {
            xs: '90%',
            md: '40px'
          }
        }}
      >
        <CallEnd />
      </IconButton>
    </Stack>
  )
}

export default GatheringHeader
