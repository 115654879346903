import React, { useContext, useState, useEffect } from 'react'
import {
  Typography,
  Avatar,
  FormControl,
  Select,
  MenuItem,
  Stack,
  ListItemButton,
  ListItemIcon,
  List,
  Collapse,
  ListItemText
} from '@mui/material'
import {
  ExpandLess,
  ExpandMore,
  AccountCircle,
  Settings,
  Help,
  ForumRounded
} from '@mui/icons-material'
import makeStyles from '@mui/styles/makeStyles'
import { useNavigate } from 'react-router-dom'
import { MapContainer, Polygon } from 'react-leaflet'
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer'
import 'leaflet/dist/leaflet.css'

// importing components
import { UserContext } from '../../context/UserContext'
// import VerifyLocationModal from "../Modals/VerifyLocationModal";
import SuggestFeature from '../Modals/Support/SuggestFeature'
import ReportBug from '../Modals/Support/ReportBug'
import NhDirectory from '../Modals/NhDirectory/NhDirectory'
import UpdatePhoneNumber from '../Modals/UpdatePhoneNumber'

// importing ui
import StandardModal from 'ui/Modals/StandardModal'

// importing api
import { personalAccountAvatar } from 'api/personal_account/avatar'
import { getGeoJson } from 'api/get/geojson'

const SettingsListButton = ({
  type, // list or item
  title,
  buttonProps,
  buttonStartIcon,
  isOpen
}) => {
  const renderRightDropIcon = () => {
    if (type === 'item') {
      return null
    }

    return isOpen ? <ExpandLess sx={(theme) => ({ color: theme.palette.primary.main })} /> : <ExpandMore sx={(theme) => ({ color: theme.palette.primary.main })} />
  }
  return (
    <ListItemButton {...buttonProps} sx={{ pl: type === 'item' ? 4 : 0 }}>
      {buttonStartIcon && (
        <ListItemIcon style={{ minWidth: 'auto', marginRight: 8 }}>
          {buttonStartIcon}
        </ListItemIcon>
      )}
      <ListItemText
        sx={(theme) => ({
          fontWeight: '800',
          color: type === 'item' ? theme.palette.primary.main : 'black'
        })}
        primary={title}
      />
      {renderRightDropIcon()}
    </ListItemButton>
  )
}

const UserSettingsModal = ({ profileData, ...props }) => {
  const classes = styles()
  const navigate = useNavigate()
  // const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false);
  const {
    personal,
    userProfiles,
    active_neighbourhood,
    switchNeighbourHood,
    logout
  } = useContext(UserContext)
  const userProfile = userProfiles[active_neighbourhood]
  const neighborhoodId = personal[active_neighbourhood].neighbourhood.obj_id

  const [center, setCenter] = useState([39.8283, -98.5795])
  const [geoJSON, setGeoJSON] = useState([[[0, 0]]])
  const [openWhichList, setOpenWhichList] = useState(null)

  const [featureSuggestOpen, setFeatureSuggestOpen] = useState(false)
  const [nhDirpen, setNhDirOpen] = useState(false)
  const [isUpdatePhoneOpen, setIsUpdatePhoneOpen] = useState(false)
  const [isReportBugOpen, setReportBugOpen] = useState(false)

  const getGeoData = async (id) => {
    getGeoJson(id)
      .then((res) => {
        let x = 0
        let y = 0
        const coor = [[[0, 0]]]
        for (let i = 0; i < res.data.coordinates[0][0].length; i++) {
          x = x + res.data.coordinates[0][0][i][0]
          y = y + res.data.coordinates[0][0][i][1]
          coor[0][0][i] = [
            res.data.coordinates[0][0][i][1],
            res.data.coordinates[0][0][i][0]
          ]
        }
        x = x / res.data.coordinates[0][0].length
        y = y / res.data.coordinates[0][0].length
        setGeoJSON(coor)
        setCenter([y, x])
      })
      .catch((err) => {
        console.log(err.message)
      })
  }

  useEffect(() => {
    getGeoData(neighborhoodId)
  }, [neighborhoodId])

  return (
    <StandardModal
      title="User Settings"
      isOpen={props.open}
      onClose={props.onClose}
    >
      <div className={classes.root}>
        <div className={classes.profileContainer}>
          <Avatar
            aria-controls="user-controls"
            alt="userIcon"
            className={classes.profilePic}
            src={personalAccountAvatar(personal[active_neighbourhood].ID)}
            style={{
              borderWidth: 2,
              borderColor: 'black',
              borderStyle: 'solid'
            }}
          />
          <Typography variant="h4" sx={{ marginTop: 2 }}>
            {`${userProfile.first_name} ${userProfile.last_name}`}
          </Typography>
          <Typography variant="body2">
            {userProfile.email}
          </Typography>
          <Typography
            variant="caption"
            component="div"
            sx={{
              marginTop: 2,
              cursor: 'pointer'
            }}
            onClick={props.openEditProfile}
          >
            Edit
          </Typography>
        </div>
        {/* Commented because all users are verified by default */}
        {/* {!personal[active_neighbourhood].isVerified && (
          <div className={classes.verifyLocationAlert}>
            <Typography variant="h6">Verify your location</Typography>
            <Typography variant="body1" style={{ marginTop: "8px" }}>
              Verification allows you to privately share nearcasts and have
              gatherings with just your neighbors
            </Typography>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              style={{ marginTop: "24px" }}
              onClick={() => {
                setIsVerificationModalOpen(true);
              }}
            >
              Verify now
            </Button>
          </div>
        )} */}
        <Stack direction="column">
          <FormControl fullWidth margin="normal">
            <Typography variant="h6">
              Your neighborhoods
            </Typography>
            <Select
              value={active_neighbourhood}
              size="small"
            >
              {personal.map((e, i) => (
                <MenuItem
                  key={personal[i].ID}
                  value={i}
                  onClick={() => {
                    switchNeighbourHood(i)
                    navigate(`/u/${personal[i].ID}`)
                  }}
                >
                  {e.neighbourhood.neighbourhood} {e.is_business && '( Business )'}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <MapContainer
            center={center}
            zoom={13}
            style={{ height: 200 }}
          >
            <ReactLeafletGoogleLayer apiKey="AIzaSyD65D3CME73uC7YqwJSRNkyZJ_eXLlSE1Y" />
            <Polygon positions={geoJSON} />
          </MapContainer>
        </Stack>
        <Stack direction="column" sx={{ marginTop: 4 }}>
          <SettingsListButton
            type="list"
            title="Neighborhood"
            buttonProps={{
              onClick: () => openWhichList === 'neighborhood' ? setOpenWhichList(null) : setOpenWhichList('neighborhood')
            }}
            buttonStartIcon={<Settings sx={(theme) => ({ color: theme.palette.primary.main })} />}
            isOpen={openWhichList === 'neighborhood'}
          />
          <Collapse in={openWhichList === 'neighborhood'} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <SettingsListButton
                type="item"
                title="Neighborhood Directory"
                buttonProps={{
                  onClick: () => setNhDirOpen(true)
                }}
              />
            </List>
          </Collapse>
        </Stack>
        <Stack direction="column" sx={{ marginTop: 1 }}>
          <SettingsListButton
            type="list"
            title="Support"
            buttonProps={{
              onClick: () => openWhichList === 'support' ? setOpenWhichList(null) : setOpenWhichList('support')
            }}
            buttonStartIcon={<ForumRounded sx={(theme) => ({ color: theme.palette.primary.main })} />}
            isOpen={openWhichList === 'support'}
          />
          <Collapse in={openWhichList === 'support'} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <SettingsListButton
                type="item"
                title="Suggest a Feature"
                buttonProps={{
                  onClick: () => setFeatureSuggestOpen(true)
                }}
              />
              <SettingsListButton
                type="item"
                title="Report a Bug"
                buttonProps={{
                  onClick: () => setReportBugOpen(true)
                }}
              />
            </List>
          </Collapse>
        </Stack>
        <Stack direction="column" sx={{ marginTop: 1 }}>
          <SettingsListButton
            type="list"
            title="Account"
            buttonProps={{
              onClick: () => openWhichList === 'account' ? setOpenWhichList(null) : setOpenWhichList('account')
            }}
            buttonStartIcon={<AccountCircle sx={(theme) => ({ color: theme.palette.primary.main })} />}
            isOpen={openWhichList === 'account'}
          />
          <Collapse in={openWhichList === 'account'} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <SettingsListButton
                type="item"
                title="Change Phone Number"
                buttonProps={{
                  onClick: () => setIsUpdatePhoneOpen(true)
                }}
              />
              <SettingsListButton
                type="item"
                title="Logout"
                buttonProps={{
                  onClick: () => {
                    logout()
                    navigate('/')
                  }
                }}
              />
            </List>
          </Collapse>
        </Stack>
        <Stack direction="column" sx={{ marginTop: 1 }}>
          <SettingsListButton
            type="list"
            title="About"
            buttonProps={{
              onClick: () => openWhichList === 'about' ? setOpenWhichList(null) : setOpenWhichList('about')
            }}
            buttonStartIcon={<Help sx={(theme) => ({ color: theme.palette.primary.main })} />}
            isOpen={openWhichList === 'about'}
          />
          <Collapse in={openWhichList === 'about'} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <SettingsListButton
                type="item"
                title="Community Guidelines"
                buttonProps={{
                  onClick: () => navigate('/community-guidelines')
                }}
              />
              <SettingsListButton
                type="item"
                title="Terms & Conditions"
                buttonProps={{
                  onClick: () => navigate('/terms')
                }}
              />
              <SettingsListButton
                type="item"
                title="Privacy Policy"
                buttonProps={{
                  onClick: () => navigate('/privacy')
                }}
              />
              <SettingsListButton
                type="item"
                title="Copyright Policy"
                buttonProps={{
                  onClick: () => navigate('/content-policy')
                }}
              />
              {/* <SettingsListButton
                type="item"
                title="Join Nearcast Testers"
                buttonProps={{
                  onClick: () => {}
                }}
              /> */}
            </List>
          </Collapse>
        </Stack>
      </div>
      {/* commented out because all users are verified by default */}
      {/* <VerifyLocationModal
        open={isVerificationModalOpen}
        onClose={() => setIsVerificationModalOpen(false)}
        neighbourhood={userProfiles[active_neighbourhood].userLocation}
        prompt
      /> */}

      {featureSuggestOpen && (
        <SuggestFeature isOpen={featureSuggestOpen} onClose={() => setFeatureSuggestOpen(false)}/>
      )}
      <NhDirectory isOpen={nhDirpen} onClose={() => setNhDirOpen(false)}/>
      <UpdatePhoneNumber
        isOpen={isUpdatePhoneOpen}
        onClose={() => setIsUpdatePhoneOpen(false)}
      />
      <ReportBug isOpen={isReportBugOpen} onClose={() => setReportBugOpen(false)} />
    </StandardModal>
  )
}

const styles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  profileContainer: {
    width: '100%',
    paddingTop: '10px',
    textAlign: 'center'
  },
  profilePic: {
    width: '120px',
    height: '120px',
    marginInline: 'auto',
    marginBottom: '8px'
  },
  verifyLocationAlert: {
    backgroundColor: '#f0f0f0',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    borderRadius: '8px',
    marginTop: '10px'
  }
}))

export default UserSettingsModal
