import * as React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Header from '../../components/Header/Header'
import ActivityMenu from '../../components/Header/ActivityMenu'

const ActivityMobile = () => {
  const classnames = classes()

  return (
        <>
            <Header />
            <div className={classnames.activityWrapper}>
                <div className={classnames.activityWrapperInner}>
                    <ActivityMenu
                        isForMobile={true}
                    />
                </div>
            </div>
        </>
  )
}

const classes = makeStyles(({ breakpoints }) => ({
  activityWrapper: {
    flex: 1,
    display: 'flex',
    height: 'calc(100vh - 70px)',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    paddingLeft: 10,
    paddingRight: 10
  },
  activityWrapperInner: {
    width: '100%',
    height: '100%',
    paddingRight: 20,
    overflowY: 'scroll',
    paddingTop: 10
  }
}))

export default ActivityMobile
