import React, { useContext, useEffect, useState } from 'react'
import { CircularProgress, Grid, Snackbar, Typography, Alert as MuiAlert } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useSnackbar } from 'notistack'
import ImageCoverCard from 'ui/Cards/ImageCoverCard'
import { Delete, ErrorRounded, Share } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

// importing context
import { UserContext } from '../../../context/UserContext'
import { GlobalFuncContext } from '../../../context/GlobalFuncHOC'

// importing components
import ActionModal from '../../Modals/ActionModal'
import GatheringEmptyState from '../../EmptyStates/GatheringGroupEmptyState'
import cookIcon from '../../../assets/Fill.svg'
import castIcon from '../../../assets/nearcast.svg'
import nogroup from '../../../assets/nogroup.webp'

// importing apis
import { groupDeleteById } from 'api/group/id'
import { groupMy } from 'api/group/my'
import { groupCover } from 'api/group/cover'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

function MyGroups(props) {
  const [groups, setGroups] = useState([])
  const [loading, setLoading] = useState(true)
  const [copied, setCopied] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState()
  // const [leaveOpen, setLeaveOpen] = useState()
  const { personal, jwt_token, active_neighbourhood } = useContext(UserContext)
  const { openShareModal } = useContext(GlobalFuncContext)
  const classes = styles()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const deleteGroupApi = (id) => {
    groupDeleteById(id, personal[active_neighbourhood].ID, jwt_token)
      .then(() => fetchMyGroupsApi())
      .catch((err) => enqueueSnackbar(err.message, { variant: 'error' }))
  }

  // const leaveGroupsApi = (id) => {}

  const fetchMyGroupsApi = () => {
    groupMy(personal[active_neighbourhood].ID, jwt_token)
      .then(async (dataGroups) => {
        if (dataGroups === null) {
          return
        }
        const groups = await Promise.all(
          dataGroups.map(async (e, i) => {
            return { ...e, users_count: e.total_members, cover_url: '' }
          })
        )
        setGroups(groups)
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: 'error'
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    setLoading(true)
    fetchMyGroupsApi()
    // eslint-disable-next-line
  }, [active_neighbourhood]);

  return (
    <Grid container spacing={2} className={`${classes.gatheringsGrid}`}>
      {loading
        ? (
          <div
            style={{
              display: 'flex',
              height: '70vh',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              background: '#FFFFFF'
            }}
          >
            <CircularProgress />
          </div>
        )
        : groups && groups.length > 0
          ? (
            groups.map((item, index) => {
              return (
                <Grid item key={item.id}>
                  <ImageCoverCard
                    imgUrl={groupCover(item.id)}
                    title={item.group_name}
                    subTitle={`${item.users_count} Member(s)`}
                    onPress={() => navigate(`/g/${item.id}`)}
                    menuItems={[
                      {
                        title: 'Share',
                        color: 'ghost',
                        onPress: () => openShareModal(
                          'Group',
                          'Check out this amazing community at Nearcast',
                          `/g/${item.id}`
                        ),
                        icon: () => <Share color="ghost" />
                      },
                      {
                        title: 'Delete',
                        color: 'error',
                        onPress: () => setDeleteOpen(item),
                        icon: () => <Delete color="error" />
                      }
                    ]}
                  />
                  <Snackbar
                    open={copied}
                    autoHideDuration={1000}
                    onClose={() => {
                      setCopied(false)
                    }}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  >
                    <Alert severity="success">Link copied to clipboard</Alert>
                  </Snackbar>
                </Grid>
              )
            })
          )
          : (
            <GatheringEmptyState
              mainImage={require('../../../assets/nogroup.webp')}
              emptyMessage={
                <div style={{ textAlign: 'center', marginTop: '16px' }}>
                  <Typography variant="h3">
                    Create a group around a topic of your choice 🍔🎵💃
                  </Typography>
                  <Typography style={{ fontSize: '15px', marginTop: '10px' }}>
                    You can share messages, create Nearcasts, announce updates, or livestream a party to stay in touch with your family, friends or coworkers.
                  </Typography>
                </div>
              }
              listItems={[
                { icon: cookIcon, text: 'Create a neighbourhood Chefs group' },
                { icon: castIcon, text: 'Announce a group trek' }
              ]}
            />
          )}
      <ActionModal
        open={Boolean(deleteOpen)}
        onClose={() => setDeleteOpen(false)}
        onConfirm={() => {
          deleteGroupApi(deleteOpen.id)
          setDeleteOpen(false)
        }}
        onCancel={() => {
          setDeleteOpen(false)
        }}
        action={{
          title: 'Do you want to delete this group',
          Accept: 'Yes',
          Decline: 'No'
        }}
      >
        <Delete style={{ fontSize: '2.5rem' }} color="error" />
      </ActionModal>
      {/* <ActionModal
        open={Boolean(leaveOpen)}
        onClose={() => setLeaveOpen(false)}
        onConfirm={() => {
          leaveGroupsApi(leaveOpen.id)
          setLeaveOpen(false)
        }}
        onCancel={() => {
          setLeaveOpen(false)
        }}
        action={{
          title: `Are you sure you want to leave ${
            leaveOpen ? leaveOpen.group_name : ''
          }`,
          Accept: 'Yes',
          Decline: 'No'
        }}
      >
        <ErrorRounded style={{ fontSize: '2.5rem' }} color="error" />
      </ActionModal> */}
    </Grid>
  )
}

const styles = makeStyles(({ breakpoints }) => ({
  mainContainer: {
    [breakpoints.down('sm')]: {
      marginInline: '5px'
    }
  },
  gatheringsGrid: {
    marginBottom: '40px',
    [breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  }
}))

export default MyGroups
