import axios from 'axios'

/**
 * @function neighborhoodDirGet Used to fetch user's personal account details
 * @param {string} nrctUID - personal[active_neighbourhood].ID from user context
 * @param {string} jwt - jwt from user context
 * @returns {Promise} Promise object representing the user's personal account details
 */
export const neighborhoodDirGet = (nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/personal_account/neighbourhood_directory`, {
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(res.data))
      .catch((err) => {
        reject(err)
      })
  })
}
