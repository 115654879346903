export const NSON2JSON = (str) => {
  const arr = str.split('')
  const obj = {}
  let key = ''
  let temp = ''
  let ignore = false
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] !== '' && arr[i] !== '&' && arr[i] !== ':' && arr[i] !== '\\') {
      temp += arr[i]
    } else if (ignore && (arr[i] === '&' || arr[i] === ':')) {
      temp += arr[i]
      ignore = false
    } else if (arr[i] === '\\') {
      ignore = true
    } else if (arr[i] === ':') {
      key = temp
      temp = ''
    } else if (arr[i] === '&') {
      obj[key] = temp
      temp = ''
    }
  }
  obj[key] = temp
  return obj
}

export const JSON2NSON = (obj) => {
  let nson = ''
  const keys = Object.keys(obj)
  for (let i = 0; i < keys.length; i++) {
    nson += keys[i] + ':' + nsonEscape(obj[keys[i]]) + '&'
  }
  if (nson.length !== 0) {
    nson = nson.substring(0, nson.length - 1)
  }
  return nson
}

const nsonEscape = (nsonUnEscaped) => {
  try {
    return nsonUnEscaped.replaceAll('&', '\\&').replaceAll(':', '\\:')
  } catch (err) {

  }
}
