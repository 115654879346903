import * as React from 'react'
import {
  Menu,
  Avatar,
  Typography,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  styled
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { formatDistanceStrict } from 'date-fns'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'

// importing redux actions
import { useAppSelector, useAppDispatch } from '../../utils/redux/store'
import { updateActivityArrayRedux } from '../../utils/redux/actions/activityActions'

// importing user context
import { UserContext } from '../../context/UserContext'

// importing services
import { notificationsRead } from 'api/notifications'
import { followUser, unfollowUser } from 'api/follow'

const StyledActivityTextButton = styled('div')(({ theme }) => ({
  margin: 0,
  padding: 2,
  height: 'auto',
  width: 'auto',
  color: 'black',
  fontSize: theme.typography.button.fontSize
}))

const ActivityMenu = ({ open, onClose, isForMobile = false }) => {
  const classnames = classes()
  const { personal, jwtToken, activeNeighborhood } = React.useContext(UserContext)
  const { enqueueSnackbar } = useSnackbar()
  const observer = React.useRef()
  const navigate = useNavigate()

  // redux
  const reduxDispatcher = useAppDispatch()
  const activityState = useAppSelector((state) => state.root.activityState)

  // component states
  const [page, setPage] = React.useState(1)
  const [loading, setLoading] = React.useState(false)

  const getNotificationsByPage = async (pageNumber) => {
    setLoading(true)
    reduxDispatcher(updateActivityArrayRedux(
      pageNumber,
      personal[activeNeighborhood].ID,
      jwtToken
    ))
    // debouncing the second intersection
    setTimeout(() => {
      setLoading(false)
    }, 1000 * 3)
  }

  React.useEffect(() => {
    setPage(1)
    getNotificationsByPage(1)
  }, [open])

  React.useEffect(() => {
    if (personal && jwtToken && page > 1) {
      getNotificationsByPage(page)
    }
  }, [jwtToken, personal, page])

  React.useEffect(() => {
    if ((open || isForMobile) && activityState.recent.length > 0) {
      notificationsRead(personal[activeNeighborhood].ID, jwtToken)
    }
  }, [jwtToken, open, personal, activityState])

  const lastElementRefObserver = React.useCallback(
    () => {
      if (loading) {
        return null
      }
      const obs = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && activityState.hasMoreNotifications) {
          setPage((p) => ++p)
        }
      })
      if (observer.current) {
        obs.observe(observer.current)
      }
      return obs
    },
    [loading, activityState.hasMoreNotifications]
  )

  React.useEffect(() => {
    const obs = lastElementRefObserver()
    return () => {
      if (obs === null) {
        return
      }
      obs.disconnect()
    }
  }, [lastElementRefObserver])

  const getActionerUserId = (noti) => {
    switch (noti.notification_type) {
      case 'like':
        return noti.metadata[0].liked_by_id
      case 'comment':
        return noti.metadata[0].commented_by_id
      case 'follow':
        return noti.metadata[0].follower_id
      case 'review_response':
        return noti.metadata[0].business_id
      default:
        return ''
    }
  }

  const localDateFormatter = (noti) => {
    return formatDistanceStrict(new Date(noti.created_at), new Date(), { addSuffix: true })
  }

  const notificationTextFormatterPrimary = (noti) => {
    const usernameAction = () => {
      if (noti.notification_type === 'review_response') {
        return navigate(`/b/${getActionerUserId(noti)}`)
      }

      navigate(`/u/${getActionerUserId(noti)}`)
    }
    const postAction = () => navigate(`/p/${noti.metadata[0].post_id}`)

    switch (noti.notification_type) {
      case 'like':
        return (
          <Stack direction="row" flexWrap="wrap">
            <StyledActivityTextButton
              variant="text"
              onClick={usernameAction}
              style={{
                color: 'blue'
              }}
            >
              {noti.metadata[0].username.split('-')[0]}
            </StyledActivityTextButton>
            <StyledActivityTextButton
              variant="text"
              onClick={postAction}
            >
              liked your post {localDateFormatter(noti)}
            </StyledActivityTextButton>
          </Stack>
        )
      case 'comment':
        return (
          <Stack direction="row" flexWrap="wrap">
            <StyledActivityTextButton
              variant="text"
              onClick={usernameAction}
              style={{
                color: 'blue'
              }}
            >
              {noti.metadata[0].username.split('-')[0]}
            </StyledActivityTextButton>
            <StyledActivityTextButton
              variant="text"
              onClick={postAction}
            >
              commented on your post {localDateFormatter(noti)} : {noti.metadata[0].comment.slice(0, 30)}
            </StyledActivityTextButton>
          </Stack>
        )
      case 'follow':
        return (
          <Stack direction="row" flexWrap="wrap">
            <StyledActivityTextButton
              variant="text"
              onClick={usernameAction}
              style={{
                color: 'blue'
              }}
            >
              {noti.metadata[0].follower_username.split('-')[0]}
            </StyledActivityTextButton>
            <StyledActivityTextButton variant="text">
              started following you
            </StyledActivityTextButton>
          </Stack>
        )
      case 'review_response':
        return (
          <Stack direction="row" flexWrap="wrap">
            <StyledActivityTextButton variant="text">
              Your review has a response from
            </StyledActivityTextButton>
            <StyledActivityTextButton
              variant="text"
              onClick={usernameAction}
              style={{
                color: 'blue'
              }}
            >
              {noti.metadata[0].responded_by}
            </StyledActivityTextButton>
          </Stack>
        )
      default:
        return (
          <Stack direction="row" flexWrap="wrap">
            <StyledActivityTextButton variant="text">
              This is a notification
            </StyledActivityTextButton>
          </Stack>
        )
    }
  }

  const followNotificationAction = async (action, noti) => {
    try {
      await action(getActionerUserId(noti), personal[activeNeighborhood].ID, jwtToken)
    } catch (err) {
      enqueueSnackbar(err.message, {
        variant: 'error'
      })
    } finally {
      if (page - 1 === 1) {
        setPage(1)
        getNotificationsByPage(1)
      } else {
        setPage(page - 1)
      }
    }
  }

  const renderNotificationAction = (noti) => {
    switch (noti.notification_type) {
      case 'like':
      case 'comment':
        return null
      case 'follow':
        // if the current user is following the actioner user then show unfollow else Follow Back
        if (noti.metadata[0].is_following) {
          return (
            <div
              className={classnames.notificationActionButton}
              onClick={() => followNotificationAction(unfollowUser, noti)}
            >
              Unfollow
            </div>
          )
        }
        return (
          <div
            className={classnames.notificationActionButton}
            onClick={() => followNotificationAction(followUser, noti)}
          >
            Follow Back
          </div>
        )
      case 'review_response':
        return (
          <div
            className={classnames.notificationActionButton}
            onClick={() => navigate(`/review/response/${noti.metadata[0].response_video_id}`)}
          >
            View
          </div>
        )
      default:
        return null
    }
  }

  const isTrueLastElement = (section) => {
    switch (section) {
      case 'past':
        return activityState.past.length !== 0
      case 'week':
        return activityState.past.length === 0 && activityState.week.length !== 0
      case 'recent':
        return (
          activityState.past.length === 0 &&
          activityState.week.length === 0 &&
          activityState.recent.length !== 0
        )
      default:
        return false
    }
  }

  const renderNotification = (noti, type = 0, lastElement = false) => {
    // if a notification's metadata was null, do not display it anymore
    // metadata becomes null if comment was deleted in future
    if (noti.metadata === null) {
      return
    }
    const actionerUserId = getActionerUserId(noti)
    return (
      <ListItem
        key={`${noti.id}-${type}`}
        style={{ width: '100%', padding: 6 }}
        ref={lastElement ? observer : null}
      >
        <ListItemAvatar
          className={classnames.notificationAvatar}
          onClick={() => {
            if (noti.notification_type === 'review_response') {
              return navigate(`/b/${actionerUserId}`)
            }
            navigate(`/u/${actionerUserId}`)
          }}
        >
          <Avatar alt="User Avatar" src={noti.profile_pic} />
        </ListItemAvatar>
        <ListItemText
          primary={notificationTextFormatterPrimary(noti)}
          className={classnames.notificationContent}
        />
          {renderNotificationAction(noti)}
      </ListItem>
    )
  }

  const renderActivityMenuContent = () => {
    return (
      <>
        <Typography variant="h3" className={classnames.activityTitle}>
            All Activity
        </Typography>
        {activityState.recent.length > 0 && (
          <>
            <Typography variant="h4" style={{ paddingLeft: 6 }}>
                Recent
            </Typography>
            {activityState.recent.map((noti, index) => renderNotification(noti, 0, (activityState.recent.length === index + 1) && isTrueLastElement('recent')))}
          </>
        )}
        {activityState.week.length > 0 && (
          <>
            <Typography variant="h4" style={{ paddingLeft: 6 }}>
                This Week
            </Typography>
            {activityState.week.map((noti, index) => renderNotification(noti, 1, (activityState.week.length === index + 1) && isTrueLastElement('week')))}
          </>
        )}
        {activityState.past.length > 0 && (
          <>
            <Typography variant="h4" style={{ paddingLeft: 6 }}>
                Past
            </Typography>
            {activityState.past.map((noti, index) => renderNotification(noti, 2, (activityState.past.length === index + 1) && isTrueLastElement('past')))}
          </>
        )}
        {activityState.hasNoNotifications &&
          (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
                <img
                  alt="No Notifications Yet"
                  style={{
                    width: 300
                  }}
                  src={require('../../assets/activityBell.png')}
                />
                <Typography variant="h6">
                  You don't have any notifications yet
                </Typography>
            </div>
          )}
      </>
    )
  }

  // is isForMobile is true, Activity.mobile page requested content
  if (isForMobile) {
    return renderActivityMenuContent()
  }

  return (
    <Menu
      id="user-activity"
      anchorEl={open}
      open={Boolean(open)}
      keepMounted
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      onClose={onClose}
      className="dropdown-menu"
    >
      <div
        className={classnames.activityWrapper}
        style={{
          flex: 0,
          height: 0,
          maxHeight: 0,
          width: 400,
          padding: 0
        }}
      >
        <div
          style={{
            position: 'absolute',
            height: 30,
            width: 30,
            background: '#fff',
            right: 40,
            transform: 'translate(calc(50%), -50%) rotate(45deg)',
            boxShadow: 'rgb(0 0 0 / 5%) -3px -3px 8px 0px',
            clipPath: 'polygon(-65px -75px, 0px 100%, 100% 0px)',
            borderTopLeftRadius: 5,
            top: -14
          }}
        ></div>
      </div>
      <div className={classnames.activityWrapper}>
        <div className={classnames.activityWrapperInner}>
          {renderActivityMenuContent()}
        </div>
      </div>
    </Menu>
  )
}

const classes = makeStyles(({ breakpoints, palette }) => ({
  activityWrapper: {
    flex: 1,
    display: 'flex',
    height: 600,
    maxHeight: '70vh',
    width: 400,
    margin: 16,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    [breakpoints.down('sm')]: {
      width: '94%'
    }
  },
  activityWrapperInner: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    paddingRight: 20
  },
  activityTitle: {
    color: '#1a6751',
    marginBottom: 10
  },
  notificationAvatar: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  notificationContent: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  notificationPostImage: {
    width: 40,
    height: 40,
    background: 'grey',
    borderRadius: 8,
    borderStyle: 'solid',
    borderWidth: 1
  },
  notificationActionButton: {
    display: 'block ruby',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
    borderRadius: 10,
    borderStyle: 'solid',
    backgroundColor: '#0c3a2d',
    color: 'white',
    fontSize: 12,
    fontWeight: 600,
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))

ActivityMenu.defaultProps = {
  open: false,
  onClose: () => {}
}

export default ActivityMenu
