// importing action types
import userReducerActionTypes from '../actions/userActions'

// store design
const initialState = {
  refreshToken: null,
  jwtToken: null,
  user: null,
  userProfiles: [],
  business: null,
  personal: null,
  activeNeighborhood: 0
}

// post reducer
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case userReducerActionTypes.login:
      return action.payload
    case userReducerActionTypes.logout:
      return initialState
    case userReducerActionTypes.refreshTokens:
      return {
        ...state,
        jwtToken: action.payload.jwtToken,
        refreshToken: action.payload.refreshToken
      }
    case userReducerActionTypes.refreshProfiles:
      return {
        ...state,
        userProfiles: action.payload.userProfiles,
        business: action.payload.business,
        personal: action.payload.personal
      }
    case userReducerActionTypes.switchActiveNeighborhood:
      return {
        ...state,
        activeNeighborhood: action.payload
      }
    default:
      return state
  }
}

export default userReducer
