import axios from 'axios'

/**
 * @function gatheringRecord Used to start the recording of the current gathering
 * @param {string} gatheringId - gathering's id
 * @param {string} nrctUID - user's current neighborhood id
 * @param {string} jwt - user's jwt token
 * @returns {Promise} Promise object represents
 */
export const gatheringRecord = (gatheringId, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/gatherings/record`, {
      params: {
        gathering: gatheringId
      },
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => {
        if (res.data.msg === 'initiated') {
          resolve(true)
        } else {
          throw new Error('Recording could not be started!')
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

/**
 * @function gatheringRecordStop Used to stop the recording of the current gathering
 * @param {string} gatheringId - gathering's id
 * @param {string} nrctUID - user's current neighborhood id
 * @param {string} jwt - user's jwt token
 * @returns {Promise} Promise object represents
 */
export const gatheringRecordStop = (gatheringId, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/gatherings/record/stop`, {
      params: {
        gathering: gatheringId
      },
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then(() => resolve(true))
      .catch((err) => {
        reject(err)
      })
  })
}
