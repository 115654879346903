const getName = (user) => {
  if (user.alias) return user.alias
  else if (user.first_name) {
    if (user.last_name) return `${user.first_name} ${user.last_name}`
    else return user.first_name
  } else if (user.userName) {
    return user.userName
  } else if (user.username) {
    return user.username
  } else return 'User'
}

export default getName
