import { makeStyles } from '@mui/styles'

export default makeStyles(({ palette, breakpoints }) => ({
  root: {
    paddingTop: 40,
    paddingBottom: 80,
    paddingLeft: '7%',
    paddingRight: 0,

    // minHeight: "100vh",

    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    // alignItems: "center",

    [breakpoints.down('sm')]: {
      paddingLeft: '5%',
      paddingRight: '5%'
    }
  },
  featureListContainer: {
    position: 'sticky',
    top: 64,
    left: 0
  },
  cardRoot: {
    display: 'flex',
    margin: '30px 0',

    transition: '0.2s all ease-in-out',

    [breakpoints.down('sm')]: {
      margin: '15px 0'
    }
  },
  inactive: {
    opacity: 0.5,
    cursor: 'pointer'
  },
  featureCardIcon: {
    width: 40,

    [breakpoints.down('sm')]: {
      width: 30
    }
  },
  featureText: {
    color: '#272727',
    fontSize: '1.5rem',
    fontWeight: 600,

    [breakpoints.down('sm')]: {
      fontSize: '1.2rem'
    }
  },
  highlight: {
    color: palette.primary.main,
    fontWeight: 600
  },
  featureDesc: {
    marginTop: 10,
    fontSize: '0.9rem',

    [breakpoints.down('sm')]: {
      marginTop: 5,
      fontSize: '0.8rem'
    }
  },
  bannerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  bannerImg: {
    borderRadius: '30px 0 0 30px',
    width: '100%'
  },
  getStartedContainer: {
    width: '100%',
    textAlign: 'center',
    padding: '20px 0',

    [breakpoints.down('sm')]: {
      padding: '10px 0'
    }
  }
}))
