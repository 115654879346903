/**
 * @function onKeypressAction Used to bind keyboard key press to actions
 * @param {number} keycode - the keycode of the keyboard key you want action to listen too
 * @param {function} action - the function to call when the event of pressing the key takes place
 * @returns {function} Should be used for cleanup like in return of a useEffect
 */
export const onKeypressAction = (keycode, action = (e) => {}) => {
  const listenerCallback = (e) => {
    if (e.keyCode === keycode) {
      action(e)
    }
  }
  window.addEventListener('keydown', listenerCallback)
  // should call the return function when component unmounts for resource cleanup
  return () => window.removeEventListener('keydown', listenerCallback)
}
