import axios from 'axios'

/**
 * @function reportGroup Used to report group a certain gathering
 * @param {string} groupId - group id of the group to report
 * @param {string} reason - the reason for reporting
 * @param {string} nrctUID - personal[active_neighborhood].ID from user context
 * @param {string} jwt - jwt from the user context
 * @returns {Promise} Promise object represents true for successful reporting
 */
export const reportGroup = (groupId, reason = '', nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/report/group`, {
      group_id: groupId,
      reason: reason
    }, {
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then(() => resolve(true))
      .catch((err) => reject(err))
  })
}
