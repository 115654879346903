import { Grid, useTheme } from '@mui/material'
import { MicOff } from '@mui/icons-material'
import React, { useEffect, useRef, useState } from 'react'

// importing apis
import { personalAccountAvatar } from 'api/personal_account/avatar'

const MediaPlayer = ({
  videoTrack,
  audioTrack,
  name,
  volume,
  id,
  ...props
}) => {
  const container = useRef(null)
  const { palette } = useTheme()
  const [userAvatar, setUserAvatar] = useState('')

  useEffect(() => {
    if (!container.current) return
    if (videoTrack) videoTrack.play(container.current)
  }, [container, videoTrack])

  useEffect(() => {
    if (audioTrack) audioTrack.play()
  }, [audioTrack])

  useEffect(() => {
    setUserAvatar(personalAccountAvatar(id))
  }, [id])

  return (
    <Grid item {...props} style={{ position: 'relative' }}>
      {videoTrack
        ? (
        <div
          ref={container}
          className="video-player"
          style={{
            width: '100%',
            minWidth: '200px',
            height: '100%',
            minHeight: '160px',
            borderRadius: 16,
            overflow: 'hidden',
            background: '#181818'
          }}
        ></div>
          )
        : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            minWidth: '200px',
            height: '100%',
            minHeight: '160px',
            borderRadius: 16,
            overflow: 'hidden',
            background: '#181818'
          }}
        >
          <div
            style={{
              width: 100,
              height: 100,
              borderRadius: '50%',
              background: `url(${userAvatar})`,
              backgroundPosition: 'center center',
              backgroundSize: 'cover',
              border: `2px solid ${palette.primary.main}`,
              boxShadow:
                volume && volume > 5
                  ? '0 0 10px rgba(15, 158, 13, 0.9), 0 0 20px rgba(15, 158, 13, 0.7), 0 0 35px rgba(15, 158, 13, 0.5)'
                  : 'none'
            }}
          ></div>
        </div>
          )}
      <div
        style={{
          color: '#FFFFFF',
          position: 'absolute',
          bottom: 16,
          left: 26,
          background: '#00000077',
          borderRadius: 4,
          padding: '5px 8px'
        }}
      >
        {name}
      </div>
      {audioTrack === undefined
        ? (
        <div
          style={{
            position: 'absolute',
            bottom: 16,
            right: 26,
            background: `${palette.error.light}`,
            width: 32,
            height: 32,
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white'
          }}
        >
          <MicOff />
        </div>
          )
        : (
        <></>
          )}
    </Grid>
  )
}

const LocalPlayer = ({
  isVideoMuted,
  isAudioMuted,
  videoTrack,
  name,
  id,
  volume,
  ...props
}) => {
  const container = useRef(null)
  const { palette } = useTheme()
  const [userAvatar, setUserAvatar] = useState('')

  useEffect(() => {
    if (!container.current) return
    if (!isVideoMuted) videoTrack.play(container.current)
    else videoTrack.stop()
  }, [container, videoTrack, isVideoMuted])

  useEffect(() => {
    setUserAvatar(personalAccountAvatar(id))
  }, [id])

  return (
    <Grid item {...props} style={{ position: 'relative' }}>
      {videoTrack && videoTrack.enabled
        ? (
        <div
          ref={container}
          className="video-player"
          style={{
            width: '100%',
            minWidth: '200px',
            height: '100%',
            minHeight: '160px',
            borderRadius: 16,
            overflow: 'hidden',
            background: '#181818',
            color: 'white'
          }}
        ></div>
          )
        : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            minWidth: '200px',
            height: '100%',
            minHeight: '160px',
            borderRadius: 16,
            overflow: 'hidden',
            background: '#181818'
          }}
        >
          <div
            style={{
              width: 100,
              height: 100,
              borderRadius: '50%',
              backgroundImage: `url(${userAvatar})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              border: `2px solid ${palette.primary.main}`,
              boxShadow:
                volume && volume > 5
                  ? '0 0 10px rgba(15, 158, 13, 0.9), 0 0 20px rgba(15, 158, 13, 0.7), 0 0 35px rgba(15, 158, 13, 0.5)'
                  : 'none'
            }}
          ></div>
        </div>
          )}
      <div
        style={{
          color: '#FFFFFF',
          position: 'absolute',
          bottom: 16,
          left: 26,
          background: '#00000077',
          borderRadius: 4,
          padding: '5px 8px'
        }}
      >
        {name}
      </div>
      {isAudioMuted
        ? (
        <div
          style={{
            position: 'absolute',
            bottom: 16,
            right: 26,
            background: `${palette.error.light}`,
            width: 32,
            height: 32,
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white'
          }}
        >
          <MicOff />
        </div>
          )
        : (
        <></>
          )}
    </Grid>
  )
}

function VideoComponent ({
  localTracks,
  hosts,
  remoteUsers,
  forceUpdate,
  isVideoMuted,
  isAudioMuted,
  localUser,
  volumes
}) {
  const [size, setSize] = useState({ xs: 12 })
  const [localVolume, setLocalVolume] = useState(0)

  useEffect(() => {
    if (hosts.current.length === 1) {
      setSize({ xs: 12 })
    } else if (hosts.current.length <= 4) {
      setSize({ xs: 12, sm: 6 })
    } else if (hosts.current.length <= 9) {
      setSize({ xs: 12, sm: 6, md: 4 })
    } else if (hosts.current.length <= 16) {
      setSize({ xs: 12, sm: 6, md: 3 })
    } else {
      setSize({ xs: 12, sm: 6, md: 3, lg: 2 })
    }

    const volume = volumes.filter(
      (volume) => volume.uid === localUser.current.uid
    )
    setLocalVolume(volume[0] ? volume[0].level : 0)

    // eslint-disable-next-line
  }, [forceUpdate]);

  return (
    <>
      {localUser.current.isHost && (
        <LocalPlayer
          name={localUser.current.name}
          id={localUser.current.userId}
          videoTrack={localTracks.current.videoTrack}
          isVideoMuted={isVideoMuted}
          isAudioMuted={isAudioMuted}
          volume={localVolume}
          {...size}
        />
      )}
      {hosts.current.map((item, i) => {
        const user = remoteUsers.filter((user) => user.uid === item.uid)
        const volume = volumes.filter((volume) => volume.uid === item.uid)
        if (user.length === 0) return null
        return (
          <MediaPlayer
            name={item.name}
            id={item.userId}
            videoTrack={user[0].videoTrack}
            audioTrack={user[0].audioTrack}
            volume={volume[0] ? volume[0].level : 0}
            key={i}
            {...size}
          />
        )
      })}
    </>
  )
}

export default VideoComponent
