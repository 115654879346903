import * as React from 'react'
import { Typography, Divider, ListItem, ListItemText, ListItemSecondaryAction, List } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import StandardModal from 'ui/Modals/StandardModal'

const reportTypes = [
  'Crime / Racial Profiling',
  'Divisive Issue',
  'Profanity',
  'Nudity / Sexualization',
  'Non-Family friendly',
  'Not related to neighborhood / nearby area in which posted.'
]

const ReportModal = ({ open, onClose }) => {
  const classnames = styles()

  return (
        <StandardModal
			isOpen={open}
			onClose={() => onClose(null)}
		>
            <div className={classnames.mainReportModalWrapper}>
                <Typography variant="h3" style={{ color: '#df693c', alignSelf: 'center', marginBottom: 12 }}>
                    Report
                </Typography>
                <Typography variant="body1" style={{ color: '#272727', fontWeight: 500 }}>
                    Why are you reporting this post?
                </Typography>
                <Typography variant="caption" style={{ marginBottom: 10 }}>
                    Your report is anonymous, except if you're reporting an intellectual
                    property infringement. If someone is in immediate danger, call the
                    local emergency services - don't wait.
                </Typography>
                <Divider />
                <List>
                    {reportTypes.map((report, index) => (
                        <div key={report}>
                            <ListItem
                                className={classnames.reportModalListItem}
                                key={report}
                                onClick={() => onClose(report)}
                            >
                                <ListItemText
                                    primary={report}
                                />
                                <ListItemSecondaryAction>
                                    <ArrowForwardIosIcon />
                                </ListItemSecondaryAction>
                            </ListItem>
                            {index !== reportTypes.length - 1 && <Divider />}
                        </div>
                    ))}
                </List>
            </div>
        </StandardModal>
  )
}

const styles = makeStyles(() => ({
  mainReportModalWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center'
  },
  reportModalListItem: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))

export default ReportModal
