import { Grid, Typography, styled, Button, IconButton, Menu, MenuItem } from '@mui/material'
import { KeyboardArrowDownRounded, KeyboardArrowUpRounded } from '@mui/icons-material'
import makeStyles from '@mui/styles/makeStyles'
import React, { useCallback, useContext, useRef, useState, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { useNavigate, useLocation } from 'react-router-dom'
import useDimensions from 'react-use-dimensions'

// importing context
import { UserContext } from '../../../context/UserContext'

// importing components
import NearcastGrid from '../../NearcastPicker/NercastGrid'
// import VerifyLocationModal from "../../Modals/VerifyLocationModal";
import NearcastsEmptyState from '../../EmptyStates/NearcastsEmptyState'
import CreateNearcastModal from '../../Modals/CreateNearcastModal/CreateNearcastModal'
import NearcoinRewardModal from '../../Modals/NearcoinRewardModal/NearcoinRewardModal'

import downloadQrCode from '../../../assets/Qrcode.png'

// importing APIs
// import { personalAccountPostalVerificationStatus } from "api/personal_account/postal_verification"

// importing helpers
import { recordGaEvent } from 'helpers/ga'

// importing redux helpers
import { useAppSelector, useAppDispatch } from '../../../utils/redux/store'
import {
  updatePostsNearbyArrayRedux,
  updatePostsNeighborhoodArrayRedux
} from '../../../utils/redux/actions/postActions'

// styled components
const StyledHeaderTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.h3,
  [theme.breakpoints.down('sm')]: {
    ...theme.typography.h4
  }
}))

function ShortVideos (props) {
  const { state } = useLocation()
  // get redux dispatcher
  const dispatch = useAppDispatch()
  // get redux state for nearby posts
  const { postsNearby, postsNeighborhood } = useAppSelector((state) => state.root.postsState)

  // component states
  const [ref, { width }] = useDimensions()
  const navigate = useNavigate()
  const { personal, jwt_token, active_neighbourhood, userProfiles, switchNeighbourHood } =
    useContext(UserContext)
  const [tabIndex, setTabIndex] = useState(0)
  const [isLoad, setIsLoad] = useState(true)
  const [hasMore, setHasMore] = useState(true)
  const [pageNumber, setPageNumber] = useState(1)
  // const [isVerificationModalOpen, setIsVerficationModalOpen] = useState(true);
  // const [isUserNeibhorhoodVerified, setIsUserNeibhorhoodVerified] = useState(false);
  const classes = styles()
  const { enqueueSnackbar } = useSnackbar()
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [isNeighborhoodSwitchOpen, setIsNeighborhoodSwitchOpen] = useState(false)
  // state for weather to open nearcoin reward modal or not
  const [isNearcoinRewardOpen, setIsNearcoinRewardOpen] = useState(false)

  const observer = useRef()
  const lastVideoElementRef = useCallback(
    (node) => {
      if (isLoad) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1)
        }
      })
      if (node) observer.current.observe(node)
    },
    [isLoad, hasMore]
  )

  // by default all users are verified
  // const hasUserCompletedPostalVerification = async () => {
  //   try {
  //     const isVerified = await personalAccountPostalVerificationStatus(personal[active_neighbourhood].ID, jwt_token);
  //     setIsUserNeibhorhoodVerified(isVerified);
  //     setIsVerficationModalOpen(true);
  //   } catch (err) {
  //     enqueueSnackbar(err.message, {
  //       variant: "error",
  //     })
  //   }
  // }

  // Called when active neighborhood changes
  useEffect(() => {
    setIsLoad(true)
    // commented because all users are verified
    // hasUserCompletedPostalVerification();
    dispatch(updatePostsNearbyArrayRedux(1, personal[active_neighbourhood].ID, jwt_token))
    // right now postal verification is disabled and all users are verified by default
    // hence the below request, which earlier required verification
    dispatch(updatePostsNeighborhoodArrayRedux(1, personal[active_neighbourhood].ID, jwt_token))
    setTabIndex(0)
    setIsLoad(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active_neighbourhood, personal, jwt_token])

  // Called when isUserNeibhorhoodVerified changes
  // commented because all users are verified
  // useEffect(() => {
  //   if (isUserNeibhorhoodVerified) {
  //     dispatch(updatePostsNeighborhoodArrayRedux(1, personal[active_neighbourhood].ID, jwt_token))
  //   }
  // }, [active_neighbourhood, dispatch, isUserNeibhorhoodVerified, jwt_token, personal])

  useEffect(() => {
    if (pageNumber > 1) {
      if (tabIndex === 0) {
        dispatch(updatePostsNearbyArrayRedux(pageNumber, personal[active_neighbourhood].ID, jwt_token))
      } else {
        dispatch(updatePostsNeighborhoodArrayRedux(pageNumber, personal[active_neighbourhood].ID, jwt_token))
      }
    }
    // eslint-disable-next-line
  }, [pageNumber]);

  useEffect(() => {
    if (window.location.pathname.split('/')[2] === 'create') {
      setIsCreateModalOpen(2)
    } else if (isCreateModalOpen) {
      setIsCreateModalOpen(false)
    }
    // keep window.location.pathname in dep list
    // if you remove it then on switching from nearcast creation screen to home will
    // not hide the nearcast creation screen
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname])

  useEffect(() => {
    if (state) {
      if (state.newReviewCreated) {
        setIsNearcoinRewardOpen(state.file)
      }
    }
  }, [state])

  return (
    <Grid style={{ width: '100%' }} ref={ref}>
      <Grid item sm={12}>
        <div className={classes.headingContainer}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <StyledHeaderTypography
              color={tabIndex === 0 ? 'primary' : 'initial'}
              variant="h4"
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => {
                if (!isLoad) setTabIndex(0)
              }}
            >
              Nearby
            </StyledHeaderTypography>
            <StyledHeaderTypography
              color={tabIndex === 1 ? 'primary' : 'initial'}
              variant="h4"
              style={{
                marginLeft: '20px',
                textDecoration: 'underline',
                cursor: 'pointer'
              }}
              onClick={() => {
                if (!isLoad) setTabIndex(1)
              }}
            >
              {userProfiles[active_neighbourhood].name}
            </StyledHeaderTypography>
            <IconButton onClick={(e) => setIsNeighborhoodSwitchOpen(e.currentTarget)}>
              {isNeighborhoodSwitchOpen ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}
            </IconButton>
            <Menu
              id="neighborhood-switch-menu"
              anchorEl={isNeighborhoodSwitchOpen}
              open={Boolean(isNeighborhoodSwitchOpen)}
              onClose={() => setIsNeighborhoodSwitchOpen(false)}
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}
            >
              {personal.map((profile, i) => {
                return (
                  <MenuItem
                    key={profile.ID}
                    onClick={() => {
                      switchNeighbourHood(i)
                      setIsNeighborhoodSwitchOpen(false)
                    }}
                  >
                    {`${profile?.neighbourhood?.neighbourhood} ${profile.is_business ? '(business)' : ''}`}
                  </MenuItem>
                )
              })}
            </Menu>
          </div>
          {width > 500 && (
            <Button
              variant="contained"
              disableElevation
              color="primary"
              onClick={() => {
                setIsCreateModalOpen(true)

                // analytics
                recordGaEvent('homeFeedPage-createNearcast-clicked', {
                  personalAccountId: personal[active_neighbourhood].ID,
                  neighbourhoodName: userProfiles[active_neighbourhood].name
                })
              }}
              style={{
                alignSelf: 'flex-end',
                marginLeft: 10,
                marginRight: 10
              }}
            >
              Create Nearcast
            </Button>
          )}
        </div>
      </Grid>
      <Grid item sm={12}>
        <NearcastGrid
          videos={tabIndex === 0 ? postsNearby : postsNeighborhood}
          loading={isLoad}
          emptyMessage={
            <NearcastsEmptyState
              emptyMessage={{
                main: 'No Nearcasts available at your neighbourhood',
                desc: (
                  <span>
                    Scan the QR Code above from your phone or{' '}
                    <a href="https://app.nearcast.com/download/noposts">
                      click here
                    </a>{' '}
                    to create nearcasts.
                  </span>
                )
              }}
              mainImage={downloadQrCode}
            />
          }
          playable
          observer={lastVideoElementRef}
        />
      </Grid>
      {/* {!isUserNeibhorhoodVerified && tabIndex === 1 && (
        <VerifyLocationModal
          open={isVerificationModalOpen}
          onClose={() => {
            setIsVerficationModalOpen(false);
          }}
          neighbourhood={userProfiles[active_neighbourhood].userLocation}
          prompt
        />
      )} */}
      <CreateNearcastModal
        isOpen={Boolean(isCreateModalOpen)}
        onClose={() => {
          setIsCreateModalOpen(false)
          if (isCreateModalOpen === 2) {
            navigate('/home')
          }
        }}
      />
      <NearcoinRewardModal
        videoFile={isNearcoinRewardOpen || ''}
        isOpen={Boolean(isNearcoinRewardOpen)}
        onClose={() => {
          setIsNearcoinRewardOpen(false)
          navigate('/home')
        }}
      />
    </Grid>
  )
}

const styles = makeStyles(({ breakpoints }) => ({
  headingContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  }
}))

export default ShortVideos
