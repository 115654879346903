import React, { useContext, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {
  Container,
  CircularProgress,
  Typography,
  Stack,
  ListItem,
  Button,
  ListItemText,
  AppBar,
  Box
} from '@mui/material'
import axios from 'axios'
import { UserContext } from '../../context/UserContext'
import { GlobalFuncContext } from '../../context/GlobalFuncHOC'

// importing component
import Dialog from 'ui/Dialog/Dialog'
import Nearcast from 'ui/Icons/Nearcast'
import { feedData } from 'api/analytics/feed'

// importing helper
import { recordGaEvent } from 'helpers/ga'

const ViewOffer = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { jwt_token, personal, active_neighbourhood } = useContext(UserContext)
  const { openCreateReviewModal } = useContext(GlobalFuncContext)

  // page states
  const [loading, setLoading] = useState(true)
  const [offer, setOffer] = useState({})
  const [isErrorDialogOpen, setIsErrDialogOpen] = useState(null)

  useEffect(() => {
    window.Tawk_API = window.Tawk_API || {}
    try {
      window.Tawk_API.hideWidget()
    } catch (err) {
      window.Tawk_API.onLoad = function () {
        window.Tawk_API.hideWidget()
      }
    }
  }, [])

  const reportOfferView = () => {
    /**
     * For Logged in users
     * user_neighbourhood_id will be user's personal account neighbourhood id
     * jwt_token will be user's jwt
     * nrctUID will be user's personal account id
     *
     * For anonymous users
     * user_neighbourhood_id will be offer's neighbourhood id
     * jwt_token will be null
     * nrctUID will be null
     */
    try {
      const data = {
        user_neighbourhood_id:
          personal
            ? personal[active_neighbourhood].neighbourhood.ID
            : offer.neighbourhood_id,
        offer_id: offer.offer_id,
        business_id: offer.business_id,
        created_at: new Date().toISOString()
      }

      feedData(
        'business_offers_scan',
        data,
        jwt_token,
        personal ? personal[active_neighbourhood].ID : null
      )
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (loading === false) {
      reportOfferView()
    }
  }, [id, loading])

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/signup/in-store/code`, {
        redemption_code: id
      })
      .then((res) => {
        setOffer(res.data.body)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err.message)
        setIsErrDialogOpen(
          "We couldn't find the offer you where looking for!"
        )
      })
  }, [id])

  return (
    <Container maxWidth="sm" style={styles.mainOfferContainer}>
      <Stack direction="row" alignItems="center" justifyContent="center">
        <Nearcast style={{ width: 100 }} />
      </Stack>
      {loading
        ? (
            <Container
              maxWidth="md"
              style={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <CircularProgress />
            </Container>
          )
        : (
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="space-evenly"
          style={styles.offerInnerStack}
        >
          <Box>
            <img
              alt="Business Avatar"
              src={offer.business_avatar}
              style={{
                width: 80,
                marginTop: 10
              }}
            />
            <Typography
              variant="h3"
              style={{
                marginTop: 20
              }}
              color="secondary"
            >
              {offer.title}
            </Typography>
            <Typography
              variant="caption"
              style={{
                marginTop: 8
              }}
              component="div"
            >
              {offer.description}
            </Typography>
          </Box>
          <img
            alt="Business Avatar"
            src={offer.cover_image}
            style={{
              marginTop: 20,
              marginBottom: 20,
              height: '50vh',
              minHeight: 160,
              // width: 160,
              borderRadius: 8,
              objectFit: 'cover'
            }}
          />
          <Box style={{ width: '100%' }}>
            {/* <ListItem
              style={{
                paddingLeft: 0,
                paddingRight: 0
              }}
            >
              <img
                alt="Nearcast App Logo"
                style={{
                  width: 40,
                  height: 40,
                  display: 'block',
                  marginRight: 10
                }}
                src={
                  require('../../assets/nearcastApp.svg')
                    .default
                }
              />
              <ListItemText
                primary={
                  <Typography
                    variant="h6"
                    style={{
                      fontSize: 14,
                      fontWeight: 500
                    }}
                  >
                    Use the nearcast app to create your
                    video
                  </Typography>
                }
              />
            </ListItem> */}
            <Stack
              direction="column"
              spacing={1}
              style={{
                width: '100%',
                marginTop: 8
              }}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  // window.open(offer.offer_link)
                  // analytics
                  recordGaEvent('viewOfferPage-continueClicked', {
                    personalAccountId: personal ? personal[active_neighbourhood].ID : null,
                    couponCode: id,
                    offerTitle: offer.title
                  })
                  openCreateReviewModal('offer', offer)
                }}
                fullWidth
                style={{
                  height: 50
                }}
                data-testid="continueButton"
              >
                Continue
              </Button>
            </Stack>
          </Box>
        </Stack>
          )}
      <Dialog
        isOpen={Boolean(isErrorDialogOpen)}
        title={isErrorDialogOpen}
        cancelText="Retry"
        onCancel={() => window.location.reload()}
        confirmText="Go Home"
        onConfirm={() => navigate('/home')}
      />
    </Container>
  )
}

const styles = {
  mainOfferContainer: {
    padding: 30,
    paddingTop: 10,
    paddingBottom: 0,
    overflowY: 'auto',
    height: '90vh'
  },
  offerInnerStack: {
    width: '100%',
    height: '100%',
    textAlign: 'center'
  }
}

export default ViewOffer
