import axios from 'axios'

/**
 * @function gatheringPromote Used to register for a gathering
 * @param {string} gatheringId - the id of the gathering to register with
 * @param {string} uid - the current user's uid
 * @param {string} nrctUID - user's current neighborhood id
 * @param {string} jwt - user's jwt token
 * @returns {Promise} Promise object represents
 */
export const gatheringPromote = (gatheringId, uid, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/gatherings/promote`, {
      params: {
        gathering: gatheringId,
        user: uid
      },
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(res.data))
      .catch((err) => {
        reject(err)
      })
  })
}
