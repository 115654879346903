import React from 'react'
import { useLocation } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'

// importing sub screens and components
import HeaderDocs from './HeaderDocs/HeaderDocs.jsx'
import PrivacyPolicy from './Docs/PrivacyPolicy.jsx'
import Terms from './Docs/Terms.jsx'
import CommunityGuidelines from './Docs/CommunityGuidelines.jsx'
import CookiePolicy from './Docs/CookiePolicy.jsx'
import ContentPolicy from './Docs/ContentPolicy.jsx'
import ReportCopyright from './Docs/ReportCopyright.jsx'
import ReportProblem from './Docs/ReportProblem.jsx'
import DataDeletion from './Docs/DataDeletion.jsx'
import Safety from './Docs/Safety.jsx'
import MemberPolicy from './Docs/MemberPolicy.jsx'
import ThirdPartyTerms from './Docs/ThirdPartyTerms.jsx'
import EEAMemberAgreement from './Docs/EEAMemberAgreement.jsx'
import FAQs from './Docs/FAQs.jsx'
import BusinessServicesTerms from './Docs/BusinessServicesTerms.jsx'
import PrivacyPolicyGoogle from './Docs/PrivacyPolicyGoogle.jsx'
import TermsGoogle from './Docs/TermsGoogle.jsx'
import EULATerms from './Docs/EULATerms.jsx'
import CookiePolicyApp from './Docs/CookiePolicyApp.jsx'

const style = makeStyles(({ breakpoints, palette }) => {
  return {
    root: {
      marginTop: '30px',
      paddingTop: '30px',
      height: 'calc(100vh - 64px)',
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    },
    fill: {
      display: 'flex',
      [breakpoints.down('xs')]: {
        marginInline: '5px'
      }
    },
    bottomGutter: {
      height: '150px',
      width: '100%'
    }
  }
})

function Documents () {
  const location = useLocation()
  const classes = style()

  return (
        <>
            <HeaderDocs />
            <div
                style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
            >
                <div
                    style={{
                      position: 'relative',
                      maxWidth: '1800px',
                      width: '100vw',
                      height: '100vh'
                    }}
                >
                    <div className={classes.root}>
                        {location.pathname === '/privacy' || location.pathname === '/privacy/'
                          ? (
                            <div className={classes.fill}>
                                <PrivacyPolicy />
                            </div>
                            )
                          : location.pathname === '/terms' || location.pathname === '/terms/'
                            ? (
                            <div>
                                <Terms />
                            </div>
                              )
                            : location.pathname === '/community-guidelines' || location.pathname === '/community-guidelines/'
                              ? (
                            <div className={classes.fill}>
                                <CommunityGuidelines />
                            </div>
                                )
                              : location.pathname === '/cookies' || location.pathname === '/cookies/'
                                ? (
                            <div>
                                <CookiePolicy />
                            </div>
                                  )
                                : location.pathname === '/content-policy' || location.pathname === '/content-policy/'
                                  ? (
                            <div className={classes.fill}>
                                <ContentPolicy />
                            </div>
                                    )
                                  : location.pathname === '/report/copyright' ||
                            location.pathname === '/report/copyright/'
                                    ? (
                            <div>
                                <ReportCopyright />
                            </div>
                                      )
                                    : location.pathname === '/report/problem' ||
                            location.pathname === '/report/problem/'
                                      ? (
                            <div>
                                <ReportProblem />
                            </div>
                                        )
                                      : location.pathname === '/data-deletion' || location.pathname === '/data-deletion/'
                                        ? (
                            <div className={classes.fill}>
                                <DataDeletion />
                            </div>
                                          )
                                        : location.pathname === '/safety' || location.pathname === '/safety/'
                                          ? (
                            <div>
                                <Safety />
                            </div>
                                            )
                                          : location.pathname === '/member-policy' || location.pathname === '/member-policy/' || location.pathname === '/member-agreement' || location.pathname === '/member-agreement/'
                                            ? (
                            <div className={classes.fill}>
                                <MemberPolicy />
                            </div>
                                              )
                                            : location.pathname === '/third-party-terms-disclosures' ||
                            location.pathname === '/third-party-terms-disclosures/'
                                              ? (
                            <div>
                                <ThirdPartyTerms />
                            </div>
                                                )
                                              : location.pathname === '/eea-member-agreement' ||
                            location.pathname === '/eea-member-agreement/'
                                                ? (
                            <div>
                                <EEAMemberAgreement />
                            </div>
                                                  )
                                                : location.pathname === '/business-services-terms' || location.pathname === '/business-services-terms/'
                                                  ? (
                            <div className={classes.fill}>
                                <BusinessServicesTerms />
                            </div>
                                                    )
                                                  : location.pathname === '/faqs' || location.pathname === '/faqs/' || location.pathname === '/help' ||
                            location.pathname === '/help/'
                                                    ? (
                            <div className={classes.fill}>
                                <FAQs />
                            </div>
                                                      )
                                                    : location.pathname === '/app-privacy-policy' || location.pathname === '/app-privacy-policy/'
                                                      ? (
                            <div className={classes.fill}>
                                <PrivacyPolicyGoogle />
                            </div>
                                                        )
                                                      : location.pathname === '/app-terms' || location.pathname === '/app-terms/'
                                                        ? (
                            <div className={classes.fill}>
                                <TermsGoogle />
                            </div>
                                                          )
                                                        : location.pathname === '/app-eula-terms' || location.pathname === '/app-eula-terms/'
                                                          ? (
                            <div className={classes.fill}>
                                <EULATerms />
                            </div>
                                                            )
                                                          : location.pathname === '/app-cookies' || location.pathname === '/app-cookies/'
                                                            ? (
                            <div className={classes.fill}>
                                <CookiePolicyApp />
                            </div>
                                                              )
                                                            : (
                                <></>
                                                              )}
                    </div>
                </div>
            </div>
        </>
  )
}

export default Documents
