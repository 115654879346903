// importing apis
import { notificationsFetch } from 'api/notifications'

/**
 * Action types
 * All the actions defined here will be implemented in reducer,
 * this way it's easier to manage action type strings
 */
const activityReducerActionTypes = {
  activityRefresh: 'ACTIVITY_REFRESH',
  activityClearAll: 'ACTIVITY_CLEAR_ALL'
}

export default activityReducerActionTypes

/**
 * @function updateActivityArrayRedux Used to update the activities list redux state with new data
 * @param {number} pageNumber - the page number to retrieve
 * @param {strin} nrctUID - user's current neighborhood id
 * @param {string} jwt - user's jwt token
 * @returns {function}  async function to perform the action
 */
export const updateActivityArrayRedux = (pageNumber = 1, nrctUID, jwt) => {
  return async (dispatch, getState) => {
    const state = getState()
    const activityState = state.root.activityState
    try {
      let newNotiFications = await notificationsFetch(pageNumber, nrctUID, jwt)
      // for new accounts, the res.body was null
      if (newNotiFications === null) {
        newNotiFications = []
      }
      if (newNotiFications.length === 0) {
        if (pageNumber === 1) {
          dispatch({
            type: activityReducerActionTypes.activityRefresh,
            payload: {
              recent: [],
              week: [],
              past: [],
              hasMoreNotifications: false,
              hasNoNotifications: true
            }
          })
        } else {
          dispatch({
            type: activityReducerActionTypes.activityRefresh,
            payload: {
              hasMoreNotifications: false
            }
          })
        }
      } else {
        const notReadNotifications = newNotiFications.filter((noti) => !noti.is_read)
        const thisWeeksNotifications = newNotiFications.filter((noti) => {
          const date1 = new Date(noti.created_at)
          const date2 = new Date(Date.now())
          const diffTime = Math.abs(date2 - date1)
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
          return diffDays < 7 && noti.is_read
        })
        const pastNotifications = newNotiFications.filter((noti) => {
          const date1 = new Date(noti.created_at)
          const date2 = new Date(Date.now())
          const diffTime = Math.abs(date2 - date1)
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
          return diffDays >= 7 && noti.is_read
        })
        // if pageNumber more then 1, then already would have notifications
        if (pageNumber > 1) {
          const recent = [...activityState.recent, ...notReadNotifications]
          const week = [...activityState.week, ...thisWeeksNotifications]
          const past = [...activityState.past, ...pastNotifications]
          dispatch({
            type: activityReducerActionTypes.activityRefresh,
            payload: {
              recent,
              week,
              past,
              hasMoreNotifications: true,
              hasNoNotifications: false
            }
          })
        } else {
          dispatch({
            type: activityReducerActionTypes.activityRefresh,
            payload: {
              recent: notReadNotifications,
              week: thisWeeksNotifications,
              past: pastNotifications,
              hasMoreNotifications: true,
              hasNoNotifications: false
            }
          })
        }
      }
    } catch (err) {
      // show the error to user
      console.log(err)
    }
  }
}

/**
 * @function postsClearAllStatesRedux Used to set redux store back to initial state ( empty state ), useful when user logs out
 * @returns {function} function to perform the action
 */
export const activityClearAllStatesRedux = () => {
  return (dispatch) => dispatch({
    type: activityReducerActionTypes.activityClearAll
  })
}
