import * as React from 'react'
import { FormControl, Grid, Slider, Typography, Paper, Switch, Stack } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useSnackbar } from 'notistack'
import { Add } from '@mui/icons-material'
import WideModal from 'ui/Modals/WideModal'

// importing context
import { UserContext } from '../../../context/UserContext'
import { GlobalFuncContext } from '../../../context/GlobalFuncHOC'

// importing components
import { TextLabel } from 'ui/TextInputs/TextInput'
import CustomPriceFields from 'ui/TextInputs/PriceFieldsInput'
import TextAreaInput from 'ui/TextInputs/TextAreaInput'
import Chip from '../../Chip/Chip'
import { groupCatList } from 'api/group/cat'
import { groupCreate } from 'api/group/create'
import SelectTopicsModal from './SelectTopicsModal'
import ImagePicker from 'ui/Custom/ImagePicker/ImagePicker'

// importing helpers
import { recordGaEvent } from 'helpers/ga'

const CreateGroupModal = (props) => {
  const { open, onClose } = props
  const classes = styles()
  // component states
  const [coverImage, setCoverImage] = React.useState()
  const [name, setName] = React.useState('')
  const [description, setDescription] = React.useState('')
  const [topics, setTopices] = React.useState([])
  const [selectedTopics, setSelectedTopices] = React.useState([])
  const [miles, setMiles] = React.useState(15)
  const [isPrivateGroup, setIsPrivateGroup] = React.useState(false)
  // state for form data validation checks
  const initialHasFormErrors = {
    name: null,
    description: null,
    selectedTopics: null
  }
  const [hasFormErrors, setHasFormError] = React.useState(initialHasFormErrors)
  const [isCreatingGroup, setIsCreatingGroup] = React.useState(false)
  // state for all topics modal
  const [isTopicModalOpen, setIsTopicModalOpen] = React.useState(false)

  const { personal, jwt_token, active_neighbourhood } = React.useContext(UserContext)
  const { openCropModal } = React.useContext(GlobalFuncContext)

  const { enqueueSnackbar } = useSnackbar()

  // for loading initial state value of category
  React.useEffect(() => {
    groupCatList(0, personal[active_neighbourhood].ID, jwt_token)
      .then((topics) => {
        const sortedTopics = topics.sort((a, b) => b.firstLetter.localeCompare(a.firstLetter))
        setTopices(sortedTopics)
      })
      .catch((err) => enqueueSnackbar(err.message, { variant: 'error' }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // should return true if a valid request can be made
  const validateGroupForm = () => {
    const newHasFormErrors = initialHasFormErrors
    if (name.length === 0) {
      newHasFormErrors.name = 'Please provide a group name!'
    }
    if (description.length === 0) {
      newHasFormErrors.description = 'Please provide a group description!'
    }
    if (selectedTopics.length === 0) {
      newHasFormErrors.selectedTopics = 'Please select a few topics!'
    }

    setHasFormError(newHasFormErrors)
    if (Object.values(newHasFormErrors).find((val) => val !== null)) {
      return false
    } else {
      return true
    }
  }

  // should handle creating a group
  const onClickNext = async () => {
    setIsCreatingGroup(true)
    try {
      if (!validateGroupForm()) {
        return enqueueSnackbar('Please fill the required fields to create a group!', {
          variant: 'error'
        })
      }
      const topicIds = selectedTopics.map((topic) => topic.ID)
      await groupCreate(
        topicIds,
        [personal[active_neighbourhood].lives_in_id],
        miles,
        name,
        description,
        coverImage,
        isPrivateGroup,
        personal[active_neighbourhood].ID,
        jwt_token
      )
      onClose()
      // analytics
      recordGaEvent('groupsPage-createGroup-created', {
        personalAccountId: personal[active_neighbourhood].ID,
        groupName: name
      })
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' })
    } finally {
      setIsCreatingGroup(false)
    }
  }

  return (
        <WideModal
            isOpen={open}
            onClose={onClose}
            title="Create a Group"
            buttons={[
              {
                title: 'Cancel',
                color: 'ghost',
                onPress: onClose,
                variant: 'outlined'
              },
              {
                title: isCreatingGroup ? 'Creating' : 'Create',
                color: isCreatingGroup ? 'ghost' : 'primary',
                onPress: onClickNext
              }
            ]}
        >
            <Grid
                spacing={1}
                container
                className={classes.contentContainer}
            >
              <Grid item xs={12} md={4}>
                <FormControl fullWidth margin="normal">
                  <div
                    style={{
                      marginTop: '8px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <ImagePicker
                      id="contained-file"
                      onChange={(e) => {
                        openCropModal(
                          e.target.files[0],
                          (f) => {
                            setCoverImage(f)
                          },
                          16 / 9
                        )
                      }}
                    />
                      <label htmlFor="contained-file" style={{ alignSelf: 'stretch' }}>
                          {coverImage
                            ? (
                              <div className={classes.uploadedImage} >
                                  <img
                                      style={{
                                        maxHeight: '100%',
                                        maxWidth: '100%',
                                        borderRadius: 10
                                      }}
                                      src={URL.createObjectURL(coverImage)}
                                      alt="groupCover"
                                  />
                              </div>
                              )
                            : (
                              <div className={classes.uploadedImage} >
                                  <Paper
                                      variant="outlined"
                                      style={{
                                        width: '100%',
                                        height: '150px',
                                        borderRadius: 10,
                                        backgroundColor: '#f2f2f2',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                      }}
                                      elevation={0}
                                  >
                                      <Add
                                          htmlColor="#FFFFFF"
                                          style={{
                                            fontSize: 28,
                                            alignSelf: 'center',
                                            backgroundColor: '#bdbdbd',
                                            borderRadius: '50%',
                                            height: 50,
                                            width: 50
                                          }}
                                      />
                                  </Paper>
                              </div>
                              )}
                      </label>
                  </div>
                  <Typography variant="body2" style={{ marginTop: 4, alignSelf: 'center' }}>
                      Upload a cover photo
                  </Typography>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={8}>
                  <FormControl fullWidth margin="normal">
                      <CustomPriceFields
                          label="Group Name *"
                          placeholder="Enter a group name"
                          value={name}
                          inputProps={{ maxLength: '63' }}
                          onChange={(e) => {
                            setName(e.target.value)
                          }}
                          style={{
                            backgroundColor: '#f2f2f2',
                            borderRadius: 10
                          }}
                          error={hasFormErrors.name}
                      />
                  </FormControl>
                  <FormControl fullWidth margin="normal">
                      <TextAreaInput
                          multiline
                          rows={4}
                          label="Description*"
                          placeholder="Enter a group description"
                          value={description}
                          inputProps={{ maxLength: '255' }}
                          onChange={(e) => {
                            setDescription(e.target.value)
                          }}
                          style={{
                            backgroundColor: '#f2f2f2',
                            borderRadius: 10
                          }}
                          error={hasFormErrors.description}
                      />
                      <span style={{ fontSize: '10px', textAlign: 'right' }}>
                      {255 - description.length} characters left
                      </span>
                  </FormControl>
                  <FormControl
                      fullWidth
                      margin="normal"
                  >
                      <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                          }}
                      >
                          <TextLabel>Topics *</TextLabel>
                          <TextLabel
                              style={{
                                color: '#54897a',
                                cursor: 'pointer'
                              }}
                              onClick={() => setIsTopicModalOpen(true)}
                          >
                              See More
                          </TextLabel>
                      </div>
                      <div
                          className={classes.topicsChipWrapper}
                          style={{
                            borderStyle: hasFormErrors.selectedTopics ? 'solid' : 'none'
                          }}
                      >
                          {
                              topics.slice(0, 15).map((topic) => {
                                const isSelected = selectedTopics.find((top) => topic.ID === top.ID)
                                return (
                                      <Chip
                                          key={topic.ID}
                                          label={topic.category_name}
                                          isSelected={isSelected}
                                          onClick={() => {
                                            if (isSelected) {
                                              setSelectedTopices(selectedTopics.filter((top) => top.ID !== topic.ID))
                                            } else {
                                              setSelectedTopices([...selectedTopics, topic])
                                            }
                                          }}
                                      />
                                )
                              })
                          }
                      </div>
                  </FormControl>
                  <FormControl fullWidth margin="normal">
                      <TextLabel>
                          Boundary
                      </TextLabel>
                      <Typography variant="caption" style={{ marginBottom: 16 }}>
                          Set a boundary by selecting distance away from your Neighborhood
                      </Typography>
                      <Stack
                          spacing={2}
                          direction="row"
                          sx={{ mb: 1 }}
                          alignItems="center"
                          style={{
                            width: '80%'
                          }}
                      >
                          <Typography variant="h6">
                              1
                          </Typography>
                          <Slider
                              aria-label="Volume"
                              valueLabelDisplay="auto"
                              min={1}
                              max={30}
                              size="medium"
                              value={miles}
                              onChange={(e, value, thumb) => {
                                setMiles(value)
                              }}
                              disabled={miles === 0}
                          />
                          <Typography variant="h6">
                              30
                          </Typography>
                      </Stack>
                  </FormControl>
                  {/* <FormControl fullWidth margin="normal">
                      <TextLabel>
                          Privacy
                      </TextLabel>
                      <Typography variant="caption" style={{ marginBottom: 4 }}>
                          Would you like the new group to be private, or can anyone join it?
                      </Typography>
                      <Stack direction="row" alignItems="center">
                          <Typography variant="caption">
                              Make Private?
                          </Typography>
                          <Switch
                              checked={isPrivateGroup}
                              onChange={() => setIsPrivateGroup((p) => !p)}
                              inputProps={{ 'aria-label': 'controlled' }}
                          />
                          <Typography variant="caption">
                              {isPrivateGroup ? 'Private' : 'Public'}
                          </Typography>
                      </Stack>
                  </FormControl> */}
              </Grid>
            </Grid>
            <SelectTopicsModal
                open={isTopicModalOpen}
                topics={topics}
                selectedTopics={selectedTopics}
                onClose={() => setIsTopicModalOpen(false)}
                onSaveSelected={(selectedTopics) => setSelectedTopices(selectedTopics)}
            />
        </WideModal>
  )
}

const styles = makeStyles(({ breakpoints }) => ({
  contentContainer: {
    width: '100%'
  },
  heading: {
    fontSize: '20px',
    fontWeight: '700',
    color: '#104F3D',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  uploadedImage: {
    height: '100%',
    width: '100%',
    marginTop: 'auto',
    marginBottom: 'auto',
    textAlign: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    opacity: '0.8',
    borderRadius: 10,
    '&:hover': {
      opacity: '0.2',
      cursor: 'pointer'
    }
  },
  topicsChipWrapper: {
    padding: 8,
    borderRadius: 8,
    borderColor: 'red',
    borderWidth: 1
  }
}))

CreateGroupModal.defaultProps = {
  open: false,
  onClose: () => {}
}

export default CreateGroupModal
