import { Button, Divider, FormControl, FormHelperText } from '@mui/material'
import { featureRequestPost } from 'api/feature/request'
import { useSnackbar } from 'notistack'
import React, { useContext, useState } from 'react'
import CustomTextArea from 'ui/TextInputs/TextAreaInput'
import { UserContext } from '../../../context/UserContext'

const FeatureForm = ({ setStep, refresh }) => {
  const { personal, jwt_token, active_neighbourhood } =
    useContext(UserContext)

  const [title, setTitle] = useState('')
  const [titleErr, setTitleErr] = useState('')
  const [description, setDescription] = useState('')
  const [descErr, setDescErr] = useState('')

  const [btnLoading, setBtnLoading] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const validate = () => {
    let error = false
    if (title.trim().length === 0) {
      setTitleErr('Title is required')
      error = true
    } else {
      setTitleErr('')
    }

    if (description.trim().length === 0) {
      setDescErr('Description is required')
      error = true
    } else {
      setDescErr('')
    }

    return !error
  }

  const onSubmit = () => {
    if (!validate()) return

    setBtnLoading(true)

    featureRequestPost(
      title,
      description,
      personal[active_neighbourhood].ID,
      jwt_token
    )
      .then((res) => {
        setStep(0)
        refresh()
      })
      .catch(() => {
        enqueueSnackbar('Could not create feature request', {
          variant: 'error'
        })
        setBtnLoading(false)
      })
  }

  return (
    <div style={{
      width: '100%',
      marginTop: '10px',
      height: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column'
    }}>
      <div>
        <FormControl fullWidth>
          <CustomTextArea
            rows={1}
            label="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            error={Boolean(titleErr)}
          />
          {titleErr && <FormHelperText error>{titleErr}</FormHelperText>}
        </FormControl>
        <FormControl
          fullWidth
          style={{ marginTop: '10px', marginBottom: '20px' }}
        >
          <CustomTextArea
            rows={3}
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            error={Boolean(descErr)}
          />
          {descErr && <FormHelperText error>{descErr}</FormHelperText>}
        </FormControl>
      </div>
      <div>
        <Divider style={{ width: '100%' }} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingTop: '10px',
            gap: '10px'
          }}
        >
          <Button
            variant="outlined"
            onClick={() => setStep((prev) => prev - 1)}
          >
            Back
          </Button>
          <Button
            variant="contained"
            onClick={onSubmit}
            disabled={btnLoading}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  )
}

export default FeatureForm
