import axios from 'axios'

/**
 * @function gatheringJoin Used to get token for joining the specific gathering
 * @param {string} gatheringId - id of the gathering
 * @param {string} nrctUID - user's current neighborhood id
 * @param {string} jwt - user's jwt token
 * @returns {Promise} Promise object represents
 */
export const gatheringJoin = (gatheringId, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/gatherings/join`, {
      params: {
        gathering: gatheringId
      },
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(res.data))
      .catch((err) => {
        reject(err)
      })
  })
}
