import axios from 'axios'

/**
 * @function groupCatList Get the category list
 * @param {number} page - page number (default is 0)
 * @param {string} nrctUID - personal[active_neighbourhood].ID from user context
 * @param {string} jwt - jwt token from user context
 * @returns {Promise} Promise object represents list of categories
 */
export const groupCatList = (page = 0, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/group/cat/list?page=${page}`, {
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => {
        if (res.data.msg === 'success') {
          const newCategories = res.data.cat.map((category) => {
            const firstLetter = category.parent_category_name.toUpperCase()
            return {
              firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
              ...category
            }
          })
          resolve(newCategories)
        }
        throw new Error('Error getting category list')
      })
      .catch((err) => {
        reject('Error getting category list')
      })
  })
}
