import { Avatar, Button, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../../context/UserContext'
import { followUser, unfollowUser } from 'api/follow'
import { useSnackbar } from 'notistack'
import { personalAccountAvatar } from 'api/personal_account/avatar'

const NhProfileCard = ({ person, style, i, changeFollowState }) => {
  const { personal, active_neighbourhood, jwt_token } =
    useContext(UserContext)

  const [avatarUrl, setAvatarUrl] = useState('')
  const [btnLoading, setBtnLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const getAvatar = async () => {
    const url = personalAccountAvatar(person.user_id, 1600000000000) // Give constant timestamp for better caching
    setAvatarUrl(url)
  }

  useEffect(() => {
    getAvatar()
  }, [])

  const btnHandler = () => {
    setBtnLoading(true)
    if (person.is_following) {
      unfollowHandler()
    } else {
      followHandler()
    }
    setBtnLoading(false)
  }

  const unfollowHandler = async () => {
    try {
      await unfollowUser(
        person.user_id,
        personal[active_neighbourhood].ID,
        jwt_token
      )
      changeFollowState(person.user_id)
    } catch (error) {
      console.log(error)
      enqueueSnackbar('Could not unfollow user', {
        variant: 'error'
      })
    }
  }

  const followHandler = async () => {
    try {
      await followUser(
        person.user_id,
        personal[active_neighbourhood].ID,
        jwt_token
      )
      changeFollowState(person.user_id)
    } catch (error) {
      console.log(error)
      enqueueSnackbar('Could not follow user', {
        variant: 'error'
      })
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        ...style
      }}
    >
      <div style={{ display: 'flex', gap: '20px' }}>
        <Avatar
          sx={{ width: '50px', height: '50px' }}
          src={avatarUrl}
        />
        <div style={{ width: '100%' }}>
          <Typography
            style={{
              width: 150,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }}
          >
            {person.first_name} {person.last_name}
          </Typography>
          <Typography
            style={{
              color: '#828282',
              fontSize: 14,
              width: 150,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }}
          >
            @{person.username}
          </Typography>
        </div>
      </div>
      <Button
        variant={person.is_following ? 'outlined' : 'contained'}
        size="small"
        disableElevation
        sx={{ height: 32, width: 120 }}
        onClick={btnHandler}
        disabled={btnLoading}
      >
        {person.is_following
          ? 'Following'
          : person.is_following_back
            ? 'Follow Back'
            : 'Follow'}
      </Button>
    </div>
  )
}

export default NhProfileCard
