import {
  Button,
  Grid,
  useMediaQuery,
  Dialog,
  DialogContent,
  useTheme,
  IconButton,
  Input,
  Stack,
  CircularProgress,
  Typography
} from '@mui/material'
import React, { useRef, useState, useContext, useEffect } from 'react'
import AudioComponent from '../AudioComponent/AudioComponent'
import VideoComponent from '../VideoComponent/VideoComponent'
import Controls from '../Controls/Controls'
import Sidebar from '../Sidebar/Sidebar'
import PrecallScreen from '../PrecallScreen/PrecallScreen'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { UserContext } from '../../../context/UserContext'
import useAgora from '../../../utils/useAgora'
import GatheringHeader from '../GatheringHeader/GatheringHeader'
import { ExitToApp, FilterNone, Mic, Videocam } from '@mui/icons-material'
import { useSnackbar } from 'notistack'

// importing apis
import { fetchProfile } from 'api/fetch/profile'
import { gatheringJoin } from 'api/gatherings/join'
import { gatheringPreview } from 'api/gatherings/preview'
import { gatheringsRegister } from 'api/gatherings/register'
import { gatheringInfo } from 'api/gatherings/info'
import { gatheringRecord, gatheringRecordStop } from 'api/gatherings/record'

// importing helpers
import { getUserDomainPreviewUrl } from 'helpers/url'

const appId = process.env.REACT_APP_AGORA_APP_ID

function GatheringScreen () {
  const { id } = useParams()
  const [gatheringName, setName] = useState('')
  const [gatheringDesc, setDesc] = useState('')
  const [sidebar, setSidebar] = useState(window.innerWidth > 991)
  const [popUpAlert, setPopUpAlert] = useState(false)
  const [info, setInfo] = useState(false)
  const [infoData, setInfoData] = useState({})
  const [loading, setLoading] = useState(true)
  const [notRegistered, setNotRegistered] = useState(false)
  const [expired, setExpired] = useState(false)
  const [open, setOpen] = useState(false)
  const [initDgOpen, setInitDgOpen] = useState(false)
  const [endTime, setEndTime] = useState('')
  const [timer, setTimer] = useState(99999)
  const [preCall, setPrecall] = useState(true)
  const [loginPrompt, setLoginPrompt] = useState(false)

  const inviteMessage = useRef({
    subject: '',
    body: ''
  })

  // Media Query for mobile screens
  const small = useMediaQuery('(max-width: 653px)')
  const medium = useMediaQuery('(max-width: 991px)')
  const large = useMediaQuery('(max-width: 1200px)')

  const navigate = useNavigate()
  const location = useLocation()

  const { personal, jwt_token, user, active_neighbourhood, logout } =
    useContext(UserContext)
  const { palette } = useTheme()
  const { enqueueSnackbar } = useSnackbar()

  const redirectToLogin = () => {
    navigate('/login', {
      state: { pathname: location.pathname }
    })
  }

  const refreshTokenAPI = async (expired) => {
    if (jwt_token) {
      if (expired) {
        return null
      } else {
        gatheringJoin(id, personal[active_neighbourhood].ID, jwt_token)
          .then((resData) => {
            return resData.token
          })
          .catch((err) => {
            enqueueSnackbar(err.message, {
              variant: 'error'
            })
            setExpired(true)
            return null
          })
      }
    } else {
      if (expired) {
        enqueueSnackbar('Login or Register to re-join the gathering.', {
          variant: 'error'
        })
        redirectToLogin()
        return null
      } else {
        setLoginPrompt(true)
        return null
      }
    }
  }

  const {
    loginRTM,
    messages,
    sendMessage,
    users,
    hosts,
    preJoinRTC,
    postJoinRTC,
    remoteUsers,
    localTracks,
    forceUpdate,
    leave,
    video,
    switchType,
    isAudioMuted,
    audioSwitch,
    isVideoMuted,
    videoSwitch,
    isHandRaised,
    raiseHand,
    localUser,
    requestTokenChange,
    updateLists,
    volumes,
    record,
    recordingButton,
    sendKickMessage,
    error,
    setError
  } = useAgora(appId, refreshTokenAPI)

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname)
    window.addEventListener('popstate', onBackButton)
    window.addEventListener('onBeforeUnload', () => {
      setPrecall(true)
    })
    return () => window.removeEventListener('popstate', onBackButton)
    // eslint-disable-next-line
  }, [sidebar, popUpAlert]);

  useEffect(() => {
    let interval
    if (endTime !== '') {
      const end = new Date(endTime)
      const now = new Date()
      if (end - now < 30 * 1000) {
        setExpired(true)
      } else {
        setTimer(Math.floor((end - now - 30000) / 1000))
        interval = setInterval(() => {
          setTimer((prev) => prev - 1)
        }, 1000)
      }
    }
    return () => {
      clearInterval(interval)
    }
  }, [endTime])

  useEffect(() => {
    if (timer === 0) {
      leave()
      setExpired(true)
    }
    // eslint-disable-next-line
  }, [timer]);

  useEffect(() => {
    if (jwt_token) gatheringInfoAPI()
    else {
      joinTempGatheringAPI()
    }
    // setLoading(false);
    return () => {
      leave()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onBackButton = () => {
    if (sidebar) {
      setSidebar(false)
    } else if (popUpAlert) {
      setPopUpAlert(false)
    } else {
      setPopUpAlert(true)
    }
    window.history.pushState(null, null, window.location.pathname)
  }

  const postCall = async () => {
    setPrecall(false)
    // await leave();
    // joinGatheringAPI();
    postJoinRTC(localUser.current.isHost)
    setInitDgOpen(localUser.current.isHost)
  }

  const joinTempGatheringAPI = async () => {
    setLoading(true)
    setError(false)
    setExpired(false)
    setNotRegistered(false)
    gatheringPreview(id)
      .then((resData) => {
        setName(resData.data.name)
        setDesc(resData.data.description)
        setEndTime(resData.data.ends_at)
        setInfoData(resData.data)
        loginRTM(
          `guest-${resData.token.uid}`,
          resData.token.rtm,
          id,
          resData.token.uid,
          `guest-${resData.token.uid}`,
          resData.role
        )
        preJoinRTC(
          id,
          resData.token.rtc,
          resData.uid,
          resData.role !== null &&
          resData.role !== 4 &&
          resData.role !== undefined
        )
      })
      .catch((err) => {
        if (err.response && err.response?.data?.status === 1001) {
          setExpired(true)
        } else {
          enqueueSnackbar(err.message, {
            variant: 'error'
          })
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const joinGatheringAPI = async () => {
    setLoading(true)
    setError(false)
    setExpired(false)
    setNotRegistered(false)
    gatheringJoin(id, personal[active_neighbourhood].ID, jwt_token)
      .then((resData) => {
        setName(resData.data.name)
        setDesc(resData.data.description)
        setEndTime(resData.data.ends_at)
        inviteMessage.current = {
          subject: `Join the Nearcast Gathering, "${resData.data.name}" | Invited by ${user.username}`,
          body: `Hello, I am ${user.username}, "${
            resData.data.name
          }" gathering is starting now. Join using the given link:-%0D%0A ${window.location
            .toString()
            .split('/')
            .slice(0, 3)
            .join('/')}/gathering/${id}`
        }
        try {
          loginRTM(
            personal[active_neighbourhood].ID,
            resData.token.rtm,
            id,
            resData.token.uid,
            user.username,
            resData.role
          )
          preJoinRTC(
            id,
            resData.token.rtc,
            resData.token.uid,
            resData.role !== null &&
            resData.role !== 4 &&
            resData.role !== undefined
          )
        } catch (err) {
          enqueueSnackbar(err.message, {
            variant: 'error'
          })
        }
      })
      .catch((err) => {
        if (err.response && err.response.data.msg === 'gathering ended') {
          setExpired(true)
          enqueueSnackbar('Ops the gathering has ended!', {
            variant: 'info'
          })
        } else {
          setError(true)
        }
      })
      .finally(() => setLoading(false))
  }

  const registerGatheringAPI = async () => {
    setError(false)
    setExpired(false)
    gatheringsRegister(id, personal[active_neighbourhood].ID, jwt_token)
      .then(() => {
        gatheringInfoAPI()
        setNotRegistered(false)
      })
      .catch((err) => {
        setError(true)
        enqueueSnackbar(err.message, {
          variant: 'error'
        })
      })
  }

  const gatheringInfoAPI = async () => {
    setLoading(true)
    gatheringInfo(id, personal[active_neighbourhood].ID, jwt_token)
      .then(async (resData) => {
        // TODO: Might break with two hosts
        if (resData.member_data !== null) {
          const userInfo = await fetchProfile(
            resData.member_data.personal_account_id,
            personal[active_neighbourhood].ID,
            jwt_token
          )
          const completeInfo = { ...resData, hostInfo: userInfo }
          setInfoData(completeInfo)
          joinGatheringAPI()
        } else {
          setNotRegistered(true)
        }
        setLoading(false)
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          enqueueSnackbar('Session expired please login again', {
            variant: 'error'
          })
          logout()
          navigate('/')
        } else {
          enqueueSnackbar(err.message, {
            variant: 'error'
          })
        }
        setError(true)
        setLoading(false)
      })
  }

  const recordAPI = async () => {
    if (!record) {
      gatheringRecord(id, personal[active_neighbourhood].ID, jwt_token)
        .then(() => recordingButton())
        .catch(() => {
          enqueueSnackbar('Could not start recording!', {
            variant: 'error'
          })
        })
    } else {
      gatheringRecordStop(id, personal[active_neighbourhood].ID, jwt_token)
        .then(() => {
          enqueueSnackbar('Recording stopped', {
            variant: 'success'
          })
          recordingButton()
        })
        .catch((err) => {
          enqueueSnackbar('Could not stop recording!', {
            variant: 'error'
          })
          recordingButton()
        })
    }
  }

  useEffect(() => {
    if (notRegistered) {
      registerGatheringAPI()
    }
  }, [notRegistered])

  if (loading) {
    return (
      <Grid container style={{ overflowX: 'hidden', height: '100vh' }}>
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            height: '100%',
            width: '100%'
          }}
        >
          <CircularProgress/>
          <Typography variant="h3" sx={{ marginTop: 4 }}>
            Loading Gathering Information
          </Typography>
        </Stack>
      </Grid>
    )
  }

  if (notRegistered) {
    return (
      <Grid container style={{ overflowX: 'hidden', height: '100vh' }}>
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            height: '100%',
            width: '100%'
          }}
        >
          <CircularProgress/>
          <Typography variant="h3" sx={{ marginTop: 4 }}>
            Registering For Gathering
          </Typography>
        </Stack>
      </Grid>
    )
  }

  // if (notRegistered) {
  //   return (
  //     <Grid
  //       container
  //       justifyContent="center"
  //       alignItems="center"
  //       direction="column"
  //       style={{ minHeight: '90vh' }}
  //     >
  //       <h2>Looks like you aren't registered for the gathering.</h2>
  //       <h4>Should I try registering you?</h4>
  //       <Grid
  //         container
  //         spacing={2}
  //         style={{ justifyContent: 'center' }}
  //         alignItems="center"
  //       >
  //         <Grid item>
  //           <Button
  //             onClick={registerGatheringAPI}
  //             variant="contained"
  //             disableElevation
  //             color="primary"
  //           >
  //             Register
  //           </Button>
  //         </Grid>
  //         <Grid item>
  //           <Button
  //             onClick={() => {
  //               navigate('/home/gathering')
  //             }}
  //             variant="contained"
  //             disableElevation
  //             color="secondary"
  //           >
  //             Go Back
  //           </Button>
  //         </Grid>
  //       </Grid>
  //     </Grid>
  //   )
  // }

  if (expired) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ minHeight: '90vh' }}
      >
        <h2>The gathering has ended.</h2>
        <Button
          onClick={() => {
            navigate('/home')
          }}
          variant="contained"
          disableElevation
          color="primary"
        >
          Back to Homescreen
        </Button>
      </Grid>
    )
  }

  if (error) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ minHeight: '90vh' }}
      >
        <h4>Oops! Something went wrong.</h4>
        <h2 style={{ paddingBottom: '20px' }}>
          Please try joining the gathering again.
        </h2>
        <Grid
          container
          spacing={2}
          style={{ justifyContent: 'center' }}
          alignItems="center"
        >
          <Grid item>
            <Button
              onClick={() => {
                leave()
                joinGatheringAPI()
              }}
              variant="contained"
              disableElevation
              color="primary"
            >
              Retry Joining
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                navigate('/home/gathering')
              }}
              variant="contained"
              disableElevation
              color="secondary"
            >
              Go Back
            </Button>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  if (preCall) {
    return (
      <PrecallScreen
        jwt_token={jwt_token}
        postCall={postCall}
        redirectToLogin={redirectToLogin}
        loginPrompt={loginPrompt}
        palette={palette}
        info={infoData}
      >
        {video
          ? (
          <VideoComponent
            remoteUsers={remoteUsers}
            isVideoMuted={isVideoMuted}
            isAudioMuted={isAudioMuted}
            localTracks={localTracks}
            forceUpdate={forceUpdate}
            localUser={localUser}
            volumes={volumes}
            hosts={hosts}
            media={{ medium, small }}
          />
            )
          : (
          <AudioComponent
            hosts={hosts}
            remoteUsers={remoteUsers}
            isAudioMuted={isAudioMuted}
            forceUpdate={forceUpdate}
            volumes={volumes}
            media={{ medium, small }}
          />
            )}
      </PrecallScreen>
    )
  }

  return (
    <Grid container style={{ overflowX: 'hidden' }}>
      <GatheringHeader
        name={gatheringName}
        link={getUserDomainPreviewUrl(`/gathering/${id}`)}
        media={{ medium, small }}
        setCopySuccess={() =>
          enqueueSnackbar('Invite link copied to clipboard.', {
            variant: 'success'
          })
        }
        setCopyFailed={() =>
          enqueueSnackbar("Couldn't copy link, please try again.", {
            variant: 'error'
          })
        }
        setInfo={setInfo}
        leave={setPopUpAlert}
        record={record}
      />
      {timer > 3 * 60
        ? (
        <></>
          )
        : (
        <div
          style={{
            background: palette.error.light,
            color: palette.error.dark,
            width: '100%',
            height: 20,
            textAlign: 'center',
            padding: 6,
            fontWeight: 600
          }}
        >
          Gathering ends in 0{Math.floor(timer / 60)}:
          {Math.floor(timer - 60 * Math.floor(timer / 60)) < 10
            ? `0${Math.floor(timer - 60 * Math.floor(timer / 60))}`
            : Math.floor(timer - 60 * Math.floor(timer / 60))}
        </div>
          )}
      {small && localUser.current.isHost && (
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            width: '100%'
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpen(true)}
            size="small"
            disableElevation
            sx={{
              width: '96%'
            }}
          >
            {video ? 'Switch to Audio Gathering' : 'Switch to Video Gathering'}
          </Button>
        </Stack>
      )}
      <Grid
        item
        container
        xs={12}
        style={{
          width: small || !sidebar ? '100%' : 'calc(100% - 422px)',
          transition: '0.3s all ease-in',
          height:
            (small && video && localUser.current.isHost) || timer < 3 * 60
              ? 'calc(100vh - 165px)'
              : small && video && localUser.current.isHost && timer < 3 * 60
                ? 'calc(100vh - 192px)'
                : 'calc(100vh - 135px)',
          flexBasis: 'auto',
          overflowY: 'auto',
          margin: 0,
          backgroundColor: 'white'
        }}
        justifyContent="center"
        spacing={1}
      >
        {video
          ? (
              <VideoComponent
                remoteUsers={remoteUsers}
                isVideoMuted={isVideoMuted}
                isAudioMuted={isAudioMuted}
                localTracks={localTracks}
                forceUpdate={forceUpdate}
                localUser={localUser}
                volumes={volumes}
                hosts={hosts}
                media={{ medium, small }}
              />
            )
          : (
              <AudioComponent
                hosts={hosts}
                remoteUsers={remoteUsers}
                isAudioMuted={isAudioMuted}
                forceUpdate={forceUpdate}
                volumes={volumes}
                media={{ medium, small }}
              />
            )}
      </Grid>
      <Controls
        sidebar={sidebar}
        setSidebar={setSidebar}
        video={video}
        switchType={switchType}
        isAudioMuted={isAudioMuted}
        setIsAudioMuted={audioSwitch}
        isVideoMuted={isVideoMuted}
        setIsVideoMuted={videoSwitch}
        isHandRaised={isHandRaised}
        raiseHand={raiseHand}
        forceUpdate={forceUpdate}
        localTracks={localTracks}
        localUser={localUser}
        media={{ medium, small, large }}
        setOpen={setOpen}
        record={record}
        setRecord={recordAPI}
      />
      <Sidebar
        open={sidebar}
        setSidebar={setSidebar}
        messages={messages}
        sendMessage={sendMessage}
        users={users}
        localUser={localUser}
        sendKickMessage={sendKickMessage}
        requestTokenChange={requestTokenChange}
        updateLists={updateLists}
        forceUpdate={forceUpdate}
        media={{ medium, small }}
        inviteMessage={inviteMessage}
      />
      <Dialog
        open={popUpAlert}
        onClose={() => setPopUpAlert(false)}
        aria-labelledby="gathering-switch"
        PaperProps={{
          style: {
            maxWidth: 400,
            padding: 10,
            textAlign: 'center',
            borderRadius: 10
          }
        }}
      >
        <DialogContent>
          <div
            style={{
              borderRadius: 36,
              width: 72,
              height: 72,
              backgroundColor: `${palette.error.main}`,
              display: 'grid',
              justifyItems: 'center',
              alignItems: 'center',
              margin: 'auto'
            }}
          >
            <ExitToApp htmlColor="#FFFFFF" style={{ fontSize: '2.5rem' }} />
          </div>
          <h3>Leaving?</h3>
          <div style={{ marginBottom: '20px' }}>
            Are you sure that you want to leave the gathering?
          </div>
          <Button
            onClick={() => setPopUpAlert(false)}
            style={{
              width: '100%',
              borderRadius: '10px',
              padding: '12px 12px 9px',
              color: 'white',
              margin: '5px 0',
              background: palette.primary.main
            }}
          >
            Stay Back
          </Button>
          <Button
            onClick={() => {
              // leave();
              setPopUpAlert(false)
              navigate('/')
            }}
            style={{
              width: '100%',
              borderRadius: '10px',
              padding: '11px 11px 8px',
              margin: '5px 0',
              color: `${palette.error.main}`,
              border: `1px solid ${palette.error.main}`
            }}
          >
            Leave Gathering
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        open={info}
        onClose={() => setInfo(false)}
        aria-labelledby="gathering-switch"
        PaperProps={{
          style: {
            maxWidth: 400,
            padding: 10,
            textAlign: 'center',
            borderRadius: 10
          }
        }}
      >
        <DialogContent>
          <h3>{gatheringName}</h3>
          <div style={{ paddingBottom: '20px' }}>{gatheringDesc}</div>
          <Input
            value={getUserDomainPreviewUrl(`/gathering/${id}`)}
            disabled={true}
            disableUnderline={true}
            style={{
              marginLeft: 'auto',
              padding: '10px 10px',
              background: '#efefefc4',
              width: '100%',
              borderRadius: 10,
              color: '#4c4c4c',
              fontSize: 16,
              marginBottom: 10
            }}
            endAdornment={
              <IconButton
                size="small"
                style={{ marginLeft: 5 }}
                onClick={() => {
                  navigator.clipboard
                    .writeText(
                      `${window.location
                        .toString()
                        .split('/')
                        .slice(0, 3)
                        .join('/')}/gathering/${id}`
                    )
                    .then(() => {
                      enqueueSnackbar('Invite link copied to clipboard.', {
                        variant: 'success'
                      })
                    })
                    .catch(() => {
                      enqueueSnackbar("Couldn't copy link, please try again.", {
                        variant: 'error'
                      })
                    })
                }}
              >
                <FilterNone htmlColor="#4c4c4c" style={{ fontSize: 18 }} />
              </IconButton>
            }
          ></Input>
          <Button
            onClick={() => setInfo(false)}
            style={{
              width: '100%',
              borderRadius: '10px',
              padding: '12px 12px 9px',
              color: 'white',
              margin: '5px 0',
              background: palette.primary.main,
              marginBottom: '25px'
            }}
          >
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="gathering-switch"
        PaperProps={{
          style: {
            maxWidth: 400,
            padding: 10,
            textAlign: 'center',
            borderRadius: 10
          }
        }}
      >
        <DialogContent>
          {video
            ? (
            <>
              <div
                style={{
                  borderRadius: 36,
                  width: 72,
                  height: 72,
                  backgroundColor: palette.primary.main,
                  display: 'grid',
                  justifyItems: 'center',
                  alignItems: 'center',
                  margin: 'auto'
                }}
              >
                <Mic style={{ fontSize: '2.5rem' }} htmlColor="#FFFFFF" />
              </div>
              <br />
              <h3>Switch to Audio Gathering?</h3>
              <div style={{ marginBottom: '20px' }}>
                This will switch your gathering to audio mode only for all.
              </div>
            </>
              )
            : (
            <>
              <div
                style={{
                  borderRadius: 36,
                  width: 72,
                  height: 72,
                  backgroundColor: palette.primary.main,
                  display: 'grid',
                  justifyItems: 'center',
                  alignItems: 'center',
                  margin: 'auto'
                }}
              >
                <Videocam style={{ fontSize: '2.5rem' }} htmlColor="#FFFFFF" />
              </div>
              <br />
              <h3>Switch to Video Gathering?</h3>
              <div style={{ marginBottom: '20px' }}>
                This will switch your gathering to video mode. Your camera will
                be turned on.
              </div>
            </>
              )}
          <Button
            onClick={() => {
              switchType()
              setOpen(false)
            }}
            style={{
              width: '100%',
              borderRadius: '10px',
              padding: '12px 12px 9px',
              color: 'white',
              margin: '5px 0',
              background: palette.primary.main
            }}
          >
            Switch to {video ? 'Audio' : 'Video'} Mode
          </Button>
          <Button
            onClick={() => setOpen(false)}
            style={{
              width: '100%',
              borderRadius: '10px',
              padding: '12px 12px 9px',
              margin: '5px 0',
              background: '#efefef'
            }}
          >
            Cancel
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        open={initDgOpen}
        onClose={() => setInitDgOpen(false)}
        aria-labelledby="Info"
        PaperProps={{
          style: {
            maxWidth: 400,
            padding: 10,
            textAlign: 'center',
            borderRadius: 10
          }
        }}
      >
        <DialogContent>
          <>
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  borderRadius: 36,
                  width: 72,
                  height: 72,
                  backgroundColor: palette.primary.main,
                  display: 'grid',
                  justifyItems: 'center',
                  alignItems: 'center',
                  margin: 'auto'
                }}
              >
                <Mic style={{ fontSize: '2.5rem' }} htmlColor="#FFFFFF" />
              </div>
              <div
                style={{
                  borderRadius: 36,
                  width: 72,
                  height: 72,
                  backgroundColor: palette.primary.main,
                  display: 'grid',
                  justifyItems: 'center',
                  alignItems: 'center',
                  margin: 'auto'
                }}
              >
                <Videocam style={{ fontSize: '2.5rem' }} htmlColor="#FFFFFF" />
              </div>
            </div>
            <br />
            <h3>Starting gathering</h3>
            <div style={{ marginBottom: '20px' }}>
              Camera and mic will be activated.
              <br />
              {/* Do you want to record this gathering? */}
            </div>
          </>
          <Button
            onClick={() => {
              // recordAPI()
              setInitDgOpen(false)
            }}
            style={{
              width: '100%',
              borderRadius: '10px',
              padding: '12px 12px 9px',
              color: 'white',
              margin: '5px 0',
              background: palette.primary.main
            }}
          >
            Yes
          </Button>
          <Button
            onClick={() => setInitDgOpen(false)}
            style={{
              width: '100%',
              borderRadius: '10px',
              padding: '12px 12px 9px',
              margin: '5px 0',
              background: '#efefef'
            }}
          >
            No
          </Button>
        </DialogContent>
      </Dialog>
    </Grid>
  )
}

export default GatheringScreen
