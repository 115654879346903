import axios from 'axios'

/**
 * @function groupDeleteById Used to delete one of the user's groups by using group's id
 * @param {string} groupId - group's id that needs to be deleted
 * @param {string} nrctUID - personal[active_neighbourhood].ID from user context
 * @param {string} jwt - jwt from user context
 * @returns {Promise} Promise object representing true if group was deleted
 */
export const groupDeleteById = (groupId, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.delete(`${process.env.REACT_APP_BACKEND_URL}/group/id/${groupId}`, {
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then(() => resolve(true))
      .catch((err) => {
        reject(new Error('Unable to delete the group!'))
      })
  })
}
