import InfluencerSignUp from '../../components/UserDash/Influencer/InfluencerSignUp'

function InfluencerDash (props) {
  return (
    <div style={{ padding: '15px' }}>
      <InfluencerSignUp />
    </div>
  )
}

export default InfluencerDash
