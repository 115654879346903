import { makeStyles } from '@mui/styles'
import React from 'react'
import { useNavigate as useHistory, useLocation } from 'react-router-dom'
import Nearcast from 'ui/Icons/Nearcast'

// importing icons
import homeIcon from '../../assets/SidebarIcons/SidebarHome.svg'
import gatheringIcon from '../../assets/SidebarIcons/SidebarGathering.svg'
import groupIcon from '../../assets/SidebarIcons/SidebarGroup.svg'
import businessIcon from '../../assets/SidebarIcons/SidebarBusiness.svg'

const style = makeStyles(({ breakpoints, palette }) => {
  return {
    sideBarElement: {
      display: 'flex',
      alignItems: 'center',
      margin: 12,
      marginTop: 10,
      fontWeight: 500,
      width: 170,
      marginLeft: '5px',
      padding: '10px 20px',
      borderRadius: 5,
      cursor: 'pointer',
      '&:hover': {
        background: '#F0F3F9'
      }
    },
    sideBarElementActive: {
      backgroundColor: palette.primary.main,
      color: 'white',
      '&:hover': {
        backgroundColor: `${palette.primary.main}de`
      }
    },
    sideBarElementInactive: {
      backgroundColor: '#F4F7FD',
      color: 'black',
      '&:hover': {
        backgroundColor: '#DAE1EF'
      }
    },
    iconDiv: {
      display: 'block',
      marginRight: '10px',
      width: '20px',
      paddingLeft: '5px',
      paddingTop: '0.5px'
    },
    iconDivActive: {
      filter: 'invert(1)'
    },
    root: {
      left: 0,
      top: 64,
      background: '#FFFFFF',
      zIndex: 1
      // eslint-disable-next-line no-useless-computed-key
    }
  }
})

export function SidebarElement (props) {
  const classes = style()

  return (
    <div
      onClick={props.onClick}
      className={`${classes.sideBarElement} ${props.pathCheck ? classes.sideBarElementActive : classes.sideBarElementInactive
        }`}
      style={props.style}
    >
      <div className={classes.iconDiv}>
        <img
          className={props.pathCheck ? classes.iconDivActive : null}
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            display: 'block',
            margin: 'auto'
          }}
          src={props.icon}
          alt="home"
        />
      </div>
      <div>{props.children}</div>
    </div>
  )
}

// function SidebarDrawer(props) {
//   const classes = style();
//   const location = useLocation();
//   const history = useHistory();

//   return (
//     <div className={classes.root}>
//       <Nearcast
//         height={27}
//         style={{
//           display: "block",
//           alignSelf: "center",
//           marginInline: 'auto',
//           marginTop: '30px',
//           marginBottom: '30px',
//         }}
//         htmlColor={"#0C3A2D"} />
//       <SidebarElement
//         onClick={() => history.push("/home")}
//         pathCheck={location.pathname === "/home"}
//         icon={homeIcon}
//       >
//         Home
//       </SidebarElement>
//       <SidebarElement
//         onClick={() => history.push("/gathering")}
//         pathCheck={
//           location.pathname === "/gathering" ||
//           location.pathname === "/gathering/create"
//         }
//         icon={gatheringIcon}
//       >
//         Gatherings
//       </SidebarElement>
//       <SidebarElement
//         onClick={() => history.push("/group")}
//         pathCheck={location.pathname === "/group"}
//         icon={groupIcon}
//       >
//         Groups
//       </SidebarElement>
//       <SidebarElement
//         onClick={() => window.open("https://business.nearcast.com")}
//         pathCheck={location.pathname === "/business/create"}
//         icon={businessIcon}
//       >
//         Create Business
//       </SidebarElement>
//     </div>
//   );
// }

// export default SidebarDrawer;
