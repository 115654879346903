import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  Grid,
  Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { CheckCircle, Error } from '@mui/icons-material'
import React from 'react'
import Header from '../../Header/Header'
import getName from '../../../utils/getName'
import { useNavigate } from 'react-router-dom'

function PrecallScreen ({
  jwt_token,
  postCall,
  redirectToLogin,
  loginPrompt,
  palette,
  setLoginPrompt,
  info,
  children
}) {
  const classes = styles()
  const experience = [
    'Free to use and join',
    'Ask questions and interact with host',
    'Interact with other neighbors',
    'New way to interact with your neighbors',
    'Make your own neighborhood livestreams'
  ]
  const navigate = useNavigate()

  return (
    <Grid container>
      <Header />
      <Grid container item md="auto" lg={1} />
      <Grid
        container
        item
        justifyContent="center"
        alignItems="center"
        className={classes.mainContainer}
        lg={10}
        md={12}
      >
        <Grid
          item
          container
          md={8}
          xs={12}
          justifyContent="center"
          alignItems="center"
          className={classes.previewContainer}
        >
          <div
            style={{
              width: '100%',
              height: '100%',
              backgroundColor: 'gray',
              borderRadius: '8px'
            }}
          >
            <Grid
              item
              container
              xs={12}
              style={{
                width: '100%',
                transition: '0.3s all ease-in',
                height: '100%',
                flexBasis: 'auto',
                overflowY: 'auto',
                margin: 0,
                marginLeft: 'auto',
                marginRight: 'auto'
              }}
              spacing={1}
            >
              {children}
            </Grid>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {jwt_token
            ? (
                info.gathering
                  ? (
              <>
                <Typography variant="h2" className={classes.gatheringHeading}>
                  {info.gathering.name}
                  <span
                    style={{ fontWeight: 'normal', textDecoration: 'unset' }}
                  >
                    {' '}
                    livestream gathering
                  </span>
                  {info.hostInfo && (
                    <>
                      <span
                        style={{
                          fontWeight: 'normal',
                          textDecoration: 'unset'
                        }}
                      >
                        {' '}
                        by{' '}
                      </span>
                      {getName(info.hostInfo)}
                      <span style={{ fontWeight: 'normal' }}> in </span>
                      {`${info.hostInfo.name}, ${info.hostInfo.metro}`}
                    </>
                  )}
                </Typography>
                {!info.hostInfo && (
                  <Card className={classes.nearcastExperienceCard}>
                    <CardHeader title="Full experience by Joining Nearcast" />
                    <CardContent>
                      {experience.map((e, i) => (
                        <div
                          className={classes.nearcastBenefit}
                          key={'beneifit' + i}
                        >
                          <CheckCircle
                            fontSize="small"
                            color="primary"
                            style={{ float: 'left', marginRight: '20px' }}
                          />
                          <div style={{ float: 'left' }}>{e}</div>
                        </div>
                      ))}
                    </CardContent>
                  </Card>
                )}
                <Grid
                  container
                  spacing={2}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Grid item>
                    <Button
                      onClick={postCall}
                      variant="contained"
                      disableElevation
                      color="primary"
                    >
                      Join
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={() => {
                        navigate("/")
                      }}
                      variant="contained"
                      disableElevation
                    >
                      Go Back
                    </Button>
                  </Grid>
                </Grid>
              </>
                    )
                  : (
              <Typography variant="h2" className={classes.gatheringHeading}>
                Loading gathering info...
              </Typography>
                    )
              )
            : (
            <>
              <Typography variant="h2" className={classes.gatheringHeading}>
                <span style={{ fontWeight: 'normal', textDecoration: 'unset' }}>
                  {' '}
                  livestream gathering
                </span>
              </Typography>
              <Card className={classes.nearcastExperienceCard}>
                <CardHeader title="Full experience by Joining Nearcast" />
                <CardContent>
                  {experience.map((e, i) => (
                    <div
                      className={classes.nearcastBenefit}
                      key={'beneifit' + i}
                    >
                      <CheckCircle
                        fontSize="small"
                        color="primary"
                        style={{ float: 'left', marginRight: '20px' }}
                      />
                      <div style={{ float: 'left' }}>{e}</div>
                    </div>
                  ))}
                </CardContent>
              </Card>
              <Button
                onClick={redirectToLogin}
                variant="contained"
                disableElevation
                color="primary"
              >
                Login/Signup
              </Button>
            </>
              )}
        </Grid>
      </Grid>
      <Grid container item md="auto" lg={1} />
      <Dialog
        open={loginPrompt}
        onClose={() => setLoginPrompt(false)}
        aria-labelledby="login-prompt"
        PaperProps={{
          style: {
            maxWidth: 400,
            padding: 10,
            textAlign: 'center',
            borderRadius: 10
          }
        }}
      >
        <DialogContent>
          <>
            <div
              style={{
                borderRadius: 36,
                width: 72,
                height: 72,
                backgroundColor: palette.primary.main,
                display: 'grid',
                justifyItems: 'center',
                alignItems: 'center',
                margin: 'auto'
              }}
            >
              <Error style={{ fontSize: '2.5rem' }} htmlColor="#FFFFFF" />
            </div>
            <br />
            <h3>Signup to continue</h3>
            <div>You need to sign up to continue the gathering</div>
          </>
          <Button
            onClick={redirectToLogin}
            style={{
              width: '100%',
              borderRadius: '24px/50%',
              padding: '12px 12px 9px',
              color: 'white',
              margin: '5px 0',
              background: palette.primary.main
            }}
          >
            Login/Signup
          </Button>
        </DialogContent>
      </Dialog>
    </Grid>
  )
}

const styles = makeStyles(({ breakpoints }) => ({
  mainContainer: {
    minHeight: '90vh',
    marginTop: '56px',
    [breakpoints.down('md')]: {
      marginTop: '66px',
      paddingBottom: '200px'
    }
  },
  previewContainer: {
    padding: 10,
    height: '80%',
    width: '100%',
    [breakpoints.down('md')]: {
      height: '50%'
    }
  },
  gatheringHeading: {
    textAlign: 'center',
    marginBottom: '16px',
    marginInline: '8px',
    [breakpoints.down('md')]: {
      marginTop: '8px',
      marginBottom: '8px',
      marginInline: '16px'
    }
  },
  nearcastExperienceCard: {
    margin: '16px 8px',
    '& .MuiCardHeader-root': {
      paddingBottom: '0px'
    },
    '& .MuiCardContent-root': {
      paddingBottom: '0px',
      paddingTop: '0px'
    }
  },
  nearcastBenefit: {
    display: 'flex',
    alignItems: 'center',
    marginBlock: '20px'
  }
}))

export default PrecallScreen
