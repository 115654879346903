import React from 'react'
import { Divider, Grid, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

const GatheringGroupEmptyState = ({ mainImage, emptyMessage, listItems }) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Grid container>
      <Grid
        item
        md={6}
        xs={12}
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          padding: '20px'
        }}
      >
        <div style={{ width: '100%' }}>
          <img
            style={{ maxWidth: '100%', maxHeight: '100%' }}
            src={mainImage}
            alt="No gathering images"
          />
        </div>
        {emptyMessage}
      </Grid>
      <Grid item md={1} xs={12}>
        <Divider
          orientation={matches ? 'horizontal' : 'vertical'}
          style={{ marginInline: 'auto' }}
        />
      </Grid>
      <Grid
        item
        md={5}
        xs={12}
        style={{
          padding: '20px'
        }}
      >
        <Typography
          variant="h3"
          style={{ textAlign: matches ? 'center' : 'start' }}
        >
          What you can do:{' '}
        </Typography>
        <div
          style={{
            display: 'flex',
            alignItems: matches ? 'center' : 'start',
            flexDirection: 'column',
            marginTop: '16px'
          }}
        >
          {listItems.map((e, i) => (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBlock: '16px'
              }}
              key={'Reaons' + i}
            >
              <div
                style={{ width: '40px', height: '40px', marginRight: '20px' }}
              >
                <img
                  style={{ maxWidth: '100%', maxHeight: '100%' }}
                  src={e.icon}
                  alt="No gathering images"
                />
              </div>
              <Typography style={{ fontSize: '18px' }}>{e.text}</Typography>
            </div>
          ))}
        </div>
      </Grid>
    </Grid>
  )
}

export default GatheringGroupEmptyState
