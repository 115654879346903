import axios from 'axios'

/**
 * @function businessReviewNearcoinsAwarded Check if nearcoins were awarded for a review or not
 * @returns {Promise} Promise resolution represents true or false
 */
export const businessReviewNearcoinsAwarded = (offerId, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BUSINESS_URL}/business/review/nearcoins-awarded`, {
      params: {
        offer_id: offerId
      },
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(res.data.body))
      .catch((err) => reject(err))
  })
}
