import React, { createContext, useCallback, useEffect, useReducer, useState } from 'react'
import { useCookies } from 'react-cookie'
import axios from 'axios'
import { StreamChat as DefaultStreamChat } from 'stream-chat'

// importing apis
import { tokenRefresh } from 'api/token/refresh'
import { fetchMyProfile } from 'api/fetch/my'
import { personalAccount } from 'api/personal_account/index'

// importing redux helpers
import { useAppDispatch, useAppSelector } from '../utils/redux/store'
import { postsClearAllStatesRedux } from '../utils/redux/actions/postActions'
import { changeActiveNeighborhood, login as reduxLogin, logout as reduxLogout, refreshUserProfiles, refreshUserTokens } from '../utils/redux/actions/userActions'

// importing api
import { axiosResponseInterceptorBuilder } from 'api'
// import { chatLogin } from 'api/chat/login'
import { Chat } from 'stream-chat-react'
import { personalAccountAvatar } from 'api/personal_account/avatar'
import { adminFeedActiveUser } from 'api/analytics/feed'
import { businessDetailsGet } from 'api/business/details'

export const UserContext = createContext()

const initialState = {
  userPreferences: {
    videoMuted: true
  },
  chatConnected: false
}

const UserContextProvider = ({ children }) => {
  const reduxDispatcher = useAppDispatch()
  const userState = useAppSelector((state) => state.root.userState)

  const [state, dispatch] = useReducer(UserReducer, initialState)
  const [cookies, setCookie, removeCookie] = useCookies([
    'refresh_token',
    'jwt_token'
  ])
  // state for chat
  const [isLoggedInChat, setIsLoggedInChat] = useState(false)
  const [wasLoggedInFor, setWasLoggedInFor] = useState('')

  const detectDomain = () => window.location.hostname

  const login = useCallback(
    (data) => {
      if (!data.isNotFresh) {
        const loginData = {
          refToken: data.ref_token,
          jwtToken: data.jwt_token,
          user: data.user,
          accounts: data.accounts,
          userProfiles: data.userProfiles
        }
        reduxDispatcher(reduxLogin(loginData))
      } else {
        if (data.jwt_token) {
          reduxDispatcher(refreshUserTokens({
            jwtToken: data.jwt_token,
            refreshToken: data.ref_token
          }))
        }
        if (data.accounts) {
          reduxDispatcher(refreshUserProfiles({
            userProfiles: data.userProfiles,
            accounts: data.accounts
          }))
        }
      }
      const cookieOptions = {
        path: '/',
        domain: detectDomain(),
        samesite: 'lax'
      }
      if (data.ref_token) {
        setCookie('refresh_token', data.ref_token, cookieOptions)
      }
      if (data.jwt_token) {
        setCookie('jwt_token', data.jwt_token, cookieOptions)
      }
    },
    [setCookie]
  )

  const refreshTokenApi = useCallback(
    (orignalRequest, refresh_token) => {
      tokenRefresh(refresh_token)
        .then((resBody) => {
          login({
            jwt_token: resBody.jwtToken,
            ref_token: resBody.refreshToken,
            isNotFresh: true
          })
          return axios(orignalRequest)
        })
        .catch((error) => {
          return error
        })
    }, [login])

  const getProfile = (id, jwt_token) => {
    return new Promise((resolve, reject) => {
      fetchMyProfile(id, jwt_token)
        .then(async (resBody) => resolve(resBody))
        .catch((err) => {
          // console.log(err);
          reject(err)
        })
    })
  }

  const refreshProfilesApi = async () => {
    let p = []
    personalAccount(
      userState.personal[userState.activeNeighborhood].id,
      userState.jwtToken
    )
      .then(async (resData) => {
        p = resData.pa.filter((p) => p.is_business === false)
        const userProfile = await Promise.all(
          p.map(
            async (account, _) => await getProfile(account.ID, userState.jwtToken)
          )
        )
        login({
          accounts: p,
          userProfiles: userProfile,
          isNotFresh: true
        })
      })
      .catch((error) => {
        // console.log(error);
        return error
      })
  }

  const logout = () => {
    logoutOfChat()
    // this will clear all loaded posts
    reduxDispatcher(postsClearAllStatesRedux())
    reduxDispatcher(reduxLogout())
    // clear out all localStorage set by context
    localStorage.clear()
    const cookieNames = Object.keys(cookies)
    cookieNames.forEach((cookieName) => {
      removeCookie(cookieName, {
        path: '/',
        domain: detectDomain(),
        samesite: 'lax'
      })
    })
  }

  const switchNeighbourHood = (neighbourhoodIdx) => {
    reduxDispatcher(changeActiveNeighborhood({ index: neighbourhoodIdx }))
  }

  const toggleVideoMuted = () => {
    const newUserPreferences = {
      ...state.userPreferences,
      videoMuted: !state.userPreferences.videoMuted
    }
    dispatch({
      type: 'SET_USER_PREFERENCES',
      data: newUserPreferences
    })
  }

  // do not remove - this is future addition
  // const getRequestHeaders = useCallback(() => {
  //   return {
  //     "Authorization": `Bearer ${state.jwt_token}`,
  //     "NRCT-UID": state.personal[state.active_neighbourhood].ID,
  //   }
  // }, [state.active_neighbourhood, state.jwt_token, state.personal])

  useEffect(() => {
    const refreshToken = userState.refreshToken
    if (refreshToken === null) {
      return
    }
    // do not remove - this is future addition
    // const requestInterceptorId = axiosRequestInterceptorBuilder(
    //   (req) => {
    //     // adding authentication details
    //     const e = getRequestHeaders();
    //     req.headers.Authorization = e.Authorization;
    //     req.headers["NRCT-UID"] = e["NRCT-UID"];
    //     return req;
    //   },
    //   (err) => {
    //     // console.log(err);
    //     return Promise.reject(err);
    //   }
    // )
    const responseInterceptorId = axiosResponseInterceptorBuilder(
      (res) => {
        return res
      },
      (error) => {
        const orignalRequest = error.config
        if (
          error.response &&
          error.response.status === 401 &&
          orignalRequest.url ===
            `${process.env.REACT_APP_BACKEND_URL}/token/refresh`
        ) {
          logout()
          return Promise.reject(error)
        }
        if (
          error.response &&
          error.response.status === 401 &&
          orignalRequest.headers.Authorization &&
          !orignalRequest._retry
        ) {
          orignalRequest._retry = true
          return refreshTokenApi(orignalRequest, refreshToken)
        }
        return Promise.reject(error)
      }
    )
    // do not remove - this is future addition
    // return () => {
    //   axiosEjectInterceptor(requestInterceptorId, responseInterceptorId);
    // }
  }, [refreshTokenApi, userState.refreshToken])

  const client = DefaultStreamChat.getInstance(
    `${process.env.REACT_APP_GETSTREAM_KEY}`
  )

  /**
   * This currently updates only the Avatar whenever app opened.
   */
  const updateSelf = async () => {
    const selfId = userState.personal[userState.activeNeighborhood].ID
    await client.partialUpdateUser({
      id: selfId,
      set: {
        image: userState.personal[userState.activeNeighborhood].is_business
          ? userState.personal[userState.activeNeighborhood].business_account_avatar
          : userState.personal[userState.activeNeighborhood].personal_account_avatar
      }
    })
  }

  const makeReq = async () => {
    console.log('Logging In of Stream Chat')
    try {
      const token = await chatLogin(
        userState.personal[userState.activeNeighborhood].ID,
        userState.jwtToken
      )
      let username = userState.personal[userState.activeNeighborhood].username
      if (userState.personal[userState.activeNeighborhood].is_business) {
        const businessDetails = await businessDetailsGet(
          userState.personal[userState.activeNeighborhood].business_id,
          userState.personal[userState.activeNeighborhood].ID,
          userState.jwtToken
        )
        username = businessDetails.body.business_details.name
      } else {
        const profile = await fetchMyProfile(
          userState.personal[userState.activeNeighborhood].ID,
          userState.jwtToken
        )
        username = `${profile.first_name} ${profile.last_name}`
      }
      console.log('Get Stream Chat Username:', username)
      await client.connectUser(
        {
          id: userState.personal[userState.activeNeighborhood].ID,
          name: username
        },
        token
      )
      await updateSelf()
      setIsLoggedInChat(true)
    } catch (e) {
      console.log(e.toString())
    }
  }

  useEffect(() => {
    if (userState.personal && userState.jwtToken) {
      adminFeedActiveUser(
        userState.personal[0].user,
        userState.personal[userState.activeNeighborhood].lives_in_id,
        userState.jwtToken
      )
    }
  }, [userState.personal, userState.jwtToken])

  const logoutOfChat = async () => {
    console.log('Logging Out of Stream Chat')
    try {
      await client.disconnectUser()
      setIsLoggedInChat(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    (async () => {
      if (userState.jwtToken === null || userState.personal === null) {
        return
      }
      if (userState.jwtToken.length !== 0 && userState.personal.length !== 0) {
        if (wasLoggedInFor !== userState.personal[userState.activeNeighborhood].ID && isLoggedInChat) {
          await logoutOfChat()
        }
        if (!isLoggedInChat) {
          // Login user to chat
          setWasLoggedInFor(userState.personal[userState.activeNeighborhood].ID)
          await makeReq()
        }
      }
    })()
  }, [isLoggedInChat, userState.jwtToken, userState.activeNeighborhood, userState.personal])

  const value = {
    ...state,
    ...userState,
    jwt_token: userState.jwtToken,
    active_neighbourhood: userState.activeNeighborhood,
    chatConnected: isLoggedInChat,
    login,
    logout,
    switchNeighbourHood,
    refreshProfilesApi,
    // dispatch,
    getProfile,
    toggleVideoMuted
  }

  return (
    <UserContext.Provider value={value}>
      {children}
      {/* <Chat client={client} theme='light' >
        {children}
      </Chat> */}
    </UserContext.Provider>
  )
}

export default UserContextProvider

const UserReducer = (state, action) => {
  switch (action.type) {
    case 'SET_USER_PREFERENCES':
      return {
        ...state,
        userPreferences: action.data
      }
    default:
      return {
        ...state
      }
  }
}
