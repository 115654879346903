import React, { useContext, useEffect, useState } from 'react'
import { Button } from '@mui/material'
import { ErrorOutline } from '@mui/icons-material'
import makeStyles from '@mui/styles/makeStyles'
import { Link, useLocation } from 'react-router-dom'
import { useSnackbar } from 'notistack'

// importing components
import Header from '../../components/Header/Header'
import PostView from '../../components/Post/PostView'
import StandardModal from '../../components/Modals/StandardModal'
import Sidebar from '../../components/UserDash/Sidebar/Sidebar'

// importing context
import { UserContext } from '../../context/UserContext'

// importing apis
import { postFetch } from 'api/post/fetch'

function Posts () {
  const location = useLocation()
  const [postData, setPostData] = useState()
  const [loadingPost, setLoadingPost] = useState(true)
  const [postError, setPostError] = useState(false)

  const { personal, active_neighbourhood, jwt_token } = useContext(UserContext)
  const classes = styles()
  const { enqueueSnackbar } = useSnackbar()

  const fetchPostApi = (postId) => {
    const nrctId = personal !== null ? personal[active_neighbourhood].ID : null
    postFetch(postId, nrctId, jwt_token)
      .then((resBody) => {
        setPostData(resBody)
        setLoadingPost(false)
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: 'error'
        })
        setPostError(true)
      })
  }

  useEffect(() => {
    const postId = location.pathname.replace(/\/$/, '').split('/').slice(-1)[0]
    fetchPostApi(postId)
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Header />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <div
          style={{
            position: 'relative',
            maxWidth: '1800px',
            width: '100vw',
            height: '100vh'
          }}
        >
          <Sidebar />
          <div className={classes.root}>
            {(!postError && postData !== undefined) && (
              <div>
                <PostView post={postData} isLoad={loadingPost} />
              </div>
            )}
            <StandardModal open={postError} onClose={() => {}}>
              <ErrorOutline />
              <div>You dont have access to this post</div>
              {jwt_token
                ? (
                <Link to="/home">
                  <Button color="primary">Go home</Button>
                </Link>
                  )
                : (
                <Link to="/login">
                  <Button color="primary">signup</Button>
                </Link>
                  )}
            </StandardModal>
          </div>
        </div>
      </div>
    </>
  )
}

const styles = makeStyles(({ breakpoints }) => ({
  root: {
    margin: '0px 20px 0px 100px',
    paddingTop: '20px',
    height: 'calc(100vh - 20px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    // eslint-disable-next-line no-useless-computed-key
    [breakpoints.down('md')]: {
      margin: '0px 10px',
      paddingTop: '0px',
      height: '100vh'
    },
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  }
}))

export default Posts
