import * as React from 'react'
import {
  FormControl,
  Typography,
  Switch,
  styled,
  Divider,
  Stack,
  Slider,
  ListItemText,
  ListItemButton
} from '@mui/material'
import { useSnackbar } from 'notistack'

// importing ui
import CreateVideo from 'ui/Custom/CreateVideo/CreateVideo'
import WideModal from 'ui/Modals/WideModal'
import CustomTextArea from 'ui/TextInputs/TextAreaInput'
import StickerButton from 'ui/Buttons/StickerButton/StickerButton'
import StandardModal from 'ui/Modals/StandardModal'
import NearcastVideoPlayer from 'ui/Custom/NearcastVideoPlayer/NearcastVideoPlayer'

// importing components
import PriceSticker from '../StickerInputModals/PriceSticker'
import CustomUrlSticker from '../StickerInputModals/CustomUrlSticker'
import DateSticker from '../StickerInputModals/DateSticker'
import LocationModal from '../StickerInputModals/LocationModal'
import TextInputSticker from '../StickerInputModals/TextInputSticker'

// importing api
import { postCreate } from 'api/post/create'
import { groupMy } from 'api/group/my'

// importing context
import { UserContext } from '../../../context/UserContext'

// importing helpers
import { getFileSizeInMB } from 'helpers/browser'
import { recordGaEvent } from 'helpers/ga'

// the different screen dimensions to record in
const screenDimensionsPortrait = {
  width: 300,
  height: 600,
  aspect: 2,
  isWide: false
}
const sTypes = {
  call: 0,
  message: 1,
  cost: 2,
  email: 3,
  donate: 7,
  date: 8,
  custom: 5,
  location: 4,
  rating: 6
}
const stickerTypeArray = [
  { type: sTypes.call },
  { type: sTypes.message },
  { type: sTypes.location, location_name: 'Add Location' },
  { type: sTypes.email },
  { type: sTypes.donate },
  { type: sTypes.custom, custom_name: 'Custom URL' },
  { type: sTypes.date, date: 'Add Date' },
  { type: sTypes.rating, rating: 5 },
  { type: sTypes.cost, price: 100 }
]

const CreateNearcastPostWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'flex-start',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'center'
  }
}))

const CreateNearcastModal = ({
  isOpen,
  onClose,
  isForGroup,
  onSuccess
}) => {
  const { personal, active_neighbourhood, jwt_token, userPreferences, toggleVideoMuted } = React.useContext(UserContext)
  const { enqueueSnackbar } = useSnackbar()
  // component states
  const [type, setType] = React.useState('record') // type: "record" | "overlay"
  const [videoFile, setVideoFile] = React.useState(null)
  const [videoSrc, setVideoSrc] = React.useState('')
  const [stickerArray, setStickerArray] = React.useState([])
  const [description, setDescription] = React.useState('')
  const [distance, setDistance] = React.useState(10)
  const [shareWithGroupId, setShareWithGroupId] = React.useState(false)
  const [groups, setGroups] = React.useState([])
  const [selectedGroupId, setSelectedGroupId] = React.useState(null)
  const [downloadVideo, setDownloadVideo] = React.useState(false)
  const [posting, setPosting] = React.useState(false)
  const [postingProgress, setPostingProgress] = React.useState(0)
  // sticker specific modal states
  const [priceStickerModal, setPriceSticker] = React.useState(false)
  const [customLinkModal, setCustomLinkModal] = React.useState(false)
  const [dateStickerModal, setDateSticker] = React.useState(false)
  const [locationModal, setLocationModal] = React.useState(false)
  const [textInputModal, setTextInputModal] = React.useState(false)

  const _onClose = () => {
    setType('record')
    setDescription('')
    setStickerArray([])
    setPosting(false)
    setPostingProgress(0)
    setSelectedGroupId(null)
    onClose()
  }

  const onClickPost = async () => {
    if (posting) {
      return
    }
    setPosting(true)
    // if downloadVideo is true then show the user download option
    if (downloadVideo) {
      const a = document.createElement('a')
      a.href = videoSrc
      a.download = `nearcast-${Date.now()}`
      a.click()
    }

    try {
      await postCreate(
        description,
        stickerArray,
        distance,
        selectedGroupId,
        videoFile,
        personal[active_neighbourhood].ID,
        jwt_token,
        (p) => setPostingProgress(p)
      )
      setPosting(false)
      enqueueSnackbar('Nearcast created!', {
        variant: 'success'
      })
      onSuccess()
      _onClose()

      // analytics
      recordGaEvent('createNearcast-postCreated', {
        personalAccountId: personal[active_neighbourhood].ID
      })
    } catch (err) {
      enqueueSnackbar(err.message, {
        variant: 'error'
      })
      setPosting(false)
    }
  }

  const updateSticker = (sticker, isDeleted) => {
    const { id } = sticker
    if (isDeleted) {
      // remove sticker
      const newStickerArray = stickerArray.filter((sticker) => sticker.id !== id)
      setStickerArray(newStickerArray)
      return
    }
    const index = stickerArray.findIndex((sticker) => sticker.id === id)
    const newStickerArray = [...stickerArray.slice(0, index), sticker, ...stickerArray.slice(index + 1)]
    setStickerArray(newStickerArray)
  }

  const handleStickerAdd = (sticker) => {
    sticker.x = -40
    sticker.y = 0
    sticker.id = Date.now()
    setStickerArray((prev) => [...prev, sticker])
  }

  const handleClick = (sticker) => {
    if (stickerArray.findIndex((item) => item.type === sticker.type) !== -1) {
      enqueueSnackbar('Already added this sticker.', { variant: 'error', autoHideDuration: 2000 })
      return
    }

    if (sticker.type === sTypes.cost) {
      setPriceSticker(true)
    } else if (sticker.type === sTypes.custom) {
      setCustomLinkModal(true)
    } else if (sticker.type === sTypes.date) {
      setDateSticker(true)
    } else if (sticker.type === sTypes.location) {
      setLocationModal(true)
    } else if (sticker.type === sTypes.email) {
      setTextInputModal({
        title: 'Add Email Address',
        input: {
          title: 'Email',
          type: 'email',
          value: ''
        },
        sticker: sticker
      })
    } else if (sticker.type === sTypes.call) {
      setTextInputModal({
        title: 'Add Mobile Number',
        input: {
          title: 'Phone Number',
          type: 'tel',
          value: ''
        },
        sticker: sticker
      })
    } else if (sticker.type === sTypes.rating) {
      setTextInputModal({
        title: 'Add Rating',
        input: {
          title: 'Rating to use',
          type: 'number',
          value: '5',
          textInputProps: {
            inputProps: {
              min: 1,
              max: 5
            }
          }
        },
        sticker: sticker
      })
    } else {
      handleStickerAdd(sticker)
      enqueueSnackbar('Your sticker has been added ✅', {
        variant: 'success',
        autoHideDuration: 2000
      })
    }
  }

  React.useEffect(() => {
    groupMy(personal[active_neighbourhood].ID, jwt_token)
      .then((dataGroups) => {
        if (dataGroups !== null) {
          setGroups(dataGroups)
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: 'error'
        })
      })
  }, [])

  React.useEffect(() => {
    if (isOpen) {
      if (isForGroup) {
        setSelectedGroupId(isForGroup)
        setDistance(1)
      } else {
        setSelectedGroupId(null)
        setDistance(10)
      }
    }
  }, [isOpen, isForGroup])

  const renderRecordVideo = () => {
    if (window.innerWidth < 600) {
      return (
                <div
                    style={{
                      zindex: 100,
                      position: 'absolute',
                      top: 0,
                      bottom: 70,
                      right: 0,
                      left: 0
                    }}
                >
                    <div
                        style={{
                          width: '100%',
                          height: '100%'
                        }}
                    >
                        <CreateVideo
                            setVideoSrc={(src, rawFile) => {
                              setVideoFile(rawFile)
                              setVideoSrc(src)
                              setType('overlay')
                            }}
                        />
                    </div>
                </div>
      )
    }

    return (
            <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: 20,
                  marginBottom: 20,
                  height: screenDimensionsPortrait.height
                }}
            >
                <div
                    style={{
                      height: screenDimensionsPortrait.height,
                      width: screenDimensionsPortrait.width
                    }}
                >
                    <CreateVideo
                        setVideoSrc={(src, rawFile) => {
                          setVideoFile(rawFile)
                          setVideoSrc(src)
                          setType('overlay')
                        }}
                    />
                </div>
            </div>
    )
  }

  // if user is at record type then show them the Camera component
  if (type === 'record') {
    if (window.innerWidth < 600 && isOpen) {
      return renderRecordVideo()
    }

    return (
      <WideModal
        title="Create Nearcast"
        isOpen={isOpen}
        onClose={_onClose}
      >
        <Divider />
          {renderRecordVideo()}
        <Divider />
      </WideModal>
    )
  }

  return (
    <WideModal
      title="Create Nearcast"
      isOpen={isOpen}
      onClose={_onClose}
      buttons={[
        {
          title: 'Cancel',
          color: 'ghost',
          onPress: _onClose,
          variant: 'outlined'
        },
        {
          title: posting ? `Posting: ${postingProgress}%` : 'Post',
          color: posting ? 'ghost' : 'primary',
          onPress: onClickPost
        }
      ]}
    >
      <Divider />
      <CreateNearcastPostWrapper
          style={{
            paddingTop: 8,
            paddingBottom: 8
          }}
      >
          <div
              item
              sm={6}
              style={styles.gridVideoWrapper}
          >
            <NearcastVideoPlayer
              type='preview'
              video={{
                source: videoSrc,
                muted: userPreferences.videoMuted,
                toggleMuted: toggleVideoMuted
              }}
              stickers={stickerArray}
              updateSticker={updateSticker}
              screenDimensions={screenDimensionsPortrait}
            />
            <Stack direction="row" justifyContent="flex-end" style={{ width: '100%' }}>
              <Typography variant="caption" >
                File size: {getFileSizeInMB(videoFile, 2)}
              </Typography>
            </Stack>
          </div>
          <div
              style={{
                ...styles.mainDivPostContainers
              }}
          >
              <FormControl
                  fullWidth
                  margin="none"
              >
                  <CustomTextArea
                      multiline
                      rows={6}
                      label="Describe Your Post"
                      placeholder="Enter caption for your post"
                      value={description}
                      inputProps={{ maxLength: '255' }}
                      onChange={(e) => setDescription(e.target.value)}
                      style={{
                        backgroundColor: '#f2f2f2',
                        borderRadius: 10
                      }}
                  />
              </FormControl>
              {!isForGroup && (
                <>
                  <Typography variant="h6" style={{ marginTop: 20 }} >
                    Sharing With
                  </Typography>
                  <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap'
                      }}
                  >
                      <div
                          style={{
                            ...styles.shareWithElementWrapper,
                            borderStyle: distance > 1 && !selectedGroupId ? 'solid' : 'none'
                          }}
                          onClick={() => {
                            setDistance(10)
                            setShareWithGroupId(false)
                            setSelectedGroupId(null)
                          }}
                      >
                          <img
                              alt="Neighborhood"
                              src={require('./a.png')}
                              style={{
                                width: 60,
                                height: 60,
                                background: 'grey',
                                borderRadius: '50%'
                              }}
                          />
                          <Typography variant="h6">
                              {personal[active_neighbourhood].neighbourhood.state}
                          </Typography>
                          <Typography variant="h6">
                              {distance} miles
                          </Typography>
                      </div>
                      <div
                          style={{
                            ...styles.shareWithElementWrapper,
                            borderStyle: distance === 1 && !selectedGroupId ? 'solid' : 'none'
                          }}
                          onClick={() => {
                            setDistance(1)
                            setShareWithGroupId(false)
                            setSelectedGroupId(null)
                          }}
                      >
                          <img
                              alt="Neighborhood"
                              src={require('./b.png')}
                              style={{
                                width: 60,
                                height: 60,
                                background: 'grey',
                                borderRadius: '50%'
                              }}
                          />
                          <Typography variant="h6">
                              {personal[active_neighbourhood].neighbourhood.neighbourhood}
                          </Typography>
                      </div>
                      <div
                          style={{
                            ...styles.shareWithElementWrapper,
                            opacity: groups.length > 0 ? 1 : 0.4,
                            borderStyle: selectedGroupId ? 'solid' : 'none'
                          }}
                          onClick={() => {
                            if (groups.length > 0) {
                              setShareWithGroupId(true)
                              setDistance(1)
                            } else {
                              enqueueSnackbar('You are not a member of a group yet!', {
                                variant: 'info'
                              })
                            }
                          }}
                      >
                          <img
                              alt="Neighborhood"
                              src={require('./c.png')}
                              style={{
                                width: 60,
                                height: 60,
                                background: 'grey',
                                borderRadius: '50%'
                              }}
                          />
                          <Typography variant="h6">
                              Groups
                          </Typography>
                      </div>
                  </div>
                  <Typography variant="h6">
                    Distance (miles)
                  </Typography>
                  <Stack
                    spacing={2}
                    direction="row"
                    sx={{ mb: 1 }}
                    alignItems="center"
                    style={{
                      width: '80%'
                    }}
                  >
                    <Typography variant="h6">
                      2
                    </Typography>
                    <Slider
                      aria-label="Volume"
                      valueLabelDisplay="auto"
                      min={2}
                      max={30}
                      size="medium"
                      value={distance}
                      onChange={(e, value, thumb) => {
                        setDistance(value)
                      }}
                      disabled={distance <= 1}
                    />
                    <Typography variant="h6">
                      30
                    </Typography>
                  </Stack>
                </>
              )}
              <Typography variant="h6" style={{ marginTop: 20 }}>
                  Add Stickers
              </Typography>
              <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap'
                  }}
              >
                {
                  stickerTypeArray.map((sticker, index) => {
                    return (
                      <div key={index} style={{ marginBottom: 4 }}>
                        <StickerButton
                            sticker={sticker}
                            overRideOnClick={() => handleClick(sticker)}
                        />
                      </div>
                    )
                  })
                }
              </div>
              <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: 20
                  }}
              >
                  <Typography variant="h6" >
                      Save to device
                  </Typography>
                  <Switch
                      checked={downloadVideo}
                      onChange={() => setDownloadVideo(!downloadVideo)}
                      name="Download"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
              </div>
          </div>
      </CreateNearcastPostWrapper>
      <PriceSticker
        open={priceStickerModal}
        onClose={() => setPriceSticker(false)}
        handleStickerAdd={handleStickerAdd}
      />
      <CustomUrlSticker
        open={customLinkModal}
        onClose={() => setCustomLinkModal(false)}
        handleStickerAdd={handleStickerAdd}
      />
      <DateSticker open={dateStickerModal} onClose={() => setDateSticker(false)} handleStickerAdd={handleStickerAdd} />
      <LocationModal open={locationModal} onClose={() => setLocationModal(false)} handleStickerAdd={handleStickerAdd} />
      {textInputModal && (
        <TextInputSticker
          open={Boolean(textInputModal)}
          onClose={() => setTextInputModal(false)}
          title={textInputModal.title}
          input={textInputModal.input}
          handleStickerAdd={(value) => {
            if (textInputModal.sticker.type === sTypes.email) {
              handleStickerAdd({
                ...textInputModal.sticker,
                email: value
              })
            } else if (textInputModal.sticker.type === sTypes.call) {
              handleStickerAdd({
                ...textInputModal.sticker,
                phone_number: value
              })
            } else if (textInputModal.sticker.type === sTypes.rating) {
              handleStickerAdd({
                ...textInputModal.sticker,
                rating: Number(value)
              })
            }
            setTextInputModal(false)
          }}
        />
      )}
      <Divider />
      <StandardModal
        title="Select a Group"
        isOpen={shareWithGroupId}
        onClose={() => setShareWithGroupId(false)}
      >
        {groups.map((group) => {
          return (
            <ListItemButton
              key={group.id}
              onClick={() => {
                setSelectedGroupId(group.id)
                setShareWithGroupId(false)
              }}
            >
              <ListItemText>
                {group.group_name}
              </ListItemText>
            </ListItemButton>
          )
        })}
      </StandardModal>
    </WideModal>
  )
}

const styles = {
  mainDivPostContainers: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingLeft: 8,
    width: '100%',
    height: '100%',
    marginTop: 20
  },
  gridVideoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  shareWithElementWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: 10,
    padding: 10,
    cursor: 'pointer',
    width: 80,
    wordWrap: 'anywhere',
    textAlign: 'center',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: 'black'
  },
  disabled: {
    opacity: 0.4
  }
}

CreateNearcastModal.defaultProps = {
  isForGroup: false,
  onSuccess: () => {}
}

export default CreateNearcastModal
