import axios from 'axios'

/**
 * @function groupJoin Used to join a specific group
 * @param {string} nrctUID - user's current neighborhood id
 * @param {string} jwt - user's jwt token
 * @returns {Promise} Promise object represents array of user's groups
 */
export const groupJoin = (groupId, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/group/join`, {
      params: {
        group: groupId
      },
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 1011) {
          resolve(true)
        } else {
          throw new Error('There was an error in sending the join request!')
        }
      })
      .catch((err) => reject(err))
  })
}
