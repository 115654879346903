import VideoIcon from '../FeatureIcons/videos-icon.svg'
import HandsIcon from '../FeatureIcons/hands-icon.svg'
import GatheringIcon from '../FeatureIcons/gathering-icon.svg'
import GroupsIcon from '../FeatureIcons/groups-icon.svg'
import TransmitIcon from '../FeatureIcons/transmit-icon.svg'
import SpeakerIcon from '../FeatureIcons/speaker-icon.svg'

const features = [
  {
    iconSrc: VideoIcon,
    head: 'Nearcast',
    headHighlight: 'to Nearby Residents.',
    description:
			'Share short-videos with people that live near you and whom you can meet in real life. For example, make an online garage sale by posting things to sell via video.'
  },
  {
    iconSrc: GroupsIcon,
    head: 'Interact through',
    headHighlight: 'Groups.',
    description:
			'Keep in touch with the groups of people that matter the most, like your family, coworkers or neighborhors. Message, share a Nearcast video, livestream, or create announcements for seemless communication.'
  },
  {
    iconSrc: TransmitIcon,
    head: 'Host',
    headHighlight: 'Citywide Debates.',
    description:
			'Host a livestream debate with people that live in your city around important issues of concern to you and your neighbors & record it.'
  },
  {
    iconSrc: HandsIcon,
    head: 'Support',
    headHighlight: 'Local Businesses.',
    description:
			'Discover special offers from nearby merchants just for the neigbhorhood, receive incentives to create videos for businesses that you love, and share with neighbors.'
  },
  {
    iconSrc: SpeakerIcon,
    head: 'Easy',
    headHighlight: 'Marketing.',
    description:
			'Anything you post goes to everyone in your neighborhood. You can create videos to promote local events, services, and ventures.'
  }
]

export default features
