import {
  AppBar,
  Toolbar
} from '@mui/material'
import React from 'react'
import styles from './HeaderDocsStyles'
import Nearcast from 'ui/Icons/Nearcast'
import { Link } from 'react-router-dom'

function HeaderDocs (props) {
  const classes = styles()

  return (
        <AppBar className={classes.root} elevation={0}>
            <Toolbar className={classes.toolbar}>
                <Link to="/" className={classes.nearcastLogo} >
                <Nearcast
                className={classes.nearcastLogo}
                    height={27}
                    htmlColor={'#0C3A2D'}
                />
                </Link>
            </Toolbar>
        </AppBar>
  )
}

export default HeaderDocs
