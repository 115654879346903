import React, { useContext, useEffect, useState, useRef, useCallback } from 'react'
import { CircularProgress, Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useSnackbar } from 'notistack'
import ImageCoverCard from 'ui/Cards/ImageCoverCard'
import { ErrorRounded, Share, JoinFullRounded, CheckCircle } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

// importing context
import { UserContext } from '../../../context/UserContext'
import { GlobalFuncContext } from '../../../context/GlobalFuncHOC'

// importing components
import ActionModal from '../../Modals/ActionModal'
import GatheringEmptyState from '../../EmptyStates/GatheringGroupEmptyState'
import cookIcon from '../../../assets/Fill.svg'
import castIcon from '../../../assets/nearcast.svg'

// importing apis
import { reportGroup } from 'api/report/group'
import { groupPublicGroups } from 'api/group/public'
import { groupJoin } from 'api/group/join'
import { groupCover } from 'api/group/cover'

const muiStyles = makeStyles(({ breakpoints }) => ({
  groupCardGrid: {
    marginBottom: '40px',
    [breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  }
}))

function AllGroups(props) {
  const { personal, jwt_token, active_neighbourhood } = useContext(UserContext)
  const { openShareModal } = useContext(GlobalFuncContext)
  const { enqueueSnackbar } = useSnackbar()
  const classes = muiStyles()
  const navigate = useNavigate()

  // states
  const groupsStateDefault = {
    groups: [],
    loading: false,
    hasMore: true,
    page: 1
  }
  const [groupState, setGroupState] = useState(groupsStateDefault)
  const [loading, setLoading] = useState(true)
  const [reportOpen, setReportOpen] = useState()
  const [joinOpen, setJoinOpen] = useState()
  // const [leaveOpen, setLeaveOpen] = useState()

  const observer = useRef()
  const lastGroupCardRef = useCallback(
    (node) => {
      if (groupState.loading) {
        return
      }
      if (observer.current) {
        observer.current.disconnect()
      }
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && groupState.hasMore) {
          getGroupPaginated(groupState.page + 1)
        }
      })
      if (node) {
        observer.current.observe(node)
      }
    },
    [groupState]
  )

  const getGroupPaginated = (pageNumber = 1) => {
    if (groupState.loading || !groupState.hasMore) {
      return false
    }
    setGroupState({
      ...groupState,
      loading: true
    })
    groupPublicGroups(pageNumber, personal[active_neighbourhood].ID, jwt_token)
      .then((dataGroups) => {
        setGroupState({
          groups: dataGroups !== null ? [...groupState.groups, ...dataGroups] : groupState.groups,
          loading: false,
          hasMore: dataGroups !== null,
          page: pageNumber
        })
        setLoading(false)
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: 'error'
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (personal && jwt_token) {
      getGroupPaginated()
    }
  }, [jwt_token, personal])

  const getGroupMenuList = (item) => {
    const menuList = [
      {
        title: 'Share',
        color: 'ghost',
        onPress: () => openShareModal(
          'Group',
          'Check out this amazing community at Nearcast!',
          `/g/${item.id}`
        ),
        icon: () => <Share color="ghost" />
      },
      {
        title: 'Report',
        color: 'error',
        onPress: () => setReportOpen(item),
        icon: () => <ErrorRounded color="error" />
      }
    ]

    if (item.is_joined === false) {
      menuList.push({
        title: 'Join',
        color: 'primary',
        onPress: () => setJoinOpen(item),
        icon: () => <JoinFullRounded color="primary" />
      })
    }

    return menuList
  }

  return (
    <Grid container spacing={2} className={classes.groupCardGrid}>
      {loading
        ? (
          <div
            style={{
              display: 'flex',
              height: '70vh',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              background: '#FFFFFF'
            }}
          >
            <CircularProgress />
          </div>
        )
        : groupState.groups.length > 0
          ? (
            groupState.groups.map((item, index) => {
              return (
                <Grid
                  item
                  key={item.id}
                  ref={index === groupState.groups.length - 1 ? lastGroupCardRef : null}
                >
                  <ImageCoverCard
                    imgUrl={groupCover(item.id)}
                    title={item.group_name}
                    subTitle={`${item.users_count} Member(s)`}
                    onPress={() => {
                      if (item.is_joined) {
                        navigate(`/g/${item.id}`)
                      } else {
                        setJoinOpen(item)
                      }
                    }}
                    menuItems={getGroupMenuList(item)}
                    hasBadge={item.is_joined && (() => <CheckCircle color="primary" />)}
                  />
                </Grid>
              )
            })
          )
          : (
            <GatheringEmptyState
              mainImage={require('../../../assets/nogroup.webp')}
              emptyMessage={
                <div style={{ textAlign: 'center', marginTop: '16px' }}>
                  <Typography variant="h3">
                    Create a group around a topic of your choice 🍔🎵💃
                  </Typography>
                  <Typography style={{ fontSize: '15px', marginTop: '10px' }}>
                    You can share messages, create Nearcasts, announce updates, or livestream a party to stay in touch with your family, friends or coworkers
                  </Typography>
                </div>
              }
              listItems={[
                { icon: cookIcon, text: 'Create a neighbourhood Chefs group' },
                { icon: castIcon, text: 'Announce a group trek' }
              ]}
            />
          )}
      <ActionModal
        open={Boolean(reportOpen)}
        onClose={() => setReportOpen(false)}
        onConfirm={() => {
          reportGroup(reportOpen.id, 'User reported group from the web', personal[active_neighbourhood].ID, jwt_token)
            .then(() => {
              enqueueSnackbar('You reported this group.', {
                variant: 'success'
              })
            })
            .catch(() => {
              enqueueSnackbar("We weren't able to report this group, please try again.", {
                variant: 'error'
              })
            })
          setReportOpen(false)
        }}
        onCancel={() => {
          setReportOpen(false)
        }}
        action={{
          title: 'Do you want to report this group',
          Accept: 'Yes',
          Decline: 'No'
        }}
      >
        <ErrorRounded style={{ fontSize: '2.5rem' }} color="secondary" />
      </ActionModal>
      <ActionModal
        open={Boolean(joinOpen)}
        onClose={() => setJoinOpen(false)}
        onConfirm={() => {
          groupJoin(joinOpen.id, personal[active_neighbourhood].ID, jwt_token)
            .then(() => navigate(`/g/${joinOpen.id}`))
            .catch((err) => {
              enqueueSnackbar(err.message, { variant: 'error' })
            })
            .finally(() => setJoinOpen(false))
        }}
        onCancel={() => {
          setJoinOpen(false)
        }}
        action={{
          title: `Are you sure you want to join ${joinOpen ? joinOpen.group_name : ''}`,
          Accept: 'Yes',
          Decline: 'No'
        }}
      >
        <ErrorRounded style={{ fontSize: '2.5rem' }} color="primary" />
      </ActionModal>
      {/* <ActionModal
        open={Boolean(leaveOpen)}
        onClose={() => setLeaveOpen(false)}
        onConfirm={() => {
          leaveGroupsApi(leaveOpen.id)
          setLeaveOpen(false)
        }}
        onCancel={() => {
          setLeaveOpen(false)
        }}
        action={{
          title: `Are you sure you want to leave ${leaveOpen ? leaveOpen.group_name : ''}`,
          Accept: 'Yes',
          Decline: 'No'
        }}
      >
        <ErrorRounded style={{ fontSize: '2.5rem' }} color="error" />
      </ActionModal> */}
    </Grid>
  )
}

export default AllGroups
