import { Button, Divider, Typography } from '@mui/material'
import { getAllFeatureReqs } from 'api/feature/request'
import React, { useState, useContext, useEffect } from 'react'
import WideModal from 'ui/Modals/WideModal'
import FeatureNullState from '../../../assets/suggestfeaturenull.png'
import { UserContext } from '../../../context/UserContext'
import FeatureCard from './FeatureCard'
import FeatureForm from './FeatureForm'
import dayjs from 'dayjs'
import { useSnackbar } from 'notistack'

export const featureStatus = {
  0: 'New',
  1: 'Under Review',
  2: 'Approved',
  3: 'Closed - Duplicate',
  4: 'Closed - Out of Scope'
}

const SuggestFeature = ({ isOpen, onClose }) => {
  const { personal, jwt_token, active_neighbourhood } =
    useContext(UserContext)

  const [step, setStep] = useState(0)
  const [requests, setRequests] = useState([])
  const [selectedReq, setSelectedReq] = useState({})

  const { enqueueSnackbar } = useSnackbar()

  const getReqs = () => {
    getAllFeatureReqs(personal[active_neighbourhood].ID, jwt_token)
      .then((res) => setRequests((res.requests ?? []).reverse()))
      .catch(() => {
        enqueueSnackbar('Could not fetch your feature requests', {
          variant: 'error'
        })
      })
  }

  useEffect(() => {
    getReqs()
  }, [])

  return (
    <WideModal
      isOpen={isOpen}
      onClose={onClose}
      title="Suggest a Feature"
      isTitleBack={step !== 0}
      onPressTitleBack={() => setStep(0)}
      buttons={
        step === 0
          ? [
              {
                title: 'Suggest a Feature',
                onPress: () => setStep(1)
              }
            ]
          : []
      }
      buttonsAlign="center"
    >
      <Divider style={{ marginBottom: 12 }} />
      <div style={{ height: '55vh' }}>
        {step === 0
          ? (
              requests.length === 0
                ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                padding: '0px 5%',
                height: '100%'
              }}
            >
              <img
                src={FeatureNullState}
                alt="No features"
                width={235}
                style={{ marginBottom: '20px' }}
              />
              <Typography textAlign="center" color="#333333">
                Suggest a feature on Nearcast and earn upto{' '}
                <strong>2000 Nearcoins.</strong>{' '}
                {/* <a href="#" target="_blank" rel="noreferrer">
                  Learn more
                </a> */}
              </Typography>
            </div>
                  )
                : (
            <>
              {requests.map((req) => (
                <FeatureCard
                  req={req}
                  key={req.id}
                  setStep={setStep}
                  setSelectedReq={setSelectedReq}
                />
              ))}
            </>
                  )
            )
          : step === 1
            ? (
          <FeatureForm setStep={setStep} refresh={getReqs} />
              )
            : (
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
            <div>
              <Typography fontSize={16} fontWeight={600}>
                {selectedReq.title}
              </Typography>
              <Typography fontSize={14} style={{ margin: '10px 0' }}>
                {selectedReq.description}
              </Typography>
              {selectedReq.response && (
                <>
                  <Typography fontSize={16} fontWeight={600}>
                    Response
                  </Typography>
                  <Typography
                    fontSize={14}
                    style={{ margin: '10px 0' }}
                  >
                    Thank you for suggesting a new feature, but we
                    are sorry to let you know that we’ll not be
                    moving forward with this feature, keep
                    suggesting new features. Thank you
                  </Typography>
                </>
              )}
              <Typography
                fontSize={16}
                fontWeight={600}
                style={{ marginBottom: '20px' }}
              >
                Status:{' '}
                <span style={{ color: '#00a7ff' }}>
                  {featureStatus[selectedReq.status]}
                </span>
              </Typography>
              <Typography fontSize={12}>
                Submitted On:{' '}
                {dayjs(selectedReq.created_at).format('DD MMM YYYY')}
              </Typography>
              <Typography fontSize={11}>
                It will take atleast a few days to review a suggestion
                and reply to it.
              </Typography>
            </div>
            <div>
            <Divider style={{ width: '100%', margin: '15px 0' }} />
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Button variant="outlined" onClick={() => setStep(0)}>
                Back
              </Button>
            </div>
            </div>
          </div>
              )}
      </div>
    </WideModal>
  )
}

export default SuggestFeature
