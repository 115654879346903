import axios from 'axios'

/**
 * @function followUser Used to follow an user
 * @param {string} userId - the user's id whome to follow
 * @param {string} nrctUID - personal[active_neigbhborhood].ID from user context
 * @param {string} jwt - jwt from user context
 * @returns {Promise} Promise object represents true for success
 */
export const followUser = (userId, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/following/send`, {
      follow_id: userId
    }, {
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then(() => resolve(true))
      .catch((err) => {
        reject(err)
      })
  })
}

/**
 * @function unfollowUser Used to unfollow an user
 * @param {string} userId - the user's id whom to unfollow
 * @param {string} nrctUID - personal[active_neighborhood].ID from user context
 * @param {string} jwt - jwt from the user context
 * @returns {Promise} Promise object represents true for sucess
 */
export const unfollowUser = (userId, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.delete(`${process.env.REACT_APP_BACKEND_URL}/following/unfollow`, {
      data: {
        follow_id: userId
      },
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then(() => resolve(true))
      .catch((err) => {
        reject(err)
      })
  })
}
