import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

function DataDeletion () {
  const classes = styles()

  return (
        <div className={classes.mainContainer}>
            <Typography variant="h2">
                Facebook Data Deletion Instructions URL
            </Typography>
            <Typography variant="h4" style={{ marginTop: '20px' }}>
                Nearcast is a Facebook login app. According to Facebook's policy, we provide the Data Deletion Instructions below:
            </Typography>
            <Typography variant="body1" style={{ marginTop: '20px' }}>
                If you want to delete your activities for Nearcast, you can remove your information by following these steps:
            </Typography>
            <Typography variant="body1" style={{ marginTop: '20px' }}>
                1. Go to your Facebook Account’s Setting and Privacy. Click “Settings”.
            </Typography>
            <Typography variant="body1" style={{ marginTop: '20px' }}>
                2. Look for “Apps and Websites” and you will see all of the apps and websites you linked with your Facebook.
            </Typography>
            <Typography variant="body1" style={{ marginTop: '20px' }}>
                3. Search and Click “Nearcast" in the search bar.
            </Typography>
            <Typography variant="body1" style={{ marginTop: '20px' }}>
                4. Scroll and click “Remove”.
            </Typography>
            <Typography variant="body1" style={{ marginTop: '20px' }}>
                5. Congratulations, you have succesfully removed your app activities!
            </Typography>
        </div>
  )
}

const styles = makeStyles(({ breakpoints, palette }) => ({
  flex: {
    display: 'flex',
    alignItems: 'center'
  },
  flexGrow: {
    flex: '1'
  },
  mainContainer: {
    padding: '40px',
    paddingBottom: '60px'
  }
}))

export default DataDeletion
