import React, { useState } from 'react'
import StandardModal from 'ui/Modals/StandardModal'
import { CloseRounded } from '@mui/icons-material'
import { IconButton, Typography, FormControl, Button } from '@mui/material'
import DateFieldInput from 'ui/TextInputs/DateFieldInput'
import { sTypes } from '../AddStickers'
import { useSnackbar } from 'notistack'

function DateSticker ({ open, onClose, handleStickerAdd }) {
  const { enqueueSnackbar } = useSnackbar()
  const [date, setDate] = useState()

  const handleClick = () => {
    if (!date) {
      enqueueSnackbar('Pick a date 📆', {
        variant: 'error',
        autoHideDuration: 2000
      })
      return
    }
    if (date) {
      enqueueSnackbar('Your sticker has been added ✅', {
        variant: 'success',
        autoHideDuration: 2000
      })
    }
    handleStickerAdd({
      type: sTypes.date,
      date: date
    })
    onClose()
  }

  return (
        <StandardModal
			isOpen={open}
			onClose={onClose}
			title="Add Date"
			buttons={[
			  {
			    title: 'Add Sticker',
			    color: 'primary',
			    onPress: handleClick
			  }
			]}
		>
			<div style={{ marginTop: 20, marginBottom: 20, width: '100%' }}>
				<FormControl fullWidth margin="none">
					<DateFieldInput
						label="Date"
						value={date}
						onChange={(e) => setDate(e.target.value)}
					/>
				</FormControl>
			</div>
		</StandardModal>
  )
}

export default DateSticker
