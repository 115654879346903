import { Avatar, Typography, FormControl } from '@mui/material'
import React, { useEffect, useState } from 'react'

// importing ui
import StandardModal from 'ui/Modals/StandardModal'
import PriceFieldsInput from 'ui/TextInputs/PriceFieldsInput'
import CustomTextArea from 'ui/TextInputs/TextAreaInput'
import CropImageModal from 'ui/Custom/Modals/CropImageModal'
import ImagePicker from 'ui/Custom/ImagePicker/ImagePicker'

// importing apis
import { personalAccountAvatar } from 'api/personal_account/avatar'

export interface EditProfileUserDetails {
  first_name: string;
  last_name: string;
  username: '',
  bio: '',
}

export interface EditProfileModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (e: EditProfileUserDetails | null, f: File | null) => void;
  profileData: EditProfileUserDetails & { id: string }
}

const EditProfileModal: React.FC<EditProfileModalProps> = ({
  open,
  onClose,
  onSubmit,
  profileData
}) => {
  const [newProfileData, setNewProfileData] = useState<EditProfileUserDetails>({
    first_name: '',
    last_name: '',
    username: '',
    bio: ''
  })
  const [coverImage, setCoverImage] = useState<File | null>(null)
  // states for crop modal
  const [tmpCropImage, setTmpCropImage] = useState<File | null>(null)
  const [openCropModal, setOpenCropModal] = useState<boolean>(false)

  useEffect(() => {
    // UI is not using last_name, make sure it is always an empty string
    // Full Name is equivalent to first_name
    profileData.last_name = ''
    setNewProfileData({
      first_name: profileData.first_name,
      last_name: profileData.last_name,
      username: profileData.username,
      bio: profileData.bio
    })
  }, [profileData])

  return (
    <StandardModal
      isOpen={Boolean(open)}
      onClose={onClose}
      title="Edit profile"
      buttons={[
        {
          title: 'Submit',
          color: 'primary',
          onPress: () => {
            newProfileData.last_name = '' // check to remove last_name on accounts which already have it set
            const didAnyValuesChange = Object.keys(newProfileData).filter((k) => newProfileData[k] !== profileData[k])
            if (didAnyValuesChange.length > 0) {
              onSubmit(newProfileData, coverImage)
            } else {
              onSubmit(null, coverImage)
            }
            onClose()
          }
        }
      ]}
    >
      <div style={{ width: '100%' }}>
        <ImagePicker
          id="contained-file"
          onChange={async (e) => {
            setTmpCropImage(e.target.files[0])
            setOpenCropModal(true)
          }}
        />
        <label htmlFor="contained-file" style={{ textAlign: 'center' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Avatar
              aria-controls="user-controls"
              alt="userIcon"
              style={{
                width: '80px',
                height: '80px',
                marginBottom: '16px',
                cursor: 'pointer'
              }}
              src={
                coverImage
                  ? URL.createObjectURL(coverImage)
                  : personalAccountAvatar(profileData.id)
              }
            />
            <Typography style={{ color: '#5b87ff', cursor: 'pointer' }}>
              Edit profile picture
            </Typography>
          </div>
        </label>
        <FormControl fullWidth margin="none">
          <PriceFieldsInput
            label="Full Name"
            placeholder="John Doe"
            value={newProfileData.first_name}
            inputProps={{ maxLength: '63' }}
            onChange={(e) => {
              if ((newProfileData.last_name + e.target.value).length < 64) {
                setNewProfileData({
                  ...newProfileData,
                  first_name: e.target.value
                })
              }
            }}
          />
          <span
            style={{ fontSize: '10px', textAlign: 'right', marginTop: '8px' }}
          >
            {63 -
              (newProfileData.first_name + newProfileData.last_name)
                .length}{' '}
            characters left
          </span>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <PriceFieldsInput
            label="Username"
            placeholder="Doe"
            value={newProfileData.username}
            inputProps={{ maxLength: '31' }}
            onChange={(e) => {
              const usernameRegex = /[a-z0-9_.]{1,31}/
              let newUsername = e.target.value.toLowerCase()
              const matched = usernameRegex.exec(newUsername)
              if (matched !== null) {
                newUsername = matched[0] // will not allow any other character apart from aphabets, numbers, bot and underscore
              }
              setNewProfileData({
                ...newProfileData,
                username: newUsername
              })
            }}
          />
          <span style={{ fontSize: '10px', textAlign: 'right' }}>
            {31 - newProfileData.username.length} characters left
          </span>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <CustomTextArea
            label="Bio"
            value={newProfileData.bio}
            inputProps={{ maxLength: '255' }}
            onChange={(e) => {
              setNewProfileData({ ...newProfileData, bio: e.target.value })
            }}
          />
          <span style={{ fontSize: '10px', textAlign: 'right' }}>
            {255 - newProfileData.bio.length} characters left
          </span>
        </FormControl>
      </div>
      <CropImageModal
        open={openCropModal}
        onClose={() => {
          setOpenCropModal(false)
          setTmpCropImage(null)
        }}
        onCropComplete={(imageBlob) => {
          const file = new File([imageBlob], `nearcast-${Date.now()}`, { type: tmpCropImage.type })
          setCoverImage(file)
        }}
        imageFile={tmpCropImage}
      />
    </StandardModal>
  )
}

export default EditProfileModal
