import React, { useEffect } from 'react'
import GatheringScreen from '../../components/Gathering/GatheringScreen/GatheringScreen'

function Gathering (props) {
  useEffect(() => {
    window.Tawk_API = window.Tawk_API || {}
    try {
      window.Tawk_API.hideWidget()
    } catch (err) {
      window.Tawk_API.onLoad = function () {
        window.Tawk_API.hideWidget()
      }
    }
  }, [])
  return <GatheringScreen />
}

export default Gathering
