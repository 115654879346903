import { Card, CardContent, Modal } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

const styles = makeStyles(({ breakpoints, palette }) => ({
  modalCard: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    borderRadius: '20px',
    width: '400px',
    [breakpoints.down('md')]: {
      width: 'auto',
      minWidth: '280px',
      margin: '0px'
    },
    outline: 'none'
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}))

function StandardModal ({ open, onClose, style, contentStyle, children, wrapperClassname = '' }) {
  const classes = styles()

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose(false)
      }}
    >
      <Card style={{ ...style, margin: '0px' }} className={[classes.modalCard, wrapperClassname]}>
        <CardContent
          className={classes.modalContent}
          style={{ ...contentStyle }}
        >
          {children}
        </CardContent>
      </Card>
    </Modal>
  )
}

export function StandardModalWithHeader ({ open, onClose, style, children }) {
  const classes = styles()

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose(false)
      }}
    >
      <Card style={style} className={classes.modalCard}>
        {children}
      </Card>
    </Modal>
  )
}

export default StandardModal
