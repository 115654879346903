import React, { useState } from 'react'
import {
  Typography,
  styled,
  Card,
  CardMedia,
  CardProps,
  IconButton,
  Menu,
  MenuItem
} from '@mui/material'
import {
  MoreHoriz
} from '@mui/icons-material'

const StyledCard = styled(Card)<CardProps>(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  height: '234px',
  width: '380px',
  [theme.breakpoints.down('md')]: {
    height: '234px',
    width: '300px'
  },
  [theme.breakpoints.down('sm')]: {
    width: '92vw'
  },
  '&:hover': {
    cursor: 'pointer'
  }
}))

export interface ImageCoverCardMenuProps {
    title: string;
    color: ['primary', 'secondary', 'ghost', 'error'];
    onPress: () => void;
    icon?: () => JSX.Element;
}

export interface ImageCoverCardProps {
    imgUrl: string;
    title: string;
    subTitle: string;
    onPress: () => void;
    menuItems: ImageCoverCardMenuProps[];
    hasBadge?: any;
}

const ImageCoverCard: React.FC<ImageCoverCardProps> = ({
  imgUrl,
  title,
  subTitle,
  onPress,
  menuItems,
  hasBadge
}) => {
  const [hover, setHover] = useState(false)
  const [menuOpen, setMenuOpen] = useState(null)

  React.useEffect(() => {
    if (window.innerWidth < 500) {
      setHover(true)
    }
  }, [])

  return (
        <StyledCard
            onMouseEnter={() => {
              if (!hover) {
                setHover(true)
              }
            }}
            onMouseLeave={() => {
              if (window.innerWidth < 500) {
                return
              }
              if (hover) {
                setHover(false)
                setMenuOpen(null)
              }
            }}
            onClick={(e) => {
              if (Boolean(menuOpen) || !hover) {
                return
              }
              onPress()
            }}
        >
            <CardMedia
                style={{
                  position: 'relative',
                  height: '100%',
                  width: '100%',
                  zIndex: 1
                }}
                component="img"
                alt={title}
                image={imgUrl}
            />
            <div style={styles.cardOverlay}>
                <div
                    style={{
                      ...styles.cardOverlayMenu,
                      display: hover ? 'block' : 'none'
                    }}
                >
                    <IconButton
                        onClick={(e) => {
                          e.stopPropagation()
                          setMenuOpen(e.currentTarget)
                        }}
                        aria-controls="post-menu"
                        color="ghost"
                        size="small"
                        style={{ backgroundColor: 'rgba(80, 80, 80, 0.8)' }}
                    >
                        <MoreHoriz htmlColor="#fff" />
                    </IconButton>
                    <Menu
                        id="people-menu"
                        anchorEl={menuOpen}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right'
                        }}
                        open={Boolean(menuOpen)}
                        keepMounted
                        onClose={() => setMenuOpen(null)}
                        style={{
                          zIndex: 99
                        }}
                    >
                        {menuItems.map((item, index) => {
                          return (
                                <MenuItem
                                    key={`mi-${index}`}
                                    onClick={item.onPress}
                                >
                                    <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          alignItems: 'center',
                                          justifyContent: 'space-between'
                                        }}
                                    >
                                        {item.icon()}
                                        <Typography style={{ marginLeft: 6 }} color={item.color}>
                                            {item.title}
                                        </Typography>
                                    </div>
                                </MenuItem>
                          )
                        })}
                    </Menu>
                </div>
                <div style={styles.overlayInnerWrapper}>
                    <div>
                        <Typography
                            variant="h2"
                            color="white"
                            style={{
                              display: 'flex',
                              alignItems: 'center'
                            }}
                        >
                            {title}
                        </Typography>
                        <Typography variant="caption" color="white">
                            {subTitle}
                        </Typography>
                    </div>
                    <div
                        style={{
                          position: 'absolute',
                          right: 10,
                          bottom: 30
                        }}
                    >
                        {hasBadge && hasBadge()}
                    </div>
                </div>
            </div>
        </StyledCard>
  )
}

const styles: any = {
  cardOverlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '10px',
    backgroundImage: 'linear-gradient(to top, #000 -20%, rgba(0, 0, 0, 0) 40%)',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  },
  overlayInnerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: 12
  },
  cardOverlayMenu: {
    position: 'absolute',
    top: 10,
    right: 10
  }
}

export default ImageCoverCard
