import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  Divider,
  Modal
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PlayArrowRounded } from '@mui/icons-material'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { UserContext } from '../../../context/UserContext'
import CustomTextField, {
  TextLabel
} from 'ui/TextInputs/TextInput'
import LikeIcon from '../../../assets/LikeIcon.svg'
import AddIcon from '@mui/icons-material/Add'
import ReactPlayer from 'react-player'
import { useSnackbar } from 'notistack'

// importing apis
import { userHomeIndex } from 'api/user/home'

const style = makeStyles(({ palette }) => {
  return {
    heading: {
      fontSize: '2rem',
      fontWeight: '500',
      color: '#104F3D'
    }
  }
})

function VideoCard ({ post, selectHandle, playHandle, selected }) {
  return (
    <div
      style={{
        backgroundColor: '#F2F2F2',
        height: '180px',
        width: '111px',
        borderRadius: '12px',
        backgroundImage: `url(https://image.mux.com/${post.post_playback_id}/thumbnail.png?token=${post.post_image_token})`,
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column',
        padding: '10px',
        borderStyle: selected ? 'inset' : 'hidden',
        borderWidth: '2px',
        borderColor: 'green'
      }}
      onClick={(e) => {
        e.preventDefault()
        selectHandle()
      }}
    >
      <div
        style={{
          marginLeft: 'auto',
          marginRight: '2px',
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <div>
          <img src={LikeIcon} alt="like" />
        </div>
        <div style={{ color: 'white', marginLeft: '4px' }}>
          {post.likes_count}
        </div>
      </div>
      <div
        style={{
          alignSelf: 'center',
          justifySelf: 'center',
          paddingTop: '20px'
        }}
      >
        <IconButton onClick={playHandle} size="large">
          <PlayArrowRounded htmlColor="#FFFFFF" style={{ fontSize: 56 }} />
        </IconButton>
      </div>
      <div style={{ display: 'flex', marginTop: 'auto', marginBottom: '5px' }}>
        <div
          style={{
            height: '30px',
            width: '30px',
            borderRadius: '15px',
            backgroundImage: 'url(https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8cGVyc29ufGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80)',
            backgroundSize: 'cover'
          }}
        />
        <div
          style={{
            fontSize: '18px',
            fontWeight: '400',
            color: 'white',
            alignSelf: 'flex-end',
            marginLeft: '10px'
          }}
        >
          You
        </div>
      </div>
    </div>
  )
}

function CustomTextArea ({ label, name, placeholder, value, onChange, row }) {
  return (
    <>
      <label
        htmlFor={name}
        style={{
          marginLeft: 6,
          fontSize: 14,
          fontWeight: 600
        }}
      >
        {label}
      </label>
      <textarea
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        style={{
          backgroundColor: '#F4F7FD',
          outline: 'none',
          border: 'none',
          padding: 13,
          fontSize: 16,
          borderRadius: 5,
          margin: 5,
          marginBottom: 10,
          height: '5rem'
        }}
      />
    </>
  )
}

function CustomPriceFields ({
  label,
  name,
  placeholder,
  value,
  onChange,
  row,
  labelStyle
}) {
  return (
    <>
      <TextLabel
        style={{
          marginLeft: 6,
          fontSize: 14,
          fontWeight: 600
        }}
      >
        {label}
      </TextLabel>
      <CustomTextField
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        name={name}
        color="#8aa6de"
      />
    </>
  )
  // return (
  //   <div
  //     style={{
  //       display: "flex",
  //       flexDirection: "row",
  //       alignItems: "center",
  //       marginBottom: 10,
  //     }}
  //   >
  //     <label
  //       htmlFor={name}
  //       style={{
  //         marginLeft: 6,
  //         fontSize: "1.2rem",
  //         fontWeight: 600,
  //         ...labelStyle,
  //       }}
  //     >
  //       {label}
  //     </label>
  //     <input
  //       type="text"
  //       name={name}
  //       placeholder={placeholder}
  //       value={value}
  //       onChange={onChange}
  //       style={{
  //         backgroundColor: "#F4F7FD",
  //         outline: "none",
  //         border: "none",
  //         padding: 13,
  //         fontSize: 16,
  //         borderRadius: 5,
  //         margin: 5,
  //         marginLeft: "auto",
  //         marginRight: 0,
  //       }}
  //     />
  //   </div>
  // );
}

function InfluencerSignup (props) {
  // const classes = styles();
  const [price, setPrice] = useState('')
  const [aboutme, setAboutme] = useState('')
  const { personal, jwt_token, active_neighbourhood } = useContext(UserContext)
  const navigate = useNavigate()
  const location = useLocation()
  const classes = style()
  const [selected, setSelected] = useState(-1)
  const [playing, setPlaying] = useState(-1)
  const [posts, setPosts] = useState([])
  const [loading, setLoading] = useState(true)
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    userHomeIndex(1, 10, personal[active_neighbourhood].ID, jwt_token)
      .then((dataBody) => setPosts(dataBody))
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: 'error'
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    if (!jwt_token) {
      navigate({
        pathname: '/login',
        state: { pathname: location.pathname }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid container justifyContent="flex-start">
      <Grid xs={12} md={6}>
        <div className={classes.heading}>Influencer Signup</div>
        <FormControl fullWidth>
          <CustomTextArea
            row={3}
            label="Tell us more about you"
            placeholder="describe your offering in more detail"
            value={aboutme}
            onChange={(e) => {
              setAboutme(e.target.value)
            }}
          />
        </FormControl>
        <Grid
          style={{ display: 'flex', flexDirection: 'column', width: '300px' }}
        >
          <FormControl>
            <CustomPriceFields
              label="Add your price"
              placeholder="$1.00 - $20.00"
              value={price}
              onChange={(e) => {
                setPrice(e.target.value)
              }}
            />
          </FormControl>
          <FormControl>
            <CustomPriceFields
              labelStyle={{ fontSize: 12 }}
              label="Nearcast fee (3%)"
              placeholder=""
              readOnly
              helperText="Nearcast will take 3% as transaction fee"
              value={price ? price * 0.3 : 0}
            />
          </FormControl>
          <FormControl>
            <CustomPriceFields
              label="What you get"
              placeholder="$1.00 - $20.00"
              readOnly
              value={price ? price - price * 0.3 : 0}
            />
          </FormControl>
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <CustomTextField
              label="Description your Gathering"
              placeholder="Enter your gathering description here..."
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </FormControl>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <CustomTextField
                label="Description your Gathering"
                placeholder="Enter your gathering description here..."
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <CustomTextField
                label="Description your Gathering"
                placeholder="Enter your gathering description here..."
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </FormControl>
          </Grid>
        </Grid> */}
      </Grid>
      <Grid xs={12} md={6}>
        <div
          className={classes.heading}
          style={{ color: 'black', fontSize: '28px' }}
        >
          Choose the videos you want to feature
        </div>
        <div style={{ padding: '8px' }}>
          <Button
            style={{
              borderRadius: '12px',
              display: 'flex',
              flexDirection: 'column',
              padding: '10px',
              backgroundColor: '#F2F2F2',
              height: '80px',
              width: '80px'
            }}
          >
            <div
              style={{
                height: '30px',
                width: '30px',
                marginTop: 'auto',
                marginBottom: 'auto',
                alignSelf: 'center',
                justifySelf: 'center',
                borderRadius: '56px',
                backgroundColor: '#BDBDBD',
                textAlign: 'center',
                display: 'flex'
              }}
            >
              <AddIcon
                htmlColor="#FFFFFF"
                style={{ fontSize: 28, alignSelf: 'center' }}
              />
            </div>
          </Button>
        </div>
        <div
          className={classes.heading}
          style={{ color: 'black', fontSize: '18px' }}
        >
          or Choose from your Nearcasts
        </div>
        <Grid
          container
          style={{ padding: '8px', display: 'flex', flexWrap: 'wrap' }}
          spacing={2}
        >
          {posts &&
            posts.map((post, index) => (
              <Grid item key={`influencerVideo${index}`}>
                <VideoCard
                  selectHandle={() => {
                    // console.log("selected" + selected);
                    setSelected(index)
                  }}
                  playHandle={(e) => {
                    e.stopPropagation()
                    setPlaying(index)
                  }}
                  selected={selected === index}
                  post={post}
                />
              </Grid>
            ))}
        </Grid>
      </Grid>
      <Divider style={{ width: '100%' }} />
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          width: '100%',
          marginTop: '10px'
        }}
      >
        <Button
          variant="contained"
          disableElevation
          style={{ marginRight: '10px' }}
        >
          Cancel
        </Button>
        <Button variant="contained" disableElevation color="secondary">
          Become an Influencer
        </Button>
      </Box>
      <Modal
        open={playing !== -1}
        onClose={() => {
          setPlaying(-1)
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {playing !== -1 && (
          <ReactPlayer
            url={`https://stream.mux.com/${posts[playing].post_playback_id}.m3u8?token=${posts[playing].post_playback_token}`}
            width="45vh"
            height="80vh"
            loop={true}
            playing={true}
            style={{ maxWidth: '100%' }}
            playsinline
          />
        )}
      </Modal>
    </Grid>
  )
}

export default InfluencerSignup
// {playing!==-1 &&(
//   <div style={{height:}}>

//   </div>
// )
// }
