import {
  Card,
  FormControl,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Button,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  MenuList,
  List,
  Stack
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  DeleteRounded,
  ErrorRounded,
  Favorite,
  LinkRounded,
  MoreVert,
  EditRounded,
  Send as SendIcon,
  Share
} from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import React, { useContext, useEffect, useState, useRef, useCallback } from 'react'
import { UserContext } from '../../context/UserContext'
import { timeDifference } from '../../utils/dateTimeParser.js'
import NoComments from '../../assets/noComments.svg'
import PaymentModal from '../Modals/PaymentModal'
import PriceFieldsInput from 'ui/TextInputs/PriceFieldsInput'
import CommentElement from './CommentElement'
import EditPostCaption from './Modal/EditPostCaption'
import { onKeypressAction } from '../../utils/keypress'
import ReportModal from '../Modals/ReportModal'
import StickerButtonCustom from 'ui/Buttons/StickerButton/StickerButton'
import Dialog from 'ui/Dialog/Dialog'
import StandardModal from 'ui/Modals/StandardModal'
import { GlobalFuncContext } from '../../context/GlobalFuncHOC'
import { NIL as uuidNil } from 'uuid'

// importing apis
import { postDelete } from 'api/post/delete'
import { postLike } from 'api/post/like'
import { postUnlike } from 'api/post/unlike'
import { postComments } from 'api/post/comments'
import { commentDelete } from 'api/comment/delete'
import { commentCreate } from 'api/comment/create'
import { reportComment } from 'api/report/comment'
import { reportPost } from 'api/report/post'
import { personalAccountAvatar } from 'api/personal_account/avatar'

// importing redux helpers
import { useAppDispatch } from '../../utils/redux/store'
import { refreshPostRedux, removePostFromRedux } from '../../utils/redux/actions/postActions'

const EmojiButton = ({ emoji, setCommentText }) => {
  return (
    <div
      style={{
        height: '35px',
        width: '40px',
        textAlign: 'center',
        paddingTop: '5px',
        fontSize: '22px',
        cursor: 'pointer'
      }}
      onClick={() => {
        setCommentText((prevCommentText) => prevCommentText + emoji)
      }}
    >
      {emoji}
    </div>
  )
}

// localRefresher is used to update posts in profile screen, where the users posts aren't coming from redux
// but are loaded in component state
function PostDetails ({ index, nearcast, onClose, localRefresher }) {
  const dispatch = useAppDispatch()
  const { personal, jwt_token, userProfiles, active_neighbourhood } = useContext(UserContext)
  const { openShareModal } = useContext(GlobalFuncContext)
  const [comments, setComments] = useState([])
  const [open, setOpen] = useState()
  const [loadComments, setLoadComments] = useState(true)
  const [commentPage, setCommentPage] = useState(1)
  const [commentHasMore, setCommentHasMore] = useState(true)
  const [commentText, setCommentText] = useState('')
  const [postLiked, setPostLiked] = useState(false)
  const [reportOpen, setReportOpen] = useState(false)
  const [paymentData, setPaymentData] = useState({})
  const [isEditPostOpen, setIsEditPostOpen] = useState()
  const [reportTypeModalOpen, setReportTypeModalOpen] = useState(false)
  const [reportType, setReportType] = useState('')
  const [isMusicModalOpen, setIsMusicModalOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const classes = styles()
  // ref to comment text input
  const commentRef = useRef()

  // const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  const likePostApi = useCallback(async () => {
    // if the user is not logged in, hence the personal array is null don't let user like
    if (personal === null) {
      return enqueueSnackbar('You are not logged in, Please login to like or comment!', {
        variant: 'info'
      })
    }

    // Bad Code: likePostApi should do what it's name suggests and not unlike a post as well
    try {
      if (!postLiked) {
        setPostLiked(true)
        await postLike(nearcast.post_id, personal[active_neighbourhood].ID, jwt_token)
        enqueueSnackbar('You liked this post.', {
          variant: 'success',
          autoHideDuration: '200'
        })
      } else {
        setPostLiked(false)
        await postUnlike(nearcast.post_id, personal[active_neighbourhood].ID, jwt_token)
        enqueueSnackbar('You disliked this post.', {
          variant: 'success'
        })
      }
    } catch (err) {
      setPostLiked((prev) => !prev)
      enqueueSnackbar(err.message, {
        variant: 'error'
      })
    }
  }, [active_neighbourhood, enqueueSnackbar, jwt_token, nearcast.post_id, personal, postLiked])

  const fetchCommentsApi = useCallback(() => {
    if (!commentHasMore) {
      return
    }
    if (commentPage === 1) {
      setLoadComments(true)
    }
    postComments(nearcast.post_id, commentPage, 10, personal[active_neighbourhood].ID, jwt_token)
      .then((resBody) => {
        if (resBody.length === 0) {
          setCommentHasMore(false)
          return
        }
        if (commentPage === 1) {
          setComments(resBody)
        } else {
          setComments([...comments, ...resBody])
        }
        setCommentPage((c) => c + 1)
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: 'error'
        })
      })
      .finally(() => {
        setLoadComments(false)
      })
  }, [active_neighbourhood, commentHasMore, commentPage, comments, enqueueSnackbar, jwt_token, nearcast.post_id, personal])

  const deleteCommentApi = (id) => {
    // remove the comment locally
    const filteredComments = comments.filter((comment) => comment.id !== id)
    setComments(filteredComments)
    commentDelete(id, personal[active_neighbourhood].ID, jwt_token)
      .then(() => {
        enqueueSnackbar('You deleted this comment.', {
          variant: 'success'
        })
      })
      .catch((err) => {
        enqueueSnackbar(err, {
          variant: 'error'
        })
      })
      .finally(() => {
        fetchCommentsApi()
        setLoadComments(false)
      })
  }

  const reportCommentApi = (id) => {
    reportComment(id, 'User reported comment from the web', personal[active_neighbourhood].ID, jwt_token)
      .then((res) => {
        enqueueSnackbar('You reported this comment.', {
          variant: 'success'
        })
      })
      .catch((err) => {
        enqueueSnackbar("We weren't able to report this comment, please try again.", {
          variant: 'error'
        })
      })
  }

  const reportPostApi = () => {
    reportPost(nearcast.post_id, reportType, personal[active_neighbourhood].ID, jwt_token)
      .then((res) => {
        enqueueSnackbar('You reported this post.', {
          variant: 'success'
        })
      })
      .catch((err) => {
        enqueueSnackbar(
          "We weren't able to report this post, please try again.", {
            variant: 'error'
          })
      })
  }

  const postCommentsApi = useCallback(() => {
    // if user not logged in, hence personal is null don't let user comment
    if (personal === null) {
      return enqueueSnackbar('You are not logged in, Please login to like or comment!', {
        variant: 'info'
      })
    }
    if (commentText.length === 0 || !jwt_token) return
    const commentedText = commentText
    setCommentText('')
    commentCreate(nearcast.post_id, commentedText, personal[active_neighbourhood].ID, jwt_token)
      .then(async (resBody) => {
        const sentComment = resBody
        setComments((prevComments) => {
          return [
            {
              comment: sentComment.payload,
              id: sentComment.ID,
              created_at: sentComment.CreatedAt,
              updated_at: sentComment.UpdatedAt,
              user_id: sentComment.personal_account_id,
              post_id: sentComment.post_id,
              first_name: userProfiles[active_neighbourhood].first_name,
              last_name: userProfiles[active_neighbourhood].last_name,
              username: userProfiles[active_neighbourhood].username
            },
            ...prevComments
          ]
        })
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: 'error'
        })
      })
  }, [active_neighbourhood, commentText, enqueueSnackbar, jwt_token, nearcast.post_id, personal, userProfiles])

  const openUserProfile = () => {
    window.open(`${window.location.toString().split('/').slice(0, 3).join('/')}/u/${nearcast.user_id}`)
  }

  const observer = useRef()
  const lastCommentElementRef = useCallback(
    (node) => {
      if (loadComments) {
        return
      }
      if (observer.current) {
        observer.current.disconnect()
      }
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && commentHasMore) {
          fetchCommentsApi()
        }
      })
      if (node) {
        observer.current.observe(node)
      }
    },
    [commentHasMore, fetchCommentsApi, loadComments]
  )

  useEffect(() => {
    const cleanUpLike = onKeypressAction(76, () => {
      // if the user is typing a comment then avoid
      if (commentRef.current === document.activeElement) {
        return
      }
      likePostApi()
    })
    const cleanUpComment = onKeypressAction(67, () => {
      commentRef.current.focus() // focus on the comment box
    })
    const cleanUpNextLine = onKeypressAction(13, (e) => {
      if (e.shiftKey) {
        // should move to the next line of comment input: need to research about because of default behavior of material ui
      } else {
        postCommentsApi()
      }
    })
    return () => {
      // on component unmount cleanup calls
      cleanUpLike()
      cleanUpComment()
      cleanUpNextLine()
    }
  }, [index, likePostApi, postCommentsApi])

  useEffect(() => {
    // if anonymous post, then don't try to load comments
    if (nearcast.post_id === uuidNil) {
      return
    }
    if (personal) {
      fetchCommentsApi()
    } else {
      setLoadComments(false)
    }
    setPostLiked(nearcast.is_liked)
  }, [nearcast])

  const renderStickers = () => {
    // if the stickers array is empty or null then don't render anything
    if (nearcast.stickers === null || nearcast.stickers.length === 0) {
      return null
    }
    return (
      <div className={classes.stickersContainer}>
        <Typography variant="h4">Stickers</Typography>
        <div style={{ display: 'flex', overflowX: 'auto', marginTop: '4px' }}>
          {
            nearcast.stickers.map((e, i) => (
              <StickerButtonCustom
                sticker={{
                  ...e,
                  requestPaymentApi: (isDonate) => {
                    setPaymentData({
                      postId: nearcast.post_id,
                      isDonate,
                      price: e.price ? e.price : 10,
                      currency: {
                        code: nearcast.currency_code,
                        name: nearcast.currency_name,
                        symbol: nearcast.currency_symbol
                      }
                    })
                  },
                  postDetails: nearcast
                }}
                key={'Sticker' + i}
              />
            ))
          }
        </div>
      </div>
    )
  }

  if (nearcast.post_id === uuidNil) {
    return (
      <Grid
        item
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Stack direction="column" alignItems="center" justifyContent="center" sx={{ height: '100%', width: '100%' }}>
          <Typography variant="h5">
            Anonymous Post
          </Typography>
        </Stack>
      </Grid>
    )
  }

  return (
    <Grid
      item
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <div className={classes.mainContainer}>
        <div style={{ marginBottom: '10px', width: '100%' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '10px'
            }}
          >
            <ListItem
              alignItems="flex-start"
              style={{ margin: 0, padding: 0 }}
              secondaryAction={
                <div
                  onClick={(e) => {
                    setOpen(e.currentTarget)
                  }}
                  aria-controls="post-menu"
                  className={classes.moreButton}
                >
                  <MoreVert />
                </div>
              }
            >
              <ListItemAvatar onClick={openUserProfile} className={classes.onHover}>
                <Avatar
                  key={nearcast.user_id}
                  alt={nearcast.username}
                  src={personalAccountAvatar(nearcast.user_id)}
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <div onClick={openUserProfile} className={classes.onHover}>
                    {nearcast.username}
                  </div>
                }
                secondary={`Posted ${timeDifference(nearcast.posted_at)}`}
              />
            </ListItem>
            <Menu
              id="people-menu"
              anchorEl={open}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={Boolean(open)}
              keepMounted
              onClose={() => {
                setOpen(null)
              }}
            >
              <MenuItem
                key="copyLink"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${process.env.REACT_APP_SHARE_URL}/p/${nearcast.post_id.trim()}
                    `.trim()
                  )
                  setOpen()
                  enqueueSnackbar(
                    "We copied this post's link to your clipboard.",
                    {
                      variant: 'success'
                    }
                  )
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <LinkRounded style={{ marginRight: '8px' }} />
                  <Typography>Copy link</Typography>
                </div>
              </MenuItem>
              {personal &&
                (nearcast.user_id === personal[active_neighbourhood].ID
                  ? (
                  <MenuList>
                    <MenuItem
                      key="editPost"
                      onClick={() => {
                        setIsEditPostOpen(true)
                        setOpen(false)
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <EditRounded style={{ marginRight: '8px' }} />
                        <Typography>Edit post</Typography>
                      </div>
                    </MenuItem>
                    <MenuItem
                      key="deletePost"
                      onClick={async () => {
                        try {
                          await postDelete(nearcast.post_id, personal[active_neighbourhood].ID, jwt_token)
                          onClose()
                          dispatch(removePostFromRedux(nearcast.post_id))
                        } catch (err) {
                          enqueueSnackbar(err.message, {
                            variant: 'error'
                          })
                        }
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <DeleteRounded
                          color="error"
                          style={{ marginRight: '8px' }}
                        />
                        <Typography color="error">Delete post</Typography>
                      </div>
                    </MenuItem>
                  </MenuList>
                    )
                  : (
                  <MenuItem
                    key="reportPost"
                    onClick={() => setReportTypeModalOpen(true)}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <ErrorRounded
                        color="secondary"
                        style={{ marginRight: '8px' }}
                      />
                      <Typography color="secondary">Report post</Typography>
                    </div>
                  </MenuItem>
                    ))}
            </Menu>
          </div>
          {nearcast.music && (
            <Typography
              variant="h6"
              style={{
                cursor: 'pointer'
              }}
              onClick={() => setIsMusicModalOpen(true)}
            >
              {nearcast.music.length === 1
                ? `🎶 ${nearcast.music[0].song_name} • ${nearcast.music[0].author_name}`
                : '🎶 Multiple Music Tracks (click to know more)'
              }
            </Typography>
          )}
          <Typography variant="subtitle1">{nearcast.post_caption}</Typography>
        </div>
        {renderStickers()}
        <Typography variant="h4">Comments</Typography>
        <div className={classes.commentsContainer}>
          {loadComments && comments.length === 0
            ? (
            <>
              <CommentElement
                className="shimmer"
                placeholder
                classes={{
                  textContainer: classes.CommentElementTextContainer
                }}
                comment={{
                  first_name: '‏‏‎ ‎‏‏‎',
                  last_name: '‎‎‎‎‏‏‎ ‎‏‏',
                  comment: '‏‏‎ '
                }}
              />
              <CommentElement
                className="shimmer"
                placeholder
                classes={{
                  textContainer: classes.CommentElementTextContainer
                }}
                comment={{
                  first_name: '‏‏‎ ‎‏‏‎',
                  last_name: '‎‎‎‎‏‏‎ ',
                  comment: '‏‏‎ ‎'
                }}
              />
            </>
              )
            : comments.length > 0
              ? (
                  comments.map((comment, index) => (
              <CommentElement
                key={comment.id}
                forwardRef={comments.length - 1 === index ? lastCommentElementRef : null}
                ownComment={
                  personal[active_neighbourhood].ID === comment.user_id ||
                  personal[active_neighbourhood].ID === nearcast.user_id
                }
                classes={{
                  textContainer: classes.CommentElementTextContainer
                }}
                comment={comment}
                deleteComment={deleteCommentApi}
                reportComment={reportCommentApi}
              />
                  ))
                )
              : (
            <>
              {personal
                ? (
                <>
                  <div className={classes.noComments}></div>
                  <Typography variant="body2" style={{ textAlign: 'center' }}>
                    Be the first one to post a comment
                  </Typography>
                </>
                  )
                : (
                <>
                  <div className={classes.noComments}></div>
                  <Typography variant="body2" style={{ textAlign: 'center' }}>
                    Login to view comments
                  </Typography>
                </>
                  )}
            </>
                )}
        </div>
        <Card
          style={{
            display: 'flex',
            borderRadius: '10px',
            marginLeft: '10px',
            justifyContent: 'space-around',
            alignItems: 'center',
            userSelect: 'none'
          }}
        >
          <EmojiButton emoji="😂" setCommentText={setCommentText} />
          <EmojiButton emoji="😡" setCommentText={setCommentText} />
          <EmojiButton emoji="😭" setCommentText={setCommentText} />
          <EmojiButton emoji="🔥" setCommentText={setCommentText} />
          <EmojiButton emoji="💯" setCommentText={setCommentText} />
          <EmojiButton emoji="🙌" setCommentText={setCommentText} />
          <EmojiButton emoji="👏" setCommentText={setCommentText} />
        </Card>
        <div
          style={{
            display: 'flex',
            paddingInline: '8px',
            position: 'relative',
            marginBlock: '8px',
            alignItems: 'center'
          }}
        >
          <div style={{ flexGrow: 1, paddingBottom: '3px' }}>
            <FormControl fullWidth>
              <PriceFieldsInput
                inputRef={commentRef}
                value={commentText}
                onChange={(e) => setCommentText(e.target.value)}
                placeholder="Say something nice..."
                disabled={!jwt_token}
                inputProps={{ maxLength: '511' }}
              />
            </FormControl>
          </div>
          <div
            style={{
              marginLeft: '10px'
            }}
          >
            <IconButton onClick={() => postCommentsApi()} color="primary" size="large">
              <SendIcon htmlColor="white" />
            </IconButton>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            paddingInline: '8px',
            paddingBottom: '10px'
          }}
        >
          <Button
            color="primary"
            variant={postLiked ? 'outlined' : 'contained'}
            disableElevation
            startIcon={<Favorite htmlColor={postLiked ? '#3A8F78' : 'white'} />}
            onClick={() => likePostApi()}
            style={stylesRaw.actionButton}
          >
            {postLiked ? 'Liked' : 'Like'}
          </Button>
          <Button
            color="ghost"
            variant="contained"
            disableElevation
            startIcon={<Share />}
            onClick={() => openShareModal(
              'Post',
              `Checkout this post at Nearcast: ${nearcast.post_caption}`,
              `/p/${nearcast.post_id}`
            )}
            style={{
              ...stylesRaw.actionButton,
              marginLeft: 10
            }}
          >
            Share
          </Button>
        </div>
      </div>
      <ReportModal
        open={reportTypeModalOpen}
        onClose={(reportType) => {
          if (reportType !== null) {
            setReportType(reportType)
            setReportOpen(true)
          }
          setReportTypeModalOpen(false)
        }}
      />
      <Dialog
        isOpen={reportOpen}
        title="Are you sure you want to report this post"
        onConfirm={() => {
          reportPostApi()
          setReportOpen(false)
          setOpen(false)
        }}
        onCancel={() => {
          setReportOpen(false)
          setOpen()
        }}
        icon={() => <ErrorRounded style={{ fontSize: '2.5rem' }} color="error" />}
      />
      <PaymentModal
        onClose={() => {
          setPaymentData({})
        }}
        postId={paymentData.postId}
        isDonate={paymentData.isDonate}
        price={paymentData.price}
        currency={paymentData.currency}
      />
      <EditPostCaption
        postId={nearcast.post_id}
        open={isEditPostOpen}
        onClose={(hasUpdated) => {
          setIsEditPostOpen(false)
          if (hasUpdated) {
            dispatch(refreshPostRedux(nearcast, personal[active_neighbourhood].ID, jwt_token))
            localRefresher() // for profile screen
          }
        }}
        caption={nearcast.post_caption}
      />
      {nearcast.music && (
        <StandardModal
          isOpen={isMusicModalOpen}
          onClose={() => setIsMusicModalOpen(false)}
          title="All Tracks on Post"
          buttons={[
            {
              title: 'Close',
              onPress: () => setIsMusicModalOpen(false),
              color: 'ghost'
            }
          ]}
        >
          <Typography variant="caption">
            These music tracks are used in this post
          </Typography>
          <List>
            {nearcast.music.map((music, index) => {
              return (
                <ListItem key={`${index}`}>
                  <ListItemAvatar>
                    <Avatar
                      alt={music.song_name}
                      src={`${process.env.REACT_APP_BACKEND_URL}/${music.cover}`}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={music.song_name}
                    secondary={music.author_name}
                  />
                </ListItem>
              )
            })}
          </List>
        </StandardModal>
      )}
    </Grid>
  )
}

const stylesRaw = {
  actionButton: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    padding: '5px 10px',
    borderRadius: 5,
    cursor: 'pointer',
    width: '90px'
  }
}

const styles = makeStyles(({ breakpoints, palette }) => ({
  mainContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '16px',
    height: 'calc(100% - 2px)',
    maxWidth: '400px',
    width: '100%',
    [breakpoints.down('md')]: {
      marginInline: 'auto'
    },
    [breakpoints.down('sm')]: {
      width: '90%',
      margin: 0
    }
  },
  noComments: {
    width: '100%',
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: `url(${NoComments})`
  },
  moreButton: {
    marginRight: '-28px',
    cursor: 'pointer'
  },
  actionButtonsActive: {
    backgroundColor: '#F4F7FD',
    color: palette.primary.main,
    border: `2px solid ${palette.primary.main}`,
    '&:hover': {
      backgroundColor: '#DAE1EF'
    }
  },
  actionButtonsInactive: {
    backgroundColor: palette.primary.main,
    border: `2px solid ${palette.primary.main}`,
    color: 'white',
    '&:hover': {
      backgroundColor: `${palette.primary.main}de`
    }
  },
  iconDiv: {
    display: 'block',
    marginRight: '10px',
    width: '20px',
    paddingLeft: '10px',
    paddingTop: '4px'
  },
  commentsContainer: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    height: '250px',
    overflowY: 'auto',
    overflowX: 'clip',
    marginRight: '-20px',
    paddingBottom: '30px'
  },
  CommentElementTextContainer: {
    textAlign: 'left',
    wordWrap: 'break-word',
    overflow: 'hidden',
    width: '308px',
    [breakpoints.down('sm')]: {
      width: '235px'
    }
  },
  actionButtonsRight: {
    width: '90px',
    marginLeft: '10px',
    background: '#f4f7fd',
    border: '2px solid #f4f7fd',
    '&:hover': {
      backgroundColor: '#f5f5f5'
    }
  },
  stickersContainer: {
    alignItems: 'center',
    marginBottom: '20px',
    width: '390px',
    [breakpoints.down('xs')]: {
      width: '300px'
    }
  },
  stickerButton: {
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    padding: '5px 10px',
    borderRadius: 5,
    cursor: 'pointer',
    marginRight: '10px',
    color: 'white',
    whiteSpace: 'nowrap',
    [breakpoints.down('lg')]: {
      marginBottom: '10px'
    }
  },
  onHover: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))

export default PostDetails
