import axios from 'axios'

/**
 * @function gatheringKick Used to kick a user out of gathering
 * @param {string} gatheringId - id of the gathering
 * @param {string} uid - id of the user to kick
 * @param {string} nrctUID - user's current neighborhood id
 * @param {string} jwt - user's jwt token
 * @returns {Promise} Promise object represents
 */
export const gatheringKick = (gatheringId, uid, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/gatherings/kick`, {
      params: {
        gathering: gatheringId,
        user: uid
      },
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then(() => resolve(true))
      .catch((err) => {
        reject(err)
      })
  })
}
