import { Box, Button, Divider, FormControl, Grid, Modal, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Add } from '@mui/icons-material'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSnackbar } from 'notistack'

// importing components
import { UserContext } from '../../../context/UserContext'

// importing ui
import PriceFieldsInput from 'ui/TextInputs/PriceFieldsInput'
import TextAreaInput from 'ui/TextInputs/TextAreaInput'
import { TextLabel } from 'ui/TextInputs/TextInput'
import ImagePicker from 'ui/Custom/ImagePicker/ImagePicker'
import CropImageModal from 'ui/Custom/Modals/CropImageModal'

// importing APIs
import { fetchMyProfile } from 'api/fetch/my'
import { groupMy } from 'api/group/my'
import { gatheringsCreate } from 'api/gatherings/create'

// importing helpers
import { recordGaEvent } from 'helpers/ga'

const styles = makeStyles({
  contentContainer: {
    padding: 0,
    marginTop: '0',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width: 960px)']: {
      marginTop: '16px',
      padding: 16
    }
  },
  heading: {
    fontSize: '2rem',
    fontWeight: '500',
    color: '#104F3D'
  },
  gatheringTypeCard: {
    textAlign: 'center',
    height: '125px',
    width: '125px',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F2F2F2',
    flexDirection: 'column',
    padding: '5px',
    '&:hover': {
      backgroundColor: '#E2E2E2'
    }
  },
  gatheringTypeCardActive: {
    textAlign: 'center',
    height: '119px',
    width: '119px',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F2F2F2',
    flexDirection: 'column',
    borderColor: '#7CB342',
    border: '2px solid',
    padding: '5px',
    '&:hover': {
      backgroundColor: '#E2E2E2'
    }
  },
  groupModal: {
    padding: '20px',
    width: '300px',
    borderRadius: '8px',
    paddingInline: '10px',
    backgroundColor: '#E2E2E2',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)'
  },
  groupModalItem: {
    backgroundColor: '#F2F2F2',
    '&:hover': {
      backgroundColor: '#E2E2E2'
    }
  },
  groupModalItemActive: {
    borderColor: '#7CB342',
    border: '2px solid',
    backgroundColor: '#F2F2F2',
    '&:hover': {
      backgroundColor: '#E2E2E2'
    }
  },
  locationText: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    height: '1.5rem',
    width: '80px'
  }
})

function CreateGathering (props) {
  // const classes = styles();
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [cropModalImage, setCropModalImage] = useState(null)
  const [coverImage, setCoverImage] = useState()
  const [gatheringType, setGatheringType] = useState(1)
  const [loadingLocation, setLoadingLocation] = useState(true)
  const [locality, setLocality] = useState('')
  const [selectedGroups, setSelectedGroups] = useState([])
  const [groups, setGroups] = useState([])
  const [showGroupSelect, setShowGroupSelect] = useState(false)
  const [isCreatingGathering, setIsCreatingGathering] = useState(false)
  const [miles, setMiles] = useState(15)
  const { personal, jwt_token, active_neighbourhood } = useContext(UserContext)
  const classes = styles()
  const navigate = useNavigate()
  const location = useLocation()

  const { enqueueSnackbar } = useSnackbar()

  const createAPI = () => {
    if (name === '') {
      return enqueueSnackbar('Please enter a Gathering Name!', {
        variant: 'error'
      })
    }

    if (gatheringType === -1) {
      return enqueueSnackbar('Invalid Gathering Type!', {
        variant: 'error'
      })
    };

    let start = Date.now()
    const end = new Date(start + 3 * 60 * 60 * 1000).toISOString()
    start = new Date(start).toISOString()

    let gatheringGroups
    let finalGatheringType

    if (props.targetGroup) {
      gatheringGroups = [props.targetGroup.id]
      finalGatheringType = 1
    } else {
      gatheringGroups = groups
        .map((group, index) =>
          selectedGroups.includes(index) ? group.id : null
        )
        .filter((n) => n)

      finalGatheringType = gatheringType
    }

    if (isCreatingGathering) return
    setIsCreatingGathering(true)

    gatheringsCreate(
      finalGatheringType,
      gatheringGroups,
      miles,
      name,
      description,
      end,
      start,
      end,
      coverImage,
      personal[active_neighbourhood].ID,
      jwt_token
    )
      .then((dataGathering) => {
        navigate(`/gathering/${dataGathering.ID}`)

        // analytics
        recordGaEvent('gatheringsPage-createGathering-created', {
          personalAccountId: personal[active_neighbourhood].ID,
          gatheringName: name
        })
      })
      .catch((err) => {
        setIsCreatingGathering(false)
        enqueueSnackbar(err.message, {
          variant: 'error'
        })
      })
  }

  useEffect(() => {
    if (!jwt_token) {
      navigate({
        pathname: '/login',
        state: { pathname: location.pathname }
      })
    }
    Promise.all([
      fetchMyProfile(personal[active_neighbourhood].ID, jwt_token),
      groupMy(personal[active_neighbourhood].ID, jwt_token)
    ])
      .then(([dataBody, dataGroups]) => {
        setLocality(dataBody.name)
        setGroups(dataGroups)
        setLoadingLocation(false)
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: 'error'
        })
      })
  }, [active_neighbourhood, enqueueSnackbar, jwt_token, location.pathname, navigate, personal])

  useEffect(() => {
    // console.log(selectedGroups);
  }, [selectedGroups])

  return (
    <>
      <Grid
        item
        container
        md={props.isModal ? 12 : 8}
        xs={props.isModal ? 12 : 12}
        lg={props.isModal ? 12 : 6}
        className={classes.contentContainer}
      >
        <Grid item xs={12} md={12}>
          <div className={classes.heading}>Create a Gathering</div>
          {props.targetGroup && (
            <Typography
              style={{ fontSize: '0.8rem', marginLeft: 3, marginBottom: 5 }}
              color="primary"
            >
              This gathering is being created for group{' '}
              <span style={{ fontWeight: 600 }}>
                {props.targetGroup.group_name}
              </span>
            </Typography>
          )}
          <FormControl fullWidth margin="normal">
            <PriceFieldsInput
              label="What is the name of your gathering"
              placeholder="Gathering name"
              value={name}
              inputProps={{ maxLength: '63' }}
              onChange={(e) => {
                setName(e.target.value)
              }}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextAreaInput
              rows={4}
              label="Describe your gathering"
              placeholder="Enter your gathering description here ..."
              value={description}
              inputProps={{ maxLength: '255' }}
              onChange={(e) => {
                setDescription(e.target.value)
              }}
            />
            <span style={{ fontSize: '10px', textAlign: 'right' }}>
              {255 - description.length} characters left
            </span>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <Grid>
              <TextLabel>Upload a cover image for your gathering</TextLabel>
              <div style={{ marginTop: '8px' }}>
                <ImagePicker
                  onChange={(e) => setCropModalImage(e.target.files[0])}
                >
                  <div
                    style={{
                      borderRadius: '12px',
                      display: 'flex',
                      flexDirection: 'column',
                      padding: '10px',
                      backgroundColor: '#F2F2F2',
                      height: '90px',
                      width: '160px',
                      cursor: 'pointer'
                    }}
                  >
                    {coverImage
                      ? (
                      <div
                        className={classes.uploadedImage}
                        style={{
                          height: '100%',
                          width: '100%',
                          marginTop: 'auto',
                          marginBottom: 'auto',
                          alignSelf: 'center',
                          justifySelf: 'center',
                          textAlign: 'center',
                          display: 'flex'
                        }}
                      >
                        <img
                          style={{
                            maxHeight: '100%',
                            maxWidth: '100%'
                          }}
                          src={URL.createObjectURL(coverImage)}
                          alt="groupCover"
                        />
                      </div>
                        )
                      : (
                      <div
                        style={{
                          height: '30px',
                          width: '30px',
                          marginTop: 'auto',
                          marginBottom: 'auto',
                          alignSelf: 'center',
                          justifySelf: 'center',
                          borderRadius: '56px',
                          backgroundColor: '#BDBDBD',
                          textAlign: 'center',
                          display: 'flex'
                        }}
                      >
                        <Add
                          htmlColor="#FFFFFF"
                          style={{ fontSize: 28, alignSelf: 'center' }}
                        />
                      </div>
                        )}
                  </div>
                </ImagePicker>
              </div>
            </Grid>
          </FormControl>
          {/*
          <FormControl fullWidth margin="normal">
            <TextLabel style={{ paddingLeft: "4px", marginBottom: 16 }}>
              To whom will the gathering be visible?
            </TextLabel>
            <Grid container item xs={12} spacing={1} style={{ maxWidth: 600 }}>
              <Grid item xs={6} md={3}>
                <GatheringTypeCard
                  image={localityIcon}
                  index={4}
                  classes={classes}
                  selected={gatheringType}
                  setSelected={setGatheringType}
                >
                  <Typography className={classes.locationText}>
                    Public
                  </Typography>
                </GatheringTypeCard>
              </Grid>
              <Grid item xs={6} md={3}>
                <GatheringTypeCard
                  image={localityIcon}
                  index={1}
                  classes={classes}
                  selected={gatheringType}
                  setSelected={setGatheringType}
                >
                  <Typography className={classes.locationText}>
                    {loadingLocation
                      ? "Fetching user location..."
                      : `${locality}`}
                  </Typography>
                  <Typography style={{ color: "blue" }}>
                    {(miles === 30 ? "30+" : miles) + " Miles"}
                  </Typography>
                </GatheringTypeCard>
              </Grid>
              <Grid item xs={6} md={3}>
                <GatheringTypeCard
                  image={neighbourhoodIcon}
                  index={2}
                  classes={classes}
                  selected={gatheringType}
                  setSelected={setGatheringType}
                >
                  <Typography>
                    {loadingLocation
                      ? "Fetching user location..."
                      : `${locality}`}
                  </Typography>
                </GatheringTypeCard>
              </Grid>
              <Grid item xs={6} md={3}>
                <GatheringTypeCard
                  image={groupMembersIcon}
                  index={3}
                  classes={classes}
                  selected={gatheringType}
                  setSelected={(i) => {
                    setShowGroupSelect(true);
                    setGatheringType(i);
                  }}
                >
                  {selectedGroups[0]
                    ? groups
                        .filter((group, groupIndex) =>
                          selectedGroups.includes(groupIndex)
                        )
                        .map((e, i) => e.group_name)
                        .toString()
                    : "Select groups"}
                </GatheringTypeCard>
              </Grid>
            </Grid>
          </FormControl>
          {gatheringType === 1 && (
            <FormControl fullWidth margin="normal">
              <Grid container>
                <TextLabel style={{ paddingLeft: "4px", marginBottom: 16 }}>
                  Select range (in miles):
                </TextLabel>
              </Grid>
              <Grid container item md={6} xs={12} spacing={2}>
                <Grid item>0</Grid>
                <Grid item xs>
                  <Slider
                    step={1}
                    min={0}
                    max={30}
                    defaultValue={15}
                    value={miles}
                    onChange={(_, newMiles) => setMiles(newMiles)}
                  />
                </Grid>
                <Grid item>30+</Grid>
              </Grid>
            </FormControl>
          )}
        */}
        </Grid>
        <Divider style={{ width: '100%', marginTop: '32px' }} />
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            width: '100%',
            marginTop: '10px'
          }}
        >
          <Button
            variant="contained"
            disableElevation
            color="ghost"
            style={{ marginRight: '10px' }}
            onClick={() => {
              if (props.isModal) {
                props.closeModal()
              } else {
                props.setActiveTab(0)
              }
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disableElevation
            color="primary"
            onClick={createAPI}
            disabled={isCreatingGathering}
          >
            {isCreatingGathering ? 'Creating' : 'Create'}
          </Button>
        </Box>
      </Grid>

      <Modal
        open={showGroupSelect}
        onClose={() => {
          setShowGroupSelect(false)
        }}
      >
        <Box className={classes.groupModal}>
          <Typography className={classes.heading}>My Groups</Typography>
          {groups.length > 0
            ? (
                groups.map((group, index) => (
              <Box
                px={2}
                py={2}
                my={1}
                className={
                  selectedGroups.includes(index)
                    ? classes.groupModalItemActive
                    : classes.groupModalItem
                }
                key={group.group_name + index}
                onClick={() => {
                  if (!selectedGroups.includes(index)) {
                    setSelectedGroups((prevSelectedGroups) => [
                      ...prevSelectedGroups,
                      index
                    ])
                  } else {
                    setSelectedGroups((prevSelectedGroups) =>
                      prevSelectedGroups.splice(
                        prevSelectedGroups.indexOf(index),
                        1
                      )
                    )
                  }
                }}
              >
                {group.group_name}
              </Box>
                ))
              )
            : (
            <Box px={2} py={2} my={1} className={classes.groupModalItem}>
              You have no groups
            </Box>
              )}
          <Button
            variant="contained"
            disableElevation
            color="secondary"
            onClick={() => setShowGroupSelect(false)}
          >
            Confirm
          </Button>
        </Box>
      </Modal>
      <CropImageModal
        open={Boolean(cropModalImage)}
        onClose={() => setCropModalImage(null)}
        aspect={ 16/9 }
        onCropComplete={(blob) => {
          let fileType = ''
          if (cropModalImage) {
            fileType = cropModalImage.type
          }
          const file = new File([blob], `nearcast-gathering-${Date.now()}`, { type: fileType })
          setCoverImage(file)
          setCropModalImage(null)
        }}
        imageFile={cropModalImage}
      />
    </>
  )
}

export default CreateGathering
