import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import { createLogger } from 'redux-logger'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import thunk from 'redux-thunk'

import rootReducer from './reducers/index'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['root'],
  blacklist: []
} // Middleware: Redux Persist Persisted Reducer

const persistedReducer = persistReducer(persistConfig, rootReducer) // Redux: Store
let store
if (process.env.REACT_APP_DEPLOYMENT === 'dev') {
  store = createStore(
    persistedReducer,
    applyMiddleware(createLogger(), thunk)
  ) // Middleware: Redux Persist Persister
} else {
  store = createStore(
    persistedReducer,
    applyMiddleware(thunk)
  ) // Middleware: Redux Persist Persister
}

const persister = persistStore(store)
export { store, persister }

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
