import React, { useState, useEffect, useContext, memo } from 'react'
import StandardModal from 'ui/Modals/StandardModal'
import {
  CircularProgress,
  Divider,
  FormControl,
  Menu,
  MenuItem,
  Typography
} from '@mui/material'
import { UserContext } from '../../../context/UserContext'
import { neighborhoodDirGet } from 'api/personal_account/neighbourhood_directory'
import { useSnackbar } from 'notistack'
import TextInput from 'ui/TextInputs/TextInput'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import NhProfileCard from './NhProfileCard'
import { FixedSizeList, areEqual } from 'react-window'

const NhDirectory = ({ isOpen, onClose }) => {
  const { personal, jwt_token, active_neighbourhood, userProfiles } =
        useContext(UserContext)

  const [currentNh, setCurrNh] = useState(active_neighbourhood)
  const [searchText, setSearchText] = useState('')

  const [data, setData] = useState([])
  const [listData, setListData] = useState([])
  const [loadingData, setLoadingData] = useState(true)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const { enqueueSnackbar } = useSnackbar()

  const changeFollowState = (user_id) => {
    const newArr = [...data]
    const idx = newArr.findIndex((item) => item.user_id === user_id)
    newArr[idx].is_following = !newArr[idx].is_following
    setData(newArr)
  }

  const rowData = {
    listData,
    changeFollowState
  }

  const getData = () => {
    setLoadingData(true)
    neighborhoodDirGet(personal[currentNh].ID, jwt_token)
      .then((res) => {
        const resData = (res.body ?? []).filter(
          (item) => item.user_id !== personal[active_neighbourhood].ID
        )
        setData(resData)
        setListData(resData)
        setLoadingData(false)
      })
      .catch(() => {
        enqueueSnackbar('Could not fetch the neighborhood directory', {
          variant: 'error'
        })
        setLoadingData(false)
      })
  }

  useEffect(() => {
    getData()
  }, [currentNh])

  const filterData = () => {
    const newData = [...data]
    const result = newData.filter((item) =>
      JSON.stringify(item)
        .toLowerCase()
        .includes(searchText.toLowerCase())
    )
    setListData(result)
  }

  useEffect(() => {
    filterData()
  }, [data, searchText])

  return (
        <StandardModal
            isOpen={isOpen}
            onClose={onClose}
            title="Neighborhood Directory"
        >
            <Divider />
            <div style={{ padding: '0 10px' }}>
                <div style={{ marginBottom: '20px' }}>
                    <FormControl
                        fullWidth
                        style={{ margin: '10px 0' }}
                        margin="none"
                    >
                        <TextInput
                            placeholder="Search"
                            row={1}
                            color="#8aa6de"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                    </FormControl>
                    <div
                        style={{
                          display: 'flex',
                          gap: '10px',
                          alignItems: 'center',
                          cursor: 'pointer'
                        }}
                        onClick={(e) => setAnchorEl(e.currentTarget)}
                    >
                        <LocationOnIcon sx={{ color: '#333' }} />
                        <span style={{ color: '#007aff' }}>
                            {userProfiles[currentNh].name}
                        </span>
                        <KeyboardArrowDownIcon sx={{ color: '#007aff' }} />
                    </div>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={() => setAnchorEl(null)}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button'
                        }}
                    >
                        {personal.map((profile, i) => {
                          return (
                                <MenuItem
                                    key={profile.ID}
                                    onClick={() => {
                                      setCurrNh(i)
                                      setAnchorEl(null)
                                    }}
                                >
                                    {`${
                                        profile?.neighbourhood?.neighbourhood
                                    } ${
                                        profile.is_business ? '(business)' : ''
                                    }`}
                                </MenuItem>
                          )
                        })}
                    </Menu>
                </div>
                <div>
                    {loadingData
                      ? (
                        <div
                            style={{
                              width: '100%',
                              textAlign: 'center',
                              padding: '10px 0'
                            }}
                        >
                            <CircularProgress />
                        </div>
                        )
                      : listData.length === 0
                        ? (
                        <div
                            style={{
                              width: '100%',
                              textAlign: 'center',
                              padding: '10px 0 20px 0'
                            }}
                        >
                            <Typography variant="h2" color="gray">
                                No Accounts found
                            </Typography>
                        </div>
                          )
                        : (
                        <FixedSizeList
                            itemCount={listData.length}
                            height={500}
                            itemSize={60}
                            itemData={rowData}
                            className="nh-dir-list"
                        >
                            {Row}
                        </FixedSizeList>
                          )}
                </div>
            </div>
        </StandardModal>
  )
}

export default NhDirectory

/**
 * This component is memoized to prevent unecessary re-renders.
 */

// eslint-disable-next-line react/display-name
const Row = memo(({ data, index, style }) => {
  const { listData, changeFollowState } = data

  return (
        <NhProfileCard
            person={listData[index]}
            style={style}
            i={index}
            changeFollowState={changeFollowState}
        />
  )
}, areEqual)
