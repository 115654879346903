import { Avatar, Typography, Button } from '@mui/material'
import React from 'react'
import StandardModal from 'ui/Modals/StandardModal'

// importing heplers
import { getUserDomainPreviewUrl } from 'helpers/url'

function GroupMembers({ open, onClose, groupData, groupMembers }) {
	return (
		<StandardModal
			isOpen={open}
			onClose={onClose}
			title="Group Members"
		>
			<div style={{ width: '100%' }}>
				<Typography
					variant="h5"
					style={{ fontSize: '0.9rem', marginTop: 15 }}
				>
					{groupData.group_info.people_count > 1 ? <>The more the merrier, <a href="https://app.nearcast.com/download/uuqs">invite your friends</a> to join {groupData.group_info.group_name} 👥</> : <>Looks like you're the only one here 😢 <a href="https://app.nearcast.com/download/uuqs">Invite a friend?</a> </>}
				</Typography>
			</div>
			<div style={{ width: '95%' }}>
				{groupMembers.length === 0
					? (
						<div style={{ width: '100%', textAlign: 'center' }}>
							Looks like no one has joined your group yet 😔
						</div>
					)
					: (
						groupMembers.map((member, i) => (
							<div
								key={`groupMember${i}`}
								style={{
									display: 'flex',
									alignItems: 'center',
									marginTop: 15,
									marginBottom: 15
								}}
							>
								<Button
									onClick={() => {
										window.open(getUserDomainPreviewUrl(`/u/${member.id}`))
									}}
								>
									<Avatar
										style={{ width: 40, height: 40 }}
										src={member.profile_pic}
									/>
									<div style={{ marginLeft: 10 }}>
										<div style={{ fontWeight: 500 }}>
											{member.username}
										</div>
									</div>
								</Button>
							</div>
						))
					)}
			</div>
		</StandardModal>
	)
}

export default GroupMembers
