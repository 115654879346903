import axios from 'axios'

/**
 * @function userOtpVerify Used to verify the otp received by the user
 * @param {string} mobile - mobile number of the user
 * @param {string} otpInput - the opt entered by the user
 * @param {string} nrctUID - personal[active_neighbourhood].ID from user context
 * @param {string} jwt - jwt token from user context
 * @returns {Promise} Promise object represents true for successful verification
 */
export const userOtpVerify = (mobile, otpInput, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/update/phone`, {
      phone: mobile,
      otp: otpInput
    }, {
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then(() => {
        resolve(true)
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data.msg)
        } else {
          reject('Error verifying OTP, please try again')
        }
      })
  })
}
