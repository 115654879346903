import axios from 'axios'

/**
 * @function emailUnsubscribeCategory Unsubscribe to further emails from Nearcast
 * @param {string} email - email id
 * @param {string} body - user's current neighborhood id
 * @returns {Promise} Promise object represents
 */
export const emailUnsubscribeCategory = (email, body) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/email/unsubscribe/category?email=${email}`, body)
      .then((res) => resolve())
      .catch((err) => reject(err))
  })
}
