import axios from 'axios'

/**
 * @function featureRequestPost Used to create a new feature request
 * @param {string} title - title of the feature request
 * @param {string} description - description of the feature request
 * @param {string} nrctUID - personal[active_neighbourhood].ID from user context
 * @param {string} jwt - jwt token from user context
 * @returns {Promise} Promise object
 */
export const featureRequestPost = (title, description, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/feature/request`,
        {
          title,
          description
        },
        {
          headers: {
            'NRCT-UID': nrctUID,
            Authorization: `Bearer ${jwt}`
          }
        }
      )
      .then((res) => resolve(res.data.body))
      .catch((err) => {
        reject(err)
      })
  })
}

/**
 * @function getAllFeatureReqs Used to get all requests of a user
 * @param {string} nrctUID - personal[active_neighbourhood].ID from user context
 * @param {string} jwt - jwt token from user context
 * @returns {Promise} Promise object
 */
export const getAllFeatureReqs = (nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/feature/my/requests`, {
        headers: {
          'NRCT-UID': nrctUID,
          Authorization: `Bearer ${jwt}`
        }
      })
      .then((res) => resolve(res.data.body))
      .catch((err) => {
        reject(err)
      })
  })
}
