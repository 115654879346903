import axios from 'axios'

/**
 * @function notificationsFetch Used to fetch user's notifications
 * @param {number} page - the page of notifications to retrive
 * @param {string} nrctUID - personal[active_neighborhood].ID from user context
 * @param {string} jwt - jwt from user context
 * @returns {Promise} Promise object represents array of notification objects
 */
export const notificationsFetch = (page = 0, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/notifications/fetch?page=${page}`, {
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(res.data.body))
      .catch((err) => {
        reject(err)
      })
  })
}

/**
 * @function notificationsRead Should be called when the user has seen new notifications
 * @param {string} nrctUID - personal[active_neighborhood].ID from user context
 * @param {string} jwt - jwt from user context
 * @returns {Promise} Promise object represents true for success
 */
export const notificationsRead = (nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/notifications/read`, {
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then(() => resolve(true))
      .catch((err) => {
        reject(err)
      })
  })
}
