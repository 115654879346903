import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Typography,
  CardMedia
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { People, ArrowForwardIos } from '@mui/icons-material'
import React, { useState, useContext, useEffect } from 'react'
import CustomTextArea from 'ui/TextInputs/TextAreaInput'
import { UserContext } from '../../context/UserContext'
import { useSnackbar } from 'notistack'
import axios from 'axios'
import GroupMembers from './GroupMembers'
import { groupCover, groupCoverS3Url } from 'api/group/cover'
import CropImageModal from 'ui/Custom/Modals/CropImageModal'
import StandardModal from 'ui/Modals/StandardModal'

function ManageGroup ({ open, onClose, groupData, setGroupData, members }) {
  const classes = styles()
  const [defaultImage, setDefaultImage] = useState(groupCover(groupData.group_info.id))
  const [image, setImage] = useState(null)
  const [openCropImage, setOpenCropImage] = useState(false)
  const [name, setName] = useState(groupData.group_info.group_name)
  const [desc, setDesc] = useState(groupData.group_info.group_description)
  const initialInputErrorState = {
    name: null,
    desc: null
  }
  const [inputError, setInputError] = useState(initialInputErrorState)

  const [btnLoading, setBtnLoading] = useState(false)
  const [membersOpen, setMembersOpen] = useState(false)

  const { personal, active_neighbourhood, jwt_token } =
        useContext(UserContext)

  const { enqueueSnackbar } = useSnackbar()

  const validate = () => {
    const newInputError = initialInputErrorState
    if (name.trim().length === 0) {
      newInputError.name = "Name can't be empty!"
    };
    if (desc.trim().length === 0) {
      newInputError.desc = "Description can't be empty!"
    };

    setInputError(newInputError)
    if (Object.values(newInputError).find((e) => e !== null)) {
      enqueueSnackbar('Please fill all the fields!', {
        variant: 'error'
      })
      return false
    } else {
      return true
    }
  }

  const updateGroupInfo = async () => {
    if (!validate()) {
      return
    }

    setBtnLoading(true)

    if (image !== null) {
      try {
        await groupCoverS3Url(groupData.group_info.id, image, personal[active_neighbourhood].ID, jwt_token)
        enqueueSnackbar('Group Cover Updated', {
          variant: 'success'
        })
      } catch (err) {
        enqueueSnackbar(err.message, {
          variant: 'error'
        })
      }
    }

    // check if name and desc have changed
    if (name === groupData.group_info.group_name && desc === groupData.group_info.group_description) {
      // none changed
      setBtnLoading(false)
      onClose()
      return enqueueSnackbar('Group info updated successfully!', {
        variant: 'success',
        autoHideDuration: 2000
      })
    }

    const url = `${process.env.REACT_APP_BACKEND_URL}/group/id/${groupData.group_info.id}`
    const data = {
      name,
      description: desc,
      id: groupData.group_info.id
    }

    try {
      const res = await axios.put(url, data, {
        headers: {
          'NRCT-UID': personal[active_neighbourhood].ID,
          Authorization: `Bearer ${jwt_token}`
        }
      })

      if (res.data.status === 200) {
        if (setGroupData) {
          const newData = { ...groupData }
          newData.group_info.group_name = name
          newData.group_info.group_description = desc

          setGroupData(newData)
        }

        enqueueSnackbar('Group info updated successfully!', {
          variant: 'success',
          autoHideDuration: 2000
        })

        onClose()
      } else {
        enqueueSnackbar(
          'There was some error updating the group info.',
          { variant: 'error', autoHideDuration: 5000 }
        )
      }
    } catch (error) {
      enqueueSnackbar('There was some error updating the group info.', {
        variant: 'error',
        autoHideDuration: 5000
      })
    }

    setBtnLoading(false)
  }

  useEffect(() => {
    setDefaultImage(groupCover(groupData.group_info.id))
  }, [groupData.group_info.id])

  return (
        <StandardModal
            isOpen={open}
            onClose={onClose}
            title="Manage Group"
        >
            <div style={{ width: '100%' }}>
                <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="contained-file"
                    type="file"
                    onClick={(e) => { e.target.value = null }} // onChange is not called if same file is selected, this makes sure that onChange is always called
                    onChange={(e) => {
                      if (e.target.files[0].size > 1000000) {
                        enqueueSnackbar('File too large. Max file size: 1 MB', {
                          variant: 'error'
                        })
                      } else {
                        setImage(e.target.files[0])
                        setOpenCropImage(true)
                      }
                    }}
                />
                 <label htmlFor="contained-file" style={{ textAlign: 'center' }}>
                    <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center'
                        }}
                    >
                        <CardMedia
                            style={{
                              width: '100%',
                              height: '200px',
                              cursor: 'pointer',
                              borderRadius: 8
                            }}
                            image={image ? URL.createObjectURL(image) : defaultImage}
                            title="Group Cover"
                        />
                        <Typography style={{ color: '#5b87ff', cursor: 'pointer' }}>
                            Edit Group Cover
                        </Typography>
                    </div>
                </label>
                <FormControl fullWidth margin="none" style={{ marginTop: 10 }}>
                    <CustomTextArea
                        rows={1}
                        label="Group Name"
                        placeholder="Enter group name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        error={Boolean(inputError.name)}
                    />
                </FormControl>
                <FormControl fullWidth margin="none" style={{ marginTop: 5 }}>
                    <CustomTextArea
                        rows={3}
                        label="Group Description"
                        placeholder="Enter group description"
                        value={desc}
                        onChange={(e) => setDesc(e.target.value)}
                        error={Boolean(inputError.desc)}
                    />
                </FormControl>
                <Button
                    variant="text"
                    style={{ width: '100%', marginTop: 10 }}
                    className={classes.memberBtn}
                    onClick={() => setMembersOpen(true)}
                >
                    <People style={{ marginRight: 20 }} />
                    Members
                    <ArrowForwardIos
                        style={{ marginLeft: 'auto', fontSize: '1rem' }}
                    />
                </Button>
            </div>
            <Divider style={{ width: '100%', margin: '20px 0' }} />
            <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    style={{ fontWeight: 600, width: 150 }}
                    onClick={updateGroupInfo}
                    disabled={btnLoading}
                >
                    {btnLoading
                      ? (
                        <CircularProgress
                            size={18}
                            style={{
                              verticalAlign: 'middle',
                              color: '#3A8F78'
                            }}
                        />
                        )
                      : (
                        <>Update</>
                        )}
                </Button>
            </div>
            <CropImageModal
                open={openCropImage}
                onClose={() => setOpenCropImage(false)}
                aspect={ 16 / 9 }
                onCropComplete={(blob) => {
                  const file = new File([blob], `nearcast-group-${Date.now()}`)
                  setImage(file)
                }}
                imageFile={image}
            />
            <GroupMembers
                open={membersOpen}
                onClose={() => setMembersOpen(false)}
                groupMembers={members}
                groupData={groupData}
            />
        </StandardModal>
  )
}

export default ManageGroup

const styles = makeStyles(() => ({
  memberBtn: {
    '& .MuiButton-label': {
      padding: '0 10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',

      fontWeight: 600,
      fontSize: '1rem'
    }
  }
}))
