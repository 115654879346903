import axios from 'axios'

export const businessNearby = (neighborhoodId, page = 1, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BUSINESS_URL}/business/nearby`,
      {
        params: {
          neighbourhood: neighborhoodId,
          page: page
        },
        headers: {
          'NRCT-UID': nrctUID,
          Authorization: `Bearer ${jwt}`
        }
      }
    )
      .then((res) => {
        if (res.data.body === null) {
          resolve([])
        } else {
          resolve(res.data.body)
        }
      })
      .catch((err) => reject(err))
  })
}

export const offersNearby = (neighborhoodId, page, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BUSINESS_URL}/offers/nearby`,
      {
        params: {
          neighbourhood: neighborhoodId,
          page: page
        },
        headers: {
          'NRCT-UID': nrctUID,
          Authorization: `Bearer ${jwt}`
        }
      }
    )
      .then((res) => {
        if (res.data.body === null) {
          resolve([])
        } else {
          resolve(res.data.body)
        }
      })
      .catch((err) => reject(err))
  })
}
