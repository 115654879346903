import axios from 'axios'

/**
 * @function postBugsReport Used to create a new bug report
 * @param {string} title - title of the feature request
 * @param {string} description - description of the feature request
 * @param {string} nrctUID - personal[active_neighbourhood].ID from user context
 * @param {string} jwt - jwt token from user context
 * @returns {Promise} Promise object representing object with bug id
 */
export const postBugsReport = (title, description, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/bugs/report`, {
      title,
      description
    },
    {
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(res.data.body))
      .catch((err) => {
        reject(err)
      })
  })
}

/**
 * @function getBugsReports Used to get all reports from a user
 * @param {number} page - paginated reports list to retrieve
 * @param {string} nrctUID - personal[active_neighbourhood].ID from user context
 * @param {string} jwt - jwt token from user context
 * @returns {Promise} Promise object
 */
export const getBugsReports = (page = 1, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/bugs/my/reports`, {
      params: {
        page
      },
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(res.data.body))
      .catch((err) => {
        reject(err)
      })
  })
}
