import React from 'react'
import Features from '../../components/Landing/Features/Features'
import FooterNew from '../../components/Landing/FooterNew/Footer'
import LandingHeader from '../../components/Landing/LandingHeader/LandingHeader'
import SubpagesHead from '../../components/Landing/SubpagesHead/SubpagesHead'
import features from './FeatureLists/BusinessList'
import BusinessBg from './HeadBackgrounds/business-bg.png'
import BusinessBanner from './Banners/business.png'

function LandingBusiness () {
  const featureBanners = [BusinessBanner]

  const description =
		'Create in-store offers for walk-in customers.   Enable your customers to promote you through short-videos sharing their experience.  And, build relationships with your customers through livestreams.'

  const onActionClick = () => window.open('https://business.nearcast.com/')

  return (
		<>
			<LandingHeader light />
			<SubpagesHead
				title="Nearcast for Businesses"
				description={description}
				bg={BusinessBg}
				actionText="Claim your Business Page"
				onActionClick={onActionClick}
			/>
			<Features
				title="Businesses use Nearcast to"
				features={features}
				featureBanners={featureBanners}
				scrollEnabled={false}
				onActionClick={onActionClick}
			/>
			<FooterNew />
		</>
  )
}

export default LandingBusiness
