import { Button, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import Error404Icon from '../../assets/error404.svg'

const useStyles = makeStyles((theme) => ({
  centerMiddle: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

const ErrorPage = () => {
  const classes = useStyles()
  return (
        <div className={
            classes.centerMiddle
        }>
            <img src={Error404Icon}
                alt="404"
                width="280px" />
            <Typography variant="h5" style={{ fontWeight: 600 }}>
                404 Page not found
            </Typography>
            <Button variant="contained" color="primary" href="/" style={{ marginTop: '40px' }}>
                Go to Homepage
            </Button>
        </div>
  )
}

export default ErrorPage
