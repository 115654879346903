import React, { useState, useContext, useEffect } from 'react'
import ReactPlayer from 'react-player'
import { Grid, Modal, Card, CardContent, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import PostDetails from './PostDetails'
import {
  Close,
  ChevronLeftRounded,
  ChevronRightRounded,
  VolumeOff
} from '@mui/icons-material'
import { UserContext } from '../../context/UserContext'
import { onKeypressAction } from '../../utils/keypress'
import { NIL as uuidNil } from 'uuid'

// importing apis
import { feedData } from 'api/analytics/feed'
import { postView } from 'api/post/view'

function ConditionalModal ({
  open,
  onClose,
  handleCycle,
  children
}) {
  const classes = useStyles()

  React.useEffect(() => {
    const onEventAction = (e, num) => {
      if (e.target.type === 'text') {
        return // the comment box is in focus
      }
      handleCycle(num)
    }
    const cleanPostSwitchLeft = onKeypressAction(37, (e) => onEventAction(e, -1))
    const cleanPostSwitchRight = onKeypressAction(39, (e) => onEventAction(e, 1))
    return () => {
      cleanPostSwitchLeft()
      cleanPostSwitchRight()
    }
  }, [handleCycle])

  if (onClose) {
    return (
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <>
          <div className={classes.controls}>
            <div className={classes.leftControl}>
              <IconButton
                style={{
                  width: '50px',
                  height: '50px',
                  backgroundColor: 'white'
                }}
                onClick={() => {
                  handleCycle(-1)
                }}
                size="large">
                <ChevronLeftRounded fontSize="large" />
              </IconButton>
            </div>
            <div className={classes.rightControl}>
              <IconButton
                style={{
                  width: '50px',
                  height: '50px',
                  backgroundColor: 'white'
                }}
                onClick={() => {
                  handleCycle(1)
                }}
                size="large">
                <ChevronRightRounded fontSize="large" />
              </IconButton>
            </div>
          </div>
          <Card className={classes.modalCard}>
            <CardContent style={{
              height: '100%',
              width: '100%',
              overflow: 'auto',
              padding: 0,
              paddingRight: 20
            }}>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  marginBottom: '10px'
                }}
              >
                <IconButton
                  style={{
                    backgroundColor: '#bdbdbd',
                    width: '16px',
                    height: '16px',
                    marginLeft: 'auto'
                  }}
                  onClick={onClose}
                  size="large">
                  <Close htmlColor="white" fontSize="small" />
                </IconButton>
              </div>
              {children}
            </CardContent>
          </Card>
        </>
      </Modal>
    )
  } else {
    return (
      <div className={classes.nonModalContainer}>
        {children}
      </div>
    )
  }
}

// localRefresher is used to update posts in profile screen, where the users posts aren't coming from redux
// but are loaded in component state
function PostView ({ index, post, open, onClose, isLoad, handleCycle, localRefresher }) {
  const { userPreferences, toggleVideoMuted, personal, active_neighbourhood, jwt_token } = useContext(UserContext)
  const classes = useStyles()
  const [isPostLoaded, setIsPostLoaded] = useState(false)

  function handleChange (dir) {
    setIsPostLoaded(false)
    handleCycle(dir)
  }

  function handleMuteUnmute () {
    toggleVideoMuted()
  }

  const feedPostView = async () => {
    // only perform feed if non anonymous post: hence has post_id
    if (post.post_id === uuidNil) {
      return
    }
    // for analytics
    const data = {
      post_id: post.post_id,
      user_id: personal[active_neighbourhood].ID
    }
    feedData('post_views', data, jwt_token, personal[active_neighbourhood].ID)
    // for post count
    postView(post.post_id, personal[active_neighbourhood].ID, jwt_token)
  }

  useEffect(() => {
    if (jwt_token) {
      feedPostView()
    }

    if (post.offer_review_id.length !== 0 && post.offer_id.length !== 0) {
      // if post has offer review id, means its also a review so feed business analytics
      feedData(
        'business_offers_reviews_views',
        {
          user_id: personal ? personal[active_neighbourhood].ID : null,
          offer_id: post.offer_id,
          review_id: post.offer_review_id,
          created_at: new Date().toISOString()
        }
      )
    }
  }, [])

  return (
    <div key={post.post_id}>
      <ConditionalModal
        open={open}
        onClose={onClose}
        handleCycle={handleChange}
      >
        <Grid container className={classes.videoModal}>
          <Grid sm={12} md={12} lg={6} item>
            <div className={classes.videoContainer} onClick={handleMuteUnmute}>
              {!isLoad && (
                <ReactPlayer
                  url={`${post.post_playback_token}`}
                  width="45vh"
                  height="80vh"
                  loop={true}
                  playing={true}
                  volume={1}
                  muted={userPreferences.videoMuted}
                  style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    maxWidth: '100%',
                    maxHeight: 'calc(100% - 32px)'
                  }}
                  onReady={() => {
                    setIsPostLoaded(true)
                  }}
                  onStart={() => {
                    setIsPostLoaded(true)
                  }}
                ></ReactPlayer>
              )}
              <div
                className={classes.muteBtnContainer}
                style={{ opacity: userPreferences.videoMuted ? 1 : 0 }}
              >
                <VolumeOff
                  htmlColor="white"
                  fontSize="large"
                  style={{
                    padding: 10,
                    backgroundColor: '#00000040',
                    borderRadius: '30px'
                  }}
                />
              </div>
              {!isPostLoaded && (
                <div className={classes.shimmer + ' shimmer'} />
              )}
            </div>
          </Grid>
          {!isLoad && <PostDetails index={index} nearcast={post} onClose={onClose} localRefresher={localRefresher} />}
        </Grid>
      </ConditionalModal>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  videoModal: {
    justifyContent: 'flex-start',
    // eslint-disable-next-line no-useless-computed-key
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'center'
    }
  },
  shimmer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '45vh',
    maxWidth: '100%',
    height: '80vh',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)'
  },
  modalCard: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    position: 'absolute',
    backgroundColor: 'white',
    borderRadius: '20px',
    width: '60vw',
    maxWidth: '880px',
    minWidth: '865px',
    padding: '20px',
    maxHeight: 'calc(100vh - 90px)',
    height: '100%',
    overflow: 'hidden',
    [theme.breakpoints.down('lg')]: {
      height: 'calc( 100vh - 100px)',
      overflowY: 'auto',
      minWidth: '0px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '94vw',
      height: '90vh',
      paddingInline: '5px'
    }
  },
  controls: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    width: 'calc(60vw + 160px)',
    maxWidth: '1040px',
    minWidth: '1020px',
    transform: 'translate(-50%,-50%)',
    zIndex: -1,
    display: 'flex',
    [theme.breakpoints.down('lg')]: {
      minWidth: '0'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  closeButton: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white'
    }
  },
  rightControl: {
    alignSelf: 'flex-end',
    marginLeft: 'auto'
  },
  modalCardContent: {
    [theme.breakpoints.down('sm')]: {
      padding: 0
    }
  },
  overlay: {
    // display: "flex",
    display: 'none',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 0,
    width: '45vh',
    maxWidth: '100%',
    height: '80vh',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  actionPanels: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 'calc( 80vh - 45vh )'
    }
  },
  nonModalContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '64px 20px auto 260px',
    overflowY: 'auto',
    // eslint-disable-next-line no-useless-computed-key
    [theme.breakpoints.down('lg')]: {
      margin: '117px 10px auto',
      height: 'calc(100vh - 133px)'
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '40px',
      marginInline: '0px',
      height: 'calc(100vh - 100px)'
    },
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  videoContainer: {
    position: 'relative',
    [theme.breakpoints.down('lg')]: {
      marginBottom: '16px'
    }
  },
  muteBtnContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50% , -50%)'
  }
}))

export default PostView
