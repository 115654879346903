import axios from 'axios'

/**
 * @function groupPublicGroups Used to get all the public groups
 * @param {number} page
 * @param {string} nrctUID - user's current neighborhood id
 * @param {string} jwt - user's jwt token
 * @returns {Promise} Promise object represents array of public groups
 */
export const groupPublicGroups = (page, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/group/public/groups`, {
      params: {
        distance: 30,
        page: page
      },
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(res.data.groups))
      .catch((err) => reject(err))
  })
}
