import * as React from 'react'
import {
  ButtonGroup,
  Button
} from '@mui/material'
import { useNavigate } from 'react-router-dom'

const GGBTabButtonGroup = () => {
  const navigate = useNavigate()
  return (
    <ButtonGroup
      color="primary"
      aria-label="primary button group"
      fullWidth
    >
      <Button
        variant={window.location.pathname.split('/')[2] === 'group' ? 'contained' : 'outlined'}
        onClick={() => navigate('/home/group')}
        color="primary"
      >
        Groups
      </Button>
      <Button
        variant={window.location.pathname.split('/')[2] === 'gathering' ? 'contained' : 'outlined'}
        onClick={() => navigate('/home/gathering')}
      >
        Gatherings
      </Button>
      <Button
        variant={window.location.pathname.split('/')[2] === 'business' ? 'contained' : 'outlined'}
        onClick={() => navigate('/home/business')}
      >
        Explore
      </Button>
    </ButtonGroup>
  )
}

export default GGBTabButtonGroup
