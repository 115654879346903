import React, { useContext, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {
  Container,
  CircularProgress,
  Typography,
  Stack,
  AppBar,
  Grid,
  Avatar
} from '@mui/material'
import { useSnackbar } from 'notistack'

// importing components
import { UserContext } from '../../context/UserContext'

// importing ui
import Dialog from 'ui/Dialog/Dialog'
import Nearcast from 'ui/Icons/Nearcast'
import NearcastVideoPlayer from 'ui/Custom/NearcastVideoPlayer/NearcastVideoPlayer'

// importing api
import { businessResponse } from 'api/business/response'

const ViewBusinessResponse = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const {
    personal,
    activeNeighborhood,
    jwtToken,
    userPreferences,
    toggleVideoMuted
  } = useContext(UserContext)
  const { enqueueSnackbar } = useSnackbar()

  // page states
  const initialReviewResponse = {
    reviewDetails: {
      businessId: '',
      businessName: '',
      businessAddress: '',
      responseMessage: '',
      responseVideoPlaybackToken: ''
    },
    isLoading: true,
    hasErrors: null
  }
  const [reviewResponse, setReviewResponse] = useState(initialReviewResponse)

  useEffect(() => {
    businessResponse(id)
      .then((details) => {
        setReviewResponse({
          reviewDetails: {
            businessId: details.business_id,
            businessName: details.business_name,
            businessAddress: details.business_address,
            responseMessage: details.response_video_message,
            responseVideoPlaybackToken: details.response_video_url
          },
          isLoading: false,
          hasErrors: null
        })
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' })
      })
  }, [id])

  return (
    <Container
      maxWidth="md"
      sx={{
        height: '100vh',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: {
          xs: 5,
          md: 0
        },
        padding: {
          xs: 2,
          md: 0
        }
      }}
    >
      <AppBar
        style={{
          backgroundColor: 'white',
          padding: 12,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Nearcast style={{ width: 100 }} />
      </AppBar>
      {reviewResponse.isLoading
        ? (
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{
                height: '100%'
              }}
            >
              <CircularProgress />
            </Stack>
          )
        : (
            <Grid
              container
              spacing={0}
              sx={{
                border: {
                  xs: 'none',
                  md: '1px solid grey'
                },
                borderRadius: 8,
                padding: {
                  xs: 0,
                  md: 2
                },
                paddingBottom: {
                  xs: 0,
                  md: 4
                },
                paddingTop: {
                  xs: 0,
                  md: 4
                },
                height: {
                  xs: '100%',
                  md: 'auto'
                }
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                sx={{
                  marginBottom: {
                    xs: 4,
                    md: 0
                  }
                }}
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  style={{
                    height: '100%'
                  }}
                >
                  <NearcastVideoPlayer
                    type="post"
                    video={{
                      source: reviewResponse.reviewDetails.responseVideoPlaybackToken,
                      muted: userPreferences.videoMuted,
                      toggleMuted: toggleVideoMuted
                    }}
                  />
                </Stack>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
              >
                <Stack
                  spacing={{
                    xs: 4,
                    md: 0
                  }}
                  direction="column"
                  alignItems="flex-start"
                  justifyContent="space-between"
                  sx={{
                    height: {
                      xs: 'auto',
                      md: '100%'
                    },
                    paddingBottom: {
                      xs: 2,
                      md: 0
                    }
                  }}
                >
                  <Stack
                    direction="column"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                  >
                    <Typography variant="h2" sx={{ marginBottom: 2 }} >
                      Video Response
                    </Typography>
                    <Typography
                      variant="body1"
                      component="pre"
                      sx={{
                        marginBottom: 2,
                        flexWrap: 'wrap',
                        whiteSpace: 'break-spaces'
                      }}>
                      {reviewResponse.reviewDetails.responseMessage}
                    </Typography>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Avatar
                        alt="Business Logo"
                        src={''}
                        variant="square"
                        sx={{
                          width: 48,
                          height: 48,
                          marginRight: 1,
                          borderRadius: 1
                        }}
                      />
                      <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                      >
                        <Typography variant="h4">
                          {reviewResponse.reviewDetails.businessName}
                        </Typography>
                        <Typography variant="caption">
                          {reviewResponse.reviewDetails.businessAddress}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Stack
                    direction="column"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                  >
                    <Typography variant="h6">
                      Powered By:
                    </Typography>
                    <Nearcast style={{ width: 140 }} />
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          )}
      <Dialog
        isOpen={Boolean(reviewResponse.hasErrors)}
        title={reviewResponse.hasErrors}
        cancelText="Retry"
        onCancel={() => window.location.reload()}
        confirmText="Go Home"
        onConfirm={() => navigate('/home')}
      />
    </Container>
  )
}

export default ViewBusinessResponse
