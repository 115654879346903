import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { useNavigate, useLocation } from 'react-router-dom'

// importing icons
import homeIcon from './SidebarIcons/SidebarHome.svg'
import gatheringIcon from './SidebarIcons/SidebarGathering.svg'
import groupIcon from './SidebarIcons/SidebarGroup.svg'
import businessIcon from './SidebarIcons/SidebarBusiness.svg'
import storeIcon from './SidebarIcons/SidebarStore.svg'

// importing helpers
import { getBusinessDomainURL } from 'helpers/url'

const style = makeStyles(({ breakpoints, palette }) => {
  return {
    sideBarElement: {
      display: 'flex',
      alignItems: 'center',
      margin: 12,
      marginTop: 10,
      fontWeight: 500,
      width: 170,
      marginLeft: '20px',
      padding: '10px 20px',
      borderRadius: 5,
      cursor: 'pointer',
      [breakpoints.down('sm')]: {
        justifyContent: 'center',
        width: 'auto',
        flex: 1
      },
      [breakpoints.up('sm')]: {
        fontSize: '1rem'
      },
      [breakpoints.down('sm')]: {
        fontSize: '.75rem',
        marginLeft: '0.5px'
      },
      '&:hover': {
        background: '#F0F3F9'
      }
    },
    sideBarElementActive: {
      backgroundColor: palette.primary.main,
      color: 'white',
      '&:hover': {
        backgroundColor: `${palette.primary.main}de`
      }
    },
    sideBarElementInactive: {
      backgroundColor: '#F4F7FD',
      color: 'black',
      '&:hover': {
        backgroundColor: '#DAE1EF'
      }
    },
    iconDiv: {
      display: 'block',
      marginRight: '10px',
      width: '20px',
      paddingLeft: '10px',
      paddingTop: '0.5px',
      [breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    iconDivActive: {
      filter: 'invert(1)'
    },
    root: {
      position: 'absolute',
      paddingTop: '20px',
      left: 0,
      top: 64,
      background: '#FFFFFF',
      zIndex: 1,
      // eslint-disable-next-line no-useless-computed-key
      [breakpoints.down('sm')]: {
        top: 64,
        width: '100vw',
        padding: 0,
        overflowX: 'auto',
        textAlign: 'center',
        scrollbarWidth: 'none',
        borderBottom: '1px solid #dfdfdf87',
        '& li': {
          margin: '6px auto'
        },
        '&::-webkit-scrollbar': {
          display: 'none'
        },
        display: 'none'
      }
    },
    btn: {
      display: 'none',
      // eslint-disable-next-line no-useless-computed-key
      [breakpoints.down('sm')]: {
        display: 'block'
      }
    }
  }
})

function SidebarElement (props) {
  const classes = style()

  return (
    <div
      onClick={props.onClick}
      className={`${classes.sideBarElement} ${
        props.pathCheck
          ? classes.sideBarElementActive
          : classes.sideBarElementInactive
      }`}
      style={props.style}
    >
      <div className={classes.iconDiv}>
        <img
          className={props.pathCheck ? classes.iconDivActive : null}
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            display: 'block',
            margin: 'auto'
          }}
          src={props.icon}
          alt="home"
        />
      </div>
      <div>{props.children}</div>
    </div>
  )
}

function Sidebar (props) {
  const classes = style()
  const location = useLocation()
  const navigate = useNavigate()
  const pathname = location.pathname.replace(/\/$/, '')

  return (
    <div className={classes.root}>
      <SidebarElement
        onClick={() => navigate('/home')}
        pathCheck={pathname === '/home'}
        icon={homeIcon}
      >
        Home
      </SidebarElement>
      <SidebarElement
        onClick={() => navigate('/home/group')}
        pathCheck={pathname === '/home/group'}
        icon={groupIcon}
      >
        Groups
      </SidebarElement>
      <SidebarElement
        onClick={() => navigate('/home/gathering')}
        pathCheck={
          pathname === '/home/gathering' ||
          pathname === '/home/gathering/create'
        }
        icon={gatheringIcon}
      >
        Gatherings
      </SidebarElement>
      <SidebarElement
        onClick={() => navigate('/home/business')}
        pathCheck={pathname === '/home/business'}
        icon={storeIcon}
      >
        Businesses
      </SidebarElement>
    </div>
  )
}

export default Sidebar
