import * as React from 'react'
import {
  Stack,
  Typography,
  Button
} from '@mui/material'
import { Flag } from '@mui/icons-material'

// importing ui
import RightItemCard from '../../Cards/RightItemCard/RightItemCard'

export interface OurStoryProps {
  isForBusinessPortal: boolean;
  story: {
    value: string;
    focusAction: () => {};
  }
}

const OurStory: React.FC<OurStoryProps> = (props) => {
  return (
    <RightItemCard
      title="About Us"
      titleLeftAction={<Flag />}
      sx={{
        marginTop: 0,
        marginBottom: 2
      }}
      cardContent={
        <>
          {props.story.value
            ? (
                <Stack direction="row" alignItems="flex-start" flexWrap="wrap" sx={{ padding: 2 }}>
                  <Typography textAlign="left" style={{ fontSize: 14, whiteSpace: 'pre-line' }} variant="caption">
                    {props.story.value}
                  </Typography>
                </Stack>
              )
            : (
                <Stack direction="column" alignItems="center" spacing={2} sx={{ padding: 2 }}>
                  <img
                    src={require('./assets/story.png')}
                    style={{
                      width: 100
                    }}
                  />
                  <Typography variant="caption" textAlign="center" style={{ width: '80%' }}>
                    {props.isForBusinessPortal
                      ? 'Tell us something unique about your business which would encourage more customers coming to you.'
                      : "This business doesn't have their details updated yet. Please contact the owner to add one."
                    }
                  </Typography>
                  {props.isForBusinessPortal && (
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={props.story.focusAction}
                    >
                      Add Story
                    </Button>
                  )}
                </Stack>
              )}
        </>
      }
    />
  )
}

export default OurStory
