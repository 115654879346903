import * as React from 'react'
import { Chip as DefaultChip } from '@mui/material'

const Chip = ({ label, isSelected, onClick }) => {
  return (
        <DefaultChip
            label={label}
            style={{
              paddingLeft: 8,
              paddingRight: 8,
              paddingTop: 4,
              paddingBottom: 4,
              fontSize: 14,
              borderRadius: 10,
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: '#bdbdbd',
              marginRight: 8,
              marginBottom: 8,
              color: isSelected ? '#fff' : '#828282',
              background: isSelected ? '#3a8f78' : 'transparent'
            }}
            onClick={onClick}
        />
  )
}

Chip.defaultProps = {
  label: 'Chip Label',
  isSelected: false,
  onClick: () => {}
}

export default Chip
