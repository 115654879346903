import axios from 'axios'

/**
 * @function getAvatarLink Used to upload user's profile image
 * @param {File} image - image file that needs to be uploaded
 * @param {string} nrctUID - personal[active_neighborhood].ID from user context
 * @param {string} jwt - jwt from user context
 * @returns {Promise} Promise object returns true for successful upload
 */
export const getAvatarLink = (image, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/get/avatar/link`, {
      photo_extension: image.type.split('/')[1]
    }, {
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    }
    )
      .then(async (res) => {
        await axios.put(res.data.url, image, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': image.type
          }
        })
      })
      .then(() => resolve(true))
      .catch((err) => reject(err))
  })
}
