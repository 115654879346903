import React, { useEffect } from 'react'
import Slide from '@mui/material/Slide'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
// importing various providers
import ThemeProvider from 'ui/Theming/ThemeProvider'
import { ParallaxProvider } from 'react-scroll-parallax'
import { CookiesProvider } from 'react-cookie'
import UserContextProvider from './context/UserContext'
import { SnackbarProvider } from 'notistack'
import { Provider as ReduxProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import GlobalFuncHOC from './context/GlobalFuncHOC'

// importing pages
import LandingNew from './pages/Landing/LandingNew'
import LandingInfluencers from './pages/Landing/LandingInfluencers'
import LandingBusiness from './pages/Landing/LandingBusiness'
import LandingBands from './pages/Landing/LandingBands'
import LandingCivic from './pages/Landing/LandingCivic'
import UserDashboard from './pages/UserDashboard/UserDashboard'
import Documents from './pages/Documents/Documents'
import ViewGroup from './pages/ViewGroup/ViewGroup'
import StreamChat from './pages/StreamChat/StreamChat'
import HostGathering from './pages/HostGathering/HostGathering'
import Gathering from './pages/Gathering/Gathering'
import Profile from './pages/Profile/Profile'
import Posts from './pages/Post/Post'
import Business from './pages/Business/Business'
import ViewOffer from './pages/ViewOffer/ViewOffer'
import CreateBusinessReview from './pages/CreateBusinessReview/CreateBusinessReview'
import ViewBusinessResponse from './pages/ViewBusinessResponse/ViewBusinessResponse'
import Unsubscribe from './pages/Unsubscribe/Unsubscribe'
// mobile specific screens
import ActivityMobile from './pages/ActivityMobile/Activity.mobile'
import Error from './pages/Error/Error'

// importing redux store
import { store, persister } from './utils/redux/store'

// importing helpers
import { GoogleAnalyticsInitialize } from 'helpers/ga'

// apps main routing
const MainRouter = () => {
  useEffect(() => {
    if (window.innerWidth < 600) {
      return
    }
    (function () {
      const s1 = document.createElement('script')
      const s0 = document.getElementsByTagName('script')[0]
      s1.async = true
      s1.src = 'https://embed.tawk.to/61f753f0b9e4e21181bcac63/1fqn1vpuv'
      s1.charset = 'UTF-8'
      s1.setAttribute('crossorigin', '*')
      s0.parentNode.insertBefore(s1, s0)
    })()
    if (process.env.REACT_APP_DEPLOYMENT !== 'dev') {
      (function (h, o, t, j, a, r) {
        h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) }
        h._hjSettings = { hjid: 2850980, hjsv: 6 }
        a = o.getElementsByTagName('head')[0]
        r = o.createElement('script'); r.async = 1
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
        a.appendChild(r)
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
    }
  }, [])
  return (
    <BrowserRouter>
      <GlobalFuncHOC>
        <Routes>
          <Route exact path="/" element={<LandingNew />} />
          <Route exact path="/login" element={<LandingNew />} />
          <Route exact path="/forgot*" element={<LandingNew />} />
          <Route exact path="/businesses" element={<LandingBusiness />} />
          <Route exact path="/influencers" element={<LandingInfluencers />} />
          <Route exact path="/bands" element={<LandingBands />} />
          <Route exact path="/civic" element={<LandingCivic />} />

          <Route exact path="/home/*" element={<UserDashboard />} />

          {/* Nearcast policies and legal stuff */}
          <Route exact path="/terms" element={<Documents />} />
          <Route exact path="/privacy" element={<Documents />} />
          <Route exact path="/community-guidelines" element={<Documents />} />
          <Route exact path="/help" element={<Documents />} />
          <Route exact path="/faqs" element={<Documents />} />
          <Route exact path="/cookies" element={<Documents />} />
          <Route exact path="/content-policy" element={<Documents />} />
          <Route exact path="/report/copyright" element={<Documents />} />
          <Route exact path="/report/problem" element={<Documents />} />
          <Route exact path="/data-deletion" element={<Documents />} />
          <Route exact path="/member-policy" element={<Documents />} />
          <Route exact path="/member-agreement" element={<Documents />} />
          <Route exact path="/third-party-terms-disclosures" element={<Documents />} />
          <Route exact path="/eea-member-agreement" element={<Documents />} />
          <Route exact path="/business-services-terms" element={<Documents />} />
          <Route exact path="/app-privacy-policy" element={<Documents />} />
          <Route exact path="/app-terms" element={<Documents />} />
          <Route exact path="/app-eula-terms" element={<Documents />} />
          <Route exact path="/app-cookies" element={<Documents />} />

          <Route exact path="/g/:id" element={<ViewGroup />} />
          {/* <Route exact path="/chat*" element={<StreamChat />} /> */}
          <Route exact path="/gathering/create" element={<HostGathering />} />
          <Route exact path="/gathering/:id" element={<Gathering />} />
          <Route exact path="/u/:id" element={<Profile />} />
          <Route exact path="/p/:id" element={<Posts />} />
          <Route exact path="/b/:id" element={<Business />} />
          <Route exact path="/b/:id/offer*" element={<Business />} />
          <Route exact path="/b/:id/allOffers" element={<Business />} />
          <Route exact path="/offer/:id" element={<ViewOffer />} />
          <Route exact path="/business/review/:id" element={<CreateBusinessReview />} />
          <Route exact path="/review/response/:id" element={<ViewBusinessResponse />} />
          <Route exact path="/email/unsubscribe/:email" element={<Unsubscribe />} />

          {/* Only Mobile Screens */}
          {window.innerWidth < 600 && <Route exact path="/activity" element={<ActivityMobile />} />}
          <Route exact path="*" element={<Error />} />
        </Routes>
      </GlobalFuncHOC>
    </BrowserRouter>
  )
}

const App = () => {
  useEffect(() => {
    GoogleAnalyticsInitialize()
  }, [])
  return (
    <ThemeProvider>
      <CookiesProvider>
        <ReduxProvider store={store} >
          <PersistGate persistor={persister} loading={<div>loading</div>}>
            <UserContextProvider>
              <ParallaxProvider>
                <SnackbarProvider
                  maxSnack={3}
                  autoHideDuration={1500}
                  preventDuplicate
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                  }}
                  TransitionComponent={Slide}
                >
                  <MainRouter />
                </SnackbarProvider>
              </ParallaxProvider>
            </UserContextProvider>
          </PersistGate>
        </ReduxProvider>
      </CookiesProvider>
    </ThemeProvider>
  )
}

export default App
