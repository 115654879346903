import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { Link } from 'react-router-dom'

function Terms () {
  const classes = styles()

  return (
        <div className={classes.mainContainer}>
            <Typography variant="h2">
                Terms and Conditions
            </Typography>
            <Typography variant="body1" style={{ marginTop: '20px' }}>
                <Link to="/member-policy">Member Policy</Link>
            </Typography>
            <Typography variant="body1" style={{ marginTop: '20px' }}>
                <Link to="/business-services-terms">Business Service Terms</Link>
            </Typography>
            <Typography variant="body1" style={{ marginTop: '20px' }}>
                <Link to="/cookies">Advertising and Cookie Policy</Link>
            </Typography>
            <Typography variant="body1" style={{ marginTop: '20px' }}>
                <Link to="/privacy">Privacy Policy</Link>
            </Typography>
            <Typography variant="body1" style={{ marginTop: '20px' }}>
                <Link to="/community-guidelines">Community Guidelines</Link>
            </Typography>
        </div>
  )
}

const styles = makeStyles(({ breakpoints, palette }) => ({
  flex: {
    display: 'flex',
    alignItems: 'center'
  },
  flexGrow: {
    flex: '1'
  },
  mainContainer: {
    padding: '40px',
    paddingBottom: '60px'
  }
}))

export default Terms
