import React, { useContext, useEffect, useState } from 'react'
import style from './ViewGroupStyles'
import { UserContext } from '../../context/UserContext'
import { Person, ErrorRounded, MoreHoriz } from '@mui/icons-material'
import {
  Avatar,
  Button,
  Chip,
  Divider,
  CircularProgress,
  Typography,
  MenuItem,
  Menu,
  IconButton,
  useTheme,
  Grid
} from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import ShareIcon from '@mui/icons-material/Share'
import DeleteIcon from '@mui/icons-material/Delete'
import { useSnackbar } from 'notistack'
import { ReactComponent as MsgIcon } from '../../assets/msg_icon.svg'
import StarIcon from '../../assets/star_icon.svg'
import ActionModal from '../../components/Modals/ActionModal'
import ManageGroup from '../../components/Modals/ManageGroup'
import WideModal from 'ui/Modals/WideModal'
import CreateGathering from '../../components/UserDash/Gathering/CreateGathering'
import { GlobalFuncContext } from '../../context/GlobalFuncHOC'

import { useNavigate, useLocation } from 'react-router'

// importing apis
import { groupDeleteById } from 'api/group/id'
import { groupJoin } from 'api/group/join'
import { reportGroup as reportGroupApiPack } from 'api/report/group'
import { groupCover } from 'api/group/cover'
import { personalAccountAvatar } from 'api/personal_account/avatar'
import { useChatContext } from 'stream-chat-react'

// import helpers
// import { createChannelForGroup } from 'helpers/chat'

function GroupInfoCard ({ groupData, id, isAdmin, setGroupInfo, members, refresh }) {
  const classes = style()
  const { enqueueSnackbar } = useSnackbar()
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()

  const { personal, active_neighbourhood, jwt_token } = useContext(UserContext)
  const { openShareModal } = useContext(GlobalFuncContext)
  const { client } = useChatContext()

  const [groupCoverUrl, setGroupCoverUrl] = useState(null)
  const [btnLoading, setBtnLoading] = useState(false)
  const [imgMenu, setImgMenu] = useState(false)
  const [reportOpen, setReportOpen] = useState()
  const [open, setOpen] = useState(null)

  const [manageOpen, setManageOpen] = useState(false)
  const [gatheringOpen, setGatheringOpen] = useState(false)

  const sendJoinRequest = async () => {
    // check if user is logged in or not
    if (personal === null) {
      // ask the user to login first and then redirect user back to Group
      return navigate('/login', {
        state: { pathname: `${location.pathname}?wasJoinWithoutAuth=true` }
      })
    }
    setBtnLoading(true)
    groupJoin(id, personal[active_neighbourhood].ID, jwt_token)
      .then(() => {
        enqueueSnackbar('Group joined successfully!', {
          variant: 'success',
          autoHideDuration: '500'
        })
        const newData = { ...groupData }
        newData.group_info.is_joined = true
        setGroupInfo(newData)
        refresh()
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: 'error'
        })
      })
      .finally(() => setBtnLoading(false))
  }

  const reportGroup = async () => {
    reportGroupApiPack(id, 'User reported group from the web.', personal[active_neighbourhood].ID, jwt_token)
      .then(() => {
        enqueueSnackbar('Group reported successfully!', {
          variant: 'success'
        })
        setReportOpen(false)
      })
      .catch(() => {
        enqueueSnackbar('There was an error in reporting the group!', {
          variant: 'error'
        })
      })
  }

  const handleMessageClick = async () => {
    try {
      const channel = await createChannelForGroup(
        client,
        groupData.group_info.id,
        members.map((member) => member.id)
      )
      channel.updatePartial({ set: { name: groupData.group_info.group_name, image: groupCoverUrl } })
      navigate(`/chat?id=${channel.id}`)
    } catch (err) {
      console.log(err.message)
    }
    // const channel = client.channel('messaging', groupData.group_info.id)
    // await channel.watch()
    // await channel.addMembers([personal[active_neighbourhood].ID])
    // await channel.updatePartial({ set: { name: groupData.group_info.group_name, image: groupCoverUrl } })
  }

  useEffect(() => {
    setGroupCoverUrl(groupCover(groupData.group_info.id))
  }, [groupData.group_info.id])

  useEffect(() => {
    const wasJoinWithoutAuth = new URLSearchParams(location.search).get('wasJoinWithoutAuth')
    if (wasJoinWithoutAuth) {
      sendJoinRequest()
      navigate('', { replace: true })
    }
  }, [])
  const adminStyle = {
    display: 'block'
  }

  return (
        <div className={classes.cardContainer}>
            <div
                key={groupData.group_info.id}
                style={{
                  backgroundImage: `url(${groupCoverUrl})`
                }}
                onMouseEnter={() => setImgMenu(true)}
                onMouseLeave={() => setImgMenu(false)}
                className={classes.imgContainer}
            >
                {imgMenu && (
                    <>
                        <IconButton
                            onClick={(e) => {
                              e.stopPropagation()
                              setOpen(e.currentTarget)
                            }}
                            aria-controls='post-menu'
                            className={classes.gc_menuIcon}
                            color="inherit"
                            size="small"
                            style={{ backgroundColor: 'rgba(80, 80, 80, 0.8)' }}
                        >
                            <MoreHoriz htmlColor="#fff" />
                        </IconButton>
                        <Menu
                            id="people-menu"
                            anchorEl={open}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right'
                            }}
                            open={Boolean(open)}
                            keepMounted
                            onClose={() => {
                              setOpen(null)
                            }}
                        >
                            <MenuItem
                                onClick={() => {
                                  openShareModal(
                                    'Group',
                                    'Check out this amazing community at Nearcast!',
                                    `/g/${id}`
                                  )
                                }}
                            >
                                <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}
                                >
                                    <ShareIcon
                                        // color="secondary"
                                        style={{ marginRight: '8px' }}
                                    />
                                    <Typography>Share</Typography>
                                </div>
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                  setReportOpen(groupData.group_info.id)
                                  setOpen(null)
                                }}
                                style={isAdmin ? { display: 'none' } : adminStyle}
                            >
                                <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}
                                >
                                    <ErrorRounded color="secondary" style={{ marginRight: '8px' }} />
                                    <Typography color="secondary">Report</Typography>
                                </div>
                            </MenuItem>
                            {isAdmin && (
                                <MenuItem
                                    onClick={() => {
                                      groupDeleteById(groupData.group_info.id, personal[active_neighbourhood].ID, jwt_token)
                                        .then(() => navigate('/home/group'))
                                        .catch((err) => enqueueSnackbar(err.message, { variant: 'error' }))
                                    }}
                                >
                                    <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                    >
                                        <DeleteIcon color="secondary" style={{ marginRight: '8px' }} />
                                        <Typography color="secondary">Delete</Typography>
                                    </div>
                                </MenuItem>
                            )}
                        </Menu>
                    </>
                )}
            </div>
            <div style={{ padding: '8px 12px' }}>
                <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',

                      color: '#333333'
                    }}
                >
                    <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '0.8rem'
                        }}
                    >
                        <Person fontSize="small" style={{ marginRight: 5 }} />{' '}
                        {groupData.group_info.people_count > 1 ? <> {groupData.group_info.people_count} Members</>: <> {groupData.group_info.people_count} Member</>} 
                    </div>
                    {isAdmin && (
                        <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              fontWeight: 600,
                              color: theme.palette.primary.main,
                              fontSize: '0.9rem'
                            }}
                        >
                            <img
                                src={StarIcon}
                                alt="admin"
                                style={{ marginRight: 5 }}
                            />
                            Admin
                        </div>
                    )}
                </div>
                <div
                    style={{
                      fontSize: '1.3rem',
                      fontWeight: 600,
                      marginTop: 15
                    }}
                >
                    {groupData.group_info.group_name}
                </div>
                <div style={{ margin: '10px 0' }}>{groupData.group_info.group_description}</div>
                <div>
                    {groupData.group_categories.map((category, i) => (
                        <Chip
                            variant="outlined"
                            color="primary"
                            label={category.category_name}
                            style={{
                              marginRight: 5,
                              marginTop: 5,
                              fontWeight: 500
                            }}
                            key={`groupCategory${i}`}
                        />
                    ))}
                </div>
                <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '15px 0'
                    }}
                >
                    {(members ?? []).slice(0, 5).map((member, i) => (
                        <Avatar
                            alt=""
                            style={{
                              width: 32,
                              height: 32,
                              border: '2px solid white',
                              marginLeft: i > 0 ? -15 : 0
                            }}
                            src={personalAccountAvatar(member.id)}
                            key={'memberAvatar' + i}
                        />
                    ))}
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.inviteBtn}
                        onClick={() => openShareModal(
                          'Group',
                          'Check out this amazing community at Nearcast!',
                          `/g/${id}`
                        )}
                    >
                        <PersonAddIcon
                            fontSize="0.7rem"
                            style={{ marginRight: 5 }}
                        />{' '}
                        Invite
                    </Button>
                </div>
                <Divider />
                <div style={{ padding: '20px 18px 12px 18px' }}>
                    {!groupData.group_info.is_joined
                      ? (
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.cardActionBtn}
                            onClick={sendJoinRequest}
                            disabled={btnLoading}
                        >
                            {btnLoading
                              ? (
                                <CircularProgress
                                    size={18}
                                    style={{
                                      verticalAlign: 'middle',
                                      marginRight: 10,
                                      color: '#3A8F78'
                                    }}
                                />
                                )
                              : (
                                <>
                                    <AddCircleIcon
                                        style={{ marginRight: 10 }}
                                    />{' '}
                                    Join Now
                                </>
                                )}
                        </Button>
                        )
                      : !isAdmin
                          ? (
                            <></>
                        // <Button
                        //     variant="contained"
                        //     color="primary"
                        //     className={classes.cardActionBtn}
                        //     onClick={handleMessageClick}
                        // >
                        //     <MsgIcon
                        //         className={'btnIconPrimary'}
                        //         color="white"
                        //         style={{ marginRight: 5 }}
                        //     />{' '}
                        //     Messages
                        // </Button>
                            )
                          : (
                        <>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.cardActionBtn}
                                onClick={() => setGatheringOpen(true)}
                            >
                                Start Livestream
                            </Button>
                            <Grid
                                container
                                spacing={2}
                                style={{ marginTop: 5 }}
                            >
                                <Grid item xs={12} sm={6}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => setManageOpen(true)}
                                        className={classes.cardActionBtn}
                                    >
                                        Manage Group
                                    </Button>
                                </Grid>
                                {/* <Grid item xs={12} sm={6}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        className={classes.cardActionBtn}
                                        onClick={handleMessageClick}
                                        startIcon={<MsgIcon />}
                                    >
                                        Messages
                                    </Button>
                                </Grid> */}
                            </Grid>
                        </>
                            )}
                </div>
            </div>
            <ActionModal
                open={Boolean(reportOpen)}
                onClose={() => setReportOpen(false)}
                onConfirm={() => {
                  reportGroup()
                  setReportOpen(false)
                }}
                onCancel={() => {
                  setReportOpen(false)
                }}
                action={{
                  title: 'Do you want to report this group?',
                  Accept: 'Yes',
                  Decline: 'No'
                }}
            >
                <ErrorRounded style={{ fontSize: '2.5rem' }} color="secondary" />
            </ActionModal>
            <ManageGroup
                open={manageOpen}
                onClose={() => setManageOpen(false)}
                groupData={groupData}
                setGroupData={setGroupInfo}
                members={members}
            />
            <WideModal
                isOpen={gatheringOpen}
                onClose={() => setGatheringOpen(false)}
            >
                <CreateGathering
                    isModal
                    targetGroup={groupData.group_info}
                    closeModal={() => setGatheringOpen(false)}
                />
            </WideModal>
        </div>
  )
}

export default GroupInfoCard
