import axios from 'axios'
import { alsoPublicRouteHeadersFormat } from '../helpers'

/**
 * @function groupPosts Used to get all posts of the group
 * @param {string} id - the group's id
 * @param {number} page - the paginated page number to get
 * @param {string} size - the size of each page
 * @param {string} nrctUID - user's current neighborhood id, set to null for public info
 * @param {string} jwt - user's jwt token, set to null for public info
 * @returns {Promise} Promise object represents array of posts
 */
export const groupPosts = (id, page = 1, size = 10, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/group/posts`, {
      ts: new Date().toISOString(),
      page,
      page_size: size
    }, {
      params: {
        group: id
      },
      headers: alsoPublicRouteHeadersFormat(nrctUID, jwt)
    })
      .then((res) => resolve(res.data.body))
      .catch((err) => reject(err))
  })
}
