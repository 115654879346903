import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

function EULATerms () {
  const classes = styles()

  return (
        <div className={classes.mainContainer}>
            <h3 style={{ textAlign: 'center' }}>MOBILE APPLICATION END-USER LICENSE AGREEMENT (EULA)</h3>
            <div style={{ marginBottom: '1rem' }}>PLEASE READ THIS MOBILE APPLICATION END-USER LICENSE AGREEMENT (&quot;EULA
                AGREEMENT&quot;) CAREFULLY, BEFORE COMPLETING THE DOWNLOAD OR INSTALLATION PROCESS OR USING THE APPLICATION, AS IT
                CONTAINS IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS AND REMEDIES.</div>
            <div style={{ marginBottom: '1rem' }}>Last Revised: 2021-12-11 09:53:35</div>
            <div style={{ marginBottom: '1rem' }}>
                <h3 style={{ fontWeight: 600, margin: 0, textTransform: 'uppercase' }}>1. OVERVIEW</h3>
                <div style={{ margin: '.5rem 0' }}>This EULA agreement is a binding agreement, entered into by and between NearCast Inc.,
                    registered address 1580 W El Camino Real STE 10, United States (&quot;NearCast Inc.&quot;) and you, and is made
                    effective as of the date you download, install or use the Application Nearcast (&quot;Application&quot;) or from
                    the date of your electronic acceptance.</div>
                <div style={{ margin: '.5rem 0' }}>This EULA agreement sets forth the general terms and conditions of your use of the
                    Application, provides a license to use NearCast Inc. Application and contains liability disclaimers. This EULA
                    agreement's terms also apply to any Application update, upgrade, internet-based service, and support service for
                    the Application. Whether you are acquiring Application directly from NearCast Inc. or through NearCast Inc.
                    authorized reseller your electronic acceptance of this EULA agreement signifies that you have read, understand,
                    acknowledge, and agree to be bound by this EULA agreement.</div>
                <div style={{ margin: '.5rem 0' }}>The terms &quot;we&quot;, &quot;us&quot; or &quot;our&quot; shall refer to NearCast
                    Inc.. The terms &quot;you&quot;, &quot;your&quot;, or &quot;User&quot; shall refer to any individual or entity
                    who accepts this EULA agreement, uses our Application, or has access to our Application. </div>
                <div style={{ margin: '.5rem 0' }}>NearCast Inc. may, in its sole and absolute discretion, change or modify this
                    Agreement, and any policies or agreements which are incorporated herein, at any time, and such changes or
                    modifications shall be effective immediately upon posting. Your use of this Application after such changes or
                    modifications have been made shall constitute your acceptance of this EULA agreement as last revised.</div>
                <div style={{ margin: '.5rem 0' }}>BY DOWNLOADING /INSTALLING /USING THE APPLICATION YOU ACKNOWLEDGE THAT YOU HAVE READ
                    AND UNDERSTAND THIS AGREEMENT. IF YOU DO NOT AGREE TO BE BOUND BY THIS EULA AGREEMENT AS LAST REVISED, DO NOT
                    DOWNLOAD, INSTALL, USE (OR CONTINUE TO USE) OUR APPLICATION. </div>
            </div>
            <div style={{ marginBottom: '1rem' }}>
                <h3 style={{ fontWeight: 600, margin: 0, textTransform: 'uppercase' }}>2. ELIGIBILITY</h3>
                <div style={{ margin: '.5rem 0' }}>Our Application is available only to Users who can form legally binding contracts under
                    the applicable law. By downloading or using this Application, you represent and warrant that you are (i) at
                    least eighteen (18) years of age, (ii) otherwise recognized as being able to form legally binding contracts
                    under applicable law, and (iii) are not a person barred from purchasing or using the Application e under the
                    laws of the United States, California or other applicable jurisdiction.</div>
                <div style={{ margin: '.5rem 0' }}>If you are entering into this EULA agreement on behalf of a company or any corporate
                    entity, you represent and warrant that you have the legal authority to bind such corporate entity to the terms
                    and conditions contained in this EULA agreement, in which case the terms “you”, “your”, or “User” shall refer to
                    such corporate entity. If you do not have such authority or if you do not agree with the terms and conditions of
                    this EULA agreement, do not install or use the Application, and you must not accept this EULA agreement. If,
                    after your electronic acceptance of this Agreement, NearCast Inc. finds that you do not have the legal authority
                    to bind such a corporate entity, you will be personally responsible for the obligations contained in this EULA
                    agreement. </div>
            </div>
            <div style={{ marginBottom: '1rem' }}>
                <h3 style={{ fontWeight: 600, margin: 0, textTransform: 'uppercase' }}>3. LICENSE GRANT</h3>
                <div style={{ margin: '.5rem 0' }}>Subject to the terms of this EULA Agreement NearCast Inc. hereby grants you a personal,
                    revocable, worldwide, non-exclusive, non-sublicensable, and non-transferable license to use the NearCast Inc.
                    Application on your own, non-commercial use devices in accordance with the terms of this EULA agreement. The
                    Application is being licensed to you and you hereby acknowledge that no title or ownership of the Application is
                    being transferred or assigned to you and this EULA agreement is not to be construed as a sale of any rights of
                    the Application.</div>
                <div style={{ margin: '.5rem 0' }}>You are permitted to load the NearCast Inc. Application (for example on a mobile,
                    tablet or laptop) under your control. You are responsible for ensuring your device meets the minimum
                    requirements of the NearCast Inc. Application.</div>
            </div>
            <div style={{ marginBottom: '1rem' }}>
                <h3 style={{ fontWeight: 600, margin: 0, textTransform: 'uppercase' }}>4. RESTRICTIONS</h3>
                <div style={{ margin: '.5rem 0' }}>Without first obtaining the express written consent of NearCast Inc., you may not
                    assign your rights and obligations under this EULA agreement, or redistribute, encumber, sell, rent, lease,
                    sublicense or in other way transfer your rights to the Application.</div>
                <div style={{ margin: '.5rem 0' }}>You are not permitted to:</div>
            </div>
            <ul>
                <li>edit, modify, alter, adapt, or otherwise change the whole or any part of the NearCast Inc. Application nor
                    permit the whole or any part of the Application to be combined with or become incorporated in any other
                    Application or any software, nor decompile, disassemble or reverse engineer the Application or attempt to do any
                    of the listed actions,</li>
                <li>copy, reproduce, duplicate, resell or distribute in any medium any part of the Application, except where
                    expressly authorized by NearCast Inc.,</li>
                <li>remove or alter NearCast Inc. trademarks or logos or legal notices included in the Application or related
                    assets,</li>
                <li>remove, disable, circumvent, or otherwise create or implement any workaround to any copy protection, rights
                    management, or security features in or protecting the Application,</li>
                <li>use the service to try to gain unauthorized access to any service, data, account or network by any means,</li>
                <li>use the Application in any way which breaches any applicable local, national or international law,</li>
                <li>use the Application for any purpose that NearCast Inc., considers is a breach of this EULA agreement.</li>
            </ul>
            <div style={{ margin: '.5rem 0' }}>NearCast Inc. reserves the right to determine in its sole discretion what kind of conduct
                is considered to be in violation of the terms of this EULA agreement.</div>
            <div style={{ margin: '.5rem 0' }}>By using our Application you acknowledge and agree that your use of the Application,
                including any content you submit, will comply with this EULA agreement and all applicable local, state, national and
                international laws, rules, and regulations.</div>
            <div style={{ marginBottom: '1rem' }}>
                <h3 style={{ fontWeight: 600, margin: 0, textTransform: 'uppercase' }}>5. INTELLECTUAL PROPERTY</h3>
                <div style={{ margin: '.5rem 0' }}>No part of this EULA agreement is or should be interpreted as a transfer of
                    intellectual property rights. NearCast Inc. shall retain ownership of the Application as originally downloaded
                    by you and all subsequent downloads of the Application by you. The Application (and the copyright, and other
                    intellectual property rights of whatever nature in the Application, including any modifications made thereto)
                    are and shall remain the property of NearCast Inc..</div>
                <div style={{ margin: '.5rem 0' }}>In addition to the general rules above, the provisions in this Section apply
                    specifically to your use of NearCast Inc. content used in the Application (NearCast Inc. content). NearCast Inc.
                    content used in this Application, including without limitation the text, scripts, source code, API, graphics,
                    photos, sounds, music, videos, and interactive features and the trademarks, service marks, and logos contained
                    therein, are owned by or licensed to NearCast Inc. in perpetuity, and are subject to copyright, trademark,
                    and/or patent protection.</div>
                <div style={{ margin: '.5rem 0' }}>NearCast Inc. content is provided to you “as is”, “as available” and “with all faults”
                    for your information and personal, non-commercial use only and may not be downloaded, copied, reproduced,
                    distributed, transmitted, broadcast, displayed, sold, licensed, or otherwise exploited for any purposes
                    whatsoever without the express prior written consent of NearCast Inc.. No right or license under any copyright,
                    trademark, patent or other proprietary right or license is granted by this EULA agreement.</div>
            </div>
            <div style={{ marginBottom: '1rem' }}>
                <h3 style={{ fontWeight: 600, margin: 0, textTransform: 'uppercase' }}>6. COLLECTION AND USE OF YOUR INFORMATION</h3>
                <div style={{ margin: '.5rem 0' }}>You acknowledge that when you download, install, or use the Application, NearCast Inc.
                    may use automatic means (including, for example, cookies and web beacons) to collect information about your
                    Mobile Device and about your use of the Application. You also may be required to provide certain information
                    about yourself as a condition to downloading, installing, or using the Application or certain of its features or
                    functionality. All information we collect through or in connection with this Application is subject to our <a
                        href="https://www.nearcast.com/app-privacy-policy" target="_blank" rel="noreferrer">Privacy policy</a>. By downloading,
                    installing, using, and providing information to or through this Application, you consent to all actions taken by
                    us with respect to your information in compliance with the Privacy Policy.</div>
            </div>
            <div style={{ marginBottom: '1rem' }}>
                <h3 style={{ fontWeight: 600, margin: 0, textTransform: 'uppercase' }}>7. UPDATES</h3>
                <div style={{ margin: '.5rem 0' }}>NearCast Inc. may from time to time in its sole discretion develop and provide
                    Application updates, which may include upgrades, bug fixes, patches, other error corrections, and/or new
                    features (collectively, including related documentation, &quot;Updates&quot;). Updates may also modify or delete
                    in their entirety certain features and functionality. You agree that NearCast Inc. has no obligation to provide
                    any Updates or to continue to provide or enable any particular features or functionality. Based on your Mobile
                    Device settings, when your Mobile Device is connected to the internet either:</div>
            </div>
            <ul>
                <li>the Application will automatically download and install all available Updates; or</li>
                <li>you may receive notice of or be prompted to download and install available Updates.</li>
            </ul>
            <div style={{ margin: '.5rem 0' }}>You shall promptly download and install all Updates and acknowledge and agree that the
                Application or portions thereof may not properly operate should you fail to do so. You further agree that all
                Updates will be deemed part of the Application and be subject to all terms and conditions of this Agreement.</div>
            <div style={{ marginBottom: '1rem' }}>
                <h3 style={{ fontWeight: 600, margin: 0, textTransform: 'uppercase' }}>8. THIRD-PARTY MATERIALS</h3>
                <div style={{ margin: '.5rem 0' }}>The Application may display, include, or make available third-party content (including
                    data, information, applications, and other products, services, and/or materials) or provide links to third-party
                    websites or services, including through third-party advertising (&quot;Third-Party Materials&quot;). You
                    acknowledge and agree that NearCast Inc. is not responsible for Third-Party Materials, including their accuracy,
                    completeness, timeliness, validity, copyright compliance, legality, decency, quality, or any other aspect
                    thereof. NearCast Inc. does not assume and will not have any liability or responsibility to you or any other
                    person or entity for any Third-Party Materials. Third-Party Materials and links thereto are provided solely as a
                    convenience to you, and you access and use them entirely at your own risk and subject to such third parties
                    terms and conditions.</div>
            </div>
            <div style={{ marginBottom: '1rem' }}>
                <h3 style={{ fontWeight: 600, margin: 0, textTransform: 'uppercase' }}>9. NEARCAST INC. USE OF USER CONTENT</h3>
                <div style={{ margin: '.5rem 0' }}>The Application may allow you to create content such as videos, data, photographs,
                    messages, graphics, text, and other information (“User Content”), and to share such User Content with NearCast
                    Inc. or with other applications, sites, including social networking sites, as you may designate.</div>
                <div style={{ margin: '.5rem 0' }}>The provisions in this Section apply specifically to NearCast Inc. use of User Content
                    posted to or through the Application.</div>
                <div style={{ margin: '.5rem 0' }}>You shall be solely responsible for any and all of your User Content or User Content
                    that is submitted by you, and the consequences of, and requirements for, distributing it. You agree that any
                    User Content that you share does not and will not violate third-party rights of any kind, including and without
                    limitation any Intellectual Property Rights or rights of publicity and privacy.</div>
                <div style={{ margin: '.5rem 0' }}>With Respect to User Content, by posting or publishing User Content to or through the
                    Application, you authorize Company to use the intellectual property and other proprietary rights in and to your
                    User Content to enable inclusion and use of the User Content in the manner contemplated by this Application and
                    this EULA agreement.</div>
                <div style={{ margin: '.5rem 0' }}>By creating User Content through NearCast Inc. Application, you hereby grant NearCast
                    Inc. a worldwide, non-exclusive, royalty-free, sub-licensable, irrevocable, and transferable license to use,
                    reproduce, distribute, prepare derivative works of, combine with other works, display, and perform your User
                    Content in connection with this Application, including without limitation for promoting and redistributing all
                    or part of this Application in any media formats and through any media channels without restrictions of any kind
                    and without payment or other consideration of any kind, or permission or notification, to you or any third
                    party. You also hereby grant each User of this Application a non-exclusive license to access your User Content
                    through this Application, and to use, reproduce, distribute, prepare derivative works of, combine with other
                    works, display, and perform your User Content as permitted through the functionality of this Software and under
                    this EULA agreement.</div>
                <div style={{ margin: '.5rem 0' }}>The above licenses granted by you in your User Content terminate within a commercially
                    reasonable time after you remove or delete your User Content from this Application. You understand and agree,
                    however, that NearCast Inc. may retain (but not distribute, display, or perform) server copies of your User
                    Content that have been removed or deleted. The above licenses granted by you in your User Content are perpetual
                    and irrevocable.</div>
                <div style={{ margin: '.5rem 0' }}>NearCast Inc. generally does not pre-screen User Content but reserves the right (but
                    undertakes no duty) to do so and decide whether any item of User Content is appropriate and/or complies with
                    this EULA agreement. NearCast Inc. may remove any item of User Content if it violating this EULA agreement, at
                    any time and without prior notice.</div>
            </div>
            <div style={{ marginBottom: '1rem' }}>
                <h3 style={{ fontWeight: 600, margin: 0, textTransform: 'uppercase' }}>10. USER SUBMISSIONS</h3>
                <div style={{ margin: '.5rem 0' }}>With Respect to User Submissions, you acknowledge and agree that:</div>
                <ul>
                    <li>your User Submissions are entirely voluntary,</li>
                    <li>your User Submissions do not establish a confidential relationship or obligate NearCast Inc. to treat your
                        User Submissions as confidential or secret.</li>
                    <li>NearCast Inc. has no obligation, either express or implied, to develop or use your User Submissions, and no
                        compensation is due to you or to anyone else for any intentional or unintentional use of your User
                        Submissions.</li>
                </ul>
                <div style={{ margin: '.5rem 0' }}>NearCast Inc. shall own exclusive rights (including all intellectual property and other
                    proprietary rights) to any User Submissions provided to the NearCast Inc. and shall be entitled to the
                    unrestricted use and dissemination of any User Submissions posted to or through the Software for any purpose,
                    commercial or otherwise, without acknowledgment or compensation to you or to anyone else.</div>
            </div>
            <div style={{ marginBottom: '1rem' }}>
                <h3 style={{ fontWeight: 600, margin: 0, textTransform: 'uppercase' }}>11. DOWNLOADING THE APPLICATION FROM THE APPLE
                    APP STORE</h3>
                <div style={{ margin: '.5rem 0' }}>The following applies to the Application accessed through or downloaded from the Apple
                    App Store (“App Store Sourced Application”):</div>
                <ul>
                    <li>You acknowledge and agree that (i) this EULA agreement is concluded between you and NearCast Inc. only, and
                        not Apple; and (ii) NearCast Inc., not Apple, is solely responsible for the App Store Sourced Application
                        and content thereof. Your use of the App Store Sourced Application must comply with the Apple App Store
                        Terms of Service.</li>
                    <li>You will use the App Store Sourced Application only (i) on an Apple-branded product that runs iOS (Apple's
                        proprietary operating system software); and (ii) as permitted by the “Usage Rules” set forth in the Apple
                        App Store Terms of Service.</li>
                    <li>You acknowledge that Apple has no obligation whatsoever to furnish any maintenance and support services with
                        respect to the App Store Sourced Application.</li>
                    <li>In the event of any failure of any App Store Sourced Application to conform to any applicable warranty, you
                        may notify Apple, and Apple will refund the purchase price for the App Store Sourced Application to you and
                        to the maximum extent permitted by applicable law, Apple will have no other warranty obligation whatsoever
                        with respect to the App Store Sourced Application. As between NearCast Inc. and Apple, any other claims,
                        losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty will
                        be the sole responsibility of NearCast Inc..</li>
                    <li>You and NearCast Inc. acknowledge that, as between NearCast Inc. and Apple, Apple is not responsible for
                        addressing any claims you have or any claims of any third party relating to the App Store Sourced
                        Application in your possession and use of the App Store Sourced Application, including but not limited to
                        (i) product liability claims; (ii) any claim that an App Store Sourced Application fails to conform to any
                        applicable legal or regulatory requirement; and (iii) claims arising under consumer protection or similar
                        legislation.</li>
                    <li>You and NearCast Inc. acknowledge that, in the event of any third party claim that an App Store Sourced
                        Application or your possession and use of that App Store Source Application infringes that third party's
                        intellectual property rights, as between NearCast Inc. and Apple, NearCast Inc., not Apple, will be solely
                        responsible for the investigation, defense, settlement and discharge of any such intellectual property
                        infringement claim to the extent required by this EULA agreement.</li>
                    <li>You and NearCast Inc., acknowledge and agree that Apple and its subsidiaries are third-party beneficiaries
                        of this EULA agreement and that upon your acceptance of this EULA agreement, Apple will have the right (and
                        will be deemed to have accepted the right) to enforce this EULA agreement.</li>
                    <li>By using the App Store Sourced Application you represent and warrant that (i) you are not located in a
                        country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as
                        a “terrorist supporting” country; and (ii) you are not listed on any U.S. Government list of prohibited or
                        restricted parties.</li>
                    <li>Without limiting any other terms of this EULA agreement, you must comply with all applicable third-party
                        terms of agreement when using the App Store Sourced Application.</li>
                </ul>
            </div>
            <div style={{ marginBottom: '1rem' }}>
                <h3 style={{ fontWeight: 600, margin: 0, textTransform: 'uppercase' }}>12. DISCLAIMER OF WARRANTIES</h3>
                <div style={{ margin: '.5rem 0' }}>UNLESS OTHERWISE EXPLICITLY AGREED TO IN WRITING BY NearCast Inc., APPLICATION IS
                    PROVIDED “AS IS”, “AS AVAILABLE” AND “WITH ALL FAULTS” AND DEFECTS AND NearCast Inc., MAKES NO OTHER WARRANTIES,
                    EXPRESS OR IMPLIED, IN FACT, OR IN LAW, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF
                    MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT, OTHER THAN AS SET FORTH IN THIS EULA
                    AGREEMENT.</div>
                <div style={{ margin: '.5rem 0' }}>NearCast Inc. MAKES NO WARRANTIES ABOUT (I) THE ACCURACY, COMPLETENESS, OR CONTENT ON
                    THIS APPLICATION AND ASSUMES NO LIABILITY OR RESPONSIBILITY FOR THE SAME. NearCast Inc. MAKES NO WARRANTIES THAT
                    THE OPERATION OF THE APPLICATION WILL BE SECURE, ERROR-FREE, OR FREE FROM INTERRUPTION.</div>
                <div style={{ margin: '.5rem 0' }}>NO ORAL OR WRITTEN ADVICE PROVIDED BY NearCast Inc. OR ANY AUTHORIZED REPRESENTATIVE OR
                    THIRD PARTY SHALL CREATE A WARRANTY. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF, OR LIMITATIONS ON,
                    IMPLIED WARRANTIES OR THE LIMITATIONS ON THE APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO SOME OR ALL OF THE
                    ABOVE EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU.</div>
                <div style={{ margin: '.5rem 0' }}>THE FOREGOING DISCLAIMER OF REPRESENTATIONS AND WARRANTIES SHALL APPLY TO THE FULLEST
                    EXTENT PERMITTED BY LAW AND SHALL SURVIVE ANY TERMINATION OR EXPIRATION OF THIS EULA AGREEMENT OR YOUR USE OF
                    THIS SITE OR THE SERVICES FOUND AT THIS SITE.</div>
            </div>
            <div style={{ marginBottom: '1rem' }}>
                <h3 style={{ fontWeight: 600, margin: 0, textTransform: 'uppercase' }}>13. LIMITATION OF LIABILITY</h3>
                <div style={{ margin: '.5rem 0' }}>TO THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE LAW, IN NO EVENT SHALL NearCast Inc.,
                    ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, AND ALL THIRD-PARTY SERVICE PROVIDERS, BE LIABLE TO YOU OR ANY OTHER
                    PERSON OR ENTITY FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES WHATSOEVER,
                    INCLUDING ANY DAMAGES THAT MAY RESULT FROM (I) THIS AGREEMENT, (II) THE ACCURACY, COMPLETENESS, OR CONTENT ON
                    THIS APPLICATION, (III) OR FROM THE FURNISHING, PERFORMANCE, INSTALLATION, OR USE OF THE APPLICATION, WHEATHER
                    DUE TO A BREACH OF CONTRACT, BREACH OF WARRANTY, OR THE NEGLIGENCE OF NearCast Inc. OR ANY OTHER PARTY, EVEN IF
                    NearCast Inc. IS ADVISED BEFOREHAND OF THE POSSIBILITY OF SUCH DAMAGES.</div>
                <div style={{ margin: '.5rem 0' }}>SOME JURISDICTIONS DO NOT ALLOW A LIMITATION OF LIABILITY FOR DEATH, PERSONAL INJURY,
                    FRAUDULENT MISREPRESENTATIONS OR CERTAIN INTENTIONAL OR NEGLIGENT ACTS, OR VIOLATION OF SPECIFIC STATUTES, OR
                    THE LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO SOME OR ALL OF THE ABOVE LIMITATIONS OF LIABILITY MAY
                    NOT APPLY TO YOU. IN NO EVENT SHALL NearCast Inc.'S TOTAL LIABILITY TO YOU FOR ALL DAMAGES (EXCEPT AS REQUIRED
                    BY APPLICABLE LAW) EXCEED THE AMOUNT ACTUALLY PAID BY YOU FOR THE APPLICATION. THIS LIMITATION APPLIES, BUT IT
                    IS NOT LIMITED TO ANYTHING RELATED TO THE APPLICATION, SERVICES, OR CONTENT MADE AVAILABLE THROUGH THE
                    APPLICATION. YOU AGREE THAT THE PROVISIONS IN THIS EULA AGREEMENT THAT LIMIT LIABILITY ARE ESSENTIAL TERMS OF
                    THIS EULA AGREEMENT.</div>
                <div style={{ margin: '.5rem 0' }}>THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY
                    LAW AND SHALL SURVIVE ANY TERMINATION OR EXPIRATION OF THIS EULA AGREEMENT.</div>
            </div>
            <div style={{ marginBottom: '1rem' }}>
                <h3 style={{ fontWeight: 600, margin: 0, textTransform: 'uppercase' }}>14. INDEMNITY</h3>
                <div style={{ margin: '.5rem 0' }}>You agree to protect, defend, indemnify and hold harmless NearCast Inc. and its
                    officers, directors, employees, agents from and against any and all claims, demands, costs, expenses, losses,
                    liabilities, and damages of every kind and nature (including, without limitation, reasonable attorneys' fees)
                    imposed upon or incurred by NearCast Inc. directly or indirectly arising from (i) your use of the Application;
                    (ii) your violation of any provision of this Agreement; and/or (iii) your violation of any third-party right,
                    including without limitation any intellectual property or other proprietary rights. The indemnification
                    obligations under this section shall survive any termination or expiration of this Agreement or your use of
                    Application.</div>
            </div>
            <div style={{ marginBottom: '1rem' }}>
                <h3 style={{ fontWeight: 600, margin: 0, textTransform: 'uppercase' }}>15. AVAILABILITY OF APPLICATION</h3>
                <div style={{ margin: '.5rem 0' }}>Subject to the terms and conditions of this Agreement and our policies, we shall use
                    commercially reasonable efforts to attempt to provide this Application on 24/7 basis. You acknowledge and agree
                    that from time to time this Application may be inaccessible for any reason including, but not limited to,
                    periodic maintenance, repairs or replacements that we undertake from time to time, or other causes beyond our
                    control including, but not limited to, interruption or failure of telecommunication or digital transmission
                    links or other failures.</div>
                <div style={{ margin: '.5rem 0' }}>You acknowledge and agree that we have no control over the availability of this
                    Application on a continuous or uninterrupted basis and that we assume no liability to you or any other party
                    with regard thereto.</div>
            </div>
            <div style={{ marginBottom: '1rem' }}>
                <h3 style={{ fontWeight: 600, margin: 0, textTransform: 'uppercase' }}>16. TERMINATION</h3>
                <div style={{ margin: '.5rem 0' }}>This EULA agreement is effective from the date you first download, install or use the
                    Application and shall continue until terminated. You may terminate this Agreement by deleting the Application
                    and all copies thereof from your Mobile Device.</div>
                <div style={{ margin: '.5rem 0' }}>This EULA agreement will also be terminated immediately if you fail to comply with any
                    term of this EULA agreement. Upon such termination, the licenses granted by this EULA agreement will immediately
                    terminate and you agree to stop all access and use of the Application. The provisions that by their nature
                    continue and survive will survive any termination of this EULA agreement.</div>
                <div style={{ margin: '.5rem 0' }}>NearCast Inc. reserves the right to cease offering or providing Application at any
                    time, for any or no reason, and without prior notice. Although NearCast Inc. makes a great effort to maximize
                    the lifespan of the Application, it might be, that the Application we offer will be discontinued. If that is the
                    case, this EULA agreement will be terminated, and the Application will no longer be supported by NearCast Inc..
                </div>
                <div style={{ margin: '.5rem 0' }}>Upon termination, all rights granted to you under this EULA agreement will also
                    terminate and you must cease all use of the Application and delete all copies of the Application from your
                    Mobile Device and account.</div>
            </div>
            <div style={{ marginBottom: '1rem' }}>
                <h3 style={{ fontWeight: 600, margin: 0, textTransform: 'uppercase' }}>17. COMPLIANCE WITH LOCAL LAWS</h3>
                <div style={{ margin: '.5rem 0' }}>NearCast Inc. makes no representation or warranty that all the content available on
                    this Application is appropriate in every country or jurisdiction and use of this Application from countries or
                    jurisdictions where its content is illegal is prohibited. Users who choose to use this Software are responsible
                    for compliance with all local laws, rules, and regulations.</div>
            </div>
            <div style={{ marginBottom: '1rem' }}>
                <h3 style={{ fontWeight: 600, margin: 0, textTransform: 'uppercase' }}>18. GOVERNING LAW</h3>
                <div style={{ margin: '.5rem 0' }}>This EULA agreement is governed by and construed in accordance with the internal laws
                    of the State of [STATE Q9] without giving effect to any choice or conflict of law provision or rule. Any legal
                    suit, action, or proceeding arising out of or related to this EULA agreement or the Application shall be
                    instituted exclusively in the federal courts of the United States or the courts of the State of California. You
                    waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such
                    courts.</div>
            </div>
            <div style={{ marginBottom: '1rem' }}>
                <h3 style={{ fontWeight: 600, margin: 0, textTransform: 'uppercase' }}>19. TITLES AND HEADINGS</h3>
                <div style={{ margin: '.5rem 0' }}>The titles and headings of this EULA agreement are for convenience and ease of
                    reference only and shall not be utilized in any way to construe or interpret the agreement of the parties as
                    otherwise set forth herein.</div>
            </div>
            <div style={{ marginBottom: '1rem' }}>
                <h3 style={{ fontWeight: 600, margin: 0, textTransform: 'uppercase' }}>20. LIMITATION OF TIME TO FILE CLAIMS</h3>
                <div style={{ margin: '.5rem 0' }}>ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THIS AGREEMENT
                    OR THE APPLICATION MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES OTHERWISE SUCH CAUSE
                    OF ACTION OR CLAIM IS PERMANENTLY BARRED.</div>
            </div>
            <div style={{ marginBottom: '1rem' }}>
                <h3 style={{ fontWeight: 600, margin: 0, textTransform: 'uppercase' }}>21. SEVERABILITY</h3>
                <div style={{ margin: '.5rem 0' }}>Each covenant and agreement in this EULA agreement shall be construed for all purposes
                    to be a separate and independent covenant or agreement. If a court of competent jurisdiction holds any provision
                    (or portion of a provision) of this EULA agreement to be illegal, invalid, or otherwise unenforceable, the
                    remaining provisions (or portions of provisions) of this EULA agreement shall not be affected thereby and shall
                    be found to be valid and enforceable to the fullest extent permitted by law.</div>
            </div>
            <div style={{ marginBottom: '1rem' }}>
                <h3 style={{ fontWeight: 600, margin: 0, textTransform: 'uppercase' }}>22. CONTACT INFORMATION</h3>
                <div style={{ margin: '.5rem 0' }}>If you have any questions about this EULA agreement, please contact us by email or
                    regular mail at the following address:</div>
            </div>
            <div style={{ margin: '.5rem 0' }}>NearCast Inc.</div>
            <div style={{ margin: '.5rem 0' }}>1580 W El Camino Real STE 10</div>
            <div style={{ margin: '.5rem 0' }}>support@nearcast.com</div>
        </div>
  )
}

const styles = makeStyles(({ breakpoints, palette }) => ({
  flex: {
    display: 'flex',
    alignItems: 'center'
  },
  flexGrow: {
    flex: '1'
  },
  mainContainer: {
    padding: '40px',
    paddingBottom: '60px'
  }
}))

export default EULATerms
