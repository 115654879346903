import React, { useEffect, useState } from 'react'
import { Typography, CircularProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useSnackbar } from 'notistack'

// importing apis
import { indexFaq } from 'api/index'

function FAQs () {
  const classes = styles()
  const [faqs, setFaqs] = useState([])
  const [loading, setLoading] = useState(true)
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    setLoading(true)
    indexFaq()
      .then((dataBody) => {
        setFaqs(dataBody)
        setLoading(false)
      })
      .catch((err) => {
        enqueueSnackbar('There was an error loading the FAQs. Please refresh the page.', {
          variant: 'error'
        })
        setLoading(false)
      })
  }, [enqueueSnackbar])

  // if (loading) return null;
  if (loading) return null

  return (
        <div className={classes.mainContainer}>
            <Typography variant="h2">
                Frequently Asked Questions
            </Typography>
            {loading
              ? (
                <div
                    style={{
                      display: 'flex',
                      height: '70vh',
                      width: '100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      background: '#FFFFFF'
                    }}
                >
                    <CircularProgress />
                </div>
                )
              : (
                <>
                    {faqs.map((faq, index) => (
                        <div key={index}>
                            <Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }} key={'faqQuestion' + index}>
                                {index + 1}. {faq.question}
                            </Typography>
                            <Typography variant="body1" style={{ marginTop: '20px', whiteSpace: "pre-wrap" }} key={'faqAnswer' + index}>
                                {faq.answer}
                            </Typography>
                        </div>
                    ))}
                </>
                )}
        </div>
  )
}

const styles = makeStyles(({ breakpoints, palette }) => ({
  flex: {
    display: 'flex',
    alignItems: 'center'
  },
  flexGrow: {
    flex: '1'
  },
  mainContainer: {
    padding: '40px',
    paddingBottom: '60px'
  }
}))

export default FAQs
