import axios from 'axios'

/**
 * @function gatheringInfo Used to get the gatherings information
 * @param {string} gatheringId - gathering's id
 * @param {string} nrctUID - user's current neighborhood id
 * @param {string} jwt - user's jwt token
 * @returns {Promise} Promise object represents member data
 */
export const gatheringInfo = (gatheringId, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/gatherings/info`, {
      params: {
        gathering: gatheringId
      },
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  })
}
