import React, { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { AddCircle } from '@mui/icons-material'
import {
  useMediaQuery,
  ButtonGroup,
  Button,
  useTheme
} from '@mui/material'
import useDimensions from 'react-use-dimensions'
import { useNavigate } from 'react-router-dom'

// importing components
import AllGroups from './AllGroups'
import MyGroups from './MyGroups'
import CreateGroupModal from './CreateGroupModal'
import GGBTabButtonGroup from '../GGBTabButtonGroup'

// importing helpers
import { recordGaEvent } from 'helpers/ga'

function Group (props) {
  const [ref, { width }] = useDimensions()
  const [value, setValue] = useState(0)
  const classes = styles()
  const navigate = useNavigate()

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <div className={classes.mainContainer} ref={ref}>
      {width < 600 && (
        <div className={classes.tabs}>
          <GGBTabButtonGroup />
        </div>
      )}
      <div className={classes.tabs}>
        <Button
          color="primary"
          variant="contained"
          startIcon={<AddCircle />}
          onClick={() => {
            setValue((currTab) => {
              return currTab === 2 ? 0 : 2
            })

            // analytics
            recordGaEvent('groupsPage-createGroup-clicked')
          }}
        >
          {matches ? 'Create group' : 'Create a new group'}
        </Button>
        <Button
          color={value === 1 ? 'secondary' : 'ghost'}
          variant={value === 1 ? 'contained' : 'outlined'}
          style={{
            marginLeft: 10
          }}
          onClick={() => {
            setValue((currTab) => {
              return currTab === 1 ? 0 : 1
            })
          }}
        >
          My Groups
        </Button>
      </div>
      {value === 0 && <AllGroups />}
      {value === 1 && <MyGroups />}
      <CreateGroupModal open={value === 2} onClose={() => setValue(0)} />
    </div>
  )
}

const styles = makeStyles(({ breakpoints, palette }) => ({
  tabs: {
    display: 'flex',
    marginBottom: '20px',
    marginRight: 20,
    [breakpoints.down('sm')]: {
      justifyContent: 'center',
      marginLeft: 20
    }
  },
  actionButtons: {
    padding: '10px 10px',
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 500,
    userSelect: 'none',
    height: '20px',
    [breakpoints.down('sm')]: {
      justifyContent: 'space-evenly'
    }
  },
  createGroupButton: {
    border: `2px solid ${palette.primary.alt}`,
    backgroundColor: palette.primary.alt,
    color: 'white',
    width: '160px',
    [breakpoints.down('sm')]: {
      width: '140px'
    }
  },
  createGroupIcon: {
    transform: 'rotate(45deg)',
    transition: 'transform 0.5s',
    [breakpoints.down('sm')]: {
      marginRight: '8px'
    }
  },
  createGroupIconActive: {
    transform: 'rotate(0)',
    transition: 'transform 0.5s',
    [breakpoints.down('sm')]: {
      marginRight: '8px'
    }
  },
  myGroupsButton: {
    marginLeft: '20px',
    border: '2px solid #332f30',
    color: '#332f30',
    width: '100px',
    justifyContent: 'center',
    [breakpoints.down('sm')]: {
      marginLeft: '5px'
    }
  },
  myGroupsButtonAcitve: {
    marginLeft: '20px',
    backgroundColor: palette.secondary.main,
    border: `2px solid ${palette.secondary.main}`,
    color: 'white',
    width: '100px',
    justifyContent: 'center',
    [breakpoints.down('sm')]: {
      marginLeft: '5px'
    }
  },
  sliderContainer: {
    width: '300px',
    marginLeft: '50px'
  },
  slider: {
    paddingInline: '10px'
  },
  flex: {
    display: 'flex',
    alignItems: 'center'
  },
  flexGrow: {
    flex: '1'
  }
}))

export default Group
