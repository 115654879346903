// importing apis
import { userHomeNeighbourhood, userHomeIndex } from 'api/user/home'
import { postFetch } from 'api/post/fetch'

/**
 * Action types
 * All the actions defined here will be implemented in reducer,
 * this way it's easier to manage action type strings
 */
const postReducerActionTypes = {
  postsRefreshNearby: 'POSTS_REFRESH_NEARBY',
  postsRefreshNeighborhood: 'POSTS_REFRESH_NEIGHBORHOOD',
  postRefreshNearby: 'POST_REFRESH_NEARBY',
  postRefreshNeighborhood: 'POST_REFRESH_NEIGHBORHOOD',
  postRemove: 'POST_REMOVE',
  postClearAll: 'POST_CLEAR_ALL'
}

export default postReducerActionTypes

/**
 * @function updatePostsNearbyArrayRedux Used to update the postsNearby redux state with new data
 * @param {number} pageNumber - the page number of the posts to retrieve
 * @param {strin} nrctUID - user's current neighborhood id
 * @param {string} jwt - user's jwt token
 * @returns {function}  async function to perform the action
 */
export const updatePostsNearbyArrayRedux = (pageNumber = 1, nrctUID, jwt) => {
  return async (dispatch, getState) => {
    const state = getState()
    const { postsNearby } = state.root.postsState
    try {
      // make the api call here
      const nearcastPosts = await userHomeNeighbourhood(
        pageNumber,
        18,
        nrctUID,
        jwt
      )
      // if page number is greater then 1, then add posts to the end of existing posts else replace
      let newPosts = []
      if (pageNumber > 1) {
        newPosts = [...postsNearby, ...nearcastPosts]
      } else {
        newPosts = nearcastPosts
      }
      dispatch({
        type: postReducerActionTypes.postsRefreshNearby,
        payload: {
          postsNearby: newPosts
        }
      })
    } catch (err) {
      // console.log(err);
    }
  }
}

/**
 * @function updatePostsNeighborhoodArrayRedux Used to update the postsNeighborhood redux state with new data
 * @param {number} pageNumber - the page number of the posts to retrieve
 * @param {strin} nrctUID - user's current neighborhood id
 * @param {string} jwt - user's jwt token
 * @returns {function}  async function to perform the action
 */
export const updatePostsNeighborhoodArrayRedux = (pageNumber = 1, nrctUID, jwt) => {
  return async (dispatch, getState) => {
    const state = getState()
    const { postsNeighborhood } = state.root.postsState
    try {
      // make the api call here
      const nearcastPosts = await userHomeIndex(
        pageNumber,
        18,
        nrctUID,
        jwt
      )
      // if page number is greater then 1, then add posts to the end of existing posts else replace
      let newPosts = []
      if (pageNumber > 1) {
        newPosts = [...postsNeighborhood, ...nearcastPosts]
      } else {
        newPosts = nearcastPosts
      }
      dispatch({
        type: postReducerActionTypes.postsRefreshNeighborhood,
        payload: {
          postsNeighborhood: newPosts
        }
      })
    } catch (err) {
      // console.log(err);
    }
  }
}

/**
 * @function refreshPostRedux Used to update the single post object user interacted with
 * @param {Object} postObj - the actual post object
 * @param {strin} nrctUID - user's current neighborhood id
 * @param {string} jwt - user's jwt token
 * @returns {function} async function to perform the action
 */
export const refreshPostRedux = (postObj, nrctUID, jwt) => {
  return async (dispatch, getState) => {
    const state = getState()
    const { postsNearby, postsNeighborhood } = state.root.postsState
    try {
      const isInNearby = postsNearby.find((p) => p.post_id === postObj.post_id)
      const isInNeighborhood = postsNeighborhood.find((p) => p.post_id === postObj.post_id)
      const newPost = await postFetch(postObj.post_id, nrctUID, jwt)
      const mergedPosts = {
        ...postObj,
        ...newPost
      }
      if (isInNearby) {
        dispatch({
          type: postReducerActionTypes.postRefreshNearby,
          payload: {
            post: mergedPosts
          }
        })
      }

      if (isInNeighborhood) {
        dispatch({
          type: postReducerActionTypes.postRefreshNeighborhood,
          payload: {
            post: mergedPosts
          }
        })
      }
    } catch (err) {
      // console.log(err);
    }
  }
}

/**
 * @function removePostFromRedux Call when post is deleted, to remove post from local data
 * @param {string} postId - id of the post that was removed
 * @returns {function}
 */
export const removePostFromRedux = (postId) => {
  return (dispatch, getState) => {
    dispatch({
      type: postReducerActionTypes.postRemove,
      payload: {
        postId: postId
      }
    })
  }
}

/**
 * @function postsClearAllStatesRedux Used to set redux store back to initial state ( empty state ), useful when user logs out
 * @returns {function} function to perform the action
 */
export const postsClearAllStatesRedux = () => {
  return (dispatch) => dispatch({
    type: postReducerActionTypes.postClearAll
  })
}
