import makeStyles from '@mui/styles/makeStyles'

export default makeStyles(({ breakpoints, palette }) => {
  return {
    root: {
      marginTop: 64,
      padding: '12px 3%',
      [breakpoints.down('sm')]: {
        marginTop: 0,
        marginBottom: 70
      }
    },
    cardContainer: {
      width: '100%',
      borderRadius: 10,
      boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.25)',
      position: 'relative'
    },
    imgContainer: {
      // width: "100%",
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '10px 10px 0 0',

      height: 200,
      backgroundSize: 'cover',
      backgroundPosition: 'center',

      [breakpoints.down('sm')]: {
        height: 200
      }
    },
    gc_menuIcon: {
      position: 'absolute',
      top: 10,
      right: 10
    },
    inviteBtn: {
      height: 30,
      padding: '5px 8px',
      fontWeight: 500,

      '& .MuiButton-label': {
        display: 'flex',
        alignItems: 'center'
      }
    },
    cardActionBtn: {
      width: '100%',
      fontWeight: 600,

      '& .MuiButton-label': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      },

      '&:hover': {
        '& .btnIconSecondary': {
          color: 'white'
        },
        '& .btnIconPrimary': {
          color: palette.primary.main
        }
      }
    },
    headText: {
      fontWeight: 700,
      fontSize: '1.5rem'
    },
    createAnnBtn: {
      fontSize: '1.1rem',
      fontWeight: 600,
      paddingTop: 0,
      paddingBottom: 0,

      '& .MuiButton-label': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }
    },
    gatheringsContainer: {
      display: 'flex',
      // justifyContent: "center",
      alignItems: 'center',
      overflowX: 'auto',
      padding: '5px 0'
    },
    cardDiv: {
      width: 'max-content',
      marginRight: 10
    },

    announcementCard: {
      borderRadius: 10,
      background: palette.primary.main,
      padding: 10,
      color: 'white',
      margin: '10px 0'
    },
    announcementTitle: {
      fontWeight: 600,
      fontSize: '1.1rem'
    },

    stickerButton: {
      height: '30px',
      display: 'flex',
      alignItems: 'center',
      fontWeight: 600,
      padding: '5px 10px',
      borderRadius: 5,
      cursor: 'pointer',
      marginRight: '10px',
      color: 'white',
      whiteSpace: 'nowrap',
      marginTop: 10
    }
  }
})
