import React, { useContext, useState, useEffect, useCallback, useRef } from 'react'
import {
  CircularProgress,
  Grid,
  Container,
  Stack,
  Button,
  Typography
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useChatContext } from 'stream-chat-react'
import QRCode from 'qrcode.react'

// import appStoreBlack from './appStoreBlack.svg'
// import playStoreBlack from './playStoreBlack.svg'

// import ui components
import DashboardTop from 'ui/Custom/Dashboard/DashboardTop'
import DashboardNearcasts from 'ui/Custom/Dashboard/DashboardNearcasts'
import CustomBox from 'ui/Custom/Box/Box'
import StoreDetails from 'ui/Custom/Dashboard/StoreDetails'
import OurStory from 'ui/Custom/Dashboard/OurStory'
import TopOffers from 'ui/Custom/Dashboard/TopOffers'

// importing components
import Header from '../../components/Header/Header'
// import ClaimOfferModal from '../../components/Modals/ClaimOfferModal'
import AllBusinessOffersModal from '../../components/Modals/AllBusinessOffersModal/AllBusinessOffersModal'
import appDownloadQRCode from '../../assets/Qrcode.png'
import AnonymousReviewModal from '../../components/Modals/AnonymousReviewModal/AnonymousReviewModal'

// importing context
import { UserContext } from '../../context/UserContext'
import { GlobalFuncContext } from '../../context/GlobalFuncHOC'

// importing apis
import { offers as businessOffersApiPack } from 'api/offers'
import { businessReviewsForUserPortal } from 'api/business/review/index'
import { businessDetailsGet } from 'api/business/details'
import { businessCoverView } from 'api/business/cover/view'
import { businessAvatarView } from 'api/business/avatar/view'
import { followUser as followUserApiPack, unfollowUser as unfollowUserApiPack } from 'api/follow'
import { feedData } from 'api/analytics/feed'
import { personalAccountAvatar } from 'api/personal_account/avatar'
import { signupInStoreCode } from 'api/signup/inStore'

// importing helpers
import { getUserDomainURL } from 'helpers/url'
// import { createChannelForUsers } from 'helpers/chat'
import { recordGaEvent } from 'helpers/ga'

const Business = (props) => {
  // ref
  const dashboardTopRef = useRef(null)
  const { id } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { personal, jwt_token, active_neighbourhood } = useContext(UserContext)
  const { showPleaseLoginDialog, openClaimOfferModal } = useContext(GlobalFuncContext)
  const { client } = useChatContext()
  const { enqueueSnackbar } = useSnackbar()

  // component states
  const [loading, setLoading] = useState(true)
  const [businessData, setBusinessData] = useState({})
  const [avatar, setAvatar] = useState()
  const [cover, setCover] = useState()
  const [businessOffers, setBusinessOffers] = useState([])
  // states for business's nearcast reviews
  const businessReviewStateEmpty = {
    nearcasts: [],
    loading: true,
    page: 1
  }
  const [businessReviewState, setBusinessReviewState] = useState(businessReviewStateEmpty)
  // all business offers modal states
  const [isAllBusinessOfferOpen, setIsAllBusinessOfferOpen] = useState(false)
  // anonymous review states
  const initialAnonymousReviewState = {
    isOpen: false,
    reviewPlaybackToken: ''
  }
  const [anonymousReview, setAnonymousReview] = useState(initialAnonymousReviewState)

  const getBusinessDetails = useCallback(() => {
    let nrctUID = null
    let jwtToken = null
    if (personal && jwt_token) {
      nrctUID = personal[active_neighbourhood].ID
      jwtToken = jwt_token
    }
    businessDetailsGet(id, nrctUID, jwtToken)
      .then((details) => {
        setBusinessData(details.body.business_details)
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: 'error'
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [personal, active_neighbourhood, id])

  const getBusinessReviews = useCallback((pageNumber = 1) => {
    setBusinessReviewState({
      nearcasts: [],
      loading: true,
      page: pageNumber
    })
    businessReviewsForUserPortal(
      id,
      'latest',
      pageNumber
    )
      .then((resData) => {
        const formattedNearcasts = resData.body.map((post) => ({
          postId: post.post_id,
          username: post.username,
          userCover: personalAccountAvatar(post.personal_account_id),
          postCover: post.post_image_token,
          postCaption: post.caption,
          reviewPlaybackToken: post.post_playback_token,
          businessOfferId: post.business_offer_id,
          reviewId: post.id
        }))
        setBusinessReviewState({
          nearcasts: formattedNearcasts,
          loading: false,
          page: pageNumber
        })
      })
      .catch((err) => {
        console.log(err.message)
        enqueueSnackbar("Error in retrieving business's nearcasts", { variant: 'error' })
      })
  }, [id])

  const getBusinessOffers = useCallback(() => {
    businessOffersApiPack(id)
      .then((offers) => {
        setBusinessOffers(offers || [])
      })
      .catch((err) => {
        console.log(err.message)
        enqueueSnackbar("Error in retrieving business's offers", { variant: 'error' })
      })
  }, [personal, active_neighbourhood, id])

  const onPressSendAMessage = async () => {
    if (!personal) {
      return showPleaseLoginDialog()
    }
    // analytics
    recordGaEvent('businessPublicPage-sendAMessage', {
      personalAccountId: personal[active_neighbourhood].ID,
      businessId: id,
      businessName: businessData.name
    })
    try {
      const channel = await createChannelForUsers(
        client,
        personal[active_neighbourhood].ID,
        businessData.business_account_id
      )
      navigate(`/chat?id=${channel.id}`)
    } catch (err) {
      console.log(err.message)
      if (err.message === 'sameReceiverAndSenderId') {
        return enqueueSnackbar('You cannot send a message to yourself!', { variant: 'error' })
      }
    }
  }

  const onPressUnfollowUser = () => {
    if (!personal) {
      showPleaseLoginDialog()
    }
    // analytics
    recordGaEvent('businessPublicPage-unfollowBusiness', {
      personalAccountId: personal[active_neighbourhood].ID,
      businessId: id,
      businessName: businessData.name
    })
    unfollowUserApiPack(businessData.business_account_id, personal[active_neighbourhood].ID, jwt_token)
      .then(() => getBusinessDetails())
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: 'error'
        })
      })
  }

  const onPressFollowUser = () => {
    if (!personal) {
      showPleaseLoginDialog()
    }
    // analytics
    recordGaEvent('businessPublicPage-followBusiness', {
      personalAccountId: personal[active_neighbourhood].ID,
      businessId: id,
      businessName: businessData.name
    })
    followUserApiPack(businessData.business_account_id, personal[active_neighbourhood].ID, jwt_token)
      .then(() => getBusinessDetails())
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: 'error'
        })
      })
  }

  // fetch data on the set business id
  useEffect(() => {
    if (!id) {
      return
    }

    setAvatar(businessAvatarView(id))
    setCover(businessCoverView(id))
    getBusinessDetails()
    getBusinessReviews()
    getBusinessOffers()

    // business analytics
    feedData(
      'business_public_page_views',
      {
        business_id: id,
        user_details: !!personal,
        user_id: personal ? personal[active_neighbourhood].ID : null,
        neighbourhood_id: personal ? personal[active_neighbourhood].lives_in_id : null,
        created_at: new Date().toISOString()
      }
    )
  }, [jwt_token, id, personal, active_neighbourhood])

  useEffect(() => {
    if (loading) {
      return
    }
    /**
     * Use the side effect for...
     * 1. To automatically open View All Offers if the route ends with `/allOffers`
     * 2. To open a specific offer if the route ends with a query of key `redemption_code`
     */

    // 1
    if (location.pathname.includes('allOffers')) {
      // analytics
      recordGaEvent('businessPublicPage-allOffersQRCodeScan', {
        personalAccountId: personal ? personal[active_neighbourhood].ID : null,
        businessId: id,
        businessName: businessData.name
      })
      setIsAllBusinessOfferOpen(true)
      return
    }

    // 2
    const redemptionCode = new URLSearchParams(location.search).get('redemption_code')
    if (redemptionCode) {
      // analytics
      recordGaEvent('businessPublicPage-openOfferWithCode', {
        personalAccountId: personal ? personal[active_neighbourhood].ID : null,
        businessId: id,
        couponCode: redemptionCode,
        businessName: businessData.name
      })
      signupInStoreCode(redemptionCode)
        .then((offer) => {
          openClaimOfferModal({
            cover: offer.cover_image,
            title: offer.title,
            description: offer.description,
            nearcoins: 100, // this is hardcoded because we are not getting this from api/v1/signup/in-store/code
            redemption: redemptionCode,
            link: offer.offer_link
          })
        })
        .catch((err) => console.log(err.message))
    }
  }, [loading])

  // use this for disabling send message and follow buttons
  let isSameUser = false
  if (personal && jwt_token && businessData.business_account_id) {
    isSameUser = personal[active_neighbourhood].ID === businessData.business_account_id
  }

  return (
    <>
      <Header />
      {loading
        ? (
        <div
          style={{
            position: 'fixed',
            height: '100vh',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#FFF',
            zIndex: 1000
          }}
        >
          <CircularProgress />
        </div>
          )
        : (
          <Container maxWidth="xl" sx={{ mt: '74px', marginBottom: '74px' }}>
            <Grid container spacing={2}>
              <Grid
                container
                spacing={2}
                item
                xs={12}
                md={12}
                lg={8}
                style={{
                  height: 'min-content'
                }}
              >
                <Grid item xs={12} ref={dashboardTopRef}>
                  <DashboardTop
                    key={businessData.id}
                    name={businessData.name}
                    website={businessData.custom_url}
                    avatar={avatar}
                    cover={cover}
                    dashboardActionContent={(
                      <Stack
                        sx={{
                          height: '100%'
                        }}
                        direction={{
                          xs: 'column',
                          md: 'row'
                        }}
                        justifyContent="flex-end"
                        spacing={1}
                      >
                        {/* <Button
                          variant="outlined"
                          size='small'
                          onClick={onPressSendAMessage}
                          disabled={isSameUser}
                        >
                          Send a Message
                        </Button> */}
                        <Button
                          variant="contained"
                          disableElevation
                          style={{
                            marginLeft: 8
                          }}
                          size='small'
                          onClick={() => {
                            if (businessData.is_following) {
                              onPressUnfollowUser()
                            } else {
                              onPressFollowUser()
                            }
                          }}
                          disabled={isSameUser}
                        >
                          {businessData.is_following ? 'Unfollow' : 'Become a Fan'}
                        </Button>
                      </Stack>
                    )}
                  />
                </Grid>
                {/* Left Column - Business Details & Story */}
                <Grid item xs={12} md={5} lg={5}>
                  {/* Store Details */}
                  <StoreDetails
                    isForBusinessPortal={false}
                    address={businessData.address_line}
                    schedule={{
                      value: businessData.business_schedules
                    }}
                    businessMobile={{
                      value: businessData.phone
                    }}
                    website={{
                      value: businessData.website
                    }}
                  />
                  {/* Our Story */}
                  <OurStory
                    isForBusinessPortal={false}
                    story={{
                      value: businessData.story
                    }}
                  />
                </Grid>
                {/* Business Reviews section */}
                <Grid item xs={12} md={7} lg={7}>
                  <DashboardNearcasts
                    loading={businessReviewState.loading}
                    nearcasts={businessReviewState.nearcasts}
                    page={businessReviewState.page}
                    onPressPrevious={() => getBusinessReviews(--businessReviewState.page)}
                    onPressNext={() => getBusinessReviews(++businessReviewState.page)}
                    onPressNearcastCard={(nearcast) => {
                      // google analytics
                      recordGaEvent('businessPublicPage-reviewClick', {
                        personalAccountId: personal ? personal[active_neighbourhood].ID : null,
                        businessId: id,
                        businessName: businessData.name,
                        reviewType: nearcast.postId ? 'non-Anonymous' : 'Anonymous',
                        reviewPlaybackToken: nearcast.reviewPlaybackToken,
                        postId: nearcast.postId
                      })
                      // business analytics
                      feedData(
                        'business_offers_reviews_views',
                        {
                          user_id: personal ? personal[active_neighbourhood].ID : null,
                          offer_id: nearcast.businessOfferId,
                          review_id: nearcast.reviewId,
                          created_at: new Date().toISOString()
                        }
                      )
                      // open the actual post
                      if (nearcast.postId === null) {
                        return setAnonymousReview({
                          isOpen: true,
                          reviewPlaybackToken: nearcast.reviewPlaybackToken
                        })
                      }
                      window.open(getUserDomainURL(`/p/${nearcast.postId}`))
                    }}
                    title="Business Reviews"
                  />
                  <AnonymousReviewModal
                    videoToken={anonymousReview.reviewPlaybackToken}
                    isOpen={anonymousReview.isOpen}
                    onClose={() => setAnonymousReview(initialAnonymousReviewState)}
                  />
                </Grid>
              </Grid>
              {/* Right Column */}
              <Grid container spacing={2} item xs={12} lg={4}>
                {/* Right Column - QR Code + Similar business */}
                <Grid item xs={12}>
                  {/* QR Code */}
                  <CustomBox>
                    <Stack
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                      spacing={2}
                      style={{
                        width: '100%',
                        height: dashboardTopRef.current !== null
                          ? dashboardTopRef.current.offsetHeight - (16 * 3)
                          : 'auto'
                      }}
                    >
                      <QRCode
                        style={{ width: 160, height: 160 }}
                        size={300}
                        value={getUserDomainURL(`/business/review/${id}`)}
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={() => navigate(`/business/review/${id}`)}
                      >
                        Create Video Review
                      </Button>
                      <Typography variant="h5" textAlign="center" sx={{ width: '80%' }}>
                        Scan this QR to share a video review about your experience with us
                      </Typography>
                      {/* <img
                        src={appDownloadQRCode}
                        style={{
                          width: 200
                        }}
                      /> */}
                      {/* <Typography variant="h6">
                        Scan this QR code to download our app
                      </Typography> */}
                      {/* <Stack direction="row" spacing={1}>
                        <a href="https://apps.apple.com/app/id1586786030" target="_blank" rel="noreferrer">
                          <img
                            src={appStoreBlack}
                            alt="App Store"
                            style={{
                              width: '100%'
                            }}
                          />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.nearcast.app" target="_blank" rel="noreferrer">
                          <img
                            src={playStoreBlack}
                            alt="Play Store"
                            style={{
                              width: '100%'
                            }}
                          />
                        </a>
                      </Stack> */}
                    </Stack>
                  </CustomBox>
                  <TopOffers
                    isForBusinessPortal={false}
                    offers={businessOffers.length === 0
                      ? null
                      : businessOffers.map((offers) => ({
                        id: offers.id,
                        cover: offers.cover,
                        title: offers.title,
                        startDate: offers.start_date,
                        couponCode: offers.in_store_redemption,
                        nearcoins: offers.nearcoins,
                        shortLink: offers.offer_short_link
                      }))}
                    offersHeaderRightAction={
                      <Button
                        variant="contained"
                        onClick={() => setIsAllBusinessOfferOpen(true)}
                        disabled={businessOffers.length < 4}
                      >
                        View All
                      </Button>
                    }
                    onOfferPress={(offer) => {
                      // analytics
                      recordGaEvent('businessPublicPage-offerClick', {
                        personalAccountId: personal ? personal[active_neighbourhood].ID : null,
                        businessId: id,
                        businessName: businessData.name,
                        offerId: offer.id,
                        offerCouponCode: offer.couponCode
                      })
                      openClaimOfferModal({
                        cover: offer.cover,
                        title: offer.title,
                        description: offer.description,
                        nearcoins: offer.nearcoins,
                        redemption: offer.couponCode,
                        link: getUserDomainURL(`/offer/${offer.couponCode}`)
                      })
                    }}
                  />
                  <AllBusinessOffersModal
                    businessId={id}
                    businessName={businessData.name}
                    isOpen={isAllBusinessOfferOpen}
                    onClose={() => setIsAllBusinessOfferOpen(false)}
                  />
                  {/* Similar business */}
                  {/* <RightItemCard
                    sx={{
                      marginTop: 2
                    }}
                    cardHeaderStyle={{
                      backgroundColor: 'white'
                    }}
                    title="Similar Businesses"
                    titleLeftAction={<></>}
                    titleRightAction={
                      <Button
                        variant="text"
                        onClick={() => {}}
                      >
                        View All
                      </Button>
                    }
                    cardContent={
                      <div style={{ marginTop: -16 }}>
                        {new Array(5).fill(1).map((_, index) => {
                          return (
                            <Card
                              key={`${index}`}
                              sx={{
                                display: 'flex',
                                marginBottom: 1,
                                cursor: 'pointer'
                              }}
                              onClick={() => {}}
                            >
                              <CardMedia
                                component="img"
                                sx={{ width: 80 }}
                                image={cover}
                                alt={''}
                              />
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column'
                                }}
                              >
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                  <Typography variant="h6">
                                    Business Name
                                  </Typography>
                                  <Typography variant="caption" component="div">
                                    10630 S De Anza Blvd
                                  </Typography>
                                </CardContent>
                              </Box>
                            </Card>
                          )
                        })}
                      </div>
                    }
                  /> */}
                </Grid>
              </Grid>
            </Grid>
          </Container>
          )}
    </>
  )
}

export default Business
