import axios from 'axios'

/**
 * @function userPosts Used to fetch the posts of the given user
 * @param {string} userId - user id of the user whose posts are required
 * @param {string} page - pagination page number (Default: 1)
 * @param {string} size - size of the page (Default: 10) (how many post details should be there in each page)
 * @param {string} nrctUID - personal[active_neighbourhood].ID from user context
 * @param {string} jwt - jwt token from user context
 * @returns {Promise} Promise object represents array of posts
 */
export const userPosts = (userId, page = 1, size = 10, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/user/posts`, {
      params: {
        page: page,
        pageSize: size,
        ts: new Date().toISOString(),
        userId: userId
      },
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(res.data.body))
      .catch((err) => reject(err))
  })
}
