import axios from 'axios'

export const signupInStoreCode = (code) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/signup/in-store/code`, {
      redemption_code: code
    })
      .then((res) => resolve(res.data.body))
      .catch((err) => reject(err))
  })
}
