import {
  AppBar,
  Avatar,
  Menu,
  MenuItem,
  Button,
  Toolbar,
  useTheme,
  useMediaQuery,
  SvgIcon,
  IconButton,
  SwipeableDrawer,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Badge,
  Stack
} from '@mui/material'
import React, { useContext, useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import Nearcast from 'ui/Icons/Nearcast'
import {
  KeyboardArrowDown,
  MenuRounded,
  NotificationsNoneRounded,
  ChatBubbleOutline
} from '@mui/icons-material'
import useDimensions from 'react-use-dimensions'
import BottomNavigation from '../BottomNavigation/BottomNavigation'
import { useChatContext } from 'stream-chat-react'

// importing context
import { UserContext } from '../../context/UserContext'

// importing components
import styles from './HeaderStyles'
import SidebarDrawer from '../UserDash/Sidebar/SidebarDrawer'
import verifiedBadge from '../../assets/unverified.svg'
import unverifiedBadge from '../../assets/verified.svg'
import AddNeighbourhood from '../Modals/AddNeighbourhood'
import ActivityMenu from './ActivityMenu'
import Tick from './tick.svg'

// importing apis
import { personalAccountAvatar } from 'api/personal_account/avatar'

// importing redux header
import { useAppSelector } from '../../utils/redux/store'

function ConditionalLink ({ small, children }) {
  const fromApp =
    new URL(document.location).searchParams.get('mobile') === 'true'

  return fromApp
    ? (
        children
      )
    : (
    <Link
      to={!fromApp ? '/' : ''}
      style={
        small
          ? {
              display: 'contents',
              justifySelf: 'center'
            }
          : {}
      }
    >
      {children}
    </Link>
      )
}

function Header (props) {
  const { client } = useChatContext()
  const classes = styles()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const theme = useTheme()
  const small = useMediaQuery(theme.breakpoints.down('md'))
  const activityState = useAppSelector((state) => state.root.activityState)
  const [open, setOpen] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const {
    logout,
    personal,
    active_neighbourhood,
    switchNeighbourHood,
    user
  } = useContext(UserContext)
  const [usernameRef, usernameProps] = useDimensions()
  const [addNeighbourhoodOpen, setAddNeighbourhoodOpen] = useState(false)
  const [isActivityAnchor, setIsActivityAnchor] = useState(null)
  // message unread count
  const [unreadMessageCount, setUnreadMessageCount] = useState(0)

  /**
   * Onboard the bottom navigation in all places
   * where Header is used but width is less then
   * 500
   */
  if (window.innerWidth < 600) {
    return <BottomNavigation />
  }

  // useEffect(() => {
  //   const clientEventListener = client.on((event) => {
  //     if (event.total_unread_count !== null && event.total_unread_count !== undefined) {
  //       setUnreadMessageCount(Number(event.total_unread_count))
  //     }
  //     if (event.type === 'health.check' && event.me !== undefined) {
  //       if (event.me.total_unread_count !== undefined) {
  //         setUnreadMessageCount(Number(event.me.total_unread_count))
  //       }
  //     }
  //   })
  //   return () => {
  //     clientEventListener.unsubscribe()
  //   }
  // }, [personal, active_neighbourhood])

  // We don't need the following filtering if the user is not logged in
  let nonBusinessProfiles = []
  let businessProfiles = []
  if (personal !== null) {
    nonBusinessProfiles = personal.filter((profile) => !profile.is_business)
    businessProfiles = personal.filter((profile) => profile.is_business)
  }

  return (
    <AppBar className={classes.root} elevation={0}>
      <Toolbar className={classes.toolbar}>
        {small && (
          <div style={{ width: personal === null ? '75px' : 'auto' }}>
            <IconButton
              edge="start"
              onClick={() => {
                setDrawerOpen(true)
              }}
              style={{ color: 'black', borderColor: 'black' }}
              size="large">
              <MenuRounded />
            </IconButton>
          </div>
        )}
        <ConditionalLink small={small}>
          {small
            ? (
            <Nearcast
              height={27}
              className={classes.nearcastLogo}
              htmlColor={'#0C3A2D'}
            />
              )
            : (
            <Nearcast
              height={34}
              className={classes.nearcastLogo}
              htmlColor={'#0C3A2D'}
            />
              )}
        </ConditionalLink>
        <div className={classes.cta}>
          {personal === null
            ? (
            <Button
              onClick={() => {
                // This component is only used after user has logged in or for public pages
                // so if login is showed, then on click pass current pathname as state
                // this will make sure user comes back on same page after login or sign up
                if (pathname) {
                  navigate('/login', {
                    state: { pathname: pathname }
                  })
                } else {
                  navigate('/login')
                }
              }}
              className="login"
              variant="text"
            >
              Login
            </Button>
              )
            : (
            <>
              <div className={classes.userDetailsContainer} >
                {/* <IconButton
                  style={{ marginRight: '18px' }}
                  onClick={() => navigate('/chat')}
                  size="large">
                  <Badge badgeContent={unreadMessageCount} color="primary">
                    <ChatBubbleOutline />
                  </Badge>
                </IconButton> */}
                <IconButton
                  style={{ marginRight: '20px' }}
                  onClick={(e) => setIsActivityAnchor(e.currentTarget)}
                  size="large">
                    <Badge badgeContent={activityState.recent.length} color="primary">
                      <NotificationsNoneRounded />
                    </Badge>
                </IconButton>
                <div
                  className={classes.userDetailsContainer}
                  onClick={(e) => {
                    setOpen(e.currentTarget)
                  }}
                >
                  <Avatar
                    aria-controls="user-controls"
                    alt={personal[active_neighbourhood].userName}
                    style={{
                      width: 35,
                      height: 35,
                      borderWidth: 1,
                      borderStyle: 'solid',
                      borderColor: '#1A6751'
                    }}
                    src={personal[active_neighbourhood].is_business
                      ? personal[active_neighbourhood].business_account_avatar
                      : personal[active_neighbourhood].personal_account_avatar}
                  />
                  {!small && (
                    <>
                      <div ref={usernameRef} className={classes.userName}>
                        {personal[active_neighbourhood].username.split('-')[0]}
                      </div>
                      <SvgIcon htmlColor="#00000070">
                        <KeyboardArrowDown />
                      </SvgIcon>
                    </>
                  )}
                </div>
              </div>
              <ActivityMenu
                open={isActivityAnchor}
                onClose={() => setIsActivityAnchor(false)}
              />
              <Menu
                id="user-controls"
                anchorEl={open}
                open={Boolean(open)}
                keepMounted
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                onClose={() => {
                  setOpen(null)
                }}
                className="dropdown-menu"
              >
                <div style={{ position: 'relative', width: 377 }}>
                  <div
                    style={{
                      position: 'absolute',
                      height: 30,
                      width: 30,
                      background: '#fff',
                      right: usernameProps.width ? usernameProps.width / 2 : 0,
                      transform: 'translate(calc(50% - 24px), -50%) rotate(45deg)',
                      boxShadow: 'rgb(0 0 0 / 5%) -3px -3px 8px 0px',
                      clipPath: 'polygon(-65px -75px, 0px 100%, 100% 0px)',
                      borderTopLeftRadius: 5,
                      top: 0
                    }}
                  ></div>
                </div>
                <div
                  style={{
                    width: 377,
                    maxWidth: '100%',
                    margin: 0,
                    padding: 0,
                    borderRadius: 20,
                    maxHeight: '70vh',
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    scrollbarWidth: 'none'
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      navigate(`/u/${personal[active_neighbourhood].ID}`)
                      setOpen(false)
                    }}
                    style={{
                      padding: 16,
                      overflow: 'hidden',
                      borderTopLeftRadius: 20,
                      borderTopRightRadius: 20
                    }}
                  >
                    <Stack sx={{ width: '100%' }} direction="row" alignItems="center" justifyContent="space-between">
                      <Stack direction="row" alignItems="center">
                        <Avatar
                          alt={personal[active_neighbourhood].userName}
                          style={{
                            width: 57,
                            height: 57,
                            borderWidth: 1,
                            borderStyle: 'solid',
                            borderColor: '#1A6751',
                            marginRight: 16
                          }}
                          src={personal[active_neighbourhood].is_business
                            ? personal[active_neighbourhood].business_account_avatar
                            : personal[active_neighbourhood].personal_account_avatar}
                        />
                        <Typography
                          variant="h5"
                          style={{
                            color: '#178747'
                          }}
                        >
                          {personal[active_neighbourhood].username.split('-')[0]}
                        </Typography>
                      </Stack>
                      <img
                        style={{
                          width: 40,
                          height: 40
                        }}
                        src={Tick}
                      />
                    </Stack>
                  </MenuItem>
                  {personal[active_neighbourhood].is_business && (
                    <>
                      <Divider sx={{ margin: '0px !important' }} />
                      <MenuItem
                        onClick={() => {
                          switchNeighbourHood(personal.findIndex((profile) => profile.ID === nonBusinessProfiles[0].ID))
                          setOpen(null)
                          navigate('/home')
                        }}
                        style={{
                          padding: 16,
                          overflow: 'hidden'
                        }}
                      >
                        <Stack sx={{ width: '100%' }} direction="row" alignItems="center" justifyContent="space-between">
                          <Stack direction="row" alignItems="center">
                            <Avatar
                              alt={personal[active_neighbourhood].userName}
                              style={{
                                width: 57,
                                height: 57,
                                borderWidth: 1,
                                borderStyle: 'solid',
                                borderColor: '#1A6751',
                                marginRight: 16
                              }}
                              src={personalAccountAvatar(personal[active_neighbourhood]
                                ? personal[active_neighbourhood].ID
                                : personal[0].ID)}
                            />
                            <Typography
                              variant="h5"
                              style={{
                                color: '#178747'
                              }}
                            >
                              {user.first_name}
                            </Typography>
                          </Stack>
                        </Stack>
                      </MenuItem>
                    </>
                  )}
                  {nonBusinessProfiles &&
                    nonBusinessProfiles.map((nonBusinessProfile, i) => (
                      <div
                        key={nonBusinessProfile.ID}
                        onClick={() => {
                          switchNeighbourHood(personal.findIndex((profile) => profile.ID === nonBusinessProfile.ID))
                          setOpen(null)
                          navigate('/home')
                        }}
                        className={classes.neighborhoods}
                        selected={false}
                        style={{ marginBottom: 16 }}
                      >
                        <div className={classes.neighborhoodsListItem} style={{
                          borderColor: nonBusinessProfile.ID === personal[active_neighbourhood].ID ? '#3A8F78' : '#a9a9a9'
                        }}>
                          <div
                            style={{
                              marginRight: '9px',
                              fontSize: '13px',
                              lineHeight: '18px',
                              color: '#445870'
                            }}
                          >
                            {nonBusinessProfile.neighbourhood ? nonBusinessProfile.neighbourhood.neighbourhood : ''}
                          </div>
                          <div
                            style={{
                              width: 30,
                              height: 30
                            }}
                          >
                            <img
                              style={{
                                maxWidth: '100%',
                                maxHeight: '100%',
                                transform: 'scale(1.2)'
                              }}
                              src={
                                nonBusinessProfile?.is_verified ? verifiedBadge : unverifiedBadge
                              }
                              alt="verification badge"
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  <div
                    className={classes.neighborhoods}
                    selected={false}
                    style={{ marginBottom: 16 }}
                    onClick={() => setAddNeighbourhoodOpen(true)}
                  >
                    <div
                      className={classes.neighborhoodsListItem}
                      style={{ background: '#FFFAF2' }}
                    >
                      <div
                        style={{
                          marginRight: '9px',
                          fontSize: '13px',
                          lineHeight: '18px',
                          color: '#CA4A4A'
                        }}
                      >
                        Add a new neighborhood
                      </div>
                      <p
                        style={{
                          margin: '0px 10px',
                          fontSize: 20,
                          color: '#CA4A4A'
                        }}
                      >
                        +
                      </p>
                    </div>
                  </div>
                  {/* <Divider />
                  {businessProfiles.length > 0 && businessProfiles.map((profile) => {
                    if (personal[active_neighbourhood].ID === profile.ID) {
                      return null
                    }
                    return (
                      <>
                        <MenuItem
                          key={`business-${profile.ID}`}
                          onClick={() => {
                            switchNeighbourHood(personal.findIndex((p) => p.ID === profile.ID))
                            setOpen(null)
                            navigate('/home')
                          }}
                          style={{
                            padding: 16
                          }}
                        >
                          <ListItemAvatar>
                            <Avatar
                              key={`business-${profile.ID}`}
                              src={profile.business_account_avatar}
                              style={{
                                width: 57,
                                height: 57,
                                borderWidth: 1,
                                borderStyle: 'solid',
                                borderColor: '#1A6751',
                                marginRight: 16
                              }}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Typography
                                variant="h5"
                                style={{
                                  color: personal[active_neighbourhood].ID === profile.ID ? '#178747' : 'black'
                                }}
                              >
                                {profile.username}
                              </Typography>
                            }
                          />
                        </MenuItem>
                        <Divider sx={{ margin: '0px !important' }} />
                      </>
                    )
                  })} */}
                  <MenuItem
                    onClick={() => {
                      logout()
                      navigate('/')
                      setOpen(false)
                    }}
                    style={{
                      color: '#CA4A4A',
                      textAlign: 'center',
                      padding: 16
                    }}
                  >
                    <p
                      style={{
                        textAlign: 'center',
                        width: '100%',
                        margin: 0
                      }}
                    >
                      Sign Out
                    </p>
                  </MenuItem>
                </div>
              </Menu>
            </>
              )}
        </div>
      </Toolbar>
      <SwipeableDrawer
        anchor="left"
        variant="temporary"
        open={drawerOpen}
        onOpen={() => {
          setDrawerOpen(true)
        }}
        onClose={() => {
          setDrawerOpen(false)
        }}
      >
        <SidebarDrawer />
      </SwipeableDrawer>

      <AddNeighbourhood
        open={addNeighbourhoodOpen}
        onClose={() => setAddNeighbourhoodOpen(false)}
      />
    </AppBar>
  )
}

export default Header
