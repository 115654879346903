import React, { Button, IconButton, useTheme } from '@mui/material'
import {
  Mic,
  MicOff,
  Videocam,
  VideocamOff,
  Chat,
  PanTool,
  RadioButtonChecked
} from '@mui/icons-material'

function Controls ({
  setSidebar,
  sidebar,
  isHandRaised,
  raiseHand,
  video,
  isAudioMuted,
  setIsAudioMuted,
  isVideoMuted,
  setIsVideoMuted,
  localUser,
  setOpen,
  media,
  record,
  setRecord
}) {
  const { palette } = useTheme()

  const renderSwitchGatheringType = () => {
    if (localUser.current.isHost && !media.small) {
      return (
        <Button
          onClick={() => setOpen(true)}
          style={{
            position:
              media.medium || (media.large && sidebar)
                ? 'relative'
                : 'absolute',
            borderRadius: 10,
            backgroundColor: palette.primary.main,
            color: '#FFFFFF',
            right: 6,
            padding: '14px 18px',
            fontSize: 14,
            textTransform: 'none',
            fontWeight: 'bold',
            marginLeft: media.medium || (media.large && sidebar) ? 'auto' : 1
          }}
        >
          {video ? 'Switch to Audio Gathering' : 'Switch to Video Gathering'}
        </Button>
      )
    }
    return null
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'all 0.4s ease-in-out',
        width: media.medium || !sidebar ? 'calc(100%)' : 'calc(100% - 422px)',
        position: 'fixed',
        bottom: -6,
        height: 76,
        zIndex: 10
      }}
    >
      <div style={{ display: 'flex' }}>
        <div
          style={{
            padding: 4,
            borderRadius: '25px/50%',
            backgroundColor: '#FFFFFF',
            border: '1px solid #f6f6f6',
            boxShadow: '0 6px 20px rgba(169,169,169,0.25)',
            width: media.small ? 'min(340px, 95%)' : 'auto',
            display: 'flex',
            justifyContent: 'space-around',
            margin: '0 2px'
          }}
        >
          <IconButton
            color={isHandRaised ? 'primary' : 'default'}
            onClick={() => {
              raiseHand()
            }}
            style={{
              borderRadius: 24,
              width: media.small ? 52 : 70,
              marginInline: '5px'
            }}
            size="large">
            <PanTool />
          </IconButton>
          <IconButton
            color={sidebar ? 'primary' : 'default'}
            onClick={() => {
              setSidebar((prev) => !prev)
            }}
            style={{
              borderRadius: 24,
              width: media.small ? 52 : 70,
              marginInline: '5px'
            }}
            size="large">
            <Chat />
          </IconButton>
        </div>
        {localUser.current.isHost
          ? (
          <div
            style={{
              padding: 4,
              borderRadius: '25px/50%',
              backgroundColor: '#FFFFFF',
              border: '1px solid #f6f6f6',
              boxShadow: '0 6px 20px rgba(169,169,169,0.25)',
              width: media.small ? 'min(340px, 95%)' : 'auto',
              display: 'flex',
              justifyContent: 'space-around',
              margin: '0 2px'
            }}
          >
            <>
              <IconButton
                color={isAudioMuted ? 'primary' : 'default'}
                onClick={setIsAudioMuted}
                style={{
                  borderRadius: 24,
                  width: media.small ? 52 : 70,
                  marginInline: '5px'
                }}
                size="large">
                {isAudioMuted ? <MicOff /> : <Mic />}
              </IconButton>
              <IconButton
                color={video && isVideoMuted ? 'primary' : 'default'}
                onClick={video ? setIsVideoMuted : () => setOpen(true)}
                style={{
                  borderRadius: 24,
                  width: media.small ? 52 : 70,
                  marginInline: '5px'
                }}
                size="large">
                {isVideoMuted && video ? <VideocamOff /> : <Videocam />}
              </IconButton>
            </>
          </div>
            )
          : (
          <></>
            )}
        {/* {localUser.current.role === 1 && (
          <div
            style={{
              padding: 4,
              borderRadius: '25px/50%',
              backgroundColor: '#FFFFFF',
              border: '1px solid #f6f6f6',
              boxShadow: '0 6px 20px rgba(169,169,169,0.25)',
              width: media.small ? 'min(340px, 95%)' : 'auto',
              display: 'flex',
              justifyContent: 'space-around',
              margin: '0 2px'
            }}
          >
            <IconButton
              onClick={() => {
                setRecord()
              }}
              style={{
                borderRadius: 24,
                width: media.small ? 52 : 70
              }}
              size="large">
              <RadioButtonChecked
                htmlColor={record ? '#FD2821' : 'default'}
              />
            </IconButton>
          </div>
        )} */}
      </div>
      {renderSwitchGatheringType()}
    </div>
  )
}

export default Controls
