import axios from 'axios'

/**
 * @function gatheringsCreate Use to create a new group
 * @param {} finalGatheringType - ...
 * @param {} gatheringGroups - ...
 * @param {number} distance - the distance in miles
 * @param {string} name - group name
 * @param {string} description - group description
 * @param {} endDate - date when the gathering will end
 * @param {} start - gathering start date
 * @param {} end - gathering end time
 * @param {Object} coverImage - input file upload
 * @param {string} nrctUID - personal[active_neighbourhood].ID from user context
 * @param {string} jwt - jwt token from user context
 * @returns {Promise} Promise object represents true for successful group creation
 */
export const gatheringsCreate = (
  finalGatheringType,
  gatheringGroups,
  distance,
  name,
  description,
  endDate,
  start,
  end,
  coverImage = null,
  nrctUID,
  jwt
) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/gatherings/create`, {
      name: name,
      description: description,
      gathering_type: finalGatheringType,
      groups: gatheringGroups,
      is_private: false,
      hosts: [],
      distance: distance,
      recurring: 0,
      recurrence_end_date: endDate,
      starts_at: start,
      ends_at: end
    }, {
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then(async (res) => {
        if (coverImage !== null) {
          axios.put(res.data.presign_url, coverImage, {
            headers: {
              'Content-type': coverImage.type,
              'Access-Control-Allow-Origin': '*'
            }
          })
        }
        resolve(res.data.gathering)
      })
      .catch((err) => reject(err))
  })
}
