import React, { useContext, useEffect, useState } from 'react'
import { CircularProgress, Typography, Grid } from '@mui/material'
import { Delete, Share } from '@mui/icons-material'
import makeStyles from '@mui/styles/makeStyles'
import { useSnackbar } from 'notistack'
import ImageCoverCard from 'ui/Cards/ImageCoverCard'
import { getFormattedDate } from '../../../utils/dateTimeParser'

// importing context
import { UserContext } from '../../../context/UserContext'
import { GlobalFuncContext } from '../../../context/GlobalFuncHOC'

// importing components
import ActionModal from '../../Modals/ActionModal'
import GatheringEmptyState from '../../EmptyStates/GatheringGroupEmptyState'
import cookIcon from '../../../assets/Fill.svg'
import castIcon from '../../../assets/nearcast.svg'

// importing apis
import { gatheringsMy } from 'api/gatherings/my'
import { gatheringsDelete } from 'api/gatherings/delete'
import { gatheringsAvatar } from 'api/gatherings/avatar'

function MyGatherings(props) {
  const [gatherings, setGatherings] = useState([])
  const [loading, setLoading] = useState(true)
  const [deleteOpen, setDeleteOpen] = useState()
  const { personal, jwt_token, active_neighbourhood } = useContext(UserContext)
  const { openShareModal } = useContext(GlobalFuncContext)
  const classes = styles()
  const { enqueueSnackbar } = useSnackbar()

  const deleteGatheringApi = (id) => {
    gatheringsDelete(id, personal[active_neighbourhood].ID, jwt_token)
      .then((res) => {
        fetchMyGatheringsApi()
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: 'error'
        })
      })
  }

  const fetchMyGatheringsApi = () => {
    gatheringsMy(personal[active_neighbourhood].ID, jwt_token)
      .then((dataGathering) => {
        if (dataGathering === null) {
          return
        }
        const gatherings = dataGathering.filter((e) => {
          gatheringsAvatar(e.id, null, personal[active_neighbourhood].ID, jwt_token)
          const ends = new Date(e.ends_at)
          return Date.now() - ends <= 0
        })
        setGatherings(gatherings)
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: 'error'
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    setLoading(true)
    fetchMyGatheringsApi()
    // eslint-disable-next-line
  }, [active_neighbourhood]);

  return (
    <Grid container spacing={2} className={`${classes.gatheringsGrid}`}>
      {loading
        ? (
          <div
            style={{
              display: 'flex',
              height: '70vh',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              background: '#FFFFFF'
            }}
          >
            <CircularProgress />
          </div>
        )
        : gatherings && gatherings.length > 0
          ? (
            gatherings.map((item, index) => {
              return (
                <Grid
                  item
                  key={item.id}
                  style={{ cursor: 'pointer' }}
                >
                  <ImageCoverCard
                    imgUrl={gatheringsAvatar(item.id)}
                    title={item.name}
                    subTitle={`happened on ${getFormattedDate(item.starts_at)}`}
                    onPress={() => { }}
                    menuItems={[
                      {
                        title: 'Share',
                        color: 'ghost',
                        onPress: () => openShareModal(
                          'Gathering',
                          'Check out this gathering at Nearcast',
                          `/gathering/${item.id}`
                        ),
                        icon: () => <Share color="ghost" />
                      },
                      {
                        title: 'Delete',
                        color: 'error',
                        onPress: () => setDeleteOpen(item),
                        icon: () => <Delete color="error" />
                      }
                    ]}
                  />
                </Grid>
              )
            })
          )
          : (
            <GatheringEmptyState
              mainImage={require('../../../assets/nogathering.webp')}
              emptyMessage={
                <div style={{ textAlign: 'center', marginTop: '16px' }}>
                  <Typography variant="h3">
                    Start a gathering and meet incredible people around you 🤗
                  </Typography>
                  <Typography style={{ fontSize: '15px', marginTop: '10px' }}>
                    Gatherings are a place to listen in on fascinating conversations, talk to your neighbors, and host live events near you as audio or video livestreams
                  </Typography>
                </div>
              }
              listItems={[
                { icon: cookIcon, text: 'Livestream a cooking class' },
                { icon: castIcon, text: 'Engage with your neighbors' }
              ]}
            />
          )}
      <ActionModal
        open={Boolean(deleteOpen)}
        onClose={() => setDeleteOpen(false)}
        onConfirm={() => {
          deleteGatheringApi(deleteOpen.id)
          setDeleteOpen(false)
        }}
        onCancel={() => {
          setDeleteOpen(false)
        }}
        action={{
          title: 'Do you want to delete this gathering',
          Accept: 'Yes',
          Decline: 'No'
        }}
      >
        <Delete style={{ fontSize: '2.5rem' }} color="error" />
      </ActionModal>
    </Grid>
  )
}

const styles = makeStyles(({ breakpoints }) => ({
  mainContainer: {
    [breakpoints.down('sm')]: {
      marginInline: '5px'
    }
  },
  gatheringsGrid: {
    marginBottom: '40px',
    [breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  }
}))

export default MyGatherings
