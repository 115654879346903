import axios from 'axios'

/**
 * @function gatheringPreview Used to retrieve the preview of the gathering
 * @param {string} gatheringId - the id of the gathering
 * @returns {Promise} Promise object represents
 */
export const gatheringPreview = (gatheringId) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/gatherings/preview`, {
      params: {
        gathering: gatheringId
      }
    })
      .then((res) => resolve(res.data))
      .catch((err) => {
        reject(err)
      })
  })
}
