import axios from 'axios'

export const businessResponse = (id) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BUSINESS_URL}/business-view/response`, {
      params: {
        response_id: id
      }
    })
      .then((res) => {
        if (res.data) {
          resolve(res.data.body)
        } else {
          throw new Error('Unable to login')
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}
