import axios from 'axios'

import { alsoPublicRouteHeadersFormat } from '../helpers'

// importing helpers (package)
import { getFileUploadProgressInPercentage } from 'helpers/browser'

export interface postReferCreateBodyType {
  sticker: any[];
  phone?: string;
  isFor: 'business' | 'offer';
  coupon?: string;
  businessId?: string;
}

export const postReferCreate = (
  body: postReferCreateBodyType,
  nearcastVideo: File,
  onVideoUploadProgress = (percentage: number) => {},
  nrctUID = null,
  jwt = null
) => {
  // make sure coupon or businessId is sent in sync with isFor
  if (body.isFor === 'business') {
    if (!body.businessId) {
      throw new Error('businessId missing for isFor business')
    }
  }
  if (body.isFor === 'offer') {
    if (!body.coupon) {
      throw new Error('coupon missing for isFor offer')
    }
  }
  return new Promise((resolve, reject) => {
    let data = {}
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/post/refer/create`, {
      caption: '',
      device_information: {
        device_width: 300.0,
        device_height: 500.0,
        sticker_size: 100.0,
        sticker_max_scale: 2.0,
        sticker_min_scale: 0.5
      },
      stickers: body.sticker,
      phone: body.phone,
      is_main_business_review: body.isFor === 'business',
      redemption_code: body.coupon, // body.isFor === 'business' => false
      business_id: body.businessId // body.isFor === 'business' => true
    }, {
      headers: alsoPublicRouteHeadersFormat(nrctUID, jwt)
    })
      .then((res) => {
        data = res.data.body
        return res.data.body.URL
      })
      .then(async (uploadUrl) => {
        await axios.put(uploadUrl, nearcastVideo, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': nearcastVideo.type,
            'Transfer-Encoding': 'chunked'
          },
          onUploadProgress: (progressEvent) => {
            const uploadPercentage = getFileUploadProgressInPercentage(progressEvent)
            onVideoUploadProgress(uploadPercentage)
          }
        })
        resolve(data)
      })
      .catch((err) => {
        console.log(err)
        reject(new Error('Error: Unable to create a new nearcast post!'))
      })
  })
}
