import { Typography } from '@mui/material'
import React from 'react'
import { featureStatus } from './SuggestFeature'

const FeatureCard = ({ req, setStep, setSelectedReq }) => {
  return (
    <div
      style={{
        background: '#f4efe7',
        borderRadius: 10,
        margin: '10px 0',
        padding: 12,
        cursor: 'pointer'
      }}
      onClick={() => {
        setStep(2)
        setSelectedReq(req)
      }}
    >
      <Typography fontSize={14} fontWeight={600}>
        {req.title}
      </Typography>
      <Typography fontSize={12} style={{ margin: '5px 0' }}>
        {req.description}
      </Typography>
      <Typography fontSize={12}>
        Status:{' '}
        <span style={{ color: '#00a7ff', fontWeight: 500 }}>
          {featureStatus[req.status]}
        </span>
      </Typography>
    </div>
  )
}

export default FeatureCard
