import MagnetIcon from '../FeatureIcons/magnet-icon.svg'
import PersonIcon2 from '../FeatureIcons/person-icon2.svg'
import GroupsIcon from '../FeatureIcons/groups-icon.svg'
import TransmitIcon from '../FeatureIcons/transmit-icon.svg'
import SpeakerIcon from '../FeatureIcons/speaker-icon.svg'

const features = [
  {
    iconSrc: TransmitIcon,
    head: 'Build brand loyalty through local livestreams.',
    headHighlight: '',
    description:
			'Livestream what makes you special to nearby residents.  For example, showcase a cooking demonstration, or host an interactive video conversation with your head chef.'
  },
  {
    iconSrc: GroupsIcon,
    head: 'Find regular and repeat customers.',
    headHighlight: '',
    description:
			'Discover customers are more likely to come to your business and support you because they live nearby and develop a better appreciation of what makes you special.'
  },
  {
    iconSrc: SpeakerIcon,
    head: 'Enable your customers to promote you.',
    headHighlight: '',
    description:
			'Empower your customers to create short-videos of their experience at your business and pick the best ones and embed them on your social media.'
  },
  {
    iconSrc: PersonIcon2,
    head: 'Develop a relationship with your customers.',
    headHighlight: '',
    description:
			'Host an event at your business and livestream it to the neighborhood.  Host a video-conversation with nearby residents and share something unique.'
  },
  {
    iconSrc: MagnetIcon,
    head: 'Attract new customers living nearby.',
    headHighlight: '',
    description:
			'People living nearby will discover your business when their neighbors share videos of their experiences at your business.'
  }
]

export default features
