import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

function ReportCopyright () {
  const classes = styles()

  return (
        <div className={classes.mainContainer}>
            <Typography variant="h2">
                Report Copyright Infringement
            </Typography>
            <Typography variant="body1" style={{ marginTop: '20px' }}>
                Nearcast respects copyright interests of artists and performers. If you see something that might be suspected of infringing copyrights, please email us at <a href="mailto:support@nearcast.com">support@nearcast.com</a>.
            </Typography>
        </div>
  )
}

const styles = makeStyles(({ breakpoints, palette }) => ({
  flex: {
    display: 'flex',
    alignItems: 'center'
  },
  flexGrow: {
    flex: '1'
  },
  mainContainer: {
    padding: '40px',
    paddingBottom: '60px'
  }
}))

export default ReportCopyright
