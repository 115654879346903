/* eslint-disable no-useless-computed-key */
import { makeStyles } from '@mui/styles'

export default makeStyles(({ breakpoints, palette }) => {
  return {
    root: {
      backgroundColor: '#3A8F78',
      position: 'fixed',
      height: 'auto',
      padding: '5px 20px',
      display: 'flex',
      alignItems: ' center',

      [breakpoints.down('md')]: {
        padding: '5px 10px'
      }
    },
    toolbar: {
      minHeight: 54,
      width: '100%',
      maxWidth: '1800px',
      display: 'flex'
    },
    nearcastLogo: {
      display: 'block',
      marginLeft: '20px',
      alignSelf: 'center',
      [breakpoints.down('sm')]: {
        marginInline: 'auto'
      }
    },
    cta: {
      marginLeft: 'auto',

      [breakpoints.down('sm')]: {
        marginLeft: '0px'
      },

      ['& button']: {
        fontSize: '1.1rem',
        fontWeight: 600
      }
    },
    loginBtn: {
      borderColor: 'white',
      color: 'white',
      boxSizing: 'border-box'
    },
    navLink: {
      color: 'white',
      margin: '0 20px'
    },
    light: {
      color: 'black'
    },
    navActive: {
      textDecoration: 'underline',
      color: palette.primary.main
    }
  }
})
