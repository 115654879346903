// importing action types
import activityReducerActionTypes from '../actions/activityActions'

// store design
const initialState = {
  recent: [],
  week: [],
  past: [],
  hasMoreNotifications: true,
  hasNoNotifications: false,
  timestamp: Date.now()
}

// post reducer
const postReducer = (state = initialState, action) => {
  switch (action.type) {
    case activityReducerActionTypes.activityRefresh:
      return {
        ...state,
        ...action.payload,
        timestamp: Date.now()
      }
    case activityReducerActionTypes.activityClearAll:
      return initialState
    default:
      return state
  }
}

export default postReducer
