import GiftIcon from '../FeatureIcons/gift-icon.svg'
import SellIcon from '../FeatureIcons/neighbours-icon.svg'
import MailIcon from '../FeatureIcons/mail-icon.svg'
import MagnetIcon from '../FeatureIcons/magnet-icon.svg'

const features = [
  {
    iconSrc: MagnetIcon,
    head: 'Become a local superstar.',
    headHighlight: '',
    description:
			'Attract followers while doing what you love to do. Be recognized in your own community. Develop an audience that loves your creations and wants to watch what your doing.'
  },
  {
    iconSrc: SellIcon,
    head: 'Get paid to make local business short-videos.',
    headHighlight: '',
    description:
			'Enable local businesses to hire you to create short videos that express your creativity to nearby residents. Support the businesses that you find the most interesting.'
  },
  {
    iconSrc: MailIcon,
    head: 'Get invited to exclusive events.',
    headHighlight: '',
    description:
			'As a neighborhood influencer, you will receive invites to exclusive events that are only for the most well connected people living nearby. Special perks and special places open to you.'
  },
  {
    iconSrc: GiftIcon,
    head: 'Earn rewards for your hard work.',
    headHighlight: '',
    description:
			'Get perks and earn Nearcoins that will develop your personal brand, and open you up to new opportunities and experiences within your local community.'
  }
]

export default features
