import * as React from 'react'
import {
  Stack,
  IconButton,
  Typography,
  List,
  ListSubheader,
  ListItemButton,
  ListItemText,
  Collapse
} from '@mui/material'
import {
  StorefrontRounded,
  LocationOn,
  AccessTime,
  Phone,
  Public,
  ExpandLess,
  ExpandMore
} from '@mui/icons-material'

// importing ui
import RightItemCard from '../../Cards/RightItemCard/RightItemCard'

// importing helper
import { getBusinessOpenScheduleForALl, getBusinessOpenScheduleForToday } from 'helpers/business'

interface StoreDetailsWithRefProperty {
  value: string;
  focusAction: () => {};
}

export interface StoreDetailsProps {
  isForBusinessPortal: boolean;
  address: string;
  schedule: StoreDetailsWithRefProperty;
  accountMobile?: string;
  businessMobile: StoreDetailsWithRefProperty,
  website: StoreDetailsWithRefProperty
}

const StoreDetails: React.FC<StoreDetailsProps> = (props) => {
  // const [open, setOpen] = React.useState(false)
  // const handleClick = () => {
  //   setOpen(!open)
  // }
  return (
    <RightItemCard
      title="Business Details"
      titleLeftAction={<StorefrontRounded />}
      sx={{
        marginTop: 0,
        marginBottom: 2
      }}
      cardContent={
        <Stack sx={{ padding: 1 }} spacing={2} direction="column">
          <Stack direction="row" alignItems="flex-start" spacing={1}>
            <IconButton style={{ backgroundColor: '#f4efe7', cursor: 'default' }} color="secondary">
              <LocationOn />
            </IconButton>
            <Typography style={{ wordBreak: 'break-all' }} variant="body2">
              {props.address}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton style={{ backgroundColor: '#f4efe7', cursor: 'default' }} color="secondary">
              <AccessTime />
            </IconButton>
            <List
                sx={{ width: '100%', bgcolor: 'background.paper' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                {/* <ListItemButton onClick={handleClick}>
                  <Typography style={{ wordBreak: 'break-all' }} variant="body2">
                      {getBusinessOpenScheduleForToday(props.schedule.value)}
                  </Typography>
                  {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton> */}
                  {getBusinessOpenScheduleForALl(props.schedule.value).map((obj) => {
                    return (
                    <List component="div" disablePadding key={obj.day} >
                      <ListItemButton>
                        <Typography style={{ wordBreak: 'break-all' }} variant="body2">
                          {`${obj.day} ${obj.time}`}
                        </Typography>
                      </ListItemButton>
                    </List>
                    )
                  })}
              </List>
          </Stack>
          {props.accountMobile && (
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
            >
              <IconButton
                style={{ backgroundColor: '#f4efe7' }}
                color="secondary"
                onClick={() => {
                  if (props.accountMobile) {
                    window.open(`tel:${props.accountMobile}`, '_blank')
                  }
                }}
              >
                <Phone />
              </IconButton>
              <Typography
                variant="body2"
                onClick={() => {
                  window.open(`tel:${props.accountMobile}`, '_blank')
                }}
                style={{
                  cursor: 'pointer',
                  wordBreak: 'break-all'
                }}
              >
                {props.accountMobile}
              </Typography>
            </Stack>
          )}
          {(props.businessMobile.value || props.isForBusinessPortal) && (
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
            >
              <IconButton
                style={{ backgroundColor: '#f4efe7' }}
                color="secondary"
                onClick={() => {
                  if (props.businessMobile.value) {
                    window.open(`tel:${props.businessMobile.value}`, '_blank')
                  }
                }}
              >
                <Phone />
              </IconButton>
              {props.businessMobile.value
                ? (
                    <Typography
                      variant="body2"
                      onClick={() => {
                        window.open(`tel:${props.businessMobile.value}`, '_blank')
                      }}
                      style={{
                        cursor: 'pointer',
                        wordBreak: 'break-all'
                      }}
                    >
                      {props.businessMobile.value}
                    </Typography>
                  )
                : (
                    <Typography
                      component="div"
                      variant="body2"
                      color={(theme) => theme.palette.link.main}
                      style={{
                        cursor: 'pointer'
                      }}
                      onClick={props.businessMobile.focusAction}
                    >
                      + Add public phone number
                    </Typography>
                  )}
            </Stack>
          )}
          {(props.website.value || props.isForBusinessPortal) && (
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
            >
              <IconButton
                style={{ backgroundColor: '#f4efe7' }}
                color="secondary"
                onClick={() => {
                  const webpage = props.website.value
                  if (webpage.includes('http')) {
                    window.open(webpage, '_blank')
                    return
                  }
                  if (webpage) {
                    window.open('http://' + webpage, '_blank')
                  }
                }}
              >
                <Public />
              </IconButton>
              {props.website.value
                ? (
                  <Typography
                    variant="body2"
                    onClick={() => {
                      const webpage = props.website.value
                      if (webpage.includes('http')) {
                        window.open(webpage, '_blank')
                        return
                      }
                      if (webpage) {
                        window.open('http://' + webpage, '_blank')
                      }
                    }}
                    style={{
                      cursor: 'pointer',
                      wordBreak: 'break-all'
                    }}
                  >
                    {props.website.value.replace('https://', '').replace('http://', '')}
                  </Typography>
                  )
                : (
                  <Typography
                    component="div"
                    variant="body2"
                    color={(theme) => theme.palette.link.main}
                    style={{
                      cursor: 'pointer'
                    }}
                    onClick={props.website.focusAction}
                  >
                    + Add Website
                  </Typography>
                  )}
            </Stack>
          )}
        </Stack>
      }
    />
  )
}

export default StoreDetails
