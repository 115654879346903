import * as React from 'react'
import { Typography, Stack, CircularProgress, Button } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'

// importing components
import { GlobalFuncContext } from '../../../context/GlobalFuncHOC'
import { UserContext } from '../../../context/UserContext'

// importing ui
import WideModal from 'ui/Modals/WideModal'
import ImageContentCard from 'ui/Cards/ImageContentCard/ImageContentCard'

// importing apis
import { offers as businessOffersApiPack } from 'api/offers'

// importing helper
import { maxStringEllipses } from 'helpers/string'
import { mobileCheck } from 'helpers/browser'
import { recordGaEvent } from 'helpers/ga'

export interface AllBusinessOffersModalProps {
  businessId: string;
  businessName: string;
  isOpen: boolean;
  onClose: () => void;
}

const AllBusinessOffersModal: React.FC<AllBusinessOffersModalProps> = (props) => {
  const { enqueueSnackbar } = useSnackbar()
  const { openClaimOfferModal } = React.useContext(GlobalFuncContext)
  const { personal, activeNeighborhood } = React.useContext(UserContext)
  const navigate = useNavigate()

  // component states
  const [loading, setLoading] = React.useState(true)
  const [businessOffers, setBusinessOffers] = React.useState<any[]>([])

  const getBusinessOffers = React.useCallback(() => {
    businessOffersApiPack(props.businessId)
      .then((offers) => {
        setBusinessOffers(offers || [])
        setLoading(false)
      })
      .catch((err) => {
        console.log(err.message)
        enqueueSnackbar("Error in retrieving the business's offers", { variant: 'error' })
      })
  }, [props.businessId])

  React.useEffect(() => {
    if (props.isOpen) {
      getBusinessOffers()
    }
  }, [props.businessId, props.isOpen])

  return (
    <WideModal
      title={`Offers from ${props.businessName}`}
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      {loading
        ? (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            style={{
              width: '100%',
              marginTop: 16,
              marginBottom: 16
            }}
          >
            <CircularProgress />
          </Stack>
          )
        : (
        <Stack
          direction="row"
          justifyContent={{
            xs: 'center',
            md: 'flex-start'
          }}
          flexWrap="wrap"
        >
          {businessOffers.map((offer, index) => {
            return (
              <div
                key={offer.id}
                // ref={offersState.offers.length - 1 === i ? lastVideoElementRef : null}
              >
                <ImageContentCard
                  id={offer.id}
                  cardCover={offer.cover}
                  cardContent={
                    <Stack
                      direction="column"
                      style={{
                        minHeight: 110
                      }}
                    >
                      <Typography variant="h6">
                        {offer.title}
                      </Typography>
                      <Typography style={{ marginTop: 2, marginBottom: 2 }} variant="caption">
                        {maxStringEllipses(offer.description, 88)}
                      </Typography>
                      {/* <Typography variant="h6" color="primary" >
                        Coupon Code: {offer?.in_store_redemption}
                      </Typography> */}
                      <Typography variant="h6" color="primary" >
                        Also earn {offer?.nearcoins} Nearcoins
                      </Typography>
                      <Stack
                        direction="row"
                        style={{
                          width: '100%',
                          marginTop: 8
                        }}
                      >
                        <Button
                          variant="contained"
                          size="small"
                          disableElevation
                          style={{
                            width: '100%'
                          }}
                          onClick={() => {
                            // analytics
                            recordGaEvent('allBusOffersModal-offerClick', {
                              personalAccountId: personal ? personal[activeNeighborhood].ID : null,
                              businessId: props.businessId,
                              offerId: offer.id,
                              couponCode: offer?.in_store_redemption
                            })
                            if (mobileCheck()) {
                              // if the offer was clicked on mobile
                              return navigate(`/offer/${offer?.in_store_redemption}`)
                            }
                            openClaimOfferModal({
                              cover: offer.cover,
                              title: offer.title,
                              description: offer.description,
                              redemption: offer?.in_store_redemption,
                              nearcoins: offer.nearcoins,
                              link: offer.offer_short_link
                            })
                          }}
                          data-testid={`businessOffer-${index}`}
                        >
                          CLICK TO REDEEM
                        </Button>
                      </Stack>
                    </Stack>
                  }
                  cardMenuItems={[]}
                />
              </div>
            )
          })}
        </Stack>
          )}
    </WideModal>
  )
}

export default AllBusinessOffersModal
