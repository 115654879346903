import React, { useContext } from 'react'
import {
  Channel,
  ChannelList,
  MessageInput,
  MessageList,
  useChatContext,
  Chat,
  ChannelHeader,
  ChannelPreviewMessenger
} from 'stream-chat-react'
import 'stream-chat-css/dist/css/index.css'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { CircularProgress, Grid, useTheme } from '@mui/material'
import PreLoader from 'ui/PreLoader/PreLoader'

// importing components
import Header from '../../components/Header/Header'

// importing context
import { UserContext } from '../../context/UserContext'
import { CustomEmptyState } from './CustomEmptyState'

// importing helpers
import useDebounce from 'hooks/useDebounce'
import { createChannelForUsers } from 'helpers/chat'

function StreamChat () {
  const navigate = useNavigate()
  const { client } = useChatContext()
  const { personal, active_neighbourhood, chatConnected } = useContext(UserContext)
  const theme = useTheme()

  // component states
  const [hasCustomChannel, setHasCustomChannel] = React.useState(null)

  const [params] = useSearchParams()

  const handleUserClick = (e, user) => {
    window.open(`/u/${user.id}`)
  }

  const debouncedChatConnected = useDebounce(chatConnected, 1000)

  React.useEffect(() => {
    const messageUser = params.get('message_user')
    if (messageUser) {
      if (!personal || !debouncedChatConnected) {
        return
      }
      createChannelForUsers(
        client,
        personal[active_neighbourhood].ID,
        messageUser
      )
        .then((channel) => {
          setHasCustomChannel(channel.id)
        })
        .catch((err) => console.log(err))
    }

    if (params.get('id')) {
      setHasCustomChannel(params.get('id'))
    } else {
      setHasCustomChannel(null)
    }
  }, [personal, debouncedChatConnected])

  // Tawk.to overlaps key UI elements
  React.useEffect(() => {
    window.Tawk_API = window.Tawk_API || {}
    try {
      window.Tawk_API.hideWidget()
    } catch (err) {
      window.Tawk_API.onLoad = function () {
        window.Tawk_API.hideWidget()
      }
    }
  }, [])

  /**
   * Important conditions to check before taking user to chat
   * 1. Make sure user is logged in, if not then send user to landing page
   * 2. Make sure the user chat is connected
   */
  // 1
  if (!personal) {
    navigate('/')
    return null
  }

  // 2
  if (!debouncedChatConnected) {
    return (
      <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <PreLoader />
      </div>
    )
  }

  const userId = [personal[active_neighbourhood].ID]

  const customTheme = {
    '--border': '#cdcecf',
    '--button-background': theme.palette.secondary.main,
    '--button-text': theme.palette.text.primary,
    '--grey-gainsboro': '#F4F5F7',
    '--grey-whisper': '#F4F5F7.',
    '--targetedMessageBackground': theme.palette.primary.main,
    '--transparent': 'transparent'
  }

  return (
    <>
      <Header />
      <Chat
        customStyles={customTheme}
        client={client}
        initialNavOpen={true}
      >
        <Grid
          container
          sx={{
            '& .str-chat': {
              width: {
                xs: 'auto',
                md: '100% !important'
              }
            },
            '& .str-chat-channel-list': {
              paddingTop: {
                xs: 0,
                md: '64px'
              },
              marginBottom: {
                xs: '64px',
                md: 0
              }
            },
            '& .str-chat__container': {
              flexDirection: 'column',
              height: {
                xs: 'calc(100vh - 70px) !important',
                md: '100% !important'
              }
            },
            '& .str-chat__input-flat-wrapper': {
              borderRadius: '8px',
              border: `1px solid ${theme.palette.primary.main}`
            }
          }}
        >
          <Grid item xs={12} md={4}>
            <ChannelList
              key={hasCustomChannel || 'channels'}
              EmptyStateIndicator={CustomEmptyState}
              filters={{
                members: {
                  $in: userId
                }
              }}
              customActiveChannel={hasCustomChannel}
              setActiveChannelOnMount={!(window.innerWidth < 900)}
              Preview={(data) => {
                let latestMessage = data.latestMessage
                if (!data.lastMessage) {
                  latestMessage = 'Start a conversation 👋'
                }
                return (
                  <ChannelPreviewMessenger {...data} latestMessage={latestMessage} />
                )
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
          >
            <Channel>
              {window.innerWidth < 900 && (
                <ChannelHeader />
              )}
              <MessageList onUserClick={handleUserClick} />
              <MessageInput />
            </Channel>
          </Grid>
        </Grid>
      </Chat>
    </>
  )
}

// Why is their an ErrorBoundary for StreamChat page?
// Search in contributing.md - streamChatErrorBoundary
class StreamChatErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false, error: null }
  }

  static getDerivedStateFromError (error) {
    return { hasError: true, error }
  }

  // componentDidCatch (error, errorInfo) {
  //   console.log(error, errorInfo)
  // }

  render () {
    if (this.state.hasError) {
      // if stream chat errored out, force page reload
      window.location.reload()
      return (
        <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <PreLoader />
        </div>
      )
    }
    return this.props.children
  }
}

// eslint-disable-next-line react/display-name
export default () => {
  return (
    <StreamChatErrorBoundary>
      <StreamChat />
    </StreamChatErrorBoundary>
  )
}
