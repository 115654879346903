import React from 'react'
import { Box, Button, CircularProgress, Stack, styled, Typography } from '@mui/material'

import NearcastCard from '../../Cards/NearcastCard/NearcastCard'
import CustomBox from '../Box/Box'

const TextButton = styled(Button)(() => ({
  margin: 0,
  padding: 0,
  paddingLeft: 4,
  paddingRight: 4,
  minHeight: 0,
  height: 'min-content',
  minWidth: 0
}))

export interface DashboardNearcastObjectType {
  postId: string;
  username: string;
  userCover: string;
  postCover: string;
  postCaption: string;
}

export interface DashboardNearcastsProps {
  loading: boolean;
  nearcasts: DashboardNearcastObjectType[];
  page: number;
  onPressPrevious: () => void;
  onPressNext: () => void;
  title?: string;
  onPressNearcastSelect?: (nearcast: DashboardNearcastObjectType) => void;
  onPressNearcastCard?: (nearcast: DashboardNearcastObjectType) => void;
  selectedNearcasts?: string[];
}

const DashboardNearcasts: React.FC<DashboardNearcastsProps> = ({
  loading,
  nearcasts,
  page,
  onPressPrevious,
  onPressNext,
  title = 'Business Reviews',
  onPressNearcastSelect,
  onPressNearcastCard,
  selectedNearcasts = []
}) => {
  const renderNearcasts = () => {
    if (nearcasts.length === 0) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Typography variant="body2" style={{textAlign:'center', marginTop:15}}>
           Make a review video sharing your experience<br/> for it to be displayed here
          </Typography>
          <img
            alt=""
            src={
              require('./assets/emptyDashboardNearcasts.svg')
                .default
            }
            style={{
              alignSelf: 'center',
              width: 280,
              marginTop: 40,
              marginBottom: 20
            }}
          />
        </div>
      )
    }

    return (
      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-start"
        flexWrap="wrap"
        style={{
          marginTop: 8,
          position: 'relative'
        }}
      >
        {nearcasts.map((nearcast, index) => {
          const isSelected = Boolean(selectedNearcasts.find((id) => id === nearcast.postId))

          return (
            <NearcastCard
              key={nearcast.postId ? nearcast.postId : index} // anonymous posts have postId as null
              author={nearcast.username || 'Anonymous'}
              authorCover={nearcast.userCover}
              postCover={nearcast.postCover}
              postCaption={nearcast.postCaption}
              onPressSelect={onPressNearcastSelect ? () => onPressNearcastSelect(nearcast) : undefined}
              onPressCard={onPressNearcastCard ? () => onPressNearcastCard(nearcast) : undefined}
              isSelected={isSelected}
            />
          )
        })}
      </Stack>
    )
  }

  const styles = {
    boxContainer: {
      backgroundColor: '#F4F7FD',
      borderRadius: 10,
      padding: 16
    }
  }

  return (
    <Box style={styles.boxContainer}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h5">
          {title} {/* (p: {page}) */}
          {/* {nearcasts.length !== 0 &&
            `(${nearcasts.length})`} */}
        </Typography>
        <Stack direction="row">
          <TextButton
            variant="text"
            color="primary"
            disabled={page === 1 || loading}
            disableElevation
            style={{
              marginRight: 8
            }}
            onClick={onPressPrevious}
          >
            PREV
          </TextButton>
          <TextButton
            variant="text"
            color="primary"
            disabled={nearcasts.length < 20 || loading}
            disableElevation
            onClick={onPressNext}
          >
            NEXT
          </TextButton>
        </Stack>
      </Stack>
      {loading
        ? (
            <Stack
              direction="row"
              justifyContent="center"
              style={{
                width: '100%'
              }}
            >
              <CircularProgress />
            </Stack>
          )
        : (
            renderNearcasts()
          )}
    </Box>
  )
}

export default DashboardNearcasts
