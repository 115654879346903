import React from 'react'
import styles from './ViewGroupStyles'
import StickerButton from 'ui/Buttons/StickerButton/StickerButton'
import { sTypes } from '../../components/Modals/AddStickers'

const disabledStickers = [sTypes.donate, sTypes.cost, sTypes.message]

function AnnouncementCard ({ announcement }) {
  const classes = styles()

  return (
		<div className={classes.announcementCard}>
			<div>
				<div
					style={{
					  width: '100%',
					  display: 'flex',
					  justifyContent: 'space-between'
					}}
				>
					<div className={classes.announcementTitle}>{announcement.title}</div>
					<div
						style={{
						  height: '100%',
						  fontSize: '0.9rem',
						  minWidth: 120,
						  display: 'flex',
						  justifyContent: 'flex-end',
						  alignItems: 'flex-start'
						}}
					>
						Exp: {new Date(announcement.valid_till).toLocaleDateString()}
					</div>
				</div>
				<div style={{ fontSize: '0.9rem', marginTop: 10 }}>{announcement.description}</div>
				<div
					style={{
					  display: 'flex',
					  justifyContent: 'flex-end',
					  flexWrap: 'wrap'
					}}
				>
					{(announcement.stickers ?? []).map((sticker, i) => {
					  if (!disabledStickers.includes(sticker.type)) {
					    return <StickerButton sticker={sticker} key={`sticker${i}`} />
					  }
					  return null
					})}
				</div>
			</div>
		</div>
  )
}

export default AnnouncementCard
