import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

// importing ui
import Dialog from 'ui/Dialog/Dialog'
import ShareOfferModal from 'ui/Custom/Modals/ShareOfferModal'
import CropImageModal from 'ui/Custom/Modals/CropImageModal'

// importing components
import ClaimOfferModal, { ClaimOfferDetails } from '../components/Modals/ClaimOfferModal'
import CreateReviewModal from '../components/Modals/CreateReviewModal/CreateReviewModal'
import AuthModal from '../components/Modals/NewAuthModal'
import CreateNearcastModal from '../components/Modals/CreateNearcastModal/CreateNearcastModal'

// importing context
import { UserContext } from './UserContext'

export interface GlobalFuncContextValueType {
  showPleaseLoginDialog: () => void
  openShareModal: (whatsBeingShared: string, whatsBeingSharedDescription: string, shareableLinkPath: string) => void,
  openCropModal: (
    image: File,
    onCropComplete: (f: File) => void,
    aspect?: number,
  ) => void,
  openClaimOfferModal: (offerDetails: ClaimOfferDetails) => void,
  openCreateReviewModal: (isFor: 'business' | 'offer', offer?: any, business?: any) => void,
  openAuthModal: (isFor: string, onSuccess?: (user: any) => void) => void,
  openCreateNearcastModal: (groupId?: string, onSuccess?: () => void) => void,
}

const GlobalFuncContextValueInitial: GlobalFuncContextValueType = {
  showPleaseLoginDialog: () => {},
  openShareModal: (w: string, wd: string, s: string) => {},
  openCropModal: (i: File, o: (f:File) => undefined, aspect: number) => {},
  openClaimOfferModal: (od: ClaimOfferDetails) => {},
  openCreateReviewModal: (o: any) => {},
  openAuthModal: (f: string) => {},
  openCreateNearcastModal: () => {}
}

export const GlobalFuncContext = React.createContext(GlobalFuncContextValueInitial)

const GlobalFuncHOC = ({ children }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const {
    personal
  } = React.useContext(UserContext)

  // extra states for HOC functionality
  const [isUserLoggedIn, setIsUserLoggedIn] = React.useState(false)
  const [isLoginDialogOpen, setIsLoginDialogOpen] = React.useState(false)
  // state for share offer modal
  const shareOfferModalInitial = {
    isOpen: false,
    whatsBeingShared: '',
    whatsBeingSharedDescription: '',
    shareableLinkPath: ''
  }
  const [shareOfferModal, setShareOfferModal] = React.useState(shareOfferModalInitial)
  // state for crop modal
  const cropImageModalInitial = {
    isOpen: false,
    image: null,
    onCropComplete: (f: File) => {},
    aspect: 4 / 4
  }
  const [cropImageModal, setCropImageModal] = React.useState(cropImageModalInitial)
  // state for claim offer modal
  const claimOfferModalInitial: {
    isOpen: boolean;
    offerDetails: ClaimOfferDetails;
  } = {
    isOpen: false,
    offerDetails: {
      cover: '',
      title: '',
      description: '',
      redemption: '',
      nearcoins: '',
      link: ''
    }
  }
  const [claimOfferModal, setClaimOfferModal] = React.useState(claimOfferModalInitial)
  // state for create review modal
  const createReviewModalInitial: {
    isFor: 'business' | 'offer';
    offer?: any;
    business?: any;
    isOpen: boolean;
  } = {
    isFor: 'offer',
    offer: {},
    isOpen: false
  }
  const [createReviewModal, setCreateReviewModal] = React.useState(createReviewModalInitial)
  // state for auth modal
  const authModalInitial = {
    isOpen: false,
    isFor: 'landing',
    onSuccess: (user: any) => undefined
  }
  const [authModal, setAuthModal] = React.useState(authModalInitial)
  // state for create nearcast modal
  const createNearcastModalInitial = {
    isOpen: false,
    groupId: '',
    onSuccess: () => {}
  }
  const [createNearcastModal, setCreateNearcastModal] = React.useState(createNearcastModalInitial)

  React.useEffect(() => {
    if (personal === null) {
      setIsUserLoggedIn(false)
    } else {
      setIsUserLoggedIn(true)
    }
  }, personal)

  const value: GlobalFuncContextValueType = {
    showPleaseLoginDialog: () => setIsLoginDialogOpen(true),
    openShareModal: (w, wd, s) => {
      setShareOfferModal({
        isOpen: true,
        whatsBeingShared: w,
        whatsBeingSharedDescription: wd,
        shareableLinkPath: s
      })
    },
    openCropModal: (i, occ, a = 4 / 4) => {
      setCropImageModal({
        isOpen: true,
        image: i,
        onCropComplete: occ,
        aspect: a
      })
    },
    openClaimOfferModal: (od) => {
      setClaimOfferModal({
        isOpen: true,
        offerDetails: od
      })
    },
    openCreateReviewModal: (isFor, offer, business) => {
      // make sure coupon or businessId is sent in sync with isFor
      if (!['business', 'offer'].includes(isFor)) {
        console.log('isFor invalid')
        return
      }
      if (isFor === 'business') {
        if (!business) {
          console.log('businessId missing for isFor business')
          return
        }
      }
      if (isFor === 'offer') {
        if (!offer) {
          console.log('coupon missing for isFor offer')
          return
        }
      }
      setCreateReviewModal({
        offer,
        business,
        isFor,
        isOpen: true
      })
    },
    openAuthModal: (f, os = (user: any) => undefined) => {
      setAuthModal({
        isOpen: true,
        isFor: f,
        onSuccess: os
      })
    },
    openCreateNearcastModal: (groupId = '', os = () => undefined) => {
      setCreateNearcastModal({
        isOpen: true,
        groupId: groupId,
        onSuccess: os
      })
    }
  }

  return (
    <GlobalFuncContext.Provider value={value}>
      {children}
      {!isUserLoggedIn && (
        <Dialog
          isOpen={isLoginDialogOpen}
          title="We noticed you aren't logged in. Please login to continue using the app"
          cancelText="No"
          onCancel={() => setIsLoginDialogOpen(false)}
          confirmText="Login"
          onConfirm={() => {
            // pass the pathname so that user is sent back to the same page
            // after auth
            navigate('/login', { state: { pathname: pathname } })
            setIsLoginDialogOpen(false)
          }}
        />
      )}
      <ShareOfferModal
        isOpen={shareOfferModal.isOpen}
        onClose={() => setShareOfferModal(shareOfferModalInitial)}
        whatsBeingShared={shareOfferModal.whatsBeingShared}
        whatsBeingSharedDescription={shareOfferModal.whatsBeingSharedDescription}
        shareableLinkPath={shareOfferModal.shareableLinkPath}
      />
      <CropImageModal
        open={cropImageModal.isOpen}
        onClose={() => setCropImageModal(cropImageModalInitial)}
        aspect={cropImageModal.aspect}
        onCropComplete={(blob) => {
          const file = new File([blob], `nearcast-${Date.now()}`)
          cropImageModal.onCropComplete(file)
        }}
        imageFile={cropImageModal.image}
      />
      <ClaimOfferModal
        open={claimOfferModal.isOpen}
        onClose={() => setClaimOfferModal(claimOfferModalInitial)}
        offer={claimOfferModal.offerDetails}
      />
      <CreateReviewModal
        offer={createReviewModal.offer}
        business={createReviewModal.business}
        isFor={createReviewModal.isFor}
        isOpen={createReviewModal.isOpen}
        onClose={() => setCreateReviewModal(createReviewModalInitial)}
      />
      <AuthModal
        open={authModal.isOpen}
        onClose={() => setAuthModal(authModalInitial)}
        isFor={authModal.isFor}
        onSuccess={(user) => {
          authModal.onSuccess(user)
          setAuthModal(authModalInitial)
        }}
      />
      {personal !== null && (
        <CreateNearcastModal
          isOpen={createNearcastModal.isOpen}
          onClose={() => setCreateNearcastModal(createNearcastModalInitial)}
          isForGroup={createNearcastModal.groupId.length > 0 ? createNearcastModal.groupId : false}
          onSuccess={createNearcastModal.onSuccess}
        />
      )}
    </GlobalFuncContext.Provider>
  )
}

export default GlobalFuncHOC
