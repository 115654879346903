import React, { useContext } from 'react'
import Features from '../../components/Landing/Features/Features'
import FooterNew from '../../components/Landing/FooterNew/Footer'
import LandingHeader from '../../components/Landing/LandingHeader/LandingHeader'
import SubpagesHead from '../../components/Landing/SubpagesHead/SubpagesHead'
import CivicBg from './HeadBackgrounds/civic-bg.png'
import features from './FeatureLists/CivicList'
import CivicBanner from './Banners/civic.png'
import { GlobalFuncContext } from '../../context/GlobalFuncHOC'

function LandingCivic () {
  const { openAuthModal } = useContext(GlobalFuncContext)

  const featureBanners = [CivicBanner]
  const description =
		'Communicate with residents and build stronger ties in the neighborhoods you serve with Nearcast’s engagement tool for public agencies and service providers.'

  return (
		<>
			<LandingHeader light onActionClick={() => openAuthModal('landing')} />
			<SubpagesHead
				title="Nearcast for Civic Organizations"
				description={description}
				bg={CivicBg}
				onActionClick={() => openAuthModal('landing')}
			/>
			<Features
				title="Civic Organizations use Nearcast to"
				features={features}
				featureBanners={featureBanners}
				scrollEnabled={false}
				onActionClick={() => openAuthModal('landing')}
			/>
			<FooterNew />
		</>
  )
}

export default LandingCivic
