import { CircularProgress } from '@mui/material'
import { useState } from 'react'
import firebase from 'firebase'

// importing components
import googleIcon from '../../../assets/googleIcon.svg'
import '../../../firebase'

function GoogleSignin ({ onSuccess, onError }) {
  const [loading, setLoading] = useState(false)

  const googleAuth = () => {
    setLoading(true)
    const provider = new firebase.auth.GoogleAuthProvider()
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(() => {
        firebase
          .auth()
          .currentUser.getIdToken(true)
          .then((token) => {
            onSuccess(token)
            setLoading(false)
          })
      })
      .catch((error) => {
        onError()
        setLoading(false)
      })
  }

  return (
    <button
      onClick={googleAuth}
      style={{
        background: '#FFFFFF',
        borderRadius: 8,
        color: '#494949',
        border: '1px solid #494949',
        display: 'inline',
        padding: '14px',
        cursor: 'pointer',
        fontSize: 16,
        margin: 2
      }}
      disabled={loading}
    >
      {loading
        ? (
        <CircularProgress
          size={18}
          style={{ verticalAlign: 'middle', marginRight: 10, color: '#494949' }}
        />
          )
        : (
        <img
          src={googleIcon}
          style={{
            verticalAlign: 'middle',
            marginRight: 10,
            height: 20,
            position: 'absolute',
            left: 30
          }}
          alt=""
        />
          )}
      Continue With Google
    </button>
  )
}

export default GoogleSignin
