import * as React from 'react'
import {
  FormControl
} from '@mui/material'
import { useSnackbar } from 'notistack'
import TextArea from 'ui/TextInputs/TextAreaInput'
import StandardModal from 'ui/Modals/StandardModal'

// importing components
import { postUpdate } from 'api/post/update'

// import context
import { UserContext } from '../../../context/UserContext'

const EditPostCaption = ({
  postId,
  open,
  onClose,
  caption
}) => {
  const { enqueueSnackbar } = useSnackbar()
  // app context
  const { personal, jwt_token, active_neighbourhood } = React.useContext(UserContext)
  // component states
  const [newCaption, setNewCaption] = React.useState(caption)

  // ActionModal onCancel
  const onCancel = () => {
    // clean up the states
    setNewCaption(caption)
    // do everything else, then call onClose prop from parent
    onClose()
  }

  // ActionModal onConfirm
  const onConfirm = async () => {
    // perform the update action
    try {
      await postUpdate(postId, newCaption, personal[active_neighbourhood].ID, jwt_token)
      enqueueSnackbar('Caption Updated', {
        variant: 'success'
      })
      onClose(true)
    } catch (err) {
      enqueueSnackbar(err.message, {
        variant: 'error'
      })
    }
  }

  return (
        <StandardModal
            isOpen={open}
            onClose={onCancel}
            title="Edit Post Caption"
            buttons={[
              {
                title: 'Cancel',
                color: 'ghost',
                onPress: onCancel
              },
              {
                title: 'Confirm',
                color: 'primary',
                onPress: onConfirm
              }
            ]}
        >
            <div>
                <FormControl fullWidth margin="none">
                    <TextArea
                        rows={4}
                        variant="outlined"
                        value={newCaption}
                        onChange={(e) => setNewCaption(e.target.value)}
                    />
                    <span style={{ fontSize: '10px', textAlign: 'right' }}>
                        {1022 - newCaption.length} characters left
                    </span>
                </FormControl>
            </div>
        </StandardModal>
  )
}

EditPostCaption.defaultProps = {
  open: false,
  onClose: () => {},
  caption: ''
}

export default EditPostCaption
