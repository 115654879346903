import axios from 'axios'
import { alsoPublicRouteHeadersFormat } from '../helpers'

/**
 * @function groupInfo Used to get all info regarding the group
 * @param {string} id - the group's id
 * @param {string} nrctUID - user's current neighborhood id, set to null for public info
 * @param {string} jwt - user's jwt token, set to null for public info
 * @returns {Promise} Promise object represents object for group info
 */
export const groupInfo = (id, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/group/info`, {
      params: {
        group: id
      },
      headers: alsoPublicRouteHeadersFormat(nrctUID, jwt)
    })
      .then((res) => resolve(res.data.data))
      .catch((err) => reject(err))
  })
}
