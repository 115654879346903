import axios from 'axios'

// for uploading the group cover image
import { groupCoverS3Url } from './cover'

/**
 * @function groupCreate Use to create a new group
 * @param {string[]} categories - list of category IDs
 * @param {string} neighbourhoods - list with 0 element being the `personal[active_neighbourhood].lives_in_id` from user context
 * @param {number} distance - the distance in miles
 * @param {string} name - group name
 * @param {string} description - group description
 * @param {Object} coverImage - input file upload
 * @param {boolean} isPrivate - should the new group be private or public
 * @param {string} nrctUID - personal[active_neighbourhood].ID from user context
 * @param {string} jwt - jwt token from user context
 * @returns {Promise} Promise object represents true for successful group creation
 */
export const groupCreate = (
  categories,
  neighbourhoods,
  distance,
  name,
  description,
  coverImage = null,
  isPrivate = false,
  nrctUID,
  jwt
) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/group/create`, {
      categories,
      neighbourhoods,
      distance,
      cover_video: '',
      cover_thumbnail: '',
      is_private: isPrivate,
      group_name: name,
      group_description: description
    }, {
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then(async (res) => {
        if (res.data.msg === 'created') {
          if (coverImage) {
            await groupCoverS3Url(res.data.group.ID, coverImage, nrctUID, jwt)
          }
          resolve(true)
        }
        throw new Error('Error creating new group')
      })
      .catch((err) => {
        reject(err)
      })
  })
}
