import React, { useEffect, useContext, useState } from 'react'
import { useParams, useNavigate } from 'react-router'
import { Grid, Button, Box, Typography, Stack } from '@mui/material'
import { Add, ArrowRight, ErrorRounded } from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import moment from 'moment'

// importing components
import Header from '../../components/Header/Header'
import GatheringCard from '../../components/UserDash/Gathering/GatheringCard'
import NearcastGrid from '../../components/NearcastPicker/NercastGrid'
import CreateAnnouncement from '../../components/Modals/CreateAnnouncement'
import ActionModal from '../../components/Modals/ActionModal'
import GroupInfoCard from './GroupInfoCard'
import styles from './ViewGroupStyles'
import AnnouncementCard from './AnnouncementCard'

// importing context
import { UserContext } from '../../context/UserContext'
import { GlobalFuncContext } from '../../context/GlobalFuncHOC'

// importing apis
import { reportGathering } from 'api/report/gathering'
import { groupMembers as groupMembersApiPack } from 'api/group/members'
import { groupInfo as groupInfoApiPack } from 'api/group/info'
import { groupGatherings as groupGatheringsApiPack } from 'api/group/gatherings'
import { groupPosts as groupPostsApiPack } from 'api/group/posts'
import { groupAnnouncementFetch } from 'api/group/announcements'

function ViewGroup () {
  const { id } = useParams()
  const navigate = useNavigate()
  const classes = styles()
  const { enqueueSnackbar } = useSnackbar()

  const [groupInfo, setGroupInfo] = useState()
  const [groupGatherings, setGroupGatherings] = useState([])
  const [groupAnnouncements, setGroupAnnouncements] = useState([])
  const [groupPosts, setGroupPosts] = useState([])
  const [groupMembers, setGroupMembers] = useState([])

  const [infoLoading, setInfoLoading] = useState(true)
  const [gatheringLoading, setGatheringLoading] = useState(true)
  const [isAdmin, setIsAdmin] = useState(false)

  const [createAnn, setCreateAnn] = useState(false)
  const [reportOpen, setReportOpen] = useState()

  const { personal, active_neighbourhood, jwt_token } = useContext(UserContext)
  const { openShareModal, openCreateNearcastModal } = useContext(GlobalFuncContext)

  const reportGatheringApi = (id) => {
    reportGathering(id, 'User reported gathering from the web', personal[active_neighbourhood].ID, jwt_token)
      .then((res) => {
        enqueueSnackbar('You reported this gathering', {
          variant: 'success'
        })
      })
      .catch((err) => {
        console.log(err.message)
        enqueueSnackbar("We weren't able to report this gathering, please try again later!", {
          variant: 'error'
        })
      })
  }

  const getMembers = async () => {
    groupMembersApiPack(
      id,
      personal ? personal[active_neighbourhood].ID : null,
      personal ? jwt_token : null
    )
      .then((dataMembers) => setGroupMembers(dataMembers))
      .catch((err) => {
        console.log(err.message)
        enqueueSnackbar("There was some error getting this group's members.", {
          variant: 'error'
        })
      })
  }

  const getGroupInfo = async () => {
    groupInfoApiPack(
      id,
      personal ? personal[active_neighbourhood].ID : null,
      personal ? jwt_token : null
    )
      .then((dataInfo) => {
        setGroupInfo(dataInfo)
        setIsAdmin(dataInfo.group_info.role === 1 && dataInfo.group_info.is_joined)
      })
      .catch((err) => {
        console.log(err.message)
        enqueueSnackbar("There was some error getting this group's details.", {
          variant: 'error'
        })
      })
      .finally(() => {
        setInfoLoading(false)
      })
  }

  const getGatherings = async () => {
    groupGatheringsApiPack(
      id,
      personal ? personal[active_neighbourhood].ID : null,
      personal ? jwt_token : null
    )
      .then((dataGatherings) => {
        setGroupGatherings(
          (dataGatherings ?? []).sort(function (a, b) {
            return a.starts_at > b.starts_at ? -1 : a.starts_at < b.starts_at ? 1 : 0
          })
        )
      })
      .catch(() => {
        enqueueSnackbar("There was some error getting this group's gatherings.", {
          variant: 'error'
        })
      })
      .finally(() => setGatheringLoading(false))
  }

  const getPosts = async () => {
    groupPostsApiPack(
      id,
      1,
      10,
      personal ? personal[active_neighbourhood].ID : null,
      personal ? jwt_token : null
    )
      .then((dataPosts) => setGroupPosts(dataPosts ?? []))
      .catch(() => {
        enqueueSnackbar("There was some error getting this group's posts.", {
          variant: 'error'
        })
      })
  }

  const getAnnouncements = async () => {
    if (!personal) {
      return
    }

    groupAnnouncementFetch(
      id,
      personal[active_neighbourhood].ID,
      jwt_token
    )
      .then((dataAnn) => setGroupAnnouncements(dataAnn ?? []))
      .catch(() => {
        enqueueSnackbar("There was some error getting this group's announcements.", {
          variant: 'error'
        })
      })
  }

  const getAllDetails = () => {
    setInfoLoading(true)
    setGatheringLoading(true)

    getGroupInfo()
    getGatherings()
    getPosts()
    getAnnouncements()
    getMembers()
  }

  useEffect(() => {
    getAllDetails()
  }, [id])

  const renderGroupAnnouncements = () => {
    if (groupAnnouncements.length > 0) {
      return (
        <>
          <div
            style={{
              marginTop: 20,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <div className={classes.headText}>Announcements</div>
            {isAdmin && (
              <Button
                variant="text"
                color="primary"
                className={classes.createAnnBtn}
                onClick={() => setCreateAnn(true)}
              >
                <Add />
                Create
              </Button>
            )}
          </div>
          <div style={{ marginTop: 10 }}>
            {groupAnnouncements.map((announcement, i) => {
              if (moment().isBefore(moment(announcement.valid_till).add(1, 'days'))) {
                return <AnnouncementCard announcement={announcement} key={`groupAnnouncement${i}`} />
              }
            })}
          </div>
        </>
      )
    }

    if (groupAnnouncements.length === 0 && isAdmin) {
      return (
        <>
          <div
            style={{
              marginTop: 20,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <div className={classes.headText}>Announcements</div>
            {isAdmin && (
              <Button
                variant="text"
                color="primary"
                className={classes.createAnnBtn}
                onClick={() => setCreateAnn(true)}
              >
                <Add />
                Create
              </Button>
            )}
          </div>
          <div style={{ marginTop: 10 }}>
            <Box
              sx={{
                backgroundColor: '#f4efe7',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '8px',
                padding: 5
              }}
            >
              <Typography textAlign="center" variant="body2">
                Create an announcement for everyone here 📢
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setCreateAnn(true)}
                disableElevation
                startIcon={<Add />}
                sx={{
                  marginTop: 2
                }}
              >
                Create
              </Button>
            </Box>
          </div>
        </>
      )
    }

    return null
  }

  if (infoLoading || gatheringLoading) {
    return null
  }

  return (
    <>
      <Header />
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12} md={4}>
          <GroupInfoCard
            groupData={groupInfo}
            setGroupInfo={setGroupInfo}
            members={groupMembers}
            id={id}
            isAdmin={isAdmin}
            refresh={getAllDetails}
          />
          {renderGroupAnnouncements()}
        </Grid>
        <Grid item xs={12} md={8}>
          {groupGatherings.length > 0 && (
            <div style={{ marginBottom: 35 }}>
              <div className={classes.headText}>Past Gathering</div>
              <div className={classes.gatheringsContainer}>
                {groupGatherings.map((item, i) => (
                  <div className={classes.cardDiv} key={`gathering${i}`}>
                    <GatheringCard
                      gathering={item}
                      setReportOpen={setReportOpen}
                      setShowShare={(ga) => {
                        openShareModal(
                          'Gathering',
                          'Checkout this Gathering at Nearcast',
                          `/gathering/${ga.id}`
                        )
                      }}
                      disable={moment().isAfter(moment(item.ends_at))}
                    />
                  </div>))
                }
              </div>
            </div>
          )}
          <div>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <div className={classes.headText}>Nearcasts</div>
              <Button
                onClick={() => navigate('/home')}
                endIcon={<ArrowRight />}
              >
                Watch All
              </Button>
            </Stack>
            <div className={classes.nearcastsContainer}>
              <NearcastGrid
                emptyMessage={(
                  <Stack
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      width: '100%',
                      paddingTop: 10,
                      paddingBottom: 10
                    }}
                  >
                    <img
                      src={require('../../assets/groupEmptyState.svg').default}
                      style={{
                        width: '300px'
                      }}
                    />
                    <Typography variant="h4" sx={{ marginTop: 2 }}>
                     Be the first to make a Nearcast
                    </Typography>
                    <Typography variant="caption" sx={{ marginTop: 1 }}>
                     and be a trendsetter in your group 📷
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => openCreateNearcastModal(id, () => setTimeout(() => getPosts(), 2000))}
                      disableElevation
                      startIcon={<Add />}
                      sx={{
                        marginTop: 2
                      }}
                    >
                      Create Nearcast Video
                    </Button>
                  </Stack>
                )}
                videos={groupPosts}
                playable={true}
              />
            </div>
          </div>
        </Grid>
      </Grid>
      <CreateAnnouncement
        open={createAnn}
        onClose={() => setCreateAnn(false)}
        groupId={id}
        setAnnouncements={setGroupAnnouncements}
      />
      <ActionModal
        open={Boolean(reportOpen)}
        onClose={() => setReportOpen(false)}
        onConfirm={() => {
          reportGatheringApi(reportOpen)
          setReportOpen(false)
        }}
        onCancel={() => {
          setReportOpen(false)
        }}
        action={{
          title: 'Do you want to report this gathering',
          Accept: 'Yes',
          Decline: 'No'
        }}
      >
        <ErrorRounded style={{ fontSize: '2.5rem' }} color="secondary" />
      </ActionModal>
    </>
  )
}

export default ViewGroup
