import axios from 'axios'

// importing helpers
import { getFileUploadProgressInPercentage } from 'helpers/browser'

/**
 * @function postCreate Create a new user post
 * @param {string} caption - string of max length 255
 * @param {Array.<Object>} stickers - array of sticker objects
 * @param {number} distance - distance in miles (if 0, video is shared in neighborhood else in nearby)
 * @param {string} groupId - if sharing in a group then group id
 * @param {File} nearcastVideo - video file of the post
 * @param {string} nrctUID - personal[active_neighbourhood].ID from user context
 * @param {string} jwt - jwt token from user context
 * @returns {Promise} Promise object represents true for successful nearcast post creation
 */
export const postCreate = (
  caption,
  stickers,
  distance,
  groupId = null,
  nearcastVideo,
  nrctUID,
  jwt,
  onVideoUploadProgress = (percentage) => {}
) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/post/create`, {
      caption: caption,
      device_information: {
        device_width: 300.0,
        device_height: 500.0,
        sticker_size: 100.0,
        sticker_max_scale: 2.0,
        sticker_min_scale: 0.5
      },
      stickers: stickers,
      music: [],
      distance_constant: distance,
      duration: 0,
      group_id: groupId
    }, {
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => res.data.body.URL)
      .then(async (uploadUrl) => {
        await axios.put(uploadUrl, nearcastVideo, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': nearcastVideo.type
          },
          onUploadProgress: (progressEvent) => {
            const uploadPercentage = getFileUploadProgressInPercentage(progressEvent)
            onVideoUploadProgress(uploadPercentage)
          }
        })
        resolve(true)
      })
      .catch((err) => {
        reject(new Error('Error: Unable to create a new nearcast post!'))
      })
  })
}
