import { Grid, Typography, Stack } from '@mui/material'
import React from 'react'
import QRCode from 'qrcode.react'
import { useNavigate } from 'react-router-dom'

import MediumModal from 'ui/Modals/MediumModal'

export interface ClaimOfferDetails {
  cover: string;
  title: string;
  description: string;
  redemption: string;
  nearcoins: string;
  link: string;
}

export interface ClaimOfferModalProps {
  open: boolean;
  onClose: () => void;
  offer: ClaimOfferDetails;
}

const ClaimOfferModal: React.FC<ClaimOfferModalProps> = (props) => {
  const navigate = useNavigate()
  return (
    <MediumModal
      title=""
      isOpen={props.open}
      onClose={props.onClose}
      buttons={[
        {
          title: 'Continue',
          color: 'primary',
          onPress: () => {
            props.onClose()
            navigate(`/offer/${props.offer.redemption}`)
          }
        },
        {
          title: 'Close',
          color: 'ghost',
          onPress: props.onClose
        }
      ]}
      style={{}}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
          <img
            style={{
              width: '100%',
              borderRadius: 8
            }}
            src={props.offer.cover}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Stack
            direction="column"
            justifyContent="space-between"
            style={{
              height: '90%'
            }}
          >
            <div>
              <Typography variant="h2" color="primary" style={{ textAlign: 'center' }}>
                {props.offer.title}
              </Typography>
              <Typography variant="h6" style={{ textAlign: 'center', marginTop: 30, marginBottom: 20 }}>
                {props.offer.description}
              </Typography>
            </div>
            <Stack
              direction="column"
              style={{
              }}
            >
              <Stack
                direction={{
                  xs: 'column-reverse',
                  md: 'row'
                }}
                alignItems={{
                  xs: 'center',
                  md: 'flex-end'
                }}
                justifyContent="space-between"
                sx={{
                  marginTop: {
                    xs: 2,
                    md: 0
                  }
                }}
              >
                <div style={{ padding: '30px' }}>
                  <Typography variant="h6">
                    Coupon Code:
                  </Typography>
                  <Typography variant="h4" color="primary" data-testid="offerCouponCode">
                    {props.offer.redemption}
                  </Typography>
                </div>
                <Stack direction="column" style={{ width: '180px'}}>
                  <QRCode
                    style={{ marginTop: 7, marginBottom: 7, width: 180, height: 180 }}
                    size={200}
                    value={props.offer.link}
                    data-testid="offerQRcodeLink"
                  />
                  <Typography variant="caption" style={{ flexWrap: 'wrap', textAlign: 'center' }}>
                    Scan QR or click the button below to claim offer
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </MediumModal>
  )
}

export default ClaimOfferModal
