import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { Link } from 'react-router-dom'

function EEAMemberAgreement () {
  const classes = styles()

  return (
        <div className={classes.mainContainer}>
            <Typography variant="h2">
                EEA + Member Agreement
            </Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                If you live in the European Economic Area or the United Kingdom, your use of our Services is governed by this EEA + Member Agreement, otherwise your use is governed by the <a href="/member-agreement" target="_blank" rel="noreferrer">Member Agreement</a>.
                <br />If you use our Business Services, click <Link to="/business-services-terms">here</Link> for additional Business Services Terms governing your use of Business Services.
                <br />If you have a Public services Page, click <Link to="/business-services-terms">here</Link> for the Public services Terms of Service governing your use of Public services Pages.
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>
                1. OUR SERVICES
            </Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                Welcome to Nearcast.  Nearcast’s mission is to create joy through shared in-person experiences between nearby residents.
                <br />People use Nearcast to participate in shared activities with people living around them, to discover gatherings around them, and to express themselves through short-videos set to music.
                <br />This EEA+ Member Agreement (the "Terms") governs your rights and obligations with respect to Nearcast's services, which include all of Nearcast's websites, domains, apps, products, services, features, and marketing campaigns (collectively, the "Services"). If you have your primary residence or establishment in the European Economic Area or the United Kingdom (collectively, the "EEA+"), you are a party to this EEA+ Member Agreement. These Terms do not apply to Agencies or Customers, nor do they cover the Public Services Pages or our Ad Service. You become a "Member" when you register to utilize our Services. If you choose not to register for our Services, you may still be able to use some features or functionality made available via our Services as a "Visitor." By accessing or using our Services, whether as a Member or Visitor, you acknowledge and agree that you have read, understand, and agree to be bound by these Terms as a legally binding contract with Nearcast (even if you are accessing or using the Services on behalf of a business). Please take a moment to read these legally binding Terms.
                <br />If you are accepting these Terms and using the Services on behalf of a company, partnership, organization, or other legal entity, you represent and warrant that you are authorized to do so and that you have the authority to bind such entity to these Terms, in which case the terms "you" and "your" in these Terms shall refer to such entity. Our Privacy Policy and Advertising and Cookie Policy detail how we collect, utilize, and share your personal information. Certain Services, particularly Business Services (as defined in our Business Services Terms), may be subject to additional terms, rules, and guidelines ("Supplemental Terms"), which will be included in these Terms or made available to you via the Services. If these Terms conflict with the Supplemental Terms, the Supplemental Terms will apply to such Service.
                <br />Throughout these Terms, we use “Nearcast”, “we”, “us” and “our” to refer to the companies offering our Services to you as set out in these Terms.
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>2. JOINING THE NEIGHBORHOOD
            </Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                a. <b>Eligibility.</b>  You may use our Services only if you are legally capable of entering into a legally binding contract with Nearcast and only in accordance with these Terms and all relevant laws. You are not permitted to use the Services if you are (1) a child for whom consent is required to fully use the Services (e.g., if you are under the age of 13 as defined by COPPA); (2) you are a registered sex offender in any jurisdiction or are otherwise subject to an applicable law, order, or legal action prohibiting you from using or accessing an online service that permits use by minor children; (4) our Services are not directed to your geographic territory; or (5) you are otherwise prohibited by applicable laws from accessing or receiving our Services or (3) we previously disabled your Account for violations of these Terms or other of our Supple We retain the right, at any time and for any reason, to deny registration for, access to, or use of our Services to any person or household.
                <br />b. <b>Becoming a Member.</b> You must supply us with correct and full information when you register to become a Member. We retain the right to refuse and cancel any registrations made using fraudulent or incorrect registration information, or in violation of these Terms in any other way.
                <br />c. <b>Account Types and Access.</b> Members may create, operate, manage, or otherwise make use of an account ("Account(s)") on the Services for personal and/or commercial purposes. If you use our Business Services, which include but are not limited to establishing or claiming a page that advertises your business or provides other information about it (a "Business Page"), you agree to our Business Services Terms, which are incorporated into these Terms. Each individual may have only one Account per household, and each business may have only one Account per business location. You are responsible for the security of your Account login information and must keep it private. You are fully accountable for all activity that occurs under your Account. You should immediately tell us if you become aware of or suspect any unauthorized use or access to your Account.
                <br />d. <b>Permitted Activities.</b> You may use, access, search for, interact with, or otherwise make use of our Services solely for the purposes for which they are given and through the channels we make accessible (such as our website, applications, and APIs), subject to any Supplemental Terms we provide limiting their allowed uses.
            </Typography>
            <Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>
                3. OUR LICENSE GRANT TO YOU.
            </Typography>
            <Typography variant="body1" style={{ marginTop: '20px' }}>
                Nearcast hereby provides you a limited, non-exclusive, non-transferable, non-sublicensable, and freely revocable license to access and use the Services as specified herein. The above license grant does not constitute a sale of the Services or any portion thereof, and Nearcast retains complete ownership of the Services and any copies thereof.
            </Typography>
            <Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>4. RIGHTS YOU GRANT</Typography>
            <Typography variant="body1" style={{ marginTop: '20px' }}>
                a. <b>Generally.</b> Certain areas of the Services enable Members to post, publish, submit, upload, transmit, or otherwise make available on the Services content such as profile pictures or information, photos, images, music, videos, information, comments, likes, recommendations, questions, and messages ("Content") (such Content that a Member makes available or submits is referred to as "Member Content"). Your Member Content remains your own.
                <br />b. <b>License Grant to Nearcast.</b> You hereby grant Nearcast a non-exclusive, transferable, fully sublicensable (via multiple tiers), royalty-free, and worldwide license to host, use, distribute, modify, copy, publish, list information about, edit, translate, distribute, syndicate, publicly perform, publicly display, and create derivative works of your Member Content. We shall use our rights under the preceding license in accordance with your Account settings, the choices you make when uploading Member Content, and as otherwise specified in our Privacy Policy. You represent and warrant that you are either the creator and owner of your Member Content or that you have obtained all necessary licenses, rights, consents, and permissions to permit Nearcast to exercise the licenses granted in this section in the manner intended by these Terms.
                <br />c. <b>License Grant to Other Members.</b> Additionally, you grant to each Member of the Services a non-exclusive license to access your Member Content via the Services and to use, reproduce, distribute, display, and perform such Member Content as permitted by the Services' functionality and these Terms.
                <br />d. <b>Use of Your Member Content with Sponsored Posts.</b> You grant us permission to display your name, neighborhood, profile picture, and information about your interactions with the Services alongside or in connection with advertisements, offers, and our efforts to promote sponsored Content displayed on the Services, all without charging you additional fees. Additionally, we may post and repost information about your interactions with the Services, along with your name and profile picture, in various areas of the Services, including any Member Content (such as recommendations) for a business in connection with that business's other ads and Business Pages on the Services. We shall do so in line with applicable data protection legislation and our Privacy Policy.
                <br />e. <b>Feedback.</b> We invite you to share any feedback, suggestions, or ideas you have about Nearcast or our Services with us ("Feedback"); By sharing your Feedback, you agree to grant Nearcast an unrestricted, perpetual, irrevocable, non-exclusive, sublicensable, transferable, fully-paid, royalty-free right to use the Feedback in connection with any of our products or services now known or hereafter developed.
                <br />f. <b>Sharing Content.</b> We welcome you to share Content from Nearcast using our sharing tools, which include the Share button and the ability to mask Member information when capturing a screenshot using our privacy settings. It is illegal to copy, share, or re-distribute Content in any other way, including by web scraping.
                <br />g. <b>Software.</b> Nearcast may require you to download software to your computer, phone, tablet, or other device in order to use it. We may ask you to install updates to our Services on your computer or mobile device. You recognize and agree that we may automatically update that software, and that any updates will be governed by the then-current version of these Terms.
            </Typography>
            <Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>5. PROHIBITED CONDUCT</Typography>
            <Typography variant="body1" style={{ marginTop: '20px' }}>
                a. <b>Prohibited Conduct.</b> Without limiting any other terms in these Terms, you agree that you will not, under any circumstances:
                <br />(1) get or try to obtain unauthorized access to any portion of the Services, including other Members' Accounts (including via the use of bots or other automations or the unauthorized use of valid Member credentials);
                <br />(2) try to tamper with, disrupt, or damage our Services (for example, by uploading viruses, initiating a denial of service attack, or spamming Members);
                <br />(3) make an effort to gain access to or tamper with non-public parts of the Services, our computer systems, or any of our providers' technical delivery systems;
                <br />(4) make any effort to establish, scan, or test the vulnerability of our systems, networks, or Services, or to circumvent any security or authentication mechanism;
                <br />(5) perform face recognition or other biometric analysis on the Nearcast Content (as defined below);
                <br />(6) develop, support, or otherwise make available software, devices, scripts, robots, or any other means or processes (including crawlers, browser plugins, and add-ons, or any other technology) for the purpose of scraping the Services or otherwise copying profiles and other data from the Services; or
                <br />(7) in any other way, access or use the Services in an unauthorized or unexpected manner. To be clear, any effort to participate in any of the activities mentioned above is likewise forbidden.
                <br />b. <b>Disputes Between Members; Waiver of Claims Against Nearcast.</b> Neighbors sometimes quarrel, both in the real world and online. If you have a disagreement with another Member, we hope you can resolve it politely. However, if you are unable to do so, please realize that Nearcast is not liable for our Members' activities. Each of our Members is fully accountable for their own activities and behavior, whether they are using Nearcast or conversing over the back fence with a neighbor. As a result, you acknowledge and accept that Nearcast is not liable for the behavior of Members or any third parties.
                <br />c. <b>Community Moderation.</b> Certain Members get extra account capabilities, such as moderating tools, to help them assist their community. WE ARE NOT RESPONSIBLE FOR THE MEMBERS' ACTIONS WHILE ACCESSING THESE ACCOUNT FEATURES. We retain the right to reverse any action taken by these Members if we feel that such action is not in the best interests of Nearcast. We retain the right to terminate or restrict a Member's access to these tools at any time, with or without notice, for any or no reason, including a violation of these Terms or any applicable rules or guidelines. If you are granted access to these extra account capabilities, which include moderating tools, then:
                <br />-&gt; You acknowledge that you are doing all community moderating activities on a voluntary basis and are not an employee or contractor of Nearcast;
                <br />-&gt; You may not falsely claim to Nearcast that you are authorized to act on its behalf;
                <br />-&gt; You may not engage into any arrangement on behalf of Nearcast with a third party;
                <br />-&gt; You are not permitted to conduct moderation activities in exchange for any kind of remuneration, payment, gift, or favor from third parties; and
                <br />-&gt; If you obtain access to non-public information via these account features, you agree to use it only in conjunction with your volunteer work.
            </Typography>
            <Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>6. MEMBER TRANSACTIONS</Typography>
            <Typography variant="body1" style={{ marginTop: '20px' }}>
                a. <b>Products and Services Offered by Members.</b> You are solely responsible for your own choices and activities on the Services, not Nearcast. Nearcast does not own or sell any of the goods or services posted by Members on the Services, and therefore any contract established is between the Member making an offer and the Member wishing to buy or otherwise acquire such goods or services. Nearcast does not handle payments between Members contracted on or via the Services.
                <br />b. <b>Engaging Other Members.</b> Nearcast does not conduct member interviews, conduct background checks on them, monitor, manage, guide, or control them. Additionally, you acknowledge and agree that using Nearcast to find a service, whether through search, Business Pages, or any other similar feature, does not constitute us as an employer, placement agency, representative, or agent of or for you or any other Member or service provider, and that these Terms and your use of the Services do not intend or create any such relationship. If you and another Member decide to collaborate, you and the other Member are entirely responsible for adhering to all relevant laws, including tax and employment regulations. Members are not independent contractors, employees, joint venture partners, franchisees, or suppliers of goods or services to or on behalf of Nearcast.
                <br />c. <b>Prohibited Transactions.</b> You may not use the Services to solicit, advertise for, or contact Members in any way other than as necessary to provide the Services. Without our prior written permission, you may not use the Services to gather contact information or other personal information about Members, whether electronically or otherwise.
                <br />d. <b>Nearcast Only Provides a Venue.</b> Nearcast is not a party to Member interactions, transactions, or conflicts, and although we may, at our discretion, assist in resolving issues, we have no control over and make no guarantees regarding:
                <br />(1) the existence, the quality, the safety, or the legality of any products or services posted on the Services by Members;
                <br />(2) the authenticity or truthfulness of Member Content or postings;
                <br />(3) a Member's capacity to sell or pay for products or services;
                <br />(4) that Members who wish to enter into contracts with one another will carry out the transaction; or
                <br />(5) the Members' integrity, accountability, or conduct.
                <br />Nearcast retains the right to delete any Member's listing on the Services at any time, with or without warning, for any reason or no reason. When dealing with other Members, you should use care and common sense to safeguard your personal safety and property, just as you would with unknown individuals.
                <br />NEITHER Nearcast NOR OUR AFFILIATES ARE RESPONSIBLE FOR ANY USER'S ONLINE OR OFFLINE CONDUCT. Nearcast AND OUR AFFILIATES DISCLAIMS ALL LIABILITY FOR ANY CLAIM, INJURY, OR DAMAGE ARISING FROM YOUR USE OF THE SERVICES.
            </Typography>
            <Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>7. NOTIFICATION PREFERENCES.</Typography>
            a. <b>Notifications.</b> By default, Nearcast will send alerts about your usage of and interactions with the Services to the email address associated with your Account, as well as to your device or browser. Additionally, you may opt in to get additional types of alerts. You may modify your default notification settings and your preferences for receiving messages from Nearcast. You may use these preference settings to unsubscribe from certain email or text message alerts.
            b. <b>Invitations.</b> You may use our Services to invite people who are not Members to register for the Services through postal mail, email, or text message ("Invitations"). You agree that these Invitations, which we may assist you in sending through our Services, are sent by you, not by Nearcast, and that Invitations sent by postal mail are sent on your behalf by Nearcast. These invitations may identify you as the sender and may include information about your area. For instance, you may invite your neighbors to Nearcast by asking that we send them an Invitation that includes your name and the information that you are a neighborhood resident. You recognize that some third-party costs, such as those imposed by your mobile carrier or Internet service provider, may apply to the usage and operation of your device in conjunction with the transmission of an Invitation, and that you are entirely liable for any such third-party fees.
            c. <b>Text Messages.</b> Nearcast includes a text messaging service via which Members may get information about their area, Account, and other Services, such as emergency alerts. Nearcast does not charge for text alerts, but your carrier may. Text alerts may not be sent if your phone is not within range of a transmission site or if there is insufficient network capacity available at a given moment. Additionally, circumstances beyond the user's cellular carrier or wireless internet provider's control, such as equipment, topography, proximity to buildings, vegetation, and weather, may obstruct message transmission. You understand that messages may not be received in a timely manner and that neither Nearcast nor your cellular carrier guarantees the delivery of text notifications. These alerts are not meant to be used in lieu of a main phone service, such as a landline or a mobile phone, which may be used to contact emergency services. You recognize and agree that Nearcast is not responsible for any damages arising out of your use of the text notification service, and that you will not attempt to hold Nearcast accountable.
            <Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>8. PROMOTIONS.</Typography>
            Additional terms and conditions may apply to any sweepstakes, competitions, raffles, games, or similar promotions (collectively, "Promotions") made accessible via and as part of the Services. If you participate in any Promotions, please read the Promotion's particular regulations carefully as well as our Privacy Policy. Where such rules contradict these Terms or the Privacy Policy, the relevant Promotion rules shall prevail.
            <Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>9. THIRD-PARTY SITES AND SERVICES.</Typography>
            Our Services may include hyperlinks to third-party websites, advertisers, services, special offers, and other events or activities not controlled or managed by Nearcast. We make no representations or warranties about such third-party websites, information, materials, goods, or services. You acknowledge that you are accessing any third-party website, advertising, service, or other Nearcast Content at your own risk. Nearcast is not obligated to notify you that you are leaving the Services and entering the terms and conditions of another website or domain that is not within Nearcast's control. You agree that Nearcast is not liable for any damages arising out of your use or access to any third-party website, service, or Content.
            <Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>10. CHANGES TO OUR SERVICE.</Typography>
            We reserve the right to provide new Services and product features, as well as to modify, alter, or terminate current Services and product features, at any time and in any area, with or without notice to you. If you are unhappy with our Services or a particular feature, your only recourse is to discontinue using them.
            <Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>11. RESTRICTIONS FROM OUR LICENSORS.</Typography>
            Certain information and services made accessible through Nearcast are licensed or sponsored by third parties. These  third-party terms and disclosures from third parties apply to your use of the Services.
            <Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>12. INDEMNIFICATION.</Typography>
            <Typography variant="body1" style={{ marginTop: '20px' }}>
                You agree to defend, indemnify, and hold harmless Nearcast and its affiliates, related companies, officers, directors, employees, agents, representatives, partners, and licensors (collectively, the "Nearcast Entities") from and against any and all claims, liabilities, damages, losses, and expenses, including without limitation reasonable attorney's fees and costs, made by any third party due to or arising out of any and all of the following:
                <br />(a) your access to and use of the Services;
                <br />(b) a breach of these Terms by you;
                <br />(c) your failure to comply with relevant laws or regulations;
                <br />(d) your infringement of any third party's rights, including those of any Members;
                <br />(e) the interactions and transactions between you and other Members; or
                <br />(f) Member Content created by you.
                <br />We retain the right to direct the defense of any issue for which you are obligated to indemnify us, and you undertake to cooperate with our defense and not to settle any claim without our prior written permission. You agree that this section's provisions shall survive any termination of your Account(s), the Terms, or your access to the Services.
            </Typography>
            <Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>13. DISCLAIMERS; LIMITATION OF LIABILITY.</Typography>
            <Typography variant="body1" style={{ marginTop: '20px' }}>
                a. <b>Disclaimers.</b> There are no warranties. You acknowledge and agree that, to the extent allowed by applicable law, your access to and use of the Services or any Content is entirely at your own risk. Our Services are offered to you "AS IS" and "AS AVAILABLE," without warranty of any kind. WITHOUT LIMITING THE FOREGOING AND TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, THE Nearcast ENTITIES EXPRESSLY DISCLAIM ALL WARRANTIES, REPRESENTATIONS, AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
                <br />The Nearcast Entities make no guarantee or assurance and disclaims all liability and responsibility for:
                <br />(i) the Services' completeness, correctness, availability, timeliness, security, or dependability;
                <br />(ii) any damage to computer systems, data loss, or other harm incurred as a consequence of access to or use of the Services;
                <br />(iii) the deletion of, or the inability of the Services to store or transmit, any Content or other communications;
                <br />(iv) viruses or other potentially destructive components transmitted via the Services; and
                <br />(v) whether the Services will fulfill needs or be provided without interruption, in a secure environment, or without errors.
                <br />We cannot guarantee uninterrupted or secure access to the Services, and their operation may be disrupted by a variety of reasons outside our control. Any advice or information, whether oral or written, received from the Nearcast Entities or via the Services will not constitute any guarantee not explicitly stated in this Agreement.
                <br />b. <b>Liability Limits.</b> YOU UNDERSTAND AND AGREE THAT, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, THE Nearcast ENTITIES SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, OR FOR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR FOR ANY LOSS OF DATA, USE,
                <br />(I) YOUR ACCESS TO, USE OF, OR INABILITY TO ACCESS, USE, OR INABILITY TO ACCESS, USE, OR INABILITY TO ACCESS, USE, OR INABILITY TO ACCESS, USE,
                <br />(II) ANY THIRD PARTY CONDUCT OR CONTENT ON THE SERVICES, INCLUDING ANY DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF OTHER MEMBERS OR THIRD PARTIES;
                <br />(III) ANY CONTENT OBTAINED THROUGH THE USE OF THE SERVICES;
                <br />(IV) ACCESS, USE, OR ALTERATION OF YOUR ACCOUNT, TRANSMISSIONS, OR CONTENT BY UNAUTHORIZED MEMBERS. IN NO EVENT SHALL THE AGGREGATE LIABILITY OF Nearcast ENTITIES EXCEED THE GREATER OF (A) THE TOTAL AMOUNT PAID OR PAYABLE TO Nearcast BY YOU FOR THE SERVICES DURING THE SIX (6) MONTH PERIOD PRIOR TO THE ACT, OMISSION, OR OCCURRENCE GIVING RISE TO SUCH LIABILITY; OR (B) ONE HUNDRED POUNDS STERLING (GBP 100) (OR THE EQUIVALENT IN LOCAL CURRENCY).
                <br />c. <b>No Liability for Conduct of other Members.</b> YOU ARE SOLELY RESPONSIBLE FOR YOUR COMMUNICATIONS AND INTERACTIONS WITH OTHER MEMBERS OR USERS OF THE SERVICES. YOU AGREE THAT Nearcast MAKES NO ATTEMPT TO VERIFY MEMBERS' OR OTHER USERS' STATEMENTS. Nearcast MAKES NO WARRANTY THAT THIRD PARTY GOODS OR SERVICES WILL MEET YOUR REQUIREMENTS OR WILL BE AVAILABLE ON AN UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS. Nearcast MAKES NO WARRANTY AS TO THE QUALITY OF SUCH GOODS OR SERVICES, NOR AS TO THE ACCURACY, TIMELINESS, TRUTHFULNESS, COMPLETENESS, OR RELIABILITY OF ANY CONTENT MADE AVAILABLE ON OR THROUGH THE SERVICES BY THIRD PARTIES.
                <br />d. <b>Nearcast Is Not a Broker.</b> Nearcast does not offer financial or real estate advice and is not intended to do so. Nearcast is not a member's attorney, escrow agent, lender, or real estate broker. Nearcast AND OUR AFFILIATES MAKE NO REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED, AS TO THE EXISTENCE, OWNERSHIP, LEGAL STATUS (INCLUDING, BUT NOT LIMITED TO, COMPLIANCE WITH BUILDING CODES AND ACCESSIBILITY LAWS), SUITABILITY, OR CONDITION OF ANY PROPERTY LISTED ON Nearcast, OR AS TO THE ACCURACY OR COMPLETENESS OF ANY INFORMATION ABOUT A PROPERTY.
                <br />e. <b>Exclusion of Damages.</b> EXCLUSION OR LIMITATION OF CERTAIN DAMAGES IS NOT PERMITTED IN SOME JURISDICTIONS. IF YOU ARE A PERSON SUBJECT TO THESE LAWS, SOME OR ALL OF THE ABOVE EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
            </Typography>
            <Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>14. GOVERNING LAW AND DISPUTE RESOLUTION.</Typography>
            <Typography variant="body1" style={{ marginTop: '20px' }}>
                a. <b>Governing Law.</b> These Terms are governed by and construed in accordance with English law.
                <br />b. <b>Dispute Resolution.</b> If a dispute arises between you and Nearcast, our goal is to provide you with a neutral and cost‐effective means of resolving the dispute quickly. Accordingly, if you have a dispute with Nearcast, you agree to contact us and try to resolve the dispute informally before pursuing other avenues.
                <br />c. <b>Consumers.</b> If you are accessing the Services as a consumer and if mandatory statutory consumer protection regulations in your country of residence contain provisions that are more beneficial for you, such provisions shall apply irrespective of the choice of English law. As a consumer, you may bring any judicial proceedings relating to these Terms before the competent court of your place of residence or the competent court of Nearcast’s place of business. If Nearcast wishes to enforce any of its rights against you as a consumer, we may do so only in the courts of the jurisdiction in which you are a resident.
                <br />d. <b>Businesses; Arbitration.</b> If you are accessing the Services in a commercial or professional capacity (including as or on behalf of a Business (as defined in the Business Services Terms)), you and Nearcast agree that any dispute arising out of or in connection with these Terms or the use of the Services, including any question regarding its existence, validity or termination, shall be referred to and finally resolved by arbitration under the LCIA Arbitration Rules (the “Rules”) which Rules are deemed to be incorporated by reference into this Section. The number of arbitrators shall be one (to be appointed by the LCIA); the seat, or legal place, of arbitration shall be London; and the language to be used in the arbitral proceedings shall be English. For these purposes, it is expressly acknowledged that the governing law of the Terms shall be the substantive law of England. If you do not agree to this Section, you must not use the Services.
            </Typography>
            <Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>15. GENERAL.</Typography>
            <Typography variant="body1" style={{ marginTop: '20px' }}>
                a. <b>Term and Termination.</b> The Terms take effect on the day you accept them (as stated in the preamble above) and continue in full force and effect for the duration of your access to or use of the Services, unless cancelled sooner in accordance with the Terms.
                <br />i. Termination by You. by deactivating your Account(s) and discontinuing your use of the Services, You may terminate these Terms with Nearcast at any time .
                <br />ii. Termination by Nearcast. We reserve the right to suspend, terminate, delete, or deactivate your Account(s) or to discontinue providing you with all or part of the Services at any time, with or without notice and without liability to you.
                <br />iii. Survival. Section 4 (Rights You Grant), Section 12 (Indemnification), Section 13 (Disclaimers; Limitation of Liability), Section 14 (Governing Law and Dispute Resolution), and Section 15 (General) of these Terms will survive termination, whether by you or us.
                <br />b. <b>Member Support.</b> We value your comments, concerns, ideas, and recommendations. Please visit <Link to="/faqs">www.nearcast.com/faqs</Link> to find answers to common questions or email us at <a href="mailto:support@nearcast.com">support@nearcast.com</a>.
                <br />c. <b>Infringement.</b> If you feel that any of the Content on the Services violates your copyright or trademark, you may contact our notice agent at Nearcast, Inc., 1580 West El Camino Real Suite 10, Mountain View California 94040. Phone: +1 650 996-1114; Email: copyright@nearcast.com. Please review our EEA+ Trademark and Copyright Policy for additional information.
                <br />d. <b>Integration.</b> These Terms, together with our Community Guidelines, applicable Business Services Terms, and other Supplemental Terms and policies referenced herein, constitute the entire agreement between you and us regarding our Services and supersede any prior agreements or understandings (oral or written), except that in the event of a conflict between these Terms and Supplemental Terms, the Supplemental Terms shall control exclusively with respect to the Services to which they apply.
                <br />e. <b>Updates.</b> We retain the right, at any time, to modify these Terms and any Supplemental Terms. We will inform you of major changes as required by law (for example, through email or by publishing an amendment notice to your neighborhood's newsfeed). These modifications to the Terms and Supplemental Terms will take effect immediately for new Visitors. Unless otherwise specified, changes to relevant rules and standards take effect immediately. If you continue to use Nearcast after the effective date of a modification to the Terms or any relevant Supplemental Terms, you accept and agree to be bound by the new terms; if you do not agree to the new terms, you must immediately cease using the Services and cancel your Account (s).
                <br />f. <b>Notices.</b> Under these Terms, notices to Nearcast must be in writing and addressed to Nearcast at the address specified below. Notices are considered given upon human delivery, upon postal delivery, or upon valid email transmission.
                <br />g. <b>No Agency.</b> These Terms do not establish between you and Nearcast any agency, partnership, joint venture, joint controllership, employment, or franchise relationship.
                <br />e. <b>Miscellaneous.</b> To the extent permitted by relevant legislation, only the English version of these Terms is binding, and any other translations are provided for convenience purposes only. Without our prior written permission, you may not transfer any of your rights or responsibilities under these Terms, which consent may be withheld in our sole discretion. If any term of these Terms is found to be unenforceable, it will be severed from these Terms and will have no effect on the legality and enforceability of the other sections. The Contracts (Rights of Third Parties) Act 1999 shall apply only to these Terms in relation to the Nearcast Entities, and no person other than you, us, and the Nearcast Entities shall have any rights under them, and they will be unenforceable against anyone other than you, us, and the Nearcast Entities. Our failure to act in a specific case does not constitute a waiver of our right to act in that or subsequent situations. We will be absolved from any liability arising from an event beyond our reasonable control. You represent and warrant on an ongoing basis and at all relevant times that you are not, nor is any individual with access to your Account, nor is any entity or person that (directly or indirectly) owns, controls, or is affiliated with you, acts on your behalf or is otherwise associated with you, subject to financial, economic or trade sanctions or embargoes, or otherwise designated on any list of prohibited or restricted parties or territories, including any such lists maintained from time to time by the United Nations Security Council, the UK Government, US Government, the Australian Government, the Canadian Government, the European Union or its member states or any member states of the European Free Trade Association, or other applicable government authority. If, at any point in time, the guarantee set out in the previous article ceases to be true, full, and accurate, you must promptly inform us.
            </Typography>
        </div>
  )
}

const styles = makeStyles(({ breakpoints, palette }) => ({
  flex: {
    display: 'flex',
    alignItems: 'center'
  },
  flexGrow: {
    flex: '1'
  },
  mainContainer: {
    padding: '40px',
    paddingBottom: '60px'
  }
}))

export default EEAMemberAgreement
