import { FormControl } from '@mui/material'
import React, { useState } from 'react'
import CustomTextArea from 'ui/TextInputs/TextAreaInput'
import { sTypes } from '../AddStickers'
import StandardModal from 'ui/Modals/StandardModal'
import { useSnackbar } from 'notistack'

function CustomUrlSticker ({ open, onClose, handleStickerAdd }) {
  const [custom_name, setText] = useState('')
  const [custom_url, setCustomUrl] = useState('')

  const { enqueueSnackbar } = useSnackbar()

  const handleClick = () => {
    if (custom_name.trim().length === 0 || custom_url.trim().length === 0) {
      enqueueSnackbar('All fields are mandatory 🛑', {
        variant: 'error',
        autoHideDuration: 2000
      })
      return
    }
    if (custom_name.trim().length !== 0 && custom_url.trim().length !== 0) {
      enqueueSnackbar('Your sticker has been added ✅', {
        variant: 'success',
        autoHideDuration: 2000
      })
    }

    handleStickerAdd({ type: sTypes.custom, custom_url, custom_name })
    onClose()
  }

  return (
        <StandardModal
			isOpen={open}
			onClose={onClose}
			title="Custom URL"
			buttons={[
			  {
			    title: 'Add Sticker',
			    color: 'primary',
			    onPress: handleClick
			  }
			]}
		>
			<div style={{ marginTop: 20, marginBottom: 20, width: '100%' }}>
				<FormControl fullWidth margin="none">
					<CustomTextArea
						label="Link Name"
						placeholder="Link Name"
						value={custom_name}
						onChange={(e) => setText(e.target.value)}
						type="number"
					/>
				</FormControl>
				<FormControl fullWidth margin="none">
					<CustomTextArea
						label="Link URL"
						placeholder="Link URL"
						value={custom_url}
						onChange={(e) => setCustomUrl(e.target.value)}
						type="url"
					/>
				</FormControl>
			</div>
		</StandardModal>
  )
}

export default CustomUrlSticker
