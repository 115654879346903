import { FormControl } from '@mui/material'
import React, { useState } from 'react'
import MuiPhoneNumber from 'material-ui-phone-number'

// importing ui
import CustomPriceFields from 'ui/TextInputs/PriceFieldsInput'
import StandardModal from 'ui/Modals/StandardModal'
import { useSnackbar } from 'notistack'

const TextInputSticker = ({ title, open, onClose, handleStickerAdd, input }) => {
  const [value, setValue] = useState(input.value)
  const { enqueueSnackbar } = useSnackbar()

  const handleClick = () => {
    if (!value) {
      enqueueSnackbar('All fields are mandatory 🛑', {
        variant: 'error',
        autoHideDuration: 2000
      })
	  return
    } if (value) {
      enqueueSnackbar('Your sticker has been added ✅', {
        variant: 'success',
        autoHideDuration: 2000
	   })
	  }
    handleStickerAdd(value)
    onClose()
  }
  return (
    <StandardModal
      isOpen={open}
      onClose={onClose}
      title={title}
      buttons={[
        {
          title: 'Add Sticker',
          color: 'primary',
          onPress: handleClick
        }
      ]}
    >
      <div style={{ marginTop: 20, marginBottom: 20, width: '100%' }}>
        <FormControl fullWidth margin="none">
          {input.type === 'tel'
            ? (
                <MuiPhoneNumber
                  defaultCountry={'us'}
                  id="phone"
                  variant="filled"
                  size="small"
                  value={value}
                  InputProps={{ hiddenLabel: true, disableUnderline: true }}
                  onChange={(value) => {
                    setValue(value.replace(/\s|-|\(|\)/g, ''))
                  }}
                  style={{
                    borderRadius: '8px'
                  }}
                  disableAreaCodes={true}
                />
              )
            : (
                <CustomPriceFields
                  label={input.title}
                  type={input.type}
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  {...input.textInputProps}
                />
              )}
        </FormControl>
      </div>
    </StandardModal>
  )
}

export default TextInputSticker
