import { Button, Dialog, DialogContent } from '@mui/material'
import React from 'react'

const ActionModal = ({
  action,
  open,
  onClose,
  onConfirm,
  onCancel,
  children
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="action-prompt"
      PaperProps={{
        style: {
          maxWidth: 400,
          minWidth: 280,
          width: '280px',
          padding: 10,
          textAlign: 'center',
          borderRadius: 10
        }
      }}
    >
      <DialogContent>
        <>
          <div
            style={{
              borderRadius: 36,
              width: 72,
              height: 72,
              display: 'grid',
              justifyItems: 'center',
              alignItems: 'center',
              margin: 'auto'
            }}
          >
            {children}
          </div>
          <div>{action.title}</div>
          <br />
        </>
        <div style={{ display: 'flex' }}>
          <Button
            color="ghost"
            variant="contained"
            disableElevation
            onClick={onCancel}
            style={{ marginInline: '10px', paddingInline: '40px' }}
          >
            {action.Decline}
          </Button>
          <Button
            variant="contained"
            onClick={onConfirm}
            disableElevation
            style={{ marginInline: '10px', paddingInline: '40px' }}
          >
            {action.Accept}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default ActionModal
