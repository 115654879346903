import React, { useState, useContext, useEffect } from 'react'
import { Stack, Typography, FormControl, InputAdornment, Button } from '@mui/material'
import { loadStripe } from '@stripe/stripe-js'
import { Elements, CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useSnackbar } from 'notistack'

// importing ui
import PriceFieldsInput from 'ui/TextInputs/PriceFieldsInput'
import StandardModal from 'ui/Modals/StandardModal'

// importing api
import { postBuy } from 'api/post/buy'
import { donationsIntentFetch } from 'api/donations'

// importing component
import { UserContext } from '../../context/UserContext'
import Success from '../../assets/success.svg'

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      // color: "#32325d",
      color: 'black',
      fontFamily: '"Avenir next", sans-serif',
      backgroundColor: 'transparent',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      fontWeight: 'regular',
      '::placeholder': {
        fontWeight: 'regular',
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
}

const PaymentModal = ({ onClose, postId, isDonate, price, currency }) => {
  const stripe = useStripe()
  const elements = useElements()
  const { enqueueSnackbar } = useSnackbar()
  const { personal, jwt_token, active_neighbourhood, user } = useContext(UserContext)

  // component state
  const [step, setStep] = useState("pay") // "pay", "success"
  const [btnLoading, setBtnLoading] = useState(false)
  const [amount, setAmount] = useState(price)
  const [paymentProcessingError, setPaymentProcessingError] = useState(null)

  const _onClose = () => {
    setStep("pay")
    setBtnLoading(false)
    setPaymentProcessingError(null)
    onClose()
  }

  const handleSubmit = async () => {
    // make sure both stripe and element are initialized
    if (!stripe || !elements || btnLoading) {
      return
    }

    setBtnLoading(true)

    try {
      let paymentIntent;
      if (isDonate) {
        paymentIntent = await donationsIntentFetch(postId, amount, personal[active_neighbourhood].ID, jwt_token)
      } else {
        paymentIntent = await postBuy(postId, personal[active_neighbourhood].ID, jwt_token)
      }

      // make sure we have payment stripe secret
      if (!paymentIntent.secret) {
        throw new Error('Payment failed, our servers could not process your payment at the moment. Please try again later.')
      }

      // get card element in the UI
      const cardElement = elements.getElement(CardElement)
      if (!cardElement) {
        throw new Error('Payment failed, we could not get your card details. Please try again later.')
      }

      // all good, process the payment with stripe
      const result = await stripe.confirmCardPayment(paymentIntent.secret, {
        payment_method: {
          card: cardElement
        },
        receipt_email: user.email
      })

      // make sure payment did not error out
      if (result.error) {
        throw new Error(result.error.message)
      }

      // check if payment was successful 
      if (result.paymentIntent.status === 'succeeded') {
        enqueueSnackbar('The payment was successful, receipt of payment is sent on your email.', {
          variant: 'success',
        })
        setStep("success")
      }
      console.log(result)
    } catch (err) {
      enqueueSnackbar(`${err.message} Try again with another card?`, {
        variant: 'error',
      })
      setPaymentProcessingError(`${err.message} Try again with another card?`)
    } finally {
      setBtnLoading(false)
    }
  }

  useEffect(() => {
    setStep("pay")
    setPaymentProcessingError(null)
  }, [])

  useEffect(() => {
    setAmount(price)
  }, [postId, isDonate, price])

  if (step === "pay") {
    return (
      <StandardModal
        title="Pay with Card"
        isOpen={Boolean(postId)}
        onClose={_onClose}
        buttons={[
          {
            title: !btnLoading ? 'Make Payment' : 'Processing',
            color: 'primary',
            onPress: () => {
              if (btnLoading) {
                return
              }
              handleSubmit()
            }
          }
        ]}
      >
        <Stack direction="column" sx={{ marginBottom: 2 }}>
          {isDonate
            ? (
                <FormControl
                  fullWidth
                  margin="normal"
                >
                  <PriceFieldsInput
                    label="Donate"
                    placeholder="10"
                    value={amount}
                    onChange={(e) => {
                      setAmount(
                        e.target.value >= 999999
                          ? Math.floor(e.target.value / Math.pow(10, parseInt(e.target.value.length) - 6))
                          : e.target.value
                      )
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        {currency.symbol}
                      </InputAdornment>
                    }      
                  />
                </FormControl>
              )
            : (
              <>
              <Typography variant="h6">
                Amount Due:
                </Typography>
                <Typography variant="h3">
                   {currency.symbol}{amount}
                </Typography>
                </>
              )}
          <Stack
            direction="column"
            sx={{
              marginTop: 2
            }}
          >
            <CardElement
              options={CARD_ELEMENT_OPTIONS}
              onChange={() => {
                setPaymentProcessingError(null)
              }}
            />
            {paymentProcessingError && (
              <Typography variant="caption" color="error" textAlign="left">
                {paymentProcessingError}
              </Typography>
            )}
          </Stack>
        </Stack>
      </StandardModal>
    )
  }

  return (
    <StandardModal
      isOpen={Boolean(postId)}
      onClose={_onClose}
    >
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          marginBottom: 2
        }}
      >
        <Typography
          variant="h3"
          textAlign="center"
        >
          Payment Successful
        </Typography>
        <Typography
          variant="caption"
          textAlign="center"
          sx={{
            marginTop: 2
          }}
        >
          Hooray! You have completed your payment 🎉
        </Typography>
        <img
          src={Success}
          style={{
            width: '160px',
            marginTop: '16px'
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={_onClose}
          disableElevation
          sx={{
            marginTop: 5
          }}
        >
          Continue Watching Nearcasts
        </Button>
      </Stack>
    </StandardModal>
  )
}

PaymentModal.defaultProps = {
  price: 10,
  currency: {
    code: '',
    name: '',
    symbol: ''
  }
}

// eslint-disable-next-line react/display-name
export default (props) => {
  // why? Component calling useStripe should be wrapped in stripe context
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)
  return (
    <Elements stripe={stripePromise}>
      <PaymentModal {...props} />
    </Elements>
  )
}
