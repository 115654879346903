import {
  Typography,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

function PrivacyPolicy () {
  const classes = styles()

  return (
        <div className={classes.mainContainer}>
            <Typography variant="h2">
                Nearcast Privacy Policy
            </Typography>
            <Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>
                1. Our Service
            </Typography><Typography variant="body1" style={{ marginTop: '20px' }}>

                Welcome to Nearcast.  Nearcast’s mission is to create joy through shared in-person experiences between nearby residents.

                People use Nearcast to participate in shared activities with people living around them, to discover gatherings around them, and to express themselves through short-videos set to music.

                Our Privacy Policy (“Privacy Policy”) can be found here, and we encourage you to read it in its entirety to fully understand how and when we gather your personal information through our services on Nearcast, and exactly what it is used for.

                “Nearcast” refers to our iOS and Android apps, and our website (“Services”). This policy may also use “Nearcast,” “we,” “us,” and “our” to refer to the companies that operate the Services as described in the Member Agreement.
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>
                2. What information is collected?
            </Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                We may collect personal information from you and third party sources through technology which automatically collects information from your device or browser (examples below). Not all Nearcast account types and features are available in
                all locations, so some of the examples below may not apply where you live. We may also collect additional information not specifically referenced in this Privacy Policy, which we will use consistent with this Privacy Policy or as defined when collected.
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>
                2.1. From you.
            </Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                -&gt; Your contact details.

                <br /><br /> - During the signup process, or in the modification of your account settings, your name, address, phone number, profile photo, email address, location, date of birth, gender, demographic information, and similar information may be provided to Nearcast.

                <br /><br /> - Prior to the introduction of Nearcast in your community you can give us your contact information and have us notify you when we introduce Nearcast to your community.

                <br /><br /> - Should you need to contact support we may have you provide us with your contact information and a description of your issue so we can respond promptly and properly.

                <br /><br /> -&gt; Data from other accounts.

                Should you use a third party login to use Nearcast (Facebook, Google, Apple, etc.) or connect a third party account to your Nearcast account, then you authorize that third party to share your personal information with us including but not limited to your name, photos, email address, and any relevant information you agree to share.

                <br /><br /> -&gt; Posts, interests, location, and recommendations.

                <br /><br /> - Any information that you share within your posts, including your interests, skills, pictures, information about your household, biographical information, emergency contact, or your thoughts and opinions.

                <br /><br /> - Information about your personal interests, including any groups you may join, or topics you engage with.

                <br /><br /> - Posts or private messages may be made or sent by you. These posts may consist of text elements, photos, video, tags for people/places, offering/requesting sales, and any other content you may share.

                <br /><br /> - Users may post about businesses in their area through recommendations, comments, or business pages.

                <br /><br /> -&gt; Payment information. Payments made to or through Nearcast will provide any third-party processors with your payment information for the purpose of the transaction in question.
                <br /><br /> -&gt; Survey data. Nearcast may occasionally send requests for participation in surveys or other research which users may opt into. Should you participate in these surveys or research, we may save your responses, information, feedback, and any comments you may respond with.
                <br /><br /> -&gt; Contests, sweepstakes, and prize drawings data. Any feedback, information or comments you make regarding a contest, sweepstakes, or prize drawing that Nearcast runs.
                <br /><br /> -&gt; Messages and email data. Any information relating to emails or messages that you send, receive, or engage with regarding our Services including whether you open or act on those messages.
                <br /><br /> -&gt; Photos and videos. Permission will be requested for our Service to collect videos, images, and other information from your device’s camera and photos. This information may include metadata indicating how, when, where and by whom the files were created and how they are formatted. Metadata makes your files more searchable and interactive, but may be accessible to other users or anyone else who can access the files.
                <br /><br /> -&gt; Contact lists. Your permission will be requested for Nearcast to sync your contacts with our service, including the names, email addresses, physical addresses, and phone numbers of those contacts. You may also enter contact information of your neighbors manually. We use this information to make your experience better, allowing you to see which of your contacts are using Nearcast already, allow you to invite contacts that are not yet using Nearcast, and receive notifications when your contacts join.
                <br /><br /> -&gt; Geolocation. Nearcast will ask if our Services can access your device’s geolocation, which allows us to confirm your address and provide you with a better service. This information may be used to operate or personalize our Services to you, including tagging of posts, adding information to a map, and helping us to show you more contant or ads that are relevant to you.
                <br /><br /> -&gt; Business information. If you choose to share details about a business including your personal association with that business, or services that business offers.
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>2.2. From your browser and device.</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                With your permission when required by applicable laws Nearcast and our service providers and partners collect technical information when you use our Services or visit our website, engage with our partners, or interact with our or our partners’ marketing communications, including:
                <br /><br /> -&gt; Device data, such as your device’s manufacturer and model, RAM and disk size, operating system and version, screen resolution, CPU usage, unique identifiers (including those used for advertising), crash data, device settings,radio/network information,  carrier, and location information.
                <br /><br /> -&gt; Online activity data, such as the time/date you accessed Nearcast, the browser/app version you used (and its settings), the URLs you came from and went to, and how you engaged with our emails and other messages (for example, how you navigated and which links you clicked on).
                For additional information on which of the aforementioned data is facilitated by cookies and similar technologies, see our Advertising and Cookie Policy.
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>2.3. From third parties and public sources.</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                We may obtain information from third parties, such as service providers and partners, as well as public sources:
                <br /><br /> -&gt;Nearcast users may sync their contacts, including email addresses, mailing addresses, and phone numbers, to our Services.
                <br /><br /> -&gt; Vendors may offer us map and residential address data to help us launch Nearcast in new areas.
                <br /><br /> -&gt; For advertising, analytics, personalization, and measurement purposes, vendors may disclose information about users and their homes.
                <br /><br /> -&gt; Vendors and partners give us information about businesses so that we may construct business pages and provide our services to them.
                <br /><br /> -&gt; Advertisers give us information about consumers or potential consumers so that we may tailor ads and track how well they function.
                <br /><br /> -&gt; Census data, street addresses, property lot maps, and postal address change information are examples of public sources that supply us with information that helps us better understand our members, improve our services, and identify possible expansion locations..
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>3. How is my information used?</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                Personal data is used for the following purposes, unless explicitly stated at the time of collection:
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>3.1. Establishing and developing neighborhoods.</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                Personal information is used to identify and invite potential new Nearcast Members, as well as to create new Nearcast neighborhoods. You can, for example, ask us to send invites and reminders on your behalf to potential new Members, send direct mail invitations, invite your friends, or alert you when we begin offering the Services in your community.
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>3.2. Setting you up on Nearcast.</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                We utilize personal information to:
                <br /><br /> -&gt; Checks against third-party data and public records, verification codes, and geo-verification can all be used to confirm Member accounts. We also use public registers to prevent registered sex offenders from signing up for the Services in the United States (and other countries where authorized by law). To confirm Member accounts, we may need extra information (such as government-issued identification or company papers) in some cases.
                <br /><br /> -&gt; As new Members join the neighborhood, notify their neighbors.
                <br /><br /> -&gt; Personalize Nearcast by populating Member profiles.
                <br /><br /> -&gt; Create and provide Business Services that assist Members in learning about businesses in their communities. Click <a href="https://business.nearcast.com">here</a> to claim your Business Page.
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>3.3. Provide and improve the Services.</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                We analyze and use your Content (defined in Member Agreement) and other information using both manual and automated systems, including:
                <br /><br /> -&gt; To run Nearcast, establish neighborhoods, make neighborhood suggestions, invite neighbors, and develop our goods and features.
                <br /><br /> -&gt; To assist you in creating, publishing, viewing, sharing, and responding to content, as well as to highlight intriguing postings.
                <br /><br /> -&gt; To draw attention to specific groups, topics in the community, or other resources.
                <br /><br /> -&gt; Surveys, sweepstakes, contests, and other promotional activities are used to collect feedback and administer them.
                <br /><br /> -&gt; Businesses, advertising, offers, products, and services that you might be interested in are highlighted.
                <br /><br /> -&gt; To offer Business Services, such as the creation of Business Pages and promotional services.
                <br /><br /> -&gt; To make your experience more unique.
                <br /><br /> -&gt; To gain a better understanding of the demographics, trends, and usage statistics of the Members and neighborhoods we serve.
                <br /><br /> -&gt; To answer your requests for assistance and feedback.
                <br /><br /> -&gt; To put prospective new items and features to the test.
                <br /><br /> -&gt; Diagnose and fix issues, assess trends, and keep track of usage and resource requirements.
                <br /><br /> -&gt; Taking payments via our third party payment processors.
                <br /><br /> -&gt; To make sending urgent alerts easier.
                <br /><br /> -&gt; To communicate with you about the Service, such as by sending you email, notifications, and text messages with announcements, updates, security alerts, and support messages.
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>3.4. Create and maintain a trusted environment.</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                We may use your information in the following ways to help develop and maintain a trustworthy environment on Nearcast:
                <br /><br /> -&gt; Fraud, spam, abuse, security issues, and other damaging, unauthorized, unethical, or illegal conduct are all detected and prevented.
                <br /><br /> -&gt; Conducting security investigations, audits, and risk assessments.
                <br /><br /> -&gt; Complying with our legal obligations.
                <br /><br /> -&gt; Confirming or authenticating information/identification.
                <br /><br /> -&gt; The legal and safety reasons in Section 4.3.
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>3.5. Advertising.</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                We utilize your information on and off the Services to provide and improve ads and other commercial offerings:
                <br /><br /> -&gt; To aid in the personalization of adverts based on what we believe may be of interest to you.
                <br /><br /> -&gt; Personalized advertising, including whether it is appealing to Members and effective for the advertiser, will be provided and measured.
                <br /><br /> -&gt; To make advertisement personalization better.
                <br /><br /> -&gt; We will use your information to send you promotional messages, marketing, advertising, and other material that we believe will be of interest to you.
                <br /><br /> -&gt; To invite you to relevant events and opportunities.
                We may tailor advertising offers you see on Nearcast based on data from our advertisers or partners, local demographics, or your actions, selections, or preferences on the platform. A home services firm, for example, might give a list of addresses that are qualified for a special deal that we could offer to Nearcast Members who live at those addresses. If you search Nearcast for a specific product, we may display you adverts for that product's suppliers, as well as related or comparable products. As mentioned in Section 4.4 below, our third-party advertising partners use cookies and similar technologies to collect information about Members for advertising purposes.
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>3.6. Research and Development.</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                Your personal information may be used for research and development reasons. We may construct aggregated, de-identified, and/or other anonymous data from personal information we gather as part of these activities. By deleting information that makes the data personally recognizable to you, we turn it into anonymous data. We may use and share this anonymous data with third parties for our legitimate business objectives, such as to analyze and enhance the Services and promote our company.
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>4. Is my information shared with third parties?</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                Personal information is shared in the following ways:
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>4.1. Content shared on Nearcast.</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                Nearcast is a platform for neighbors to share helpful information with each other. To that end, the Content you post on Nearcast, such as posts, replies, recommendations, messages, and your profile information, may be visible to other Members, businesses, governments and agencies, or the public, including Visitors (as defined in the Member Agreement) and search engines. You can adjust the visibility of your posts in the preferences in your settings, the preferences on the Content you provide, and as described in Section 5 below. Please be sure to read the options you have under the “What are My Choices” section below. If this information is cached by third party search engines or other third parties, please note we do not control the practices of these third parties, and they may use caches containing outdated information. Please consult these third parties’ privacy policies.
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>4.2. Account confirmation.</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                To verify your account, we may share your personal information with verification partners such as telecommunications companies (if you want to validate your account by entering your phone number) and data providers.
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>4.3. Legal and safety reasons.</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                If we have a good-faith belief that it is reasonably necessary to: (a) respond, based on applicable law, to a legal request (e.g., a subpoena, a search warrant, a court order, or other request from government or law enforcement); (b) detect, investigate, prevent, and address fraud and other unauthorized, illegal, or unethical activity, we may retain, preserve, or share your Content and personal information with law enforcement, government authorities, and private For example, to aid disaster or emergency response operations, we may share your personal information with disaster relief agencies, first responders, and other individuals.
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>4.4. Advertising.</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                We may share your response and limited Member profile information with the advertiser if you see, click on, or reply to an advertisement or other offer on the Services, and we may log your engagement to track the overall efficacy of the advertisement. We may also use your contact information to pre-populate responses to ads you click on, but we never share that information with the advertiser without your permission.
                Third-party advertising networks help to fund the Services. Our advertising partners, with your permission, use cookies and other similar technologies to collect information about our Members and their devices over time while using Nearcast and other online services. We may share limited personal information, such as hashed emails and advertising IDs, with our advertising partners who help offer targeted online advertising to those Members or similar audiences, when permitted by applicable law. Based on your advertising IDs or other personal information they have about you, our advertising partners may be able to link this information to you. Our advertising partners then utilize the data they acquire to serve targeted ads to our members on Nearcast and other websites and services, as well as track the performance of the ads.
                <br />
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>4.5. Third party partners.</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                Nearcast may form partnerships with third parties to allow Members to book a variety of services, including home improvement, dog walking, personal training, and tuition. These offers may be embedded within Nearcast or may be linked to other websites or applications that provide the offers. We may share certain personal information with third parties in order to customise these offers to you, such as your area or zip code, the business topic of the material you are seeing, or a business search query (for example, a search for "plumber"). When you take advantage of one of these offers, you may give the third-party partner additional information (such as your contact information). If you use Nearcast to engage with third-party partners, the third-party partners may share certain personal information about you with us so that we can coordinate, assess, and enhance the services we provide to you. This could include details regarding a booking you made with a third party, such as the type of service, the provider's name and address, and the date and time of the booking. These third parties' privacy policies will apply to your activities if you click on or engage with their offerings on Nearcast or other websites or apps.
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>4.6. Service providers.</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                To help us operate, provide, improve, understand, customize, support, and market our Services, we may share your personal information with service providers and/or data processors. These service providers and data processors are contractually obligated to use it solely to deliver services to us and are barred from using it for any other purpose. Payment processing, information technology, cybersecurity, fraud protection, hosting, customer relationship management and support, communications delivery, marketing, advertising, and website and mobile application analytics are just some of the services they offer.
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>4.7. Social Media.</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                Many social media features, such as a social media share button, are included in our Services. If you use the social media share button to share a post, information about that post will be shared with the appropriate social media platform.
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>4.8. Within the Nearcast corporate family.</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                To offer, maintain, and improve Services, we may share your information throughout our corporate network, including between Nearcast and international subsidiaries. It's possible that some of these businesses aren't based in your native nation. We may expand the corporate family within which we share your information as we grow and Nearcast becomes available in other countries, by creating local subsidiaries or other affiliates in these countries as needed to assist us provide Nearcast there.
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>4.9. Business reorganization.</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                We may need to share your personal information with relevant parties in connection with a corporate divestiture, merger, consolidation, acquisition, restructuring, reorganization, or sale or other disposition of some or all of Nearcast's assets or equity interests (or potential transactions) (including, in connection with a bankruptcy or similar proceedings).
                4.10. Professional advisors.
                In the course of providing professional services to us, we may share personal information with our professional advisors, such as lawyers, accountants, bankers, and insurance.
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>5. What are my choices?</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                Using your account settings, you may control how much of your personal information, such as your home address's street number, is shared in your personal profile and accompanying your posts. These account settings can be changed at any time.
                When you publish anything on Nearcast, you have the option of controlling who sees it. Some of these options, such as our Business Pages, may not be available for our Business Services.
                By altering your cookie settings or changing the privacy settings on your mobile device, you can opt out of third-party targeted advertising. See our Advertising and Cookie Policy for more information on third-party use of cookies, mobile advertising identifiers, and similar technologies, as well as how to modify your settings. Because no industry-wide standard for dealing with "Do Not Track" signals has been adopted, our systems are not currently equipped to receive them.
                For additional information about your legal rights if you live in a listed jurisdiction, see our jurisdiction-specific disclosures in Section 11 below.
                <br />
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>6. How long is my information kept?</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                For as long as you use our Services we keep your information in our server logs, databases, and records. Some of your information may be kept for a longer amount of time if we need to comply with legal or regulatory responsibilities, resolve disputes or defend against legal claims, or enforce our Member Agreement.
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>7. How is my information protected?</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>7.1. Account security.</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                Nearcast employs a variety of technical and organizational safeguards to protect your data, including the use of https on our web pages to decrease the danger of unauthorized interception of your communications during your visits to the website and, where necessary, two-factor authentication. However, because the internet is not a completely secure environment, we cannot guarantee that your personal information will not be intercepted or accessed inappropriately. Please keep your password confidential and do not share it with anybody.
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>7.2. Other services and websites.</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                When using Nearcast, you may come across links to third-party websites and services that Nearcast does not own or control (for example, if a Member shares a link to a video on YouTube, or if an advertiser provides a link to a contest or sweepstakes). This Privacy Policy does not apply to information collected on any third-party website or application that may be linked to or accessed through the Services. These third parties have their own privacy policies that apply to any personal data they collect. We assume no responsibility or liability for the privacy and security practices of such external sites.
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>8. Children</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                If a child is under the age of 13 or is not old enough for Nearcast to handle their personal information without parental or age-specific consent where they live, they are not permitted to use the Services. If you suspect we have obtained personal information from a child in one of these categories, please email us at <a href="mailto:support@nearcast.com">support@nearcast.com</a>, and we will erase their information if we discover it.
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>9. Amendments to this Privacy Policy.</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                This Privacy Policy may be updated from time to time, so please review it frequently. If we make a major change to our Privacy Policy, we will make efforts to tell you as required by law, such as sending you an email or putting a notice in your neighborhood feed. You have the option to close your account if you don't agree with any of the changes. You agree that if you continue to use our Services after we publish or send a notice regarding changes to this Policy, the collection, use, and sharing of your personal data will be governed by the amended Privacy Policy as of the effective date.
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>10. Contact us.</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                If you are a user of Nearcast, contact us at Nearcast, Inc., 1580 West El Camino Real Suite 10, Mountain View California 94040. Phone: +1 650 996-1114. Email: <a href="mailto:support@nearcast.com">support@nearcast.com</a>
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>11. Jurisdiction-specific disclosures.</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>11.1. California.</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                This section pertains to you if you live in California. This section explains how we collect, utilize, and share your personal information in our capacity as a "business" as defined by the California Consumer Privacy Act of 2018 ("CCPA"), as well as your privacy rights. "Personal information" has the meaning granted in the CCPA for the purposes of this provision, but does not contain information not covered by the CCPA.
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>11.1.1. Personal information we collect, use, and disclose.</Typography>
            <TableContainer component={Card}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                Statutory category of personal information (PI)
                                (Cal. Civ. Code § 1798.140)
                            </TableCell>
                            <TableCell>PI we collect in this category (See Personal information we collect above for description)</TableCell>
                            <TableCell>
                                Source of PI
                            </TableCell>
                            <TableCell>
                                Business/
                                commercial purpose for collection
                            </TableCell>
                            <TableCell>
                                Categories of third parties to whom we “disclose” PI for a business purpose
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Identifiers (other than online identifiers)
                            </TableCell>
                            <TableCell>Contact details; Network details; Data from other accounts; Posts, interests, location, and recommendations; Business information; Information from third parties and public sources</TableCell>
                            <TableCell>You; Third parties and public sources</TableCell>
                            <TableCell>
                                Establishing and developing neighborhoods; Setting you up on Nearcast; Provide and improve the Services; Create and maintain a trusted environment; Advertising; Research and development
                            </TableCell>
                            <TableCell>
                                Public; Verification partners; Legal and safety-related third parties; Advertising partners; Third party partners; Nearcast corporate family; Business transferees; Professional advisors.
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                California Customer Records (as defined in California Civil Code section 1798.80)
                            </TableCell>
                            <TableCell>
                                Contact details; Network details; Data from other accounts; Posts, interests, location, and recommendations; Payment information; Business information; Information from third parties and public sources
                            </TableCell>
                            <TableCell>
                                You; Third parties and public sources
                            </TableCell>
                            <TableCell>
                                Establishing and developing neighborhoods; Setting you up on Nearcast; Provide and improve the Services; Create and maintain a trusted environment; Advertising; Research and development
                            </TableCell>
                            <TableCell>
                                Public; Verification partners; Legal and safety-related third parties; Advertising partners; Third party partners; Nearcast corporate family; Business transferees; Professional advisors
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Commercial Information
                            </TableCell>
                            <TableCell>Contact details; Network details; Posts, interests, location, and recommendations; Payment information; Survey data; Contests, sweepstakes, and prize drawings data; Messages and email data; Business information; Online activity data; Information from third parties and public sources
                            </TableCell>
                            <TableCell>
                                You; Your browser and device; Third parties and public sources
                            </TableCell>
                            <TableCell>
                                Establishing and developing neighborhoods; Setting you up on NearcastSetting you up on Nearcast; Provide and improve the ServicesProvide and improve the Services; Create and maintain a trusted environment; Advertising; Research and development
                            </TableCell>
                            <TableCell>
                                Public; Verification partners; Legal and safety-related third parties; Advertising partners; Third party partners; Nearcast corporate family; Business transferees; Professional advisors
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Financial Information
                            </TableCell>
                            <TableCell>
                                Payment information
                            </TableCell>
                            <TableCell>You</TableCell>
                            <TableCell>
                                Provide and improve the Services; Create and maintain a trusted environment; Research and development
                            </TableCell>
                            <TableCell>
                                Legal and safety-related third parties; Third party partners; Nearcast corporate family; Business transferees; Professional advisors
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Identifiers (other than online identifiers)
                            </TableCell>
                            <TableCell>Contact details; Network details; Data from other accounts; Posts, interests, location, and recommendations; Business information; Information from third parties and public sources</TableCell>
                            <TableCell>You; Third parties and public sources</TableCell>
                            <TableCell>
                                Establishing and developing neighborhoods; Setting you up on Nearcast; Provide and improve the Services; Create and maintain a trusted environment; Advertising; Research and development
                            </TableCell>
                            <TableCell>
                                Public; Verification partners; Legal and safety-related third parties; Advertising partners; Third party partners; Nearcast corporate family; Business transferees; Professional advisors
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Online Identifiers
                            </TableCell>
                            <TableCell>
                                Messages and email data; Contact lists; Geolocation; Device data; Online activity data; Information from third parties and public sources
                            </TableCell>
                            <TableCell>You; Your browser and device; Third parties and public sources</TableCell>
                            <TableCell>
                                Establishing and developing neighborhoods; Setting you up on Nearcast; Provide and improve the Services; Create and maintain a trusted environment; Advertising; Research and development
                            </TableCell>
                            <TableCell>
                                Public; Verification partners; Legal and safety-related third parties; Advertising partners; Third party partners; Nearcast corporate family; Business transferees; Professional advisors
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Internet or Network Information
                            </TableCell>
                            <TableCell>
                                Messages and email data; Device data; Online activity data
                            </TableCell>
                            <TableCell>You; Your browser and device; Third parties and public sources</TableCell>
                            <TableCell>
                                Establishing and developing neighborhoods; Setting you up on Nearcast; Provide and improve the Services; Create and maintain a trusted environment; Advertising; Research and development
                            </TableCell>
                            <TableCell>
                                Legal and safety-related third parties; Advertising partners; Third party partners; Nearcast corporate family; Business transferees; Professional advisors
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Geolocation Data
                            </TableCell>
                            <TableCell>Geolocation; Device data</TableCell>
                            <TableCell>You; Your browser and device</TableCell>
                            <TableCell>
                                Establishing and developing neighborhoods; Setting you up on Nearcast; Provide and improve the Services; Create and maintain a trusted environment; Advertising; Research and development
                            </TableCell>
                            <TableCell>
                                Legal and safety-related third parties; Advertising partners; Nearcast corporate family; Business transferees; Professional advisors
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Inferences
                            </TableCell>
                            <TableCell>

                                May be derived from your:; Contact details; Network details; Posts, interests, location, and recommendations; Survey data; Contests, sweepstakes, and prize drawings data; Messages and email data; Geolocation; Device data; Online activity data; Information from third parties and public sources

                            </TableCell>
                            <TableCell>
                                N/A
                            </TableCell>
                            <TableCell>
                                Establishing and developing neighborhoods; Setting you up on Nearcast; Provide and improve the Services; Create and maintain a trusted environment; Advertising; Research and development
                            </TableCell>
                            <TableCell>
                                Legal and safety-related third parties; Advertising partners; Third party partners; Nearcast corporate family; Business transferees; Professional advisors
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Professional or Employment Information
                            </TableCell>
                            <TableCell>
                                Business information; Information from third parties and public sources
                            </TableCell>
                            <TableCell>
                                You; Third party sources
                            </TableCell>
                            <TableCell>
                                Establishing and developing neighborhoods; Setting you up on Nearcast; Provide and improve the Services; Create and maintain a trusted environment; Advertising; Research and development
                            </TableCell>
                            <TableCell>
                                Public; Verification partners; Legal and safety-related third parties; Advertising partners; Third party partners; Nearcast corporate family; Business transferees; Professional advisors
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Sensory Information
                            </TableCell>
                            <TableCell>
                                Photos and videos
                            </TableCell>
                            <TableCell>
                                You
                            </TableCell>
                            <TableCell>
                                Establishing and developing neighborhoods; Setting you up on Nearcast; Provide and improve the Services; Create and maintain a trusted environment; Advertising; Research and development
                            </TableCell>
                            <TableCell>
                                Public; Legal and safety-related third parties; Third party partners; Nearcast corporate family; Business transferees; Professional advisors
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>11.1.2. What are my rights?</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                The rights listed in this section are not absolute and are subject to exceptions, and we may be required or permitted by law to refuse your request. We won't be able to process your request if you don't include enough information for us to understand and reply to it.
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>11.1.2.1. Access Categories of Information.</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                You have the right to request the following information about how we have collected and used your personal information over the last 12 months:
                The categories of personal information we have collected about you; the sources from which we collected the personal information; the business or commercial purpose for collecting or selling the personal information; the categories of third parties with whom we share the personal information; the categories of personal information we sold or disclosed for a business purpose; and the categories of third parties to whom we sold or disclosed the personal information.
                11.1.2.2. Access.
                Logging into your Nearcast account will give you access to a lot of your personal data.
                <br />
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>11.1.2.3. Requesting Copies.</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                You may request a copy of the personal information we have collected about you during the past 12 months by logging into your account if you have one and selecting “Request my information” under the Privacy section of your account settings, submitting a request via email to <a href="mailto:support@nearcast.com">support@nearcast.com</a>, or calling +1 650 996-1114.
                11.1.2.4. Deletion.
                By logging into your Nearcast account, you can delete the majority of the content you've published.
                If you have an account, you may request that we erase the personal information we have acquired about you by contacting us: please pick the "My account" option in the first drop-down menu and the "Delete my account " option in the second drop-down menu. Additionally, you may send an email to <a href="mailto:support@nearcast.com">support@nearcast.com</a> or contact +1 650 996-1114 to make a request.
                <br />
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>11.1.2.5. Opt-out of sales.</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                Our use of these services may be construed as a "sale," under the CCPA, of your personal information to the advertising partners that provide the services, as they collect information about our users (i.e., the device data and online activity data described in Section 2.2) to assist them in serving advertisements that are more likely to be of interest to you.
                You can opt-out of this "sale" of your personal information by clicking on the "Do Not Sell My Personal Information" link, which will take you to instructions on how to opt-out of your information being used for interest-based advertising.
                <br />
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>11.1.2.6. Nondiscrimination.</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                You are entitled to exercise the rights described above without fear of discrimination, as the CCPA prohibits.
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>11.1.3. Requests by authorized agents.</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                Your authorized agent may submit a request for information, access, or deletion on your behalf after we verify the agent's identification and receive a copy of the agent's legal power of attorney granted pursuant to California Probate Code Sections 4000-4465. If you have not given your agent such a power of attorney, you must give your agent signed permission to exercise your CCPA rights on your behalf, provide us with the information we request to verify your identity, and provide us with written confirmation that you have authorized the authorized agent to submit the request. California law requires authorized agents to implement and maintain appropriate security policies and practices to safeguard their clients' personal information.
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>11.1.4. Verifying requests for information, access, and deletion.</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                To handle your information, access, and deletion requests, we will need to authenticate your identification and reserve the right to establish your California residency.
                You can authenticate your identification as a Member by submitting your request while logged into your Nearcast account.
                If you are a Visitor or a Member who is unable to access your account, we may verify your identity by requiring you to provide government identification, sign a declaration of your identity under penalty of perjury, respond to a text message sent to the phone number on file, and/or provide additional information.
                <br />
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>11.1.5. Shine the light.</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                Under California’s Shine the Light law (California Civil Code Section 1798.83), California residents may ask companies with whom they have formed a business relationship primarily for personal, family or household purposes to provide the names of third parties to which they have disclosed certain personal information (as defined under the Shine the Light law) during the preceding calendar year for their own direct marketing purposes and the categories of personal information disclosed. You may send us requests for this information to Nearcast, Inc., 1580 West El Camino Real Suite 10, Mountain View California 94040. Phone: +1 650 996-1114, Attention: Chief Legal Officer. In your request, you must include the statement “Shine the Light Request,” and provide your first and last name and mailing address and certify that you are a California resident. We reserve the right to require additional information to confirm your identity and California residency. Please note that we will not accept requests via telephone, email, or facsimile, and we are not responsible for notices that are not labeled or sent properly, or that do not have complete information.
                11.2. Australia, Canada, UK, and Europe.
                This section relates to residents of the United Kingdom and Europe. If you live in Australia or Canada, you may be entitled to some of the following rights:
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>11.2.1. On what legal basis do you collect my information?</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                To the extent permitted by applicable law, we will collect your personal information with your consent and for the purpose of providing you with our Services.
                Nearcast is required by EU law to rely on one or more legal bases for collecting, using, sharing, and otherwise processing information about you. We discuss these legal bases in further detail below, along with some associated illustrations.
                <br /><br /> -&gt; Performing the Member Agreement
                Nearcast processes your information in order to carry out our contractual obligations to you, the Member Agreement. For instance:
                We use your information to create your account and enroll you as a Member on Nearcast; we transfer your information outside of the EU to the U.S., such as to Nearcast, Inc., in order to provide the Services.
                <br /><br /> -&gt; Your Consent
                Certain information is processed by Nearcast with your consent, which you may revoke at any moment. For example:
                We and our partners use cookies and similar technologies, as described in greater detail in our Cookie Policy, with your consent; when you choose a method of address verification, we do so with your consent; if you choose to use location services in conjunction with Nearcast, we collect and process location information with your consent; we tailor ads based on non-Nearcast actions with your consent.
                <br /><br /> -&gt; Legal Obligation
                Nearcast may process your information to comply with a legal requirement, a court order, or to assert and defend legal claims. For instance, we may retain and disclose your information if required to do so by applicable law in response to a valid legal request (e.g. a subpoena, search warrant, court order, or other binding request from government or law enforcement); and we may retain and process your information if required to do so by applicable tax laws.
                <br /><br /> -&gt; Vital Interests
                Nearcast may process your information in limited circumstances to protect your essential interests or the interests of others. For instance, we may need to process your information in order to protect your life or the lives or safety of others, or to prevent physical injury or other harm to any person, including you, other Members, Visitors, our staff, or the general public; we may preserve or share your information with law enforcement and related authorities when we have a good faith belief that it is necessary in the context of an imminent threat; we may share your personal information with third parties for marketing purposes.
                <br /><br /> -&gt; Legitimate Interests
                We may process your information in order to protect Nearcast's or a third party's legitimate interests, such as those of Visitors, Members, or partners. We process your information in order to advance the following legitimate interests:
                - Ensuring the safety and security of the Services: We utilize your information to safeguard our legitimate interests and those of our Members in guaranteeing Nearcast's security, which includes adopting and strengthening security measures and protections, preventing fraud, spam, and abuse, and enforcing our Member Agreement.
                - Providing, enhancing, and developing the Services: We use your information to provide the Services, including any personalized services, as well as to better understand and manage our business and Member relationships. We do so in order to maintain our legitimate interests in providing an innovative and personalised product to our Members.
                – Providing seamless Services throughout the Nearcast group of companies: Where the Services require the participation of other Nearcast companies, we share your information with other affiliates in order to provide and improve the Services. For example, Nearcast EMEA transfers information to Nearcast, Inc., which aids Nearcast EMEA in providing the Services technically and infrastructurally.
                – Providing advertisements, as well as measurement and analytics services: We use your information to deliver personalized advertisements on and off Nearcast, as well as to measure and analyze the effectiveness of those advertisements in order to provide accurate and reliable reporting to our advertisers. We do so to advance our legitimate business interests in marketing and monetizing our Services.
                - Marketing communications: We use your contact information and send you marketing messages in line with applicable legislation, except where applicable law requires us to obtain your consent to send such communications.
                – Information sharing for legal and security reasons: Where needed by law or for reasons of public safety, we may release information to law enforcement and similar agencies and authorities in pursuit of the legitimate objectives outlined further below.
                As outlined in the Privacy Policy, you have the right to object to processing of your information based on legitimate interests. If you exercise your right to object, we will stop processing your information for that specific purpose, unless persuasive legitimate grounds override your objection or the processing is required by law.
                <br />
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>11.2.2. What are my rights?</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                You have a number of rights in relation to the information we process about you. While some of these rights are universal, others apply only in particular limited circumstances. These rights are described in greater detail below.
                In specific circumstances, such as when requesting access to, correction of, or deletion of your information, please read the alternatives listed above under the "What are My Choices" section.
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>11.2.3. Access.</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                If you are unable to access the needed information through your Nearcast account, or if you are not currently a Nearcast Member, please email us at personal- <a href="mailto:support@nearcast.com">support@nearcast.com</a> to receive a copy of your Nearcast information. If you request it and the applicable law requires it, we will offer your information in an easily accessible manner and assist you in transferring some of this information to third parties.
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>11.2.4. Rectify, restrict, delete.</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                By signing into your Nearcast account, you can access, correct, and remove your Nearcast information. If you are unable to do so or do not have a Nearcast account, please contact us and we will amend, restrict, or delete your information upon your request and in accordance with applicable law.
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>11.2.5. Object.</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                If we handle your information in accordance with the aforementioned legitimate purposes, you may object to that processing in specific circumstances. In such situations, we shall discontinue processing your information unless we have compelling legitimate grounds to do so (including if relevant legislation permits us to do so) or unless processing is required for legal reasons. If we use your data for direct marketing purposes, you can always opt out by clicking the unsubscribe link included in such messages, updating your email account settings, or contacting us if you do not have an account.
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>11.2.6. Revoke consent.</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                Where relevant law requires consent for processing, you may have the right to withdraw your consent at any time. If you withdraw your consent to the use of your information as described in this Privacy Policy, you may lose access to all (or some) of our Services, and we may be unable to provide you with all (or some) of the Services described in this Privacy Policy and our Member Agreement. We may continue to process your information after you withdraw consent in some circumstances if we have a legal basis to do so or if your withdrawal of consent was restricted to certain processing activities.
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>11.2.7. Complain.</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                If you have an account in the United Kingdom or an EU Member State and wish to raise a concern about our use of your information (without prejudice to any other rights you may have), you may do so with your local supervisory authority or with Nearcast's lead supervisory authority, the <a href="https://www.dataprotection.ie/docs/Contact-us/b/11.htm">Irish Data Protection Commission</a>.
                <br />
                If you have an Australian or Canadian Member account, please contact us with as much information about your issue as possible. We take all privacy complaints seriously and will investigate them thoroughly with the goal of resolving them expeditiously and efficiently. If you are dissatisfied with the conclusion of our investigation, you may decide to contact the Australian Information Commissioner's Office (for Australian Members) or the Canadian Privacy Commissioner's Office (for Canadian Members).
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>11.3. Where is my information stored?</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                If you live in Australia or Canada, your personal information is transferred to the United States.
                If you live in the United Kingdom or the European Union, we transfer your personal information in accordance with the following protections:
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>11.3.1. Standard contractual clauses.</Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                To secure information moved beyond the EU and the UK, we may employ a legal mechanism called as "standard contractual clauses." Standard contractual provisions relate to agreements between businesses that transfer personal information (for example, from Nearcast EMEA Limited to Nearcast, Inc.) that include standard promises approved by the EU Commission to guarantee the privacy and security of the transmitted information. Please send an email to <a href="mailto:support@nearcast.com">support@nearcast.com</a> to receive a copy of the provisions.
                By continuing to use Nearcast, you acknowledge that we may transfer and keep your data outside of your country of residence, including to and within the United States, as defined in this Privacy Policy. In some of these nations, privacy safeguards and authorities' rights to access your personal information may differ from those in your native country. Nearcast will send personal data only as permitted by law and will take reasonable precautions to safeguard your personal information. Please <a href="mailto:support@nearcast.com">contact us</a> if you have any questions.
            </Typography>
        </div>
  )
}

const styles = makeStyles(({ breakpoints, palette }) => ({
  flex: {
    display: 'flex',
    alignItems: 'center'
  },
  flexGrow: {
    flex: '1'
  },
  mainContainer: {
    padding: '40px',
    paddingBottom: '60px'
  }
}))

export default PrivacyPolicy
