import * as React from 'react'
import { Stack, Typography, FormControl, Button, Fade, Box } from '@mui/material'
import MuiPhoneNumber from 'material-ui-phone-number'
import validator from 'validator'
import { useSnackbar } from 'notistack'

// importing ui
import StandardModal from 'ui/Modals/StandardModal'
import OtpInput from 'ui/TextInputs/OtpInput'
import { TextLabel } from 'ui/TextInputs/TextInput'

// importing api
import { signupOtpSend } from 'api/signup/otp'
import { userOtpVerify } from 'api/user/update'

// importing component
import { UserContext } from '../../context/UserContext'

export interface UpdatePhoneNumberProps {
  isOpen: boolean;
  onClose: () => {}
}

const UpdatePhoneNumber: React.FC<UpdatePhoneNumberProps> = (props) => {
  const { jwtToken, personal, activeNeighborhood } = React.useContext(UserContext)
  const { enqueueSnackbar } = useSnackbar()

  const [mobile, setMobile] = React.useState('')
  const [isMobileNumberValid, setIsMobileNumberValid] = React.useState(false)
  const otpSentStateInitial = {
    shouldSendOtp: true,
    timer: 0,
    count: 0
  }
  const [otpSendState, setOtpSendState] = React.useState(otpSentStateInitial)
  const [otpInput, setOtpInput] = React.useState('')
  const [isOtpSent, setIsOtpSent] = React.useState(false)
  const [isVerifyingOtp, setIsVerifyingOtp] = React.useState(false)

  const _onClose = () => {
    setMobile('')
    setIsMobileNumberValid(false)
    setOtpSendState(otpSentStateInitial)
    setOtpInput('')
    setIsOtpSent(false)
    setIsVerifyingOtp(false)
    props.onClose()
  }

  const sendMobileOTP = async () => {
    if (!otpSendState.shouldSendOtp) {
      return
    }

    setOtpSendState({
      shouldSendOtp: false,
      timer: 60 * (otpSendState.count + 1),
      count: otpSendState.count + 1
    })
    setIsOtpSent(true)
    try {
      await signupOtpSend(mobile, jwtToken)
    } catch (err) {
      enqueueSnackbar(err.message, {
        variant: 'error'
      })
    }
  }

  const updateUserMobileWithOtp = async () => {
    if (isVerifyingOtp) {
      return
    }

    setIsVerifyingOtp(true)
    try {
      await userOtpVerify(mobile, otpInput, personal[activeNeighborhood].ID, jwtToken)
      enqueueSnackbar('Phone number updated', {
        variant: 'success'
      })
      _onClose()
    } catch (err) {
      enqueueSnackbar(err.message, {
        variant: 'error'
      })
    } finally {
      setOtpInput('')
      setIsVerifyingOtp(false)
    }
  }

  React.useEffect(() => {
    if (props.isOpen === false && otpSendState.shouldSendOtp === false) {
      return setOtpSendState(otpSentStateInitial)
    }
    if (otpSendState.timer > 0) {
      setTimeout(() => {
        setOtpSendState({
          ...otpSendState,
          timer: otpSendState.timer - 1
        })
      }, 1000)
    }

    if (otpSendState.timer === 0 && otpSendState.shouldSendOtp === false) {
      setOtpSendState({
        ...otpSendState,
        shouldSendOtp: true
      })
    }
  }, [otpSendState, props.isOpen])

  return (
    <StandardModal
      title='Change phone number'
      isTitleBack={true}
      onPressTitleBack={_onClose}
      isOpen={props.isOpen}
      onClose={_onClose}
    >
      <Stack
        direction="column"
        alignItems="flex-start"
        justifyContent="space-between"
        style={{
          width: '100%',
          minHeight: '50vh'
        }}
      >
        <Box style={{ width: '100%' }}>
          <FormControl fullWidth margin="normal">
            <TextLabel style={{ marginBottom: 12 }}>
              Please enter your new phone number
            </TextLabel>
            <MuiPhoneNumber
              defaultCountry={'us'}
              id="phone"
              // variant="outlined"
              placeholder='New phone number'
              value={mobile}
              InputProps={{ hiddenLabel: false, disableUnderline: false }}
              onChange={(value) => {
                const cleanMobile = value.replace(/\s|-|\(|\)/g, '')
                if (validator.isMobilePhone(cleanMobile, 'any')) {
                  setIsMobileNumberValid(true)
                } else {
                  setIsMobileNumberValid(false)
                }
                setMobile(cleanMobile)
              }}
              disableAreaCodes={true}
            />
          </FormControl>
          <Fade in={isOtpSent}>
            <FormControl fullWidth margin="normal">
              <TextLabel style={{ marginBottom: 12 }}>
                Please enter you your OTP:
              </TextLabel>
              <OtpInput
                value={otpInput}
                onChange={(val) => {
                  setOtpInput(val)
                }}
                numInputs={4}
                isInputNum
                containerStyle={{
                  justifyContent: 'center'
                }}
                inputStyle={{
                  width: '2rem',
                  padding: '5px 0px',
                  fontSize: '1.2rem',
                  fontWeight: '600',
                  marginRight: 4,
                  marginLeft: 4
                }}
              />
              <Typography
                variant='caption'
                textAlign="right"
                sx={{
                  width: '100%',
                  marginTop: '6px',
                  cursor: 'pointer'
                }}
                color={otpSendState.timer > 0 ? 'ghost' : 'primary'}
                onClick={sendMobileOTP}
              >
                Resend Code ({otpSendState.timer}s)
              </Typography>
            </FormControl>
          </Fade>
        </Box>

        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{
            width: '100%'
          }}
        >
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => {
              if (isOtpSent) {
                return updateUserMobileWithOtp()
              }
              sendMobileOTP()
            }}
            disabled={isMobileNumberValid === false || (isOtpSent && otpInput.length < 4)}
          >
            {isOtpSent ? 'Verify & Update' : 'Send Code'}
          </Button>
          <Typography variant="caption" style={{ width: '100%', textAlign: 'center' }}>
            By continuing, you agree to NearCast’s Terms of Service.
          </Typography>
        </Stack>
      </Stack>
    </StandardModal>
  )
}

export default UpdatePhoneNumber
