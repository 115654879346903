import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { Link } from 'react-router-dom'

function Safety () {
  const classes = styles()

  return (
        <div className={classes.mainContainer}>
            <Typography variant="h2">
                Safety Center
            </Typography>
            <Typography variant="body1" style={{ marginTop: '20px' }}>
                Nearcast is a place for uniting neighbors around shared values.
                <br />
                If you feel that there is content posted on Nearcast that does not meet our <Link to="/community-guidelines">Community Guidelines</Link>, please report it by emailing us as <a href="mailto:support@nearcast.com">support@nearcast.com</a>.
            </Typography>
        </div>
  )
}

const styles = makeStyles(({ breakpoints, palette }) => ({
  flex: {
    display: 'flex',
    alignItems: 'center'
  },
  flexGrow: {
    flex: '1'
  },
  mainContainer: {
    padding: '40px',
    paddingBottom: '60px'
  }
}))

export default Safety
