import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

function CommunityGuidelines () {
 const classes = styles()

 return (
  <div className={classes.mainContainer}>
    <Typography variant="h2">
      Community Guidelines
    </Typography>
    <Typography variant="h3" style={{ marginTop: '20px', fontWeight: 'bolder' }}>
      Nearcast is a place for joy, not hate.
    </Typography>
    <Typography variant="body1" style={{ marginTop: '20px' }}>
      We think neighborhoods work better together when neighbors focus on what unites. Therefore, Nearcast's mission is to drive social engagement around things that spread joy and happiness. We welcome video content that is non-controversial, and which is family friendly. Therefore, all content on Nearcast is centrally moderated to ensure we highlight what makes us united. We don't allow videos of suspected crime. We don't allow videos of divisive politics. However, we welcome posts on hope, joy, and inspiration. We welcome neighbors helping neighbors and the ethos of supporting our neighbors and local businesses.
    </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>
      1. Broadcast magical moments.
    </Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
      Showcase what inspires you. Whether it is a moment in nature or an amazing sunrise, capture and share the magical moments that make your neighborhood amazing.
    </Typography>
    <Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>
      2. Focus on the good.
    </Typography>
    <Typography variant="body1" style={{ marginTop: '20px' }}>
      There are aspects of living in a place that unite. Focus on posts that help bring neighbors together - off their phones and devices and into the real world. Whether it is promoting a volunteer opportunity or recognizing a neighborhood leader, highlight what makes your neighborhood a special place.
    </Typography>
    <Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>
      3. Share among neighbors.
    </Typography>
    <Typography variant="body1" style={{ marginTop: '20px' }}>
      Nearcast encourages commerce between neighbors, and welcomes it. We encourage neighbors to sell things to each other, and experience the power that only comes when we support the communities and people nearest to us.
    </Typography>
    <Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>
      4. Livestream something delightful.
    </Typography>
    <Typography variant="body1" style={{ marginTop: '20px' }}>
      Nearcast enables neighbors to livestream things in their neighborhood that draw neighbors to a specific place, or which educate and inspire others to learn something new. Use Nearcast to livestream something that drives neighbors to experience what you are experiencing and encourages them to next time attend in person.
    </Typography>
  </div>
 )
}

const styles = makeStyles(({ breakpoints, palette }) => ({
  flex: {
    display: 'flex',
    alignItems: 'center'
  },
  flexGrow: {
    flex: '1'
  },
  mainContainer: {
    padding: '40px',
    paddingBottom: '60px'
  }
}))

export default CommunityGuidelines
