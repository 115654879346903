import React, { useContext, useEffect, useRef, useState, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Grid, CircularProgress, Avatar, Divider, Typography, ButtonBase, Button, Stack, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ArrowBackRounded, ErrorOutline, LocationOn, Settings, ShareRounded } from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import Dialog from 'ui/Dialog/Dialog'
import { useChatContext } from 'stream-chat-react'

// importing context
import { UserContext } from '../../context/UserContext'
import { GlobalFuncContext } from '../../context/GlobalFuncHOC'

// importing components
import Header from '../../components/Header/Header'
import NearcastGrid from '../../components/NearcastPicker/NercastGrid'
import UserList from '../../components/Profile/UserList'
import EditProfileModal from '../../components/Profile/EditProfileModal'
import UserSettingsModal from '../../components/Profile/UserSettingsModal'
import NearcastsEmptyState from '../../components/EmptyStates/NearcastsEmptyState'
import downloadQrCode from '../../assets/Qrcode.png'
import cameraIcon from '../../assets/camera.png'

// importing APIs
import { fetchProfileAnonymous, fetchProfile } from 'api/fetch/profile'
import { personalAccountUpdate } from 'api/personal_account/update'
import { followUser as followUserApiPack, unfollowUser as unfollowUserApiPack } from 'api/follow'
import { fetchMyProfile } from 'api/fetch/my'
import { userPosts as userPostsApiPack } from 'api/user/posts'
import { profileFollowings } from 'api/profile/followings'
import { profileFollowers } from 'api/profile/followers'
import { removeFollower as removeFollowerApiPack } from 'api/following/removeFollower'
import { getAvatarLink } from 'api/get/avatar'
import { personalAccountAvatar } from 'api/personal_account/avatar'
import { adminFeedPersonalProfile } from 'api/analytics/feed'

function Profile () {
  const { id } = useParams()
  const {
    personal,
    jwt_token,
    active_neighbourhood,
    refreshProfilesApi
  } = useContext(UserContext)
  const {
    showPleaseLoginDialog,
    openShareModal
  } = useContext(GlobalFuncContext)
  const [profileData, setProfileData] = useState()
  const [profileError, setProfileError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loadingPosts, setLoadingPosts] = useState(true)
  const [userPosts, setUserPosts] = useState([])
  const [userFollowers, setUserFollowers] = useState([])
  const [userFollowing, setUserFollowing] = useState([])
  const [selfFollowing, setSelfFollowing] = useState({})
  const [showEditUserModal, setShowEditUserModal] = useState(false)
  const [showUserSettings, setShowUserSettings] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [profilePic, setProfilePic] = useState()

  const classes = styles()
  const uid = useRef('')
  const { enqueueSnackbar } = useSnackbar()
  const { client } = useChatContext()

  const navigate = useNavigate()

  // 0 - Posts
  // 1 - Followers
  // 2 - Following
  const [contentType, setContentType] = useState(0)

  const getProfileDataApi = () => {
    // setLoading(true)
    fetchMyProfile(personal[active_neighbourhood].ID, jwt_token)
      .then((bodyData) => {
        setProfileData(bodyData)
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: 'error'
        })
      })
      .finally(() => {
        // setLoading(false)
      })
  }

  const getProfileDataFromUIDApi = () => {
    // setLoading(true)
    fetchProfile(uid.current, personal[active_neighbourhood].ID, jwt_token)
      .then(async (dataBody) => {
        setProfileData(dataBody)
        getUserFollowingApi()
        if (userFollowers.filter((user, index) => user.user_id === uid.current).length !== 0) {
          setProfileData((oldProfileDat) => ({
            ...oldProfileDat,
            follow: true
          }))
        } else {
          setProfileData((oldProfileDat) => ({
            ...oldProfileDat,
            follow: false
          }))
        }
        setLoading(false)
      })
      .catch((err) => {
        setProfileError(true)
        enqueueSnackbar(err.message, {
          variant: 'error'
        })
      })
  }

  const getUserPostsApi = (overRidePage = pageNumber) => {
    if (overRidePage === 1) {
      setLoadingPosts(true)
    }
    userPostsApiPack(uid.current, overRidePage, 10, personal[active_neighbourhood].ID, jwt_token)
      .then((dataBody) => {
        if (overRidePage === 1) {
          setUserPosts(dataBody)
        } else {
          setUserPosts((d) => [...d, ...dataBody])
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: 'error'
        })
      })
      .finally(() => {
        setLoadingPosts(false)
      })
  }

  // const getSelfFollowingApi = () => {
  //   if (pageNumber === 1) {
  //     setLoadingPosts(true);
  //   }
  //   profileFollowings(pageNumber, 50, personal[active_neighbourhood].ID, jwt_token)
  // 		.then((dataBody) => {
  // 			const obj = {};
  // 			for (const user of dataBody) {
  // 				obj[user.user_id] = true;
  // 			}
  // 			setSelfFollowing(obj);
  // 		})
  // 		.catch((err) => {
  // 			enqueueSnackbar(err.message, {
  //         variant: "error"
  //       });
  // 		})
  //     .finally(() => {
  //       setLoadingPosts(false);
  //     })
  // };

  const getUserFollowingApi = (overRidePage = pageNumber) => {
    if (overRidePage === 1) {
      setLoadingPosts(true)
    }
    profileFollowings(overRidePage, 50, uid.current, personal[active_neighbourhood].ID, jwt_token)
      .then((dataBody) => {
        if (overRidePage === 1) {
          setUserFollowing(dataBody)
        } else {
          setUserFollowing((d) => [...d, ...dataBody])
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: 'error'
        })
      })
      .finally(() => {
        setLoadingPosts(false)
      })
  }

  const getUserFollowersApi = (overRidePage = pageNumber) => {
    if (overRidePage === 1) {
      setLoadingPosts(true)
    }
    profileFollowers(overRidePage, 50, uid.current, personal[active_neighbourhood].ID, jwt_token)
      .then((dataBody) => {
        if (overRidePage === 1) {
          setUserFollowers(dataBody.followers)
        } else {
          setUserFollowers((d) => [...d, ...dataBody.followers])
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: 'error'
        })
      })
      .finally(() => {
        setLoadingPosts(false)
      })
  }

  const fetchContentType = (type) => {
    setPageNumber(1)
    setContentType(type)
    switch (type) {
      case 0:
        getUserPostsApi(1)
        break
      case 1:
        getUserFollowersApi(1)
        break
      case 2:
        getUserFollowingApi(1)
        break
      default:
        break
    }
  }

  const unfollowUser = (userId) => {
    unfollowUserApiPack(userId, personal[active_neighbourhood].ID, jwt_token)
      .then(() => {
        if (userId === uid.current) {
          setProfileData((oldProfileDat) => ({
            ...oldProfileDat,
            is_following: false
          }))
        } else {
          delete selfFollowing[userId]
        }
        getProfileDataFromUIDApi()
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: 'error'
        })
      })
  }

  const removeFollower = (userId) => {
    removeFollowerApiPack(userId, personal[active_neighbourhood].ID, jwt_token)
      .then(() => {
        if (contentType !== 0) {
          setLoadingPosts(true)
        }
        getUserFollowersApi()
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: 'error'
        })
      })
  }

  const followUser = (userId) => {
    followUserApiPack(userId, personal[active_neighbourhood].ID, jwt_token)
      .then(() => {
        if (userId === uid.current) {
          setProfileData((oldProfileDat) => ({
            ...oldProfileDat,
            is_following: true
          }))
        } else {
          selfFollowing[userId] = true
        };
        getProfileDataFromUIDApi()
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: 'error'
        })
      })
  }

  const updateUserDetails = async (newUserDetails, newUserProfilePicture) => {
    try {
      if (newUserProfilePicture !== null) {
        await getAvatarLink(newUserProfilePicture, personal[active_neighbourhood].ID, jwt_token)
        // There was no autoupdate on profilepic, tried with timeouts in range of 100-1500, doesn't work before 1500ms
        setTimeout(() => {
          setProfilePic(personalAccountAvatar(uid.current))
        }, 1000 * 3)
      }
      if (newUserDetails !== null) {
        await personalAccountUpdate(newUserDetails, personal[active_neighbourhood].ID, jwt_token)
        getProfileDataApi()
      }

      if (newUserDetails !== null || newUserProfilePicture !== null) {
        enqueueSnackbar('Profile Updated Successfully', { variant: 'success' })
        refreshProfilesApi()
        // needs a timeout call to, for getting correct images
        setTimeout(() => {
          refreshProfilesApi()
        }, 1000 * 2)
      }
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' })
    }
  }

  const getUserProfileWithoutAuth = async (uid) => {
    try {
      const profile = await fetchProfileAnonymous(uid)
      setProfileData(profile)
    } catch (err) {
      setProfileError(true)
    } finally {
      setLoading(false)
      setLoadingPosts(false)
    }
  }

  const onRequiredAuthPress = (message, contentType) => {
    if (!currentUserId) {
      showPleaseLoginDialog()
      return enqueueSnackbar(message, {
        variant: 'info'
      })
    }
    fetchContentType(contentType)
  }

  useEffect(() => {
    setLoading(true)
    // a user might be hoping from another profile to this profile
    // hence move most states to default states
    setUserFollowers([])
    setUserFollowing([])
    setUserPosts([])
    setContentType(0)
    setPageNumber(1)
    uid.current = id
    setProfilePic(personalAccountAvatar(uid.current))
    if (!jwt_token) {
      getUserProfileWithoutAuth(uid.current)
    } else {
      getProfileDataFromUIDApi()
      getUserPostsApi(1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (personal && jwt_token) {
      adminFeedPersonalProfile(
        id,
        true,
        personal[active_neighbourhood].ID,
        personal[active_neighbourhood].lives_in_id
      )
    } else {
      adminFeedPersonalProfile(
        id,
        false
      )
    }
  }, [personal, jwt_token])

  const observer = useRef()
  const lastVideoElementRef = useCallback(
    (node) => {
      if (loading) {
        return
      }
      if (observer.current) {
        observer.current.disconnect()
      }
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1)
        }
      })
      if (node) {
        observer.current.observe(node)
      };
    },
    [loading]
  )

  useEffect(() => {
    if (pageNumber === 1) {
      return
    }
    if (contentType === 1) {
      getUserFollowersApi()
    } else if (contentType === 0) {
      getUserPostsApi()
    } else {
      getUserFollowingApi()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber])

  const handleMessageButton = async (members) => {
    const channel = client.channel('messaging', { members: members })
    await channel.watch()
    if (!channel.id) {
      enqueueSnackbar('Cannot message this user', {
        variant: 'error'
      })
      return
    }
    navigate(`/chat?id=${channel.id}`)
  }

  // current user ID, if user logged in then in personal array else null
  const currentUserId = jwt_token ? personal[active_neighbourhood].ID : null

  return !loading && !profileError
    ? (
    <div>
      <Header />
      <Grid container className={classes.mainContainer}>
        <div className={classes.root}>
          <div className={classes.profileContainer}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              style={{
                width: '100%'
              }}
              sx={{
                margin: 2
              }}
            >
              <ButtonBase
                className={classes.backButton}
                onClick={() => {
                  navigate(-1)
                }}
              >
                <ArrowBackRounded className={classes.backButtonIcon} />
                <div className={classes.backButtonText}>Go back</div>
              </ButtonBase>
              <Stack direction="row">
                <IconButton
                  onClick={() => {
                    openShareModal(
                      'Profile',
                      `Check out ${profileData.first_name.trim()}'s profile at Nearcast!`,
                      `/u/${id}`
                    )
                  }}
                >
                  <ShareRounded style={{ width: 32, height: 32 }} htmlColor='black' />
                </IconButton>
                {currentUserId === uid.current && (
                  <IconButton
                    onClick={() => setShowUserSettings(true)}
                  >
                    <Settings style={{ width: 32, height: 32 }} htmlColor='black'/>
                  </IconButton>
                )}
              </Stack>
            </Stack>
            <Grid container className={classes.profileContent}>
              <Grid item className={classes.profilePicContainer}>
                <Avatar
                  aria-controls="user-controls"
                  alt="userIcon"
                  className={classes.profilePic}
                  src={profilePic}
                />
              </Grid>
              <Grid item className={classes.profileDetails}>
                <div className={classes.profileName}>
                  {profileData.first_name
                    ? `${profileData.first_name} ${profileData.last_name}`
                    : `${profileData.username}`}
                </div>
                <div style={{ display: 'flex' }}>
                  <LocationOn style={{ width: '17px', marginTop: '-2px' }} />
                  <div>{`${profileData.name}, ${profileData.metro}`}</div>
                </div>
              </Grid>
              <Grid container className={classes.profileCountsContainer}>
                <Grid
                  className={classes.countItem}
                  onClick={() => onRequiredAuthPress("Please login first to see user's posts", 0)}
                >
                  <Grid className={classes.countItemNumber} item>
                    {profileData.posts_count}
                  </Grid>
                  <Grid className={classes.countItemDescription} item>
                    Nearcasts
                  </Grid>
                </Grid>
                <Divider
                  orientation="vertical"
                  flexItem
                  className={classes.divider}
                />
                <Grid
                  className={classes.countItem}
                  onClick={() => onRequiredAuthPress('Please login first to see who follows the user', 1)}
                >
                  <Grid className={classes.countItemNumber} item>
                    {profileData.followers_count}
                  </Grid>
                  <Grid className={classes.countItemDescription} item>
                    Followers
                  </Grid>
                </Grid>
                <Divider
                  orientation="vertical"
                  flexItem
                  className={classes.divider}
                />
                <Grid
                  className={classes.countItem}
                  onClick={() => onRequiredAuthPress('Please login first to see who the user follows', 2)}
                >
                  <Grid className={classes.countItemNumber} item>
                    {profileData.following_count}
                  </Grid>
                  <Grid className={classes.countItemDescription} item>
                    Following
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className={classes.profileDescription}>
                {profileData.bio}
              </Grid>
              <Grid item className={classes.actionButtonContainer}>
                {uid.current === currentUserId
                  ? (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.actionButton}
                    onClick={() => {
                      setShowEditUserModal(true)
                    }}
                  >
                    Edit Profile
                  </Button>
                    )
                  : (
                  <>
                    {profileData.is_following
                      ? (
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.actionButton}
                        onClick={() => unfollowUser(uid.current)}
                      >
                        Unfollow
                      </Button>
                        )
                      : (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            if (!currentUserId) {
                              showPleaseLoginDialog()
                              return enqueueSnackbar('Join Nearcast to follow this user', {
                                variant: 'info'
                              })
                            }
                            followUser(uid.current)
                          }}
                          className={classes.actionButton}
                        >
                          Follow
                        </Button>
                        </>
                        )}
                      {/* <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          if (!currentUserId) {
                            showPleaseLoginDialog()
                            return enqueueSnackbar('Join Nearcast to message this user', {
                              variant: 'info'
                            })
                          }
                          handleMessageButton([uid.current, currentUserId])
                        }}
                        className={classes.actionButton}
                      >
                        Message
                      </Button> */}
                    </>
                    )}
              </Grid>
            </Grid>
          </div>
          <Grid
            item
            sm={12}
            style={{
              paddingInline: '24px',
              paddingTop: '12px'
            }}
          >
            <Typography variant="h5">
              {contentType === 0
                ? 'Nearcasts'
                : contentType === 1
                  ? 'Followers'
                  : contentType === 2
                    ? 'Following'
                    : ''}
            </Typography>
            <Divider style={{ width: '100%' }} />
            {contentType === 0
              ? (
              <NearcastGrid
                observer={lastVideoElementRef}
                videos={userPosts}
                loading={loadingPosts}
                playable={true}
                emptyMessage={
                  !currentUserId
                    ? ( // If user not logged in
                    <NearcastsEmptyState
                      emptyMessage={{
                        main: `Please login to view ${profileData.first_name}'s Nearcasts`
                      }}
                      mainImage={cameraIcon}
                    />
                      )
                    : currentUserId === uid.current // If current user's profile is opened
                      ? (
                    <NearcastsEmptyState
                      emptyMessage={{
                        main: 'You havent posted any nearcasts yet',
                        desc: (
                          <span>
                            Scan the QR Code above from your phone or{' '}
                            <a href="https://app.nearcast.com/download/noposts">
                              click here
                            </a>{' '}
                            to create nearcasts.
                          </span>
                        )
                      }}
                      mainImage={downloadQrCode}
                    />
                        )
                      : ( // If no nearcasts have been posted by the user
                    <NearcastsEmptyState
                      emptyMessage={{
                        main: "This user hasn't posted any nearcasts yet"
                      }}
                      mainImage={cameraIcon}
                    />
                        )
                }
                localRefresher={() => {
                  getUserPostsApi(1)
                }}
              />
                )
              : uid.current === currentUserId
                ? (
                    contentType === 1
                      ? (
                <UserList
                  users={userFollowers}
                  action={{ text: 'Remove', color: 'Red' }}
                  isLoad={loadingPosts}
                  onclick={(i) => removeFollower(i)}
                  emptyMessage="You have no followers"
                  ownId={personal[active_neighbourhood].ID}
                  observer={lastVideoElementRef}
                />
                        )
                      : (
                <UserList
                  users={userFollowing}
                  action={{
                    text: 'Unfollow',
                    color: 'Red'
                  }}
                  isLoad={loadingPosts}
                  onclick={(i) => {
                    unfollowUser(i)
                    setUserFollowing((oldUserFollowing) => oldUserFollowing.filter((u) => u.user_id !== i))
                  }}
                  emptyMessage="You don't follow anyone"
                  ownId={personal[active_neighbourhood].ID}
                  observer={lastVideoElementRef}
                />
                        )
                  )
                : contentType === 1
                  ? (
              <UserList
                users={userFollowers}
                action={{
                  active: { text: 'Follow', color: 'Black' },
                  inActive: { text: 'Unfollow', color: 'Red' }
                }}
                isLoad={loadingPosts}
                onclick={(i) => {
                  console.log(Object.keys(selfFollowing), i)
                  if (Object.keys(selfFollowing).includes(i)) {
                    unfollowUser(i)
                  } else {
                    followUser(i)
                  }
                }}
                emptyMessage="User has no followers"
                ownId={personal[active_neighbourhood].ID}
                selfFollowing={selfFollowing}
                observer={lastVideoElementRef}
              />
                    )
                  : (
              <UserList
                users={userFollowing}
                action={{
                  active: { text: 'Follow', color: 'Black' },
                  inActive: { text: 'Unfollow', color: 'Red' }
                }}
                isLoad={loadingPosts}
                onclick={(i) => {
                  console.log(Object.keys(selfFollowing), i)
                  if (Object.keys(selfFollowing).includes(i)) {
                    unfollowUser(i)
                  } else {
                    followUser(i)
                  }
                }}
                emptyMessage="User hasn't started following anyone yet"
                ownId={personal[active_neighbourhood].ID}
                selfFollowing={selfFollowing}
                observer={lastVideoElementRef}
              />
                    )}
          </Grid>
        </div>
      </Grid>
      {currentUserId && (
          <>
          <EditProfileModal
            open={showEditUserModal}
            onClose={() => setShowEditUserModal(false)}
            onSubmit={updateUserDetails}
            profileData={profileData}
          />
          <UserSettingsModal
            open={showUserSettings}
            openEditProfile={() => {
              setShowEditUserModal(true)
              setShowUserSettings(false)
            }}
            onClose={() => {
              setShowUserSettings(false)
              getProfileDataFromUIDApi()
            }}
            profileData={profileData}
          />
          </>
      )}
    </div>
      )
    : (
    <>
      <Header />
      {profileError
        ? (
        <Dialog
          isOpen={profileError}
          title="We couldn't find the profile you were looking for!"
          cancelText="Retry"
          onCancel={() => window.location.reload()}
          confirmText={jwt_token ? 'Go Home' : 'Login'}
          onConfirm={() => navigate(jwt_token ? '/home' : '/login')}
          icon={() => <ErrorOutline color="error" sx={{ fontSize: 60 }} />}
        />
          )
        : (
        <div
          style={{
            display: 'flex',
            height: '70vh',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#FFFFFF'
          }}
        >
          <CircularProgress />
        </div>
          )}
    </>
      )
}

const styles = makeStyles(({ breakpoints, palette }) => {
  return {
    mainContainer: {
      marginTop: '64px',
      display: 'flex',
      justifyContent: 'center',
      [breakpoints.down('sm')]: {
        marginTop: 0
      }
    },
    root: {
      position: 'relative',
      maxWidth: '1800px',
      width: '100vw',
      height: 'calc( 100vh - 64px )',
      overflowY: 'auto',
      overflowX: 'clip',
      display: 'flex',
      flexDirection: 'row',
      [breakpoints.down('md')]: {
        flexDirection: 'column'
      }
    },
    profileContainer: {
      width: '550px',
      borderRadius: '20px',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'column',
      paddingBottom: '60px',
      minWidth: '600px',
      [breakpoints.down('md')]: {
        width: '100%',
        paddingBottom: '20px',
        minWidth: '200px'
      }
    },
    backButtonContainer: {
      alignSelf: 'flex-start',
      width: '100%',
      marginInline: '20px',
      paddingTop: '20px',
      [breakpoints.down('sm')]: {
        margin: 0
      }
    },
    backButton: {
      float: 'left',
      display: 'flex',
      fontSize: '23px',
      alignItems: 'center',
      padding: '8px 20px'
    },
    backButtonIcon: {
      width: '30px',
      height: '30px',
      padding: '5px',
      borderRadius: '20px',
      backgroundColor: '#E8E8E8'
    },
    backButtonText: {
      display: 'inline',
      lineHeight: 'normal',
      textDecoration: 'none',
      fontFamily: 'avenir next',
      marginLeft: '10px',
      [breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    settingsButton: {
      float: 'right',
      marginRight: '30px',
      borderRadius: '50px',
      padding: '8px',
      [breakpoints.down('sm')]: {
        marginRight: '10px'
      }
    },
    settingsButtonIcon: {
      backgroundColor: 'transparent'
    },
    profileContent: {
      marginTop: '80px',
      width: '450px',
      [breakpoints.down('sm')]: {
        marginTop: '30px',
        width: '100%'
      }
    },
    profilePicContainer: {
      marginTop: 'auto',
      marginBottom: 'auto',
      paddingInline: '20px',
      [breakpoints.down('sm')]: {
        fontSize: '12px'
      }
    },
    profilePic: {
      width: 100,
      height: 100,
      [breakpoints.down('sm')]: {
        width: 70,
        height: 70
      },
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: '#1A6751',
      boxShadow: '1px 1px 8px 1px #1A6751'
    },
    profileDetails: {},
    profileName: {
      marginTop: '22px',
      fontSize: '24px',
      fontWeight: 'bold',
      [breakpoints.down('sm')]: {
        marginTop: '12px',
        fontSize: '18px'
      }
    },
    profileCountsContainer: {
      display: 'flex',
      justifyContent: 'space-evenly',
      marginTop: '50px',
      [breakpoints.down('sm')]: {
        marginTop: '25px'
      }
    },
    countItem: {
      cursor: 'pointer',
      textAlign: 'center'
    },
    countItemNumber: {
      fontWeight: '600',
      fontSize: '1.75rem',
      [breakpoints.down('sm')]: {
        fontSize: '18px'
      }
    },
    countItemDescription: {
      opacity: '0.8',
      [breakpoints.down('sm')]: {
        fontSize: '12px'
      }
    },
    profileDescription: {
      paddingInline: '20px',
      marginBlock: '45px',
      width: '100%',
      overflowWrap: 'break-word',
      [breakpoints.down('sm')]: {
        marginBlock: '25px'
      }
    },
    actionButtonContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      gap: '20px',
      padding: '0 10px'
    },
    actionButton: {
      width: '100%'
    }
  }
})

export default Profile
