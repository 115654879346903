import * as React from 'react'
import {
  BottomNavigation as DefaultBottomNavigation,
  BottomNavigationAction,
  Badge
} from '@mui/material'
import {
  HomeRounded,
  SearchRounded,
  NotificationsRounded,
  AccountCircleRounded
} from '@mui/icons-material'
import { useNavigate, useLocation } from 'react-router-dom'
import { UserContext } from '../../context/UserContext'
import Dialog from 'ui/Dialog/Dialog'

// importing redux
import { useAppSelector } from '../../utils/redux/store'

const BottomNavigation = ({ classes }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { personal, active_neighbourhood } = React.useContext(UserContext)

  // redux
  const activityState = useAppSelector((state) => state.root.activityState)

  // component states
  const [isUserLoggedIn, setIsUserLoggedIn] = React.useState(false)
  const [value, setValue] = React.useState(0)
  const [isLoginDialogOpen, setIsLoginDialogOpen] = React.useState(false)

  const onPressActionHandler = (routeUrl) => {
    if (!isUserLoggedIn) {
      setIsLoginDialogOpen(true)
      return
    }
    navigate(routeUrl)
  }

  React.useEffect(() => {
    // we need to make sure the bottom navigation buttons are active only if the user is logged in
    if (personal === null) {
      setIsUserLoggedIn(false)
    } else {
      setIsUserLoggedIn(true)
    }
    // to make sure the right bottom button has accent
    switch (location.pathname.split('/')[1]) {
      case 'home':
        if (['group', 'gathering', 'business'].includes(location.pathname.split('/')[2])) {
          setValue(1)
          break
        }
        setValue(0)
        break
      case 'activity':
        setValue(3)
        break
      case 'u':
        setValue(4)
        break
      default:
        setValue(0)
    }
  }, [location.pathname, personal])

  return (
        <DefaultBottomNavigation
            style={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              height: 70,
              zIndex: 1002,
              paddingRight: 10,
              paddingLeft: 10,
              borderTop: '2px solid #e0e0e0'
            }}
            showLabels
            value={value}
            onChange={(event, newValue) => {
              if (isUserLoggedIn) {
                setValue(newValue)
              }
            }}
        >
            <BottomNavigationAction
                label="Home"
                icon={<HomeRounded />}
                onClick={() => onPressActionHandler('/home')}
            />
            <BottomNavigationAction
                label="Browse"
                icon={<SearchRounded />}
                onClick={() => onPressActionHandler('/home/group')}
            />
            <BottomNavigationAction
                label=""
                icon={<img alt="Create Nearcast" src={require('../../assets/bottomNavNearcast.svg').default} />}
                onClick={() => onPressActionHandler('/home/create')}
            />
            <BottomNavigationAction
                label="Activity"
                icon={(
                  <Badge badgeContent={activityState.recent.length} color="primary">
                    <NotificationsRounded />
                  </Badge>
                )}
                onClick={() => onPressActionHandler('/activity')}
            />
            <BottomNavigationAction
                label="Profile"
                icon={<AccountCircleRounded />}
                onClickCapture={() => {
                  if (!isUserLoggedIn) {
                    return
                  }
                  onPressActionHandler(`/u/${personal[active_neighbourhood].ID}`)
                }}
            />
            {/* We will like the unauthenticated user to login first, before using our app */}
            {!isUserLoggedIn && (
                <Dialog
                    isOpen={isLoginDialogOpen}
                    title="We noticed you aren't logged in. Please login to continue using the app"
                    cancelText="No"
                    onCancel={() => setIsLoginDialogOpen(false)}
                    confirmText="Login"
                    onConfirm={() => navigate('/login')}
                />
            )}
        </DefaultBottomNavigation>
  )
}

export default BottomNavigation
