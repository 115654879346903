import { makeStyles } from '@mui/styles'
import React from 'react'
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material'

function CookiePolicy () {
  const classes = styles()

  return (
        <div className={classes.mainContainer}>
            <Typography variant="h2">
                Advertising and Cookie Policy
            </Typography>
            <Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>
                1. What are cookies?
            </Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                Cookies are strings of information that constitute ‘trackers’ which are placed on your computer when you visit various websites. We use cookies on our site to keep track of the Services you have used, record registration information, record your user preferences, keep you logged into the site, facilitate any purchase or subscription procedures, and track what pages you visit (including what page you are coming from and going to). There are various types of cookies, including: Advertising Cookies (allowing for ad providers to gather information about your visits to the site and other websites, and track how often ads are interacted with. These are linked to a computer and do not gather personal information about you), Analytics Cookies (monitoring how users reach the Site, how they interact with and move around on the Site, and what features are best working and can be improved), First-Party Cookies (either permanent or temporary cookies that aid with certain functionalities and features on our Site; some may be manually disabled in your browser, but may affect Site functionality), Personalization Cookies (used to recognize repeat visitors to the Site, and record your browsing history, pages you visit, and settings/preferences each time you visit the Site), Security Cookies (aid in identification of security risks; authenticate users and protect user data from unauthorized parties), Site Management Cookies (maintain your identity or session on the Site so you are not logged off unexpectedly, and retains information from page to page), and Third-Party Cookies (may be placed on your computer when you visit the Site by companies that run certain services we offer; these cookies allow the third parties to gather and track certain information about you).
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>
                2. What is a mobile advertising identifier?
            </Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                Most phones and tablets include a mobile advertising identifier that uniquely identifies that device and allows for third parties to connect you to your activities on that app to your activities on other unrelated apps and websites. This is generally used for personalizing advertising and measurement of advertising campaigns. Ads still appear without them, but they will not be personalized.
            </Typography><Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>
                3. What cookies does Nearcast use, and why?
            </Typography><Typography variant="body1" style={{ marginTop: '20px' }}>
                Nearcast uses the following cookies:
                <br /> -&gt; <b>Strictly necessary cookies:</b> These cookies are required for our Services to function property. You can block these via your browser settings, but the majority of our Services will not function properly and we will be unable to guarantee how the security of our Services will function.3w
                <br /> -&gt; <b>Functional cookies:</b> These cookies provide enhanced functionality/personalization features. Not adding these cookies may cause some functionality to be lost.
                <br /> -&gt; <b>Targeting cookies:</b> These cookies are used in profiling your interests, and showing relevant information and advertisements. If you do not allow for these cookies, you will be shown less relevant advertisements to you.
                <br /> -&gt; <b>Performance cookies:</b> These cookies will collect information on how you use our Services, showing us a record of what pages you visit or errors you encounter. These are used to improve our Service, and show us what interests our users and measure the effectiveness of our content. Not allowing these cookies will prevent us from knowing when you visit the Site.
                <br /> <br />
                Nearcast uses both session cookies and persistent cookies:
                <br /> -&gt; <b>Session cookies:</b> These cookies are used to identify you during a specific session using our Services. Session cookies are not permanent, and will expire after either a certain time, or when you cease using our services for a session.
                <br /> -&gt; <b>Persistent cookies:</b> These cookies are used to identify you over a longer period of time, remaining on your device for a set period of time specified in the cookie. These help us identify when a user wants long-term services like remaining logged in, or particular settings.
                <br /> <br />
                We use first and third party cookies to ensure Nearcast operates properly:
                <br />-&gt; To authenticate you and secure our Services: Cookies are used to track login, session, and status data. This enables us to determine, for example, if you are currently logged in.
                <br />-&gt; To remember your settings and preferences: Cookies enable us to identify your browser and save your settings. For instance, we use cookies to keep track of your language choices.
                <br />-&gt; To carry out analytics and research: Cookies are used to better understand how you use Nearcast and how we can make it better. For instance, we use cookies to keep track of the sites you visit and the emails you read.
                <br />-&gt; To personalize your experience: Cookies are used to customize your experience with Nearcast, including presenting you with customized advertising that may be more relevant to your interests.
                <br />-&gt; For fraud, trust and safety, and investigations: Cookies are used to help secure the Services, such as detecting and preventing fraud and conducting investigations.
            </Typography>
            <Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>
                4. What third-party cookies are used on Nearcast?
            </Typography>
            <Typography variant="body1" style={{ marginTop: '20px' }}>
                On Nearcast, a variety of third-party cookies are set, some of which are dependent on how you use our Services. When you use Nearcast, the following kinds of third-party cookies may be set:
                <br /> -&gt; <b>Social media login cookies:</b> If you register or sign in using a social media account, the social media platform may set a cookie.
                <br /> -&gt; <b>Analytics cookies:</b> We employ analytics cookies to gather data on how Members and Visitors access and utilize Nearcast, as well as for other analytics services related to website activity and internet use.
                <br /> -&gt; <b>Advertising cookies:</b> The Services allow third-party advertising firms with whom we collaborate to gather information about how you use Nearcast and other websites and services over time via the use of cookies. These businesses use the information they gather to serve you advertisements they think are relevant to you on Nearcast and elsewhere, as well as to track the performance of those advertisements.
                <br /> -&gt; <b>Third-party cookies from content shared by Members:</b> Members may share content on Nearcast that originates from a third-party website or service, and when you engage with that content, the third-party website or service may set its own cookies. For instance, if a Member uploads a video to Nearcast from YouTube, YouTube may set a cookie when you click to view the video.
                <br /> -&gt; <b>Pixels:</b> Our advertising partners may use pixels or similar technologies to determine the effectiveness of sponsored posts or other advertising goods. A "pixel" (occasionally referred to as a web beacon or clear GIF) is a small piece of code that is invisible to the user that is placed on a webpage or in an email and used in conjunction with a cookie to associate a particular activity or impression with the user; the advertiser includes it in its content to track how our Members interact with it (for example, by clicking to view an offer). We may place our own pixel on an advertiser's website in order to determine the effectiveness or interest of the advertiser's content by evaluating whether the sponsored post encouraged Members to take advantage of the offered offer. This data enables us to determine whether sponsored posts and other forms of advertising are both relevant and attractive to our Members and profitable for the advertiser.
                <br /> -&gt; <b>SDKs:</b> Our Nearcast applications, as well as those of our partners and service providers, contain SDKs. SDKs include third-party code that enables us to understand how our app is used and what activities take place in connection with it, such as when a Member joins up or logs in. SDKs are also used in advertising for a variety of reasons, including measurement, customization, and audience segmentation.
                <br /><br />These cookies, the third parties that set them, the goals for which they are used, and the mechanisms for opting out are detailed in the table below.
            </Typography>
            <TableContainer component={Card}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <a href="https://facebook.com/">Facebook</a>
                            </TableCell>
                            <TableCell>
                                <a href="https://www.facebook.com/policy.php">Facebook Privacy Policy</a>
                            </TableCell>
                            <TableCell>
                                Advertising
                            </TableCell>
                            <TableCell>
                                <a href="https://www.facebook.com/policies/cookies/#u_0_17">Facebook Opt Out</a>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <a href="http://google.com/">Google</a>
                            </TableCell>
                            <TableCell>
                                <a href="https://policies.google.com/privacy">Google Privacy Policy</a>
                            </TableCell>
                            <TableCell>
                                Advertising (Google Ad Manager and Google Marketing Platform)
                            </TableCell>
                            <TableCell>
                                <a href="https://adssettings.google.com/authenticated?hl=en">Google Opt Out</a>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <a href="http://google.com/">
                                    Google Analytics
                                </a>
                            </TableCell>
                            <TableCell>
                                <a href="https://policies.google.com/privacy">Google Privacy Policy</a>
                            </TableCell>
                            <TableCell>
                                Analytics
                            </TableCell>
                            <TableCell>
                                <a href="https://tools.google.com/dlpage/gaoptout?hl=en">Google Analytics Opt Out</a>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>
                5. Can I opt-out of use of my information for third party personalized advertising?
            </Typography>
            <Typography variant="body1" style={{ marginTop: '20px' }}>
                Yes, here is how:
                <br /> -&gt; <b>Browsers.</b> You can disable or limit the usage of cookies in your browser by choosing the relevant settings. Limiting or rejecting cookies may impair your ability to utilize Nearcast. Visit www.allaboutcookies.org for additional information about cookies, including how to view which cookies have been placed on your computer or mobile device and how to manage and remove them. Certain advertising networks include an opt-out mechanism for customized advertising. For additional information, please visit the Digital Advertising Alliance's website at https://www.aboutads.info/choices, the Network Advertising Initiative's website at https://optout.networkadvertising.org (for members in the United States), or the European Digital Advertising Alliance's website at http://www.youronlinechoices.eu (for EU Members).
                <br /> -&gt; <b>Mobile apps.</b> Your mobile device may have a feature that enables you to opt out of some kinds of customized advertising based on your mobile advertising identifier, such as the "limit ad tracking" option in the privacy settings of your iOS or Android smartphone. For additional information, see the Network Advertising Initiative's mobile choices website. Additionally, the Digital Advertising Alliance has a mobile app called AppChoices that enables you to opt out of receiving interest-based advertising from participating advertisers.
                <br /> -&gt; <b>Third parties.</b> As stated in the table above, some of the third-party advertising partners with which we work have opt-out options. Because we do not manage any of these opt-out links, we cannot guarantee their availability or reliability.
                <br /> While using these opt-out services will not prevent you from seeing all advertisements, they may make the advertisements you do view more relevant to you. Additionally, you must configure these opt-out settings on each browser or device you use.
            </Typography>
            <Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>
                6. Changes to this policy.
            </Typography>
            <Typography variant="body1" style={{ marginTop: '20px' }}>
                Our company is continuously changing, and as a result, our cookie policy will change as well, so please check it often. We will inform you of any changes by publishing the revised policy with the new effective date on this page. We will take reasonable efforts to inform you in advance if the change is material, as required by relevant legislation.
            </Typography>
            <Typography variant="h4" style={{ marginTop: '20px', fontWeight: 'bolder' }}>
                7. Questions.
            </Typography>
            <Typography variant="body1" style={{ marginTop: '20px' }}>
                Our Privacy Policy contains more information about how we collect, handle, and share your information. If you have any concerns regarding our cookie use, you can reach out to us online or through email at <a href="mailto:support@nearcast.com">support@nearcast.com</a>. Additionally, you may contact us at the address given in our Privacy Statement.
            </Typography>
        </div>
  )
}

const styles = makeStyles(({ breakpoints, palette }) => ({
  flex: {
    display: 'flex',
    alignItems: 'center'
  },
  flexGrow: {
    flex: '1'
  },
  mainContainer: {
    padding: '40px',
    paddingBottom: '60px'
  }
}))

export default CookiePolicy
