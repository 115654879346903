import axios from 'axios'

/**
 * @function groupCoverS3Url Used to update the group cover photo
 * @param {string} groupId - group's id, eg: "qwe....948ijg"
 * @param {Object} coverImage - input file upload
 * @param {string} nrctUID - personal[active_neighbourhood].ID from user context
 * @param {string} jwt - jwt token from user context
 * @returns {Promise} Promise object represents true for successful group cover upload
 */
export const groupCoverS3Url = (groupId, coverImage, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/group/cover/presign?group=${groupId}`,
      {
        params: {
          extension: coverImage.type.split('/')[1]
        },
        headers: {
          'NRCT-UID': nrctUID,
          Authorization: `Bearer ${jwt}`
        }
      }
    )
      .then((res) => {
        if (res.data.status === 200) {
          return res.data.url
        }
        throw new Error(res.data.msg)
      })
      .then(async (coverUrl) => {
        await axios.put(coverUrl, coverImage, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': coverImage.type
          }
        })
        resolve(true)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

/**
 * @function groupCover Used to gathering ...
 * @param {string} groupId - gathering's id, eg: "qwe....948ijg"
 * @returns {url} Promise object represents true for successful group cover upload
 */
export const groupCover = (groupId) => {
  return `${process.env.REACT_APP_BACKEND_URL}/group/cover?group=${groupId}&updated_at=${Date.now()}`
}
