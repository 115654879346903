import {
  Avatar,
  ButtonBase,
  CircularProgress,
  Divider,
  Typography
} from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import getName from '../../utils/getName'

// importing apis
import { personalAccountAvatar } from 'api/personal_account/avatar'

function UserList ({
  users,
  action,
  onclick,
  emptyMessage,
  isLoad,
  ownId,
  selfFollowing,
  observer
}) {
  const navigate = useNavigate()
  if (isLoad) {
    return (
      <div
        style={{
          textAlign: 'center',
          width: '100%',
          paddingTop: '16px'
        }}
      >
        <div style={{ width: '60px', marginInline: 'auto' }}>
          <CircularProgress />
        </div>
      </div>
    )
  }

  if (users && users.length > 0) {
    return (
      <div style={{ paddingTop: '8px' }}>
        {users.map((user, index) => (
          <div key={'userList' + index} ref={users.length - 1 === index ? observer : null}>
            <div
              style={{
                paddingBlock: '20px',
                display: 'flex',
                cursor: 'pointer'
              }}
            >
              <a
                onClick={() => {
                  navigate(`/u/${user?.user_id}`, { replace: true })
                }}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Avatar
                  aria-controls="user-controls"
                  alt="userIcon"
                  style={{
                    marginRight: 10
                  }}
                  src={personalAccountAvatar(user?.user_id)}
                />
                <Typography style={{ color: 'black' }}>
                  {getName(user)}
                </Typography>
                <Typography
                  style={{
                    fontSize: '1rem',
                    color: '#00000060',
                    marginLeft: '8px'
                  }}
                >{`@${user.username}`}</Typography>
              </a>
              {user.user_id !== ownId && (
                <ButtonBase
                  style={{
                    marginLeft: 'auto',
                    marginRight: '8px',
                    color: selfFollowing
                      ? selfFollowing[user?.user_id]
                        ? action.inActive.color
                        : action.active.color
                      : action.color
                  }}
                  onClick={(e) => {
                    e.stopPropagation()
                    onclick(user?.user_id)
                  }}
                >
                  {selfFollowing
                    ? selfFollowing[user?.user_id]
                      ? action.inActive.text
                      : action.active.text
                    : action.text}
                </ButtonBase>
              )}
            </div>
            <Divider
              style={{ width: '100%', opacity: '0.5' }}
              key={'userlistdivider' + index}
            />
          </div>
        ))}
      </div>
    )
  }

  return (
    <div style={{ textAlign: 'center', width: '100%', paddingTop: '16px' }}>
      {emptyMessage}
    </div>
  )
}

export default UserList
