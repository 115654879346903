// importing action types
import postReducerActionTypes from '../actions/postActions'

// store design
const initialState = {
  postPreferences: {
    isMuted: true
  },
  postsNearbyLoading: true,
  postsNearby: [],
  postsNeighborhoodLoading: true,
  postsNeighborhood: []
}

// post reducer
const postReducer = (state = initialState, action) => {
  switch (action.type) {
    case postReducerActionTypes.postsRefreshNearby:
      return {
        ...state,
        postsNearbyLoading: false,
        postsNearby: action.payload.postsNearby
      }
    case postReducerActionTypes.postsRefreshNeighborhood:
      return {
        ...state,
        postsNeighborhoodLoading: false,
        postsNeighborhood: action.payload.postsNeighborhood
      }
    case postReducerActionTypes.postRefreshNearby:
      const newPosts = state.postsNearby.map((post) => {
        if (post.post_id !== action.payload.post.post_id) {
          return post
        }
        return action.payload.post
      })
      return {
        ...state,
        postsNearby: newPosts
      }
    case postReducerActionTypes.postRefreshNeighborhood:
      const newPostsN = state.postsNeighborhood.map((post) => {
        if (post.post_id !== action.payload.post.post_id) {
          return post
        }
        return action.payload.post
      })
      return {
        ...state,
        postsNeighborhood: newPostsN
      }
    case postReducerActionTypes.postRemove:
      return {
        ...state,
        postsNearby: state.postsNearby.filter((post) => post.post_id !== action.payload.postId),
        postsNeighborhood: state.postsNeighborhood.filter((post) => post.post_id !== action.payload.postId)
      }
    case postReducerActionTypes.postClearAll:
      return initialState
    default:
      return state
  }
}

export default postReducer
