import makeStyles from '@mui/styles/makeStyles'

export default makeStyles(({ breakpoints, palette }) => {
  return {
    toolbar: {
      minHeight: 54,
      width: '100%',
      maxWidth: '1800px',
      display: 'flex'
    },
    root: {
      backgroundColor: 'white',
      position: 'fixed',
      height: 'auto',
      display: 'flex',
      alignItems: ' center',
      boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)'
    },
    nearcastLogo: {
      display: 'block',
      margin: 'auto',
      alignSelf: 'center'
    }
  }
})
