import axios from 'axios'

/**
 * @function gatheringsRegister Used to register for a gathering
 * @param {string} gatheringId - the id of the gathering to register with
 * @param {string} nrctUID - user's current neighborhood id
 * @param {string} jwt - user's jwt token
 * @returns {Promise} Promise object represents
 */
export const gatheringsRegister = (gatheringId, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/gatherings/register`, {
      params: {
        gathering: gatheringId
      },
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => {
        if (res.data.status === 200) {
          resolve(true)
        } else {
          throw new Error('An Error Occurred!')
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}
