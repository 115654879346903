import React, { useContext, useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import {
  Container,
  CircularProgress,
  Typography,
  Stack,
  Button,
  Box
} from '@mui/material'
import { UserContext } from '../../context/UserContext'
import { GlobalFuncContext } from '../../context/GlobalFuncHOC'
import { NIL as uuidNil } from 'uuid'

// importing component
import Dialog from 'ui/Dialog/Dialog'
import Nearcast from 'ui/Icons/Nearcast'

// importing apis
import { businessDetailsGet } from 'api/business/details'
import { feedData } from 'api/analytics/feed'
import { businessAvatarView } from 'api/business/avatar/view'

// importing helper
import { recordGaEvent } from 'helpers/ga'

const CreateBusinessReview = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { jwt_token, personal, active_neighbourhood } = useContext(UserContext)
  const { openCreateReviewModal } = useContext(GlobalFuncContext)

  // page states
  const [loading, setLoading] = useState(true)
  const [businessDetails, setBusinessDetails] = useState<any>({})
  const [isErrorDialogOpen, setIsErrDialogOpen] = useState(null)

  const reportBusinessReviewScanned = () => {
    /**
     * For Logged in users
     * user_neighbourhood_id will be user's personal account neighbourhood id
     * jwt_token will be user's jwt
     * nrctUID will be user's personal account id
     *
     * For anonymous users
     * user_neighbourhood_id will be offer's neighbourhood id
     * jwt_token will be null
     * nrctUID will be null
     *
     * In this flow we are re-purposing the business_offers_scan
     * We send offer id as nil uuid
     */
    try {
      const data = {
        user_neighbourhood_id:
          personal
            ? personal[active_neighbourhood].neighbourhood.ID
            : businessDetails.neighbourhood_id,
        offer_id: uuidNil,
        business_id: id,
        created_at: new Date().toISOString()
      }

      feedData(
        'business_offers_scan',
        data,
        jwt_token,
        personal ? personal[active_neighbourhood].ID : null
      )
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (loading === false) {
      reportBusinessReviewScanned()
    }
  }, [id, loading])

  useEffect(() => {
    // we want both logged in users and anonymous users to be able to use this flow
    businessDetailsGet(id, null, null)
      .then((resData) => {
        setBusinessDetails(resData.body.business_details)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err.message)
        setIsErrDialogOpen(
          "We couldn't find the business you where looking for!"
        )
      })
  }, [id])

  if (location.pathname.includes('3453a765-a296-4557-be83-7067ec4d164b')) {
    return (
      <div
        style={{
          height: '100vh',
          width: '100vw',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {loading
          ? (
              <Container
                maxWidth="md"
                style={{
                  height: '100vh',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <CircularProgress />
              </Container>
            )
          : (
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="flex-start"
                sx={{
                  height: '100%',
                  textAlign: 'center',
                  width: {
                    xs: '96%',
                    md: '500px'
                  }
                }}
              >
                <Box sx={{ marginTop: '4%' }}>
                  <img
                    src={businessAvatarView(id)}
                    style={{
                      width: '100px',
                      height: '100px',
                      objectFit: 'fill',
                      borderRadius: 8
                    }}
                  />
                </Box>
                <Box sx={{ marginTop: '6%' }}>
                  <Typography variant="h3">
                    What Did You Think Of The Food?
                  </Typography>
                </Box>
                <Box sx={{ marginTop: '6%', height: '46%' }}>
                  <img
                    src={require('./pnp.jpg')}
                    style={{
                      height: '100%',
                      objectFit: 'fill',
                      borderRadius: 8
                    }}
                  />
                </Box>
                <Box sx={{ marginTop: '6%' }} style={{ width: '100%' }}>
                  <Stack
                    direction="column"
                    spacing={1}
                    style={{
                      width: '100%',
                      marginTop: 8
                    }}
                  >
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => {
                        // analytics
                        recordGaEvent('createBusinessReview-continueClicked', {
                          personalAccountId: personal ? personal[active_neighbourhood].ID : null,
                          businessId: id,
                          businessName: businessDetails.name
                        })
                        openCreateReviewModal('business', null, businessDetails)
                      }}
                      fullWidth
                      style={{
                        height: 50,
                        fontSize: 18,
                        fontWeight: 'bold'
                      }}
                      disableElevation
                      data-testid="continueButton"
                    >
                      Create a Review
                    </Button>
                  </Stack>
                </Box>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    marginTop: '2%'
                  }}
                >
                  <Typography variant="body2">
                    Powered By:
                  </Typography>
                  <Nearcast style={{ width: 80, marginLeft: 8 }} />
                </Stack>
              </Stack>
            )}
        <Dialog
          isOpen={Boolean(isErrorDialogOpen)}
          title={isErrorDialogOpen}
          cancelText="Retry"
          onCancel={() => window.location.reload()}
          confirmText="Go Home"
          onConfirm={() => navigate('/home')}
        />
      </div>
    )
  }

  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      {loading
        ? (
            <Container
                maxWidth="md"
              style={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <CircularProgress />
            </Container>
          )
        : (
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="flex-start"
              sx={{
                height: '100%',
                textAlign: 'center',
                width: {
                  xs: '96%',
                  md: '500px'
                }
              }}
            >
              <Box sx={{ marginTop: '4%' }}>
                <img
                  src={businessAvatarView(id)}
                  style={{
                    width: '100px',
                    height: '100px',
                    objectFit: 'fill',
                    borderRadius: 8
                  }}
                />
              </Box>
              <Box sx={{ marginTop: '4%' }}>
                <Typography variant="h3">
                  How was your experience?
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    fontWeight: 500
                  }}
                >
                  Please leave a video review of your experience at
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    fontWeight: 500
                  }}
                  color="primary"
                >
                  {businessDetails.name}
                </Typography>
              </Box>
              <Box sx={{ marginTop: '6%', height: '46%' }}>
                <img
                  src={require('./mobileReview.png')}
                  style={{
                    height: '100%',
                    objectFit: 'fill',
                    borderRadius: 8
                  }}
                />
              </Box>
              <Box sx={{ marginTop: '6%' }} style={{ width: '100%' }}>
                <Stack
                  direction="column"
                  spacing={1}
                  style={{
                    width: '100%',
                    marginTop: 8
                  }}
                >
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      // analytics
                      recordGaEvent('createBusinessReview-continueClicked', {
                        personalAccountId: personal ? personal[active_neighbourhood].ID : null,
                        businessId: id,
                        businessName: businessDetails.name
                      })
                      openCreateReviewModal('business', null, businessDetails)
                    }}
                    fullWidth
                    style={{
                      height: 50,
                      fontSize: 18,
                      fontWeight: 'bold'
                    }}
                    disableElevation
                    data-testid="continueButton"
                  >
                    Create a Review
                  </Button>
                </Stack>
              </Box>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{
                  marginTop: '2%'
                }}
              >
                <Typography variant="body2">
                  Powered By:
                </Typography>
                <Nearcast style={{ width: 80, marginLeft: 8 }} />
              </Stack>
            </Stack>
          )}
      <Dialog
        isOpen={Boolean(isErrorDialogOpen)}
        title={isErrorDialogOpen}
        cancelText="Retry"
        onCancel={() => window.location.reload()}
        confirmText="Go Home"
        onConfirm={() => navigate('/home')}
      />
    </div>
  )
}

export default CreateBusinessReview
