import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Container,
  Typography,
  Stack,
  Button,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  FormControl
} from '@mui/material'
import { useSnackbar } from 'notistack'

// importing component
import Nearcast from 'ui/Icons/Nearcast'

// importing APIs
import { emailUnsubscribeCategory } from 'api/email'

const whatAllNewsLetters = [
  {
    text: 'Unsubscribe from Newsletters',
    key: 'newsletters'
  },
  {
    text: 'Unsubscribe from Product emails',
    key: 'products'
  },
  {
    text: 'Unsubscribe from Marketing emails',
    key: 'marketing'
  },
  {
    text: 'Unsubscribe from Feedback emails',
    key: 'feedback'
  }
]

const whatReasonFor = [
  'I never signed up for your emails',
  'I get too many emails from you',
  'The content is no longer relevant to me',
  'The content is not what I expected',
  'Other'
]

const Unsubscribe = () => {
  const { email } = useParams()
  const { enqueueSnackbar } = useSnackbar()

  // page states
  const [selected, setSelected] = useState([])
  const [text, setText] = useState('')
  const [customReason, setCustomReason] = useState('')

  useEffect(() => {
    window.Tawk_API = window.Tawk_API || {}
    try {
      window.Tawk_API.hideWidget()
    } catch (err) {
      window.Tawk_API.onLoad = function () {
        window.Tawk_API.hideWidget()
      }
    }
  }, [])

  const onClickUnsubscribe = () => {
    if (selected.length === 0) {
      return enqueueSnackbar('Please select atleast one unsubscribe too!', { variant: 'error' })
    }

    const body = {}
    whatAllNewsLetters.forEach((s) => {
      const isSelected = selected.find((f) => f.key === s.key)
      body[s.key] = !isSelected
    })

    body.reason = text.toLowerCase() === 'other' ? customReason : text

    emailUnsubscribeCategory(
      email,
      body
    )
      .then(() => enqueueSnackbar('Successfully unsubscribed!', { variant: 'success' }))
      .catch((err) => console.log(err.message))
  }

  return (
    <Container maxWidth="sm" style={styles.mainOfferContainer}>
      <Stack direction="row" alignItems="center" justifyContent="center">
        <Nearcast style={{ width: 140 }} />
      </Stack>
      <Stack
          direction="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          style={styles.offerInnerStack}
          sx={{
            marginTop: 6
          }}
      >
        <Typography variant="h6" textAlign="left">
          We are sorry to find you are no longer interested in our newsletters
        </Typography>
        {whatAllNewsLetters.map((n) => {
          return (
            <FormControlLabel
              key={n.key}
              label={n.text}
              sx={{
                textAlign: 'left'
              }}
              control={(
                <Checkbox
                  checked={selected.find((s) => s.key === n.key) !== undefined}
                  onChange={(_, checked) => {
                    if (checked) {
                      setSelected([...selected, n])
                    } else {
                      setSelected(selected.filter((s) => s.key !== n.key))
                    }
                  }}
                />
              )}
            />
          )
        })}
        <Typography variant="h6" textAlign="left" sx={{ marginTop: 2 }}>
          We'd be grateful if you'd let us know why you're unsubscribing.
        </Typography>
        <RadioGroup
          value={text}
          onChange={(event) => setText(event.target.value)}
        >
          {whatReasonFor.map((m) => {
            return (
              <FormControlLabel
                key={m}
                value={m}
                control={<Radio />}
                label={m}
                sx={{
                  textAlign: 'left'
                }}
              />
            )
          })}
        </RadioGroup>
        <FormControl
          disabled={text.toLowerCase() === 'other'}
        >
          <TextField
            id="other-unsubscribe-reason"
            label="Reason for un-subscription"
            multiline
            rows={4}
            variant="outlined"
            value={customReason}
            onChange={(event) => setCustomReason(event.current.value)}
          />
        </FormControl>
        <FormControl fullWidth>
          <Button
            variant="contained"
            color="secondary"
            disableElevation
            sx={{
              marginTop: 4
            }}
            onClick={() => onClickUnsubscribe()}
            disabled={selected.length === 0}
          >
            Submit
          </Button>
        </FormControl>
      </Stack>
    </Container>
  )
}

const styles = {
  mainOfferContainer: {
    paddingTop: 20,
    paddingBottom: 20,
    overflowY: 'auto'
  },
  offerInnerStack: {
    width: '100%',
    height: '100%',
    textAlign: 'center'
  }
}

export default Unsubscribe
