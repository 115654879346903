import { useTheme } from '@mui/material'
import { MicOff } from '@mui/icons-material'
import { useState, useEffect } from 'react'
import avatar from '../../../assets/avatar.jpg'

// importing apis
import { personalAccountAvatar } from 'api/personal_account/avatar'

function Speaker ({ name, audio, isAudioMuted, volume, id, role }) {
  const { palette } = useTheme()
  const [loadError, setLoadError] = useState(true)

  useEffect(() => {
    if (audio) audio.play()
    return () => {
      if (audio) audio.stop()
    }
  }, [audio])

  useEffect(() => {}, [volume])

  return (
    <div
      style={{
        width: 150,
        height: 170,
        margin: 15,
        position: 'relative',
        textAlign: 'center'
      }}
    >
      <img
        src={personalAccountAvatar(id)}
        onError={(e) => {
          if (loadError) e.target.src = avatar
          else { e.target.src = personalAccountAvatar(id) }
          setLoadError(true)
        }}
        alt=""
        style={{
          width: 120,
          height: 120,
          borderRadius: '50%',
          border: `2px solid ${palette.primary.light}`,
          margin: '15px auto',
          objectFit: 'cover',
          boxShadow:
            volume && volume > 5
              ? '0 0 5px rgba(15, 158, 13, 0.9), 0 0 10px rgba(15, 158, 13, 0.7), 0 0 15px rgba(15, 158, 13, 0.5)'
              : 'none'
        }}
      />
      {audio === undefined || isAudioMuted
        ? (
        <div
          style={{
            position: 'absolute',
            right: 12,
            bottom: 36,
            background: '#ffffff',
            padding: '6px 5px 0.5px',
            borderRadius: '50%',
            border: `2px solid ${palette.primary.main}`,
            color: `${palette.primary.main}`,
            fontSize: 10
          }}
        >
          <MicOff style={{ fontSize: 18 }} />
        </div>
          )
        : (
        <></>
          )}
      <div style={{ textAlign: 'center' }}>{name}</div>
      <div
        style={{
          textAlign: 'center',
          color: role === 3 ? palette.secondary.main : palette.primary.light,
          fontWeight: 600
        }}
      >
        {role === 1 ? '(Host)' : '(Co-Host)'}
      </div>
    </div>
  )
}

function AudioComponent ({
  hosts,
  forceUpdate,
  remoteUsers,
  isAudioMuted,
  volumes,
  media
}) {
  useEffect(() => {}, [forceUpdate])

  return (
    <>
      <div
        style={{
          display: 'flex',
          padding: 20,
          maxHeight: 'calc(100% - 40px)',
          width: 'calc(100% - 40px)',
          flexWrap: 'wrap',
          alignContent: 'flex-start',
          alignItems: 'flex-start',
          justifyContent: media.small ? 'center' : 'left'
        }}
      >
        {hosts.current.map((item, i) => {
          const temp = remoteUsers.filter((user) => user.uid === item.uid)
          const volume = volumes.filter((volume) => volume.uid === item.uid)
          if (temp.length === 0) {
            return (
              <Speaker
                name={item.name}
                id={item.userId}
                role={item.role}
                audio={null}
                isAudioMuted={isAudioMuted}
                volume={volume[0] ? volume[0].level : 0}
                key={i}
              />
            )
          }
          return (
            <Speaker
              name={item.name}
              id={item.userId}
              role={item.role}
              audio={temp[0].audioTrack}
              volume={volume[0] ? volume[0].level : 0}
              key={i}
            />
          )
        })}
      </div>
    </>
  )
}

export default AudioComponent
