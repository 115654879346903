import React from 'react'
import { Typography } from '@mui/material'

const NearcastEmptyState = ({ mainImage, emptyMessage }) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        marginTop: '80px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <div style={{}}>
        <img
          style={{ maxWidth: '250px', maxHeight: '100%' }}
          src={mainImage}
          alt="No Images"
        />
      </div>
      <div style={{ textAlign: 'center', marginTop: '16px' }}>
        <Typography color="textPrimary" variant="h2" style={{ maxWidth: '360px' }}>
          {emptyMessage.main}
        </Typography>
        <Typography
          style={{ fontSize: '15px', marginTop: '10px', maxWidth: '360px' }}
        >
          {emptyMessage.desc}
        </Typography>
      </div>
    </div>
  )
}

export default NearcastEmptyState
