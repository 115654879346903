import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Menu,
  MenuItem,
  Typography
} from '@mui/material'
import React, { useState, useMemo } from 'react'
import getName from '../../utils/getName'
import { timeDifference } from '../../utils/dateTimeParser.js'
import {
  Delete,
  DeleteRounded,
  MoreVert,
  ErrorRounded
} from '@mui/icons-material'

// importing apis
import { personalAccountAvatar } from 'api/personal_account/avatar'

function CommentElement ({
  forwardRef = null,
  ownComment,
  ownPost,
  comment,
  className,
  deleteComment,
  reportComment,
  placeholder,
  classes
}) {
  const [open, setOpen] = useState()
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [reportOpen, setReportOpen] = useState(false)
  const userAvatar = useMemo(() => {
    return personalAccountAvatar(comment.user_id)
  }, [comment.user_id])
  return (
    <div
      style={{
        display: 'flex',
        marginTop: '10px'
      }}
      ref={forwardRef}
      key={comment.id}
    >
      <div
        className={placeholder && className}
        style={{
          height: '40px',
          width: '40px',
          borderRadius: '20px',
          marginRight: '9px',
          backgroundImage: `url(${userAvatar})`,
          backgroundSize: 'cover',
          backgroundColor: '#2E2E2E80'
        }}
      />
      <div style={{ flex: 1 }} className={className}>
        <Typography className={classes.textContainer}>
          <a
            style={{
              fontWeight: 'bold',
              textDecoration: 'none',
              color: 'black'
            }}
            href={`${window.location
              .toString()
              .split('/')
              .slice(0, 3)
              .join('/')}/u/${comment.user_id}`}
          >
            @{comment.username}
          </a>{' '}
          {comment.comment}
        </Typography>
        <Typography style={{ fontSize: '12px' }}>
          {comment.created_at ? timeDifference(comment.created_at) : '‏‏‎'}
        </Typography>
      </div>
      <div
        style={{
          justifySelf: 'flex-end',
          display: placeholder ? 'none' : 'block'
        }}
      >
        {!placeholder && (
          <>
            <IconButton
              onClick={(e) => {
                setOpen(e.currentTarget)
              }}
              aria-controls="people-menu"
              disableRipple
              disableFocusRipple
              disableTouchRipple
              size="large">
              <MoreVert />
            </IconButton>
            <Menu
              id="people-menu"
              anchorEl={open}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={Boolean(open)}
              keepMounted
              onClose={() => {
                setOpen(null)
              }}
            >
              {ownComment || ownPost
                ? (
                <MenuItem
                  onClick={() => {
                    setDeleteOpen(true)
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <DeleteRounded
                      color="error"
                      style={{ marginRight: '8px' }}
                    />
                    <Typography color="error">Delete comment</Typography>
                  </div>
                </MenuItem>
                  )
                : (
                <MenuItem
                  onClick={() => {
                    setReportOpen(true)
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ErrorRounded
                      color="error"
                      style={{ marginRight: '8px' }}
                    />
                    <Typography color="error">Report comment</Typography>
                  </div>
                </MenuItem>
                  )}
            </Menu>
          </>
        )}
      </div>
      <Dialog
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        aria-labelledby="delet-prompt"
        PaperProps={{
          style: {
            maxWidth: 400,
            padding: 10,
            textAlign: 'center',
            borderRadius: 10
          }
        }}
      >
        <DialogContent>
          <>
            <div
              style={{
                borderRadius: 36,
                width: 72,
                height: 72,
                display: 'grid',
                justifyItems: 'center',
                alignItems: 'center',
                margin: 'auto'
              }}
            >
              <Delete style={{ fontSize: '2.5rem' }} color="error" />
            </div>
            <div>Do you want to delete this comment</div>
            <br />
          </>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            onClick={() => {
              setDeleteOpen(false)
              setOpen()
            }}
            style={{ marginInline: '10px', paddingInline: '40px' }}
          >
            No
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              deleteComment(comment.id)
              setDeleteOpen(false)
              setOpen(false)
            }}
            disableElevation
            style={{ marginInline: '10px', paddingInline: '40px' }}
          >
            Yes
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        open={reportOpen}
        onClose={() => reportOpen(false)}
        aria-labelledby="report-prompt"
        PaperProps={{
          style: {
            maxWidth: 400,
            padding: 10,
            textAlign: 'center',
            borderRadius: 10
          }
        }}
      >
        <DialogContent>
          <>
            <div
              style={{
                borderRadius: 36,
                width: 72,
                height: 72,
                display: 'grid',
                justifyItems: 'center',
                alignItems: 'center',
                margin: 'auto'
              }}
            >
              <ErrorRounded style={{ fontSize: '2.5rem' }} color="error" />
            </div>
            <div>Do you want to report this comment</div>
            <br />
          </>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            onClick={() => {
              setReportOpen(false)
              setOpen()
            }}
            style={{ marginInline: '10px', paddingInline: '40px' }}
          >
            No
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              reportComment(comment.id)
              setReportOpen(false)
              setOpen(false)
            }}
            disableElevation
            style={{ marginInline: '10px', paddingInline: '40px' }}
          >
            Yes
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default React.memo(CommentElement)
