import React, { useContext, useEffect, useState } from 'react'
import { CircularProgress, Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ErrorRounded, Share } from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import ImageCoverCard from 'ui/Cards/ImageCoverCard'
import { getFormattedDate } from '../../../utils/dateTimeParser'
import { useNavigate } from 'react-router-dom'

// importing context
import { UserContext } from '../../../context/UserContext'
import { GlobalFuncContext } from '../../../context/GlobalFuncHOC'

// importing components
import ActionModal from '../../Modals/ActionModal'
import GatheringEmptyState from '../../EmptyStates/GatheringGroupEmptyState'
import cookIcon from '../../../assets/Fill.svg'
import castIcon from '../../../assets/nearcast.svg'

// importing apis
import { gatheringNeighbourhood } from 'api/gatherings/neighbourhood'
import { reportGathering } from 'api/report/gathering'
import { gatheringsAvatar } from 'api/gatherings/avatar'

function AllGatherings(props) {
  const [currentGatherings, setCurrentGatherings] = useState([])
  const [pastGatherings, setPastGatherings] = useState([])
  const [reportOpen, setReportOpen] = useState()
  const [loading, setLoading] = useState(true)
  const { personal, jwt_token, active_neighbourhood } = useContext(UserContext)
  const { openShareModal } = useContext(GlobalFuncContext)
  const { enqueueSnackbar } = useSnackbar()
  const classes = styles()
  const navigate = useNavigate()

  useEffect(() => {
    setLoading(true)
    gatheringNeighbourhood(1, 30, personal[active_neighbourhood].ID, jwt_token)
      .then((dataGathering) => {
        const oldGatherings = []
        const newGatherings = []
        if (dataGathering !== null) {
          dataGathering.forEach((element) => {
            (Date.now() - new Date(element.ends_at) <= 0
              ? newGatherings
              : oldGatherings
            ).push(element)
          })
        }
        setCurrentGatherings(newGatherings)
        // setPastGatherings(oldGatherings)
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: 'error'
        })
      })
      .finally(() => {
        setLoading(false)
      })
    // eslint-disable-next-line
  }, [active_neighbourhood]);

  return (
    <Grid container className={classes.mainContainer}>
      {loading
        ? (
          <div
            style={{
              display: 'flex',
              height: '70vh',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              background: '#FFFFFF'
            }}
          >
            <CircularProgress />
          </div>
        )
        : (currentGatherings && currentGatherings.length > 0) ||
          (pastGatherings && pastGatherings.length > 0)
          ? (
            <>
              {currentGatherings && currentGatherings.length > 0 && (
                <>
                  <Typography variant="h2" style={{ marginBlock: '8px 16px' }}>
                    Happening Now
                  </Typography>
                  <Grid container spacing={2} className={classes.gatheringsGrid}>
                    {currentGatherings.map((item, index) => (
                      <Grid
                        item
                        key={item.id}
                      >
                        <ImageCoverCard
                          imgUrl={gatheringsAvatar(item.id)}
                          title={item.name}
                          subTitle={`happening on ${getFormattedDate(item.starts_at)}`}
                          onPress={() => navigate(`/gathering/${item.id}`)}
                          menuItems={[
                            {
                              title: 'Share',
                              color: 'ghost',
                              onPress: () => openShareModal(
                                'Gathering',
                                'Join a gathering on Nearcast',
                                `/gathering/${item.id}`
                              ),
                              icon: () => <Share color="ghost" />
                            },
                            {
                              title: 'Report',
                              color: 'error',
                              onPress: () => setReportOpen(item),
                              icon: () => <ErrorRounded color="error" />
                            }
                          ]}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}
              {pastGatherings && pastGatherings.length > 0 && (
                <>
                  <Typography
                    variant="h2"
                    style={{
                      marginBlock:
                        currentGatherings.length > 0 ? '32px 16px' : '8px 16px'
                    }}
                    className={classes.gatheringSubHeader}
                  >
                    Past Gatherings
                  </Typography>
                  <Grid container spacing={2} className={classes.gatheringsGrid}>
                    {pastGatherings.map((item, index) => (
                      <Grid
                        item
                        key={item.id}
                        style={{ cursor: 'pointer' }}
                      >
                        <ImageCoverCard
                          imgUrl={gatheringsAvatar(item.id)}
                          title={item.name}
                          subTitle={`happened on ${getFormattedDate(item.starts_at)}`}
                          onPress={() => { }}
                          menuItems={[
                            {
                              title: 'Report',
                              color: 'error',
                              onPress: () => setReportOpen(item),
                              icon: () => <ErrorRounded color="secondary" />
                            }
                          ]}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}
            </>
          )
          : (
            <GatheringEmptyState
              mainImage={require('../../../assets/nogathering.webp')}
              emptyMessage={
                <div style={{ marginTop: '16px' }}>
                  <Typography variant="h3" style={{ textAlign: 'center' }}>
                    Start a gathering and meet incredible people around you 🤗
                  </Typography>
                  <Typography style={{ fontSize: '14px', marginTop: '10px', textAlign: 'center' }}>
                    Gatherings are a place to listen in on fascinating conversations, talk to your neighbors, and host live events near you as audio or video livestreams
                  </Typography>
                </div>
              }
              listItems={[
                { icon: cookIcon, text: 'Livestream a cooking class' },
                { icon: castIcon, text: 'Engage with your neighbors' }
              ]}
            />
          )}
      <ActionModal
        open={Boolean(reportOpen)}
        onClose={() => setReportOpen(false)}
        onConfirm={() => {
          reportGathering(reportOpen.id, 'User reported gathering from the web', personal[active_neighbourhood].ID, jwt_token)
            .then(() => {
              enqueueSnackbar('Gathering Reported!', { variant: 'success' })
            })
            .catch((err) => {
              enqueueSnackbar(err.message, { variant: 'error' })
            })
          setReportOpen(false)
        }}
        onCancel={() => {
          setReportOpen(false)
        }}
        action={{
          title: 'Do you want to report this gathering',
          Accept: 'Yes',
          Decline: 'No'
        }}
      >
        <ErrorRounded style={{ fontSize: '2.5rem' }} color="secondary" />
      </ActionModal>
    </Grid>
  )
}

const styles = makeStyles(({ breakpoints }) => ({
  mainContainer: {
    [breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  gatheringSubHeader: {
    alignSelf: 'center'
  },
  gatheringsGrid: {
    marginBottom: '40px',
    [breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  }
}))

export default AllGatherings
