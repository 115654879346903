import React, { useContext, useEffect, useState } from 'react'
import { MapContainer, Polygon } from 'react-leaflet'
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer'
import 'leaflet/dist/leaflet.css'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  MenuList,
  Stack,
  Typography
} from '@mui/material'
import { useSnackbar } from 'notistack'
import firebase from 'firebase'
import Dialog from 'ui/Dialog/Dialog'
import { getProviderForProviderId } from '../../utils/authHelpers'

// importing ui
import StandardModal from 'ui/Modals/StandardModal'
import PriceFieldsInput from 'ui/TextInputs/PriceFieldsInput'
import ForgotPasswordModal from 'ui/Custom/Modals/ForgetPasswordModal/ForgotPasswordModal'
import ContinueWithEmail from 'ui/Custom/Auth/ContinueWithEmail'

// importing components
// import StandardModal from "./StandardModal";
import AppleSignin from '../SignIn/AppleSignin/AppleSignin'
import FacebookSignin from '../SignIn/FacebookSignin/FacebookSignin'
import GoogleSignin from '../SignIn/GoogleSignin/GoogleSignin'

// importing context
import { UserContext } from '../../context/UserContext'

// importing apis
import { login as loginApiCorePack } from 'api/login'
import { getGeoJson } from 'api/get/geojson'
import { getLocation } from 'api/get/location'
import { signupInit } from 'api/signup/init'
import { fetchMyProfile } from 'api/fetch/my'
import { signUp } from 'api/signup/index'
import { userPersonalAccountAdd as addAccAPICore } from 'api/user/personalAccount'
import { personalAccount } from 'api/personal_account/index'

export interface AuthModalProps {
  open: boolean,
  onClose: () => void,
  isFor?: string,
  onSuccess?: (user: any) => void
}

const AuthModal: React.FC<AuthModalProps> = ({
  open,
  onClose,
  isFor = 'landing', // if landing then the general variant of AuthModal
  onSuccess = (user) => undefined // if any followup onSuccess code has to be run
}) => {
  const { login } = useContext(UserContext)

  // component states
  const [currentStep, setCurrentStep] = useState(0)
  const [chosenEmail, setChosenEmail] = useState(false)
  const [token, setToken] = useState('')
  // state for auto detect location
  const [autoDetectError, setAutoDetectError] = useState(null)
  // state for neighborhood
  const [address, setAddress] = useState('')
  const [formError, setFormError] = useState(false)
  const [geoDetails, setGeoDetails] = useState({
    neighbourhood_slug: '',
    city_slug: '',
    state_slug: '',
    country_slug: ''
  })
  const [latlon, setLatlon] = useState({
    lat: 0,
    lng: 0
  })
  const [neighborhood, setNeigh] = useState('')
  const [geoJSON, setGeoJSON] = useState<[number, number][][]>([[[0, 0]]])
  const [center, setCenter] = useState([39.8283, -98.5795])
  const [loading, setLoading] = useState(true)
  const [renderKey, setRenderKey] = useState(0)
  const [userSelectedNeighborhood, setUserSelectedNeighborhood] = useState(false)
  // states for when auth give "account-exists-with-different-credential"
  const [alreadyHasAccount, setAlreadyHasAccount] = useState(null)
  const [askUserForPassword, setAskUserForPassword] = useState(false)
  const [password, setPassword] = useState('')
  const [openDialog, setOpenDialog] = useState<string | boolean>(false)
  // state for forgot password modal
  const [isForgotOpen, setIsForgotOpen] = useState(false)
  // if business account exists but no user account
  const [businessExistsButNoUserAccount, setBusinessExistsButNoUserAccount] = useState<any | false>(false)

  const navigate = useNavigate()
  const { state } = useLocation()

  const { enqueueSnackbar } = useSnackbar()

  const clearAllDataOnClose = () => {
    setCurrentStep(0)
    setAddress('')
    setLatlon({
      lat: 0,
      lng: 0
    })
    setNeigh('')
    setFormError(false)
    setGeoJSON([[[0, 0]]])
    setChosenEmail(false)
    setCenter([39.8283, -98.5795])
    setRenderKey(0)
    setUserSelectedNeighborhood(false)
    setBusinessExistsButNoUserAccount(false)
    onClose()
  }

  const emailSignupInit = (token) => {
    setToken(token)
    signupInit(undefined, token)
      .then(() => {
        enqueueSnackbar('Signing up new user!', {
          variant: 'success'
        })
        setCurrentStep(2)
      })
      .catch((error) => {
        if (error.response) {
          // userexits or 409 - log the user in
          if (
            error.response.data.msg === 'userexists' ||
            error.response.status === 409
          ) {
            loginAPI(token, true)
          } else {
            error = error.response.data.msg.toString().replace('Error: ', '')
            enqueueSnackbar(error, {
              variant: 'error'
            })
            setLoading(false)
          }
        }
      })
  }

  const onAuthActionError = (error: any) => {
    console.log(error?.code)
    if (error?.code === 'auth/account-exists-with-different-credential') {
      // if the user account with the email already exists, we need to link oauth to that account

      // save details from temp auth
      const pendingCred = error.credential
      const email = error.email
      setAlreadyHasAccount({
        pendingCred,
        email
      })

      // get the signin methods for this email
      firebase.auth().fetchSignInMethodsForEmail(email)
        .then((methods) => {
          // as a rule of thumb we are using which ever sign in method is there on index zero as parent

          // if that method happens to be password, we need to ask the user for there password
          if (methods[0] === 'password') {
            setAskUserForPassword(true)
            return
          }

          // All the other cases are external providers, we'll handle other oauths here
          // We'll handle this using our dialog
          setOpenDialog(methods[0])
        })
    } else {
      enqueueSnackbar(
        'Oops something went wrong! Please try again.',
        {
          variant: 'error'
        }
      )
    }
  }

  const selectPlace = (add, e, r) => {
    setAddress(add)
    setUserSelectedNeighborhood(true)
    geocodeByAddress(add)
      .then((results) => {
        const pre = {
          neighbourhood_slug: add,
          city_slug: '',
          state_slug: '',
          country_slug: ''
        }

        // find all geoDetails from data
        for (let i = 0; i < results[0].address_components.length; i++) {
          if (results[0].address_components[i].types.includes('country')) {
            pre.country_slug = results[0].address_components[i].short_name
          }
          if (
            results[0].address_components[i].types.includes(
              'administrative_area_level_1'
            )
          ) {
            pre.state_slug = results[0].address_components[i].short_name
          }
          if (results[0].address_components[i].types.includes('locality')) {
            pre.city_slug = results[0].address_components[i].short_name
          }
        }
        setGeoDetails(pre)
        return getLatLng(results[0])
      })
      .then((latLng) => {
        setLatlon(latLng)
        getBoundary(latLng.lat, latLng.lng)
      })
      .catch((error) => console.error('Error', error))
  }

  const getGeoData = (id) => {
    getGeoJson(id)
      .then((res) => {
        let x = 0
        let y = 0
        const coor: any = [[[0, 0]]]
        for (let i = 0; i < res.data.coordinates[0][0].length; i++) {
          x = x + res.data.coordinates[0][0][i][0]
          y = y + res.data.coordinates[0][0][i][1]
          coor[0][0][i] = [
            res.data.coordinates[0][0][i][1],
            res.data.coordinates[0][0][i][0]
          ]
        }
        x = x / res.data.coordinates[0][0].length
        y = y / res.data.coordinates[0][0].length
        setGeoJSON(coor)
        setCenter([y, x])
        setLoading(false)
        setRenderKey((k) => ++k)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const getBoundary = (lat: number, lng: number) => {
    setLoading(true)
    getLocation(lng, lat)
      .then((resData) => {
        setGeoDetails(resData.data[0])
        setNeigh(resData.data[0].neighbourhood)
        getGeoData(resData.data[0].obj_id)
      })
      .catch((err) => {
        console.log(err)
        if (err.response && err.response.status === 400) {
          setCenter([lat, lng])
        }
        setLoading(false)
      })
  }

  const getProfile = (id, jwt_token) => {
    return new Promise((resolve, reject) => {
      fetchMyProfile(id, jwt_token)
        .then((resBody) => resolve(resBody))
        .catch((err) => {
          // enqueueSnackbar(err.message, {
          //   variant: 'error'
          // })
          reject(err)
        })
    })
  }

  const getAllProperUserDetails = async (dataBody) => {
    /**
     * We retrieve personal account details again
     * because the personal_accounts key in login response has a lot
     * of data which is missing, primarily the Neighborhood details
     * which are required in home page right after login
     */
    return new Promise((resolve, reject) => {
      let p = []
      personalAccount(
        dataBody.personal_accounts[0].ID,
        dataBody.jwtToken
      )
        .then((resData) => {
          p = resData.pa.filter((p) => p.is_business === false)
          return Promise.all(
            p.map(
              async (account, _) =>
                await getProfile(account.ID, dataBody.jwtToken)
            )
          )
        })
        .then((userProfiles) => resolve({
          pa: p,
          userProfiles
        }))
        .catch((err) => reject(err))
    })
  }

  const addAccount = async (geo = geoDetails, coordinates = latlon) => {
    setLoading(true)
    const address = {
      state: geo.state_slug,
      city: geo.city_slug,
      country: geo.country_slug,
      lat: coordinates.lat,
      lon: coordinates.lng,
      address: geo.neighbourhood_slug
    }
    addAccAPICore(
      address,
      businessExistsButNoUserAccount.personal_accounts[0].ID,
      businessExistsButNoUserAccount.jwtToken
    )
      .then(async (resData) => {
        const properUserDetails: any = await getAllProperUserDetails(businessExistsButNoUserAccount)
        login({
          jwt_token: resData.body.jwtToken,
          ref_token: resData.body.refreshToken,
          accounts: properUserDetails.pa,
          user: resData.body.user,
          userProfiles: properUserDetails.userProfiles
        })
        onSuccess({
          token
        })
        if (isFor === 'landing') {
          if (state) {
            navigate((state as any).pathname)
          } else {
            navigate('/')
          }
        }
        clearAllDataOnClose()
      })
      .catch((err) => {
        let error
        if (err.message) {
          error = err.message
        } else {
          error = 'Please Try Again'
        }
        enqueueSnackbar(error, { variant: 'error' })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const signupApi = (geo = geoDetails, coordinates = latlon) => {
    setLoading(true)
    const address = {
      state: geo.state_slug,
      city: geo.city_slug,
      country: geo.country_slug,
      lat: coordinates.lat,
      lon: coordinates.lng,
      address: geo.neighbourhood_slug
    }
    signUp(token, address, coordinates.lat, coordinates.lng, '', '') // last two are mobile and otp
      .then(async (resBody) => {
        const properUserDetails: any = await getAllProperUserDetails(resBody)
        login({
          jwt_token: resBody.jwtToken,
          ref_token: resBody.refreshToken,
          accounts: properUserDetails.pa,
          user: resBody.user,
          userProfiles: properUserDetails.userProfiles
        })
        onSuccess({
          token: token,
          allUserData: resBody,
          type: 'signUp'
        })
        if (isFor === 'landing') {
          if (state) {
            navigate((state as any).pathname)
          } else {
            navigate('/')
          }
        }
        clearAllDataOnClose()
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: 'error'
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const loginAPI = (token, auto) => {
    loginApiCorePack(token)
      .then(async (dataBody) => {
        // make sure user has atleast one user facing account
        // if all accounts are business facing then take them to create account flow
        if (!dataBody.personal_accounts.find((p) => p.is_business === false)) {
          setBusinessExistsButNoUserAccount(dataBody)
          setCurrentStep(2)
          return
        }
        const properUserDetails: any = await getAllProperUserDetails(dataBody)
        login({
          jwt_token: dataBody.jwtToken,
          ref_token: dataBody.refreshToken,
          accounts: properUserDetails.pa,
          user: dataBody.user,
          userProfiles: properUserDetails.userProfiles
        })
        onSuccess({
          token,
          allUserData: dataBody,
          type: 'login'
        })
        if (isFor === 'landing') {
          if (state) {
            navigate((state as any).pathname)
          } else {
            navigate('/')
          }
        }
        clearAllDataOnClose()
      })
      .catch((err) => {
        console.log(err.message)
        if (auto) {
          enqueueSnackbar('User already exists', {
            variant: 'error'
          })
        } else {
          enqueueSnackbar(
            "We couldn't find a user with these credentials. Please Sign Up to continue.",
            { variant: 'error', autoHideDuration: 1000 }
          )
        }
        setLoading(false)
      })
  }

  // for auto detecting neighborhood
  // useEffect(() => {
  //   if (window.navigator.geolocation && currentStep === 1) {
  //     window.navigator.geolocation.getCurrentPosition(
  //       (location) => {
  //         // work with location.coords.accuracy to make sure user doesn't get added to far away neighborhood
  //         if (location.coords.accuracy > 100) {
  //           setAutoDetectError("Can't accurately find user location!")
  //           setCurrentStep((s) => ++s)
  //           return
  //         }
  //         // Test lat: 13.0569, lon: 80.2425 (Nungambakkam)
  //         getLocation(location.coords.longitude, location.coords.latitude)
  //           .then((resData) => {
  //             setCurrentStep((s) => s + 2)
  //             // console.log(`Address: ${resData.data[0].neighbourhood}, ${resData.data[0].city}, ${resData.data[0].state}, ${resData.data[0].country}`)

  //             // if user already had business account then add new account
  //             if (businessExistsButNoUserAccount) {
  //               return addAccount(resData.data[0], { lng: location.coords.longitude, lat: location.coords.latitude })
  //             }
  //             signupApi(resData.data[0], { lng: location.coords.longitude, lat: location.coords.latitude })
  //           })
  //           .catch((err) => {
  //             console.log(err.message)
  //             if (err.response && err.response.status === 404) {
  //               setAutoDetectError('Could not find your Neighborhood!')
  //             } else {
  //               setAutoDetectError(err.message)
  //             }
  //             setCurrentStep((s) => ++s)
  //           })
  //       },
  //       (error) => {
  //         setAutoDetectError("You didn't give us access to your device's location.")
  //         setCurrentStep((s) => ++s)
  //         console.log(error)
  //       },
  //       {
  //         maximumAge: 0,
  //         enableHighAccuracy: true
  //       }
  //     )
  //   }
  // }, [currentStep])

  // render funcs

  const renderModalTitle = () => {
    if (chosenEmail && currentStep === 0) {
      return 'Continue with Email'
    }
    return [
      'Select an option to continue',
      'Auto detecting location',
      'Enter your home address',
      'Signing up'
    ][currentStep]
  }

  const renderSignUp = () => {
    // on currentStep = 0 -> Display OAuth
    if (currentStep === 0) {
      return (
        <>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={1}
          >
            {!chosenEmail && (
              <>
                <Grid item>
                  <FormControl fullWidth>
                    <GoogleSignin
                      onSuccess={emailSignupInit}
                      onError={onAuthActionError}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <FacebookSignin
                      onSuccess={emailSignupInit}
                      onError={onAuthActionError}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <AppleSignin
                      onSuccess={emailSignupInit}
                      onError={onAuthActionError}
                    />
                  </FormControl>
                </Grid>
              </>
            )}
            <ContinueWithEmail
              chosen={chosenEmail}
              isLogin={false}
              setChosen={setChosenEmail}
              onSuccess={(token) => {
                emailSignupInit(token)
              }}
              onError={(err) => onAuthActionError(err)}
              firebase={firebase}
            />
            <StandardModal
              isOpen={askUserForPassword}
              onClose={() => setAskUserForPassword(false)}
              title="Login to Existing Account"
            >
              <Typography variant="h6">
                We detected you already have an account with {alreadyHasAccount?.email} email. If you would like to continue please type your password below so we can connect your accounts.
              </Typography>
              <FormControl fullWidth margin="normal">
                <PriceFieldsInput
                  label="Password"
                  type="password"
                  placeholder="Type your account password here"
                  value={password}
                  inputProps={{ maxLength: '63' }}
                  onChange={(e) => {
                    setPassword(e.target.value)
                  }}
                  style={{
                    backgroundColor: '#f2f2f2',
                    borderRadius: 10
                  }}
                />
                <Button
                  style={{
                    marginTop: 10
                  }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    firebase.auth().signInWithEmailAndPassword(alreadyHasAccount.email, password)
                      .then((user) => (user as any).linkWithCredential(alreadyHasAccount.pendingCred))
                      .then(() => {
                        firebase
                          .auth()
                          .currentUser.getIdToken(true)
                          .then((token) => {
                            emailSignupInit(token)
                            setLoading(false)
                          })
                      })
                      .catch((err) => {
                        console.log(err.message)
                        enqueueSnackbar("Sorry we couldn't link your account, please try again later", {
                          variant: 'error'
                        })
                      })
                  }}
                >
                  Continue
                </Button>
              </FormControl>
            </StandardModal>
            <Dialog
              isOpen={Boolean(openDialog)}
              title="The email is already being used with another account, would you like to link the oauth provider? If yes, please select the same account on next popup as well"
              onCancel={() => setOpenDialog(false)}
              onConfirm={() => {
                const provider = getProviderForProviderId(openDialog)
                firebase.auth().signInWithPopup(provider)
                  .then((result) => {
                    if (result.user.email !== alreadyHasAccount.email) {
                      return
                    }
                    result.user.linkWithCredential(alreadyHasAccount.pendingCred)
                      .then(() => {
                        firebase
                          .auth()
                          .currentUser.getIdToken(true)
                          .then((token) => {
                            emailSignupInit(token)
                          })
                      })
                      .catch((err) => {
                        console.log(err.message)
                        enqueueSnackbar("Sorry we couldn't link your account, please try again later", {
                          variant: 'error'
                        })
                      })
                  })
              }}
            />
          </Grid>
        </>
      )
    }

    // on currentStep = 1 -> Try to auto detect location
    // if (currentStep === 1) {
    //   return (
    //     <Stack direction="column" alignItems="center" justifyContent="center" sx={{ height: '200px' }}>
    //       <CircularProgress color="primary"/>
    //       <Typography variant="h5" sx={{ marginTop: 4 }}>
    //         Please provide us access to your location!
    //       </Typography>
    //     </Stack>
    //   )
    // }

    // on currentStep = 1 -> Display the location content
    if (currentStep === 2) {
      return (
        <>
          {/* <Stack sx={{ marginBottom: 2, marginLeft: '6px' }}>
            <Typography variant="h6" component="span" >
              We ran into the following error while trying to detect your location:
              <Typography variant="h6" component="span" color="error" sx={{ marginLeft: 1 }}>
                {autoDetectError}
              </Typography>
            </Typography>
          </Stack> */}
          <PlacesAutocomplete
            value={address}
            onChange={(e) => {
              setAddress(e)
              setFormError(false)
            }}
            onSelect={selectPlace}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading
            }) => (
              <FormControl fullWidth>
                <PriceFieldsInput
                  label="Enter Address*"
                  id="street"
                  variant="outlined"
                  value={address}
                  style={{
                    border: formError
                      ? '2px solid #FD2821'
                      : '2px solid transparent',
                    borderRadius: '10px'
                  }}
                  size="small"
                  inputProps={{
                    ...getInputProps({
                      placeholder: neighborhood || 'Search Places ...',
                      className: 'location-search-input'
                    })
                  }}
                />
                {loading || suggestions.length > 0
                  ? (
                  <div>
                    <MenuList
                      style={{
                        position: 'absolute',
                        zIndex: 1000002,
                        maxHeight: 100,
                        width: '94%',
                        background: 'white',
                        border: '1px solid #d3d3d3',
                        borderRadius: 3,
                        overflow: 'auto'
                      }}
                    >
                      {loading && <MenuItem value="">Loading...</MenuItem>}
                      {suggestions.map((suggestion) => {
                        // inline style for demonstration purpose
                        const style = {
                          backgroundColor: suggestion.active
                            ? '#efefef'
                            : '#ffffff',
                          cursor: 'pointer',
                          padding: '2px 12px',
                          fontSize: '15px',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          display: 'list-item'
                        }
                        return (
                          <MenuItem key={suggestion.placeId}
                            {...getSuggestionItemProps(suggestion, {
                              style
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </MenuItem>
                        )
                      })}
                    </MenuList>
                  </div>
                    )
                  : (
                  <></>
                    )}
              </FormControl>
            )}
          </PlacesAutocomplete>
          <div
            style={{
              width: '100%',
              height: 200,
              maxWidth: '100%',
              margin: 'auto',
              marginTop: '16px',
              opacity: loading ? 0.5 : 1,
              cursor: loading ? 'pointer' : 'grab'
            }}
            onClick={(e) => {
              if (loading) e.stopPropagation()
            }}
          >
            <MapContainer center={[center[0], center[1]]} zoom={13} key={renderKey}>
              <ReactLeafletGoogleLayer apiKey="AIzaSyD65D3CME73uC7YqwJSRNkyZJ_eXLlSE1Y" />
              <Polygon positions={geoJSON} />
            </MapContainer>
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setCurrentStep((c) => c + 1)
              // if user already had business account then add new account
              if (businessExistsButNoUserAccount) {
                return addAccount()
              }
              signupApi()
            }}
            disableElevation
            style={{
              paddingInline: '40px',
              marginTop: '10px',
              width: '100%'
            }}
            disabled={loading || userSelectedNeighborhood === false}
          >
            Continue
          </Button>
        </>
      )
    }

    return (
      <Stack direction="column" alignItems="center" justifyContent="center" sx={{ height: '200px' }}>
        <CircularProgress color="primary"/>
        <Typography variant="h5" sx={{ marginTop: 4 }}>
          Please Wait
        </Typography>
      </Stack>
    )
  }

  return (
    <StandardModal
      isOpen={open}
      onClose={clearAllDataOnClose}
      title={renderModalTitle()}
      isTitleBack={currentStep > 0 || chosenEmail}
      onPressTitleBack={() => {
        if (currentStep === 0 && chosenEmail) {
          return setChosenEmail(false)
        }
        if (currentStep === 2) {
          setCurrentStep((step) => step - 2) // from select neighborhood to oauth
        } else {
          setCurrentStep((step) => --step)
        }
      }}
    >
      <div
        style={{
          marginTop: '34px',
          marginBottom: '16px',
          width: '100%',
          height: '100%'
        }}
      >
        {renderSignUp()}
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          marginTop: 'auto'
        }}
      >
        {/* <div>
          {!isLoginLocal && (
            <Typography
              style={{
                fontSize: '12px',
                textAlign: 'center',
                color: '#4F4F4F'
              }}
            >
              By signing up you agree to our{' '}
              <Link to="/privacy">Privacy Policy</Link>,{' '}
              <Link to="/cookies">Cookie Policy</Link>,{' '}
              <Link to="/member-policy">Member Agreement</Link>.
            </Typography>
          )}
        </div> */}
        {chosenEmail && currentStep === 0 && (
          <Typography
            variant='caption'
            textAlign='center'
            style={{
              color: '#0080dc',
              cursor: 'pointer'
            }}
            onClick={() => setIsForgotOpen(true)}
          >
            Forgot your password?
          </Typography>
        )}
      </div>
      <ForgotPasswordModal
        open={isForgotOpen}
        onClose={() => setIsForgotOpen(false)}
        navigate={(e) => navigate(e)}
      />
    </StandardModal>
  )
}

AuthModal.defaultProps = {
  open: false,
  onClose: () => undefined
}

export default AuthModal
