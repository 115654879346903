import axios from 'axios'

/**
 * @function fetchProfileAnonymous Used to fetch a user's profile (no authentication required)
 * @param {string} uid - will be the current uid of the user profile to retrieve
 * @returns {Promise} Promise object represents user's profile object on success
 */
export const fetchProfileAnonymous = (uid) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/fetch/profile/anonymous?user=${uid}`)
      .then((res) => resolve(res.data.body))
      .catch((err) => {
        reject(err)
      })
  })
}

/**
 * @function fetchProfile Used to fetch a user's profile (no authentication required)
 * @param {string} uid - will be the uid of the user profile to retrieve
 * @param {string} nrctUID - personal[active_neigbhborhood].ID from user context
 * @param {string} jwt - jwt from user context
 * @returns {Promise} Promise object represents user's profile object on success
 */
export const fetchProfile = (uid, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/fetch/profile`, {
      params: {
        user: uid
      },
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(res.data.body))
      .catch((err) => {
        reject(err)
      })
  })
}
