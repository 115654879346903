import * as React from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import StandardModal from 'ui/Modals/StandardModal'

// importing components
import { TextLabel } from 'ui/TextInputs/TextInput'
import Chip from '../../Chip/Chip'

const SelectTopicsModal = ({ open, topics, selectedTopics, onClose, onSaveSelected }) => {
  const classes = styles()
  // components states
  const [modalSelectedTopics, setModalSelectedTopics] = React.useState([])

  const renderTopicChip = (topic) => {
    const isSelected = modalSelectedTopics.find((top) => topic.ID === top.ID)
    return (
            <Chip
                label={topic.category_name}
                isSelected={isSelected}
                onClick={() => {
                  if (isSelected) {
                    setModalSelectedTopics(modalSelectedTopics.filter((top) => top.ID !== topic.ID))
                  } else {
                    setModalSelectedTopics([...modalSelectedTopics, topic])
                  }
                }}
            />
    )
  }

  React.useEffect(() => {
    setModalSelectedTopics(selectedTopics)
  }, [selectedTopics])

  return (
        <StandardModal
            isOpen={open}
            onClose={onClose}
            title="All Topics"
            buttons={[
              {
                title: 'Save',
                color: 'primary',
                onPress: () => {
                  onSaveSelected(modalSelectedTopics)
                  onClose()
                }
              }
            ]}
        >
            <Grid
                spacing={1}
                container
                className={classes.contentContainerInner}
            >
                <Grid item xs={12} className={classes.topicsWrapper}>
                    {
                        topics.map((topic, index) => {
                          if (index !== 0) {
                            if (topic.parent_category_name !== topics[index - 1].parent_category_name) {
                              return (
                                        <>
                                            <TextLabel
                                                style={{
                                                  display: 'flex',
                                                  width: '100%',
                                                  marginTop: 10
                                                }}
                                            >{topic.parent_category_name}</TextLabel>
                                            {renderTopicChip(topic)}
                                        </>
                              )
                            }
                          } else {
                            return (
                                    <>
                                        <TextLabel
                                            style={{
                                              display: 'flex',
                                              width: '100%',
                                              marginTop: 10
                                            }}
                                        >
                                            {topic.parent_category_name}
                                        </TextLabel>
                                        {renderTopicChip(topic)}
                                    </>
                            )
                          }

                          return renderTopicChip(topic)
                        })
                    }
                </Grid>
            </Grid>
        </StandardModal>
  )
}

const styles = makeStyles(({ breakpoints }) => ({
  contentContainerInner: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '60vh',
    overflowY: 'auto'
  },
  topicsWrapper: {
    overflowY: 'auto'
  },
  heading: {
    fontSize: '20px',
    fontWeight: '700',
    color: '#104F3D',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  topicsChipWrapper: {
    marginTop: 12
  }
}))

SelectTopicsModal.defaultProps = {
  open: false,
  topics: [],
  selectedTopics: [],
  onClose: () => {},
  onSaveSelected: () => {}
}

export default SelectTopicsModal
