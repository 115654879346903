import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
// import { Redirect } from "react-router-dom";
import features from './FeatureLists/LandingList.js'

// importing components
import HeadNew from '../../components/Landing/HeadNew/HeadNew'
import FooterNew from '../../components/Landing/FooterNew/Footer'
import Features from '../../components/Landing/Features/Features'
import LandingHeader from '../../components/Landing/LandingHeader/LandingHeader'

// importing contexts
import { UserContext } from '../../context/UserContext.jsx'
import { GlobalFuncContext } from '../../context/GlobalFuncHOC'

// importing Banner images
import VideoBanner from './Banners/videos.png'
import DealsBanner from './Banners/deals.png'
import NeighbourBanner from './Banners/neigbours.png'
import GatheringBanner from './Banners/gatherings.png'
import GroupBanner from './Banners/groups.png'

// importing ui
import ForgotPasswordModal from 'ui/Custom/Modals/ForgetPasswordModal/ForgotPasswordModal'

function LandingNew () {
  const { jwt_token } = useContext(UserContext)
  const { openAuthModal } = useContext(GlobalFuncContext)
  const location = useLocation()
  const navigate = useNavigate()

  // state for reset password
  const [isResetPassword, setIsResetPassword] = useState(false)

  const featureBanners = [
    VideoBanner,
    DealsBanner,
    NeighbourBanner,
    GatheringBanner,
    GroupBanner
  ]

  useEffect(() => {
    if (location.pathname === '/login') {
      openAuthModal('landing')
    }

    window.Tawk_API = window.Tawk_API || {}
    try {
      window.Tawk_API.showWidget()
    } catch (err) {
      window.Tawk_API.onLoad = function () {
        window.Tawk_API.showWidget()
      }
    }
  }, [location, jwt_token, navigate])

  useEffect(() => {
    const resetToken = new URLSearchParams(location.search).get('token')
    // if it is forgot password flow we open the reset password
    // and return so that we are not send to home by navigate call
    if (resetToken) {
      setIsResetPassword(resetToken)
      return
    }
    if (jwt_token) {
      navigate('/home')
    }
  }, [location, jwt_token])

  return (
    <>
      <LandingHeader onActionClick={() => openAuthModal('landing')} />
      <HeadNew onActionClick={() => openAuthModal('landing')} />
      <Features
        features={features}
        featureBanners={featureBanners}
        scrollEnabled={false}
        onActionClick={() => openAuthModal('landing')}
      />
      <FooterNew />
      {Boolean(isResetPassword) && (
        <ForgotPasswordModal
          open={Boolean(isResetPassword)}
          onClose={() => {
            setIsResetPassword(false)
            navigate('/')
          }}
          hasResetToken={isResetPassword}
          navigate={navigate}
        />
      )}
    </>
  )
}

export default LandingNew
