import axios from 'axios'

/**
 * @function reportGathering Used to report a certain gathering
 * @param {string} gatheringId - gathering id of the gathering to report
 * @param {string} reason - the reason for reporting
 * @param {string} nrctUID - personal[active_neighborhood].ID from user context
 * @param {string} jwt - jwt from the user context
 * @returns {Promise} Promise object represents true for successful reporting
 */
export const reportGathering = (gatheringId, reason = '', nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/report/gathering`, {
      gathering_id: gatheringId,
      reason: reason
    }, {
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then(() => resolve(true))
      .catch((err) => reject(err))
  })
}
