import { Button, Grid } from '@mui/material'
import React from 'react'
import { Parallax } from 'react-scroll-parallax'

import styles from './HeadNewStyles'
import app from './app.svg'
import play from './play.svg'
import Phone1 from './phone1.png'
import Phone2 from './phone2.png'

function HeadNew ({ onActionClick }) {
  const classes = styles()

  return (
		<Grid
			container
			justify="center"
			className={classes.root}
			alignItems="center"
			spacing={2}
		>
			<Grid
				item
				xs={12}
				md={6}
				style={{
				  display: 'flex',
				  flexDirection: 'column',
				  justifyContent: 'center',
				  alignItems: 'center'
				}}
			>
				<div className={classes.text}>What's happening in my</div>
				<div className={`${classes.text} ${classes.bold}`}>Community?</div>
				<div className={classes.btnContainer}>
					<Button
						variant="contained"
						disableElevation
						color="secondary"
						className={classes.joinBtn}
						onClick={() => {
						  onActionClick()
						}}
					>
						I Want to Find Out
					</Button>
					<div style={{ textAlign: 'center' }}>
						<a href="https://apps.apple.com/app/id1586786030">
							<img
								src={app}
								alt="App Store"
								style={{
								  maxWidth: '46%',
								  marginTop: '20px',
								  paddingRight: 10
								}}
							/>
						</a>
						<a href="https://play.google.com/store/apps/details?id=com.nearcast.app">
							<img
								src={play}
								alt="Play Store"
								style={{
								  maxWidth: '46%',
								  width: 230,
								  marginTop: '20px',
								  paddingLeft: 10
								}}
							/>
						</a>
					</div>
				</div>
			</Grid>
			<Grid
				item
				xs={12}
				md={6}
				style={{ textAlign: 'center', height: '100%' }}
				className={classes.bannerContainer}
			>
				<div
					style={{
					  width: '100%',
					  height: '100%',
					  display: 'flex',
					  justifyContent: 'center',
					  alignItems: 'center'
					}}
				>
					<Parallax className={classes.phone} y={[-10, 10]} tagOuter="figure">
						<img
							src={Phone1}
							alt="Screenshot 1"
							className={classes.bannerImg}
						/>
					</Parallax>
					<Parallax
						className={[classes.phone, classes.two].join(' ')}
						y={[20, -20]}
						tagOuter="figure"
					>
						<img
							src={Phone2}
							alt="Screenshot 2"
							className={`${classes.bannerImg} ${classes.offsetDown}`}
						/>
					</Parallax>
				</div>
			</Grid>
			{/* {Boolean(isAuthModalOpen) && (
        <AuthModal
          open={isAuthModalOpen}
          onClose={() => {
            setIsAuthModalOpen(false);
          }}
        />
      )} */}
		</Grid>
  )
}

export default HeadNew
