import axios from 'axios'

/**
 * @function personalAccountUpdate Used to update user's personal account details
 * @param {object} newUserProfile - updated user profile
 * @param {string} nrctUID - personal[active_neighbourhood].ID from user context
 * @param {string} jwt - jwt from user context
 * @returns {Promise} Promise object representing true if update was successful
 */
export const personalAccountUpdate = (newUserProfile, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.put(`${process.env.REACT_APP_BACKEND_URL}/personal_account/update`, {
      ...newUserProfile
    }, {
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then(() => resolve(true))
      .catch((err) => {
        reject(new Error('Failed to update user profile!'))
      })
  })
}
