import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

function ReportProblem () {
  const classes = styles()

  return (
        <div className={classes.mainContainer}>
            <Typography variant="h2">
                Report a Problem
            </Typography>
            <Typography variant="body1" style={{ marginTop: '20px' }}>
                If you find something that is not quite right or working on Nearcast, or something we can do better, we encourage you to email us at <a href="mailto:support@nearcast.com">support@nearcast.com</a>. We are constantly improving Nearcast, and welcome feedback from our users.
            </Typography>
        </div>
  )
}

const styles = makeStyles(({ breakpoints, palette }) => ({
  flex: {
    display: 'flex',
    alignItems: 'center'
  },
  flexGrow: {
    flex: '1'
  },
  mainContainer: {
    padding: '40px',
    paddingBottom: '60px'
  }
}))

export default ReportProblem
