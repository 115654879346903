import React, { useContext, useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { useNavigate, useLocation } from 'react-router-dom'
import useDimensions from 'react-use-dimensions'

// importing components
import Sidebar from '../../components/UserDash/Sidebar/Sidebar'
import Gathering from '../../components/UserDash/Gathering/Gathering'
import CreateGathering from '../../components/UserDash/Gathering/CreateGathering'
import ShortVideos from '../../components/UserDash/ShortVideos/ShortVideos'
import Group from '../../components/UserDash/Groups/Group'
import Header from '../../components/Header/Header'
import BottomNavigation from '../../components/BottomNavigation/BottomNavigation'
import InfluencerDash from './InfluencerDash'
import Business from '../../components/UserDash/Business/Business'

// importing context
import { UserContext } from '../../context/UserContext'

const style = makeStyles(({ breakpoints, palette }) => {
  return {
    root: {
      margin: '74px 8px auto 260px',
      paddingTop: '20px',
      height: 'calc(100vh - 64px)', // header has height of 64px
      overflowY: 'auto',
      // eslint-disable-next-line no-useless-computed-key
      [breakpoints.down('sm')]: {
        marginTop: '0px',
        marginInline: '0px',
        height: 'calc(100vh - 70px)', // bottom navigation has height of 70px
        paddingTop: 10
      },
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    },
    fill: {
      display: 'flex',
      [breakpoints.down('xs')]: {
        marginInline: '5px'
      }
    },
    bottomGutter: {
      height: '150px',
      width: '100%'
    }
  }
})

function UserDashboard (props) {
  const navigate = useNavigate()
  const [ref, { width }] = useDimensions()
  const location = useLocation()
  const classes = style()
  const { jwt_token, personal, logout } = useContext(UserContext)

  useEffect(() => {
    window.Tawk_API = window.Tawk_API || {}
    try {
      window.Tawk_API.hideWidget()
    } catch (err) {
      window.Tawk_API.onLoad = function () {
        window.Tawk_API.hideWidget()
      }
    }
  }, [])

  useEffect(() => {
    if (jwt_token === null || personal === null) {
      navigate('/')
    }
  }, [jwt_token, navigate, personal])

  // user is not logged in, hence jwt_token and personal is null
  // don't let app display any content, useEffect will kick in
  // and take user back to "/"
  if (jwt_token === null || personal === null) {
    logout()
    return null
  }

  const renderScreen = () => {
    if (['/home', '/home/', '/home/create'].includes(location.pathname)) {
      return (
        <div className={classes.fill}>
          <ShortVideos />
        </div>
      )
    } else if (['/home/gathering', '/home/gathering/'].includes(location.pathname)) {
      return (
        <div>
          <Gathering />
        </div>
      )
    } else if (['/home/gathering/create', '/home/gathering/create/'].includes(location.pathname)) {
      return (
        <div className={classes.fill}>
          <CreateGathering />
        </div>
      )
    } else if (['/home/group', '/home/group/'].includes(location.pathname)) {
      return (
        <div>
          <Group />
        </div>
      )
    } else if (['/home/business', '/home/business/'].includes(location.pathname)) {
      return (
        <div>
          <Business />
        </div>
      )
    } else if (['/home/influencer', '/home/influencer/'].includes(location.pathname)) {
      return (
        <div>
          <InfluencerDash />
        </div>
      )
    } else {
      return <></>
    }
  }

  return (
    <div ref={ref}>
      {width > 500 && <Header /> }
      <div
        style={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <div
          style={{
            position: 'relative',
            maxWidth: '1800px',
            width: '100vw',
            height: '100vh'
          }}
        >
          {width > 500 && <Sidebar />}
          <div className={classes.root}>
            {renderScreen()}
          </div>
        </div>
      </div>
      {width <= 500 && <BottomNavigation />}
    </div>
  )
}

export default UserDashboard
