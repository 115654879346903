import * as React from 'react'
import {
  FormControl,
  Typography,
  styled,
  Divider,
  Rating,
  Stack,
  Button
} from '@mui/material'
import { useSnackbar } from 'notistack'
import MuiPhoneNumber from 'material-ui-phone-number'
import validator from 'validator'

// importing ui
import CreateVideo from 'ui/Custom/CreateVideo/CreateVideo'
import WideModal from 'ui/Modals/WideModal'
import CustomTextArea from 'ui/TextInputs/TextAreaInput'
import { TextLabel } from 'ui/TextInputs/TextInput'
import StandardModal from 'ui/Modals/StandardModal'
import NearcastVideoPlayer from 'ui/Custom/NearcastVideoPlayer/NearcastVideoPlayer'

// importing api
import { postReferCreate } from 'api/post/referCreate'
import { login } from 'api/login'
import { businessReviewNearcoinsAwarded } from 'api/business/review/nearcoinAwarded'
import { feedData } from 'api/analytics/feed'

// importing context
import { UserContext } from '../../../context/UserContext'
import { GlobalFuncContext } from '../../../context/GlobalFuncHOC'
import { useNavigate } from 'react-router-dom'

// importing helpers
import { getFileSizeInMB } from 'helpers/browser'
import { recordGaEvent } from 'helpers/ga'

// the different screen dimensions to record in
const screenDimensionsPortrait = {
  width: 300,
  height: 600,
  aspect: 2,
  isWide: false
}

const CreateNearcastPostWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'flex-start',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'center'
  }
}))

export interface CreateReviewModalProps {
  offer?: any;
  business?: any;
  isFor?: 'business' | 'offer';
  isOpen: boolean;
  onClose: () => void;
}

const CreateReviewModal: React.FC<CreateReviewModalProps> = ({
  offer,
  business,
  isFor,
  isOpen,
  onClose
}) => {
  const { personal, active_neighbourhood, jwt_token, userPreferences, toggleVideoMuted } = React.useContext(UserContext)
  const { openAuthModal } = React.useContext(GlobalFuncContext)
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  // component states
  const [type, setType] = React.useState<'record' | 'post' | 'posted'>('record')
  const [videoFile, setVideoFile] = React.useState(null)
  const [videoSrc, setVideoSrc] = React.useState('')
  // state for review sticker
  const reviewStickerInitial = {
    x: -40,
    y: 100,
    id: Date.now(),
    type: 9, // 9 is for review
    rating: 0,
    business_name: 'Business',
    rating_description: ''
  }
  const [reviewSticker, setReviewSticker] = React.useState(reviewStickerInitial)
  const [mobile, setMobile] = React.useState('')
  const [posting, setPosting] = React.useState(false)
  const [postingProgress, setPostingProgress] = React.useState(0)
  const [reviewData, setReviewData] = React.useState<any>({})
  // state for validation
  const inputErrorsInitial: {
    rating: false | string;
    mobile: false | string;
    description: false | string;
  } = {
    rating: false,
    mobile: false,
    description: false
  }
  const [inputErrors, setInputErrors] = React.useState(inputErrorsInitial)

  const _onClose = () => {
    if (posting) {
      return
    }
    setType('record')
    setReviewSticker(reviewStickerInitial)
    setReviewData({})
    setMobile('')
    setInputErrors(inputErrorsInitial)
    setPostingProgress(0)
    onClose()
  }

  const validate = () => {
    const newInputErrors = inputErrorsInitial
    if (reviewSticker.rating === 0 || reviewSticker.rating === null) {
      newInputErrors.rating = 'Please enter a rating for your experience.'
    }
    if (mobile.length > 0) {
      if (!validator.isMobilePhone(mobile.replace(/\s|-|\(|\)/g, ''), 'any')) {
        newInputErrors.mobile = 'This phone number looks invalid, either remove or check the number.'
      }
    }
    if (reviewSticker.rating_description.length === 0) {
      newInputErrors.description = 'Please describe your experience.'
    }

    setInputErrors(newInputErrors)
    if (Object.values(newInputErrors).find((e) => e !== false)) {
      return newInputErrors
    } else {
      return false
    }
  }

  const onClickPostHandler = async () => {
    // first validate
    const hasErrors = validate()
    if (hasErrors) {
      return enqueueSnackbar(hasErrors.mobile || hasErrors.rating || hasErrors.description, { variant: 'error' })
    }
    if (posting) {
      return
    }
    setPosting(true)

    try {
      if (isFor === 'business') {
        await onClickPostBusinessReview()
      } else {
        await onClickPostOfferReview()
      }
    } catch (err) {
      enqueueSnackbar(err.message, {
        variant: 'error'
      })
    } finally {
      setPosting(false)
    }
  }

  const onClickPostBusinessReview = async () => {
    // analytics
    recordGaEvent('createReviewModal-businessReviewPosted', {
      personalAccountId: personal ? personal[active_neighbourhood].ID : null,
      business: business,
      reviewType: personal ? 'non-Anonymous' : 'Anonymous',
      rating: reviewSticker.rating
    })

    if (personal && jwt_token) {
      await Promise.all([
        postReferCreate(
          {
            sticker: [reviewSticker],
            phone: mobile,
            isFor: 'business',
            businessId: business.id
          },
          videoFile,
          (p) => setPostingProgress(p),
          personal[active_neighbourhood].ID,
          jwt_token
        )
      ])
      _onClose()
      navigate('/home')
    } else {
      const data = await postReferCreate(
        {
          sticker: [reviewSticker],
          phone: mobile,
          isFor: 'business',
          businessId: business.id
        },
        videoFile,
        (p) => setPostingProgress(p),
        null,
        null
      )
      setType('posted')
    }
    enqueueSnackbar('Offer review created!', {
      variant: 'success'
    })
  }

  const onClickPostOfferReview = async () => {
    // analytics
    recordGaEvent('createReviewModal-offerReviewPosted', {
      personalAccountId: personal ? personal[active_neighbourhood].ID : null,
      couponCode: offer.redemption_code,
      reviewType: personal ? 'non-Anonymous' : 'Anonymous',
      rating: reviewSticker.rating,
      offerTitle: offer.title
    })

    if (personal && jwt_token) {
      // create review
      // check if nearcoins were awarded for review
      const [d, didNearcoinsGetAwarded] = await Promise.all([
        postReferCreate(
          {
            sticker: [reviewSticker],
            phone: mobile,
            isFor: 'offer',
            coupon: offer.redemption_code
          },
          videoFile,
          (p) => setPostingProgress(p),
          personal[active_neighbourhood].ID,
          jwt_token
        ),
        businessReviewNearcoinsAwarded(
          offer.offer_id,
          personal[active_neighbourhood].ID,
          jwt_token
        )
      ])
      _onClose()
      navigate('/home', {
        state: {
          newReviewCreated: didNearcoinsGetAwarded === 'Rewarded',
          file: videoSrc
        }
      })
    } else {
      const data = await postReferCreate(
        {
          sticker: [reviewSticker],
          phone: mobile,
          isFor: 'offer',
          coupon: offer.redemption_code
        },
        videoFile,
        (p) => setPostingProgress(p),
        null,
        null
      )
      setReviewData(data)
      setType('posted')
    }
    setPosting(false)
  }

  const updateSticker = (sticker, isDeleted) => {
    if (isDeleted) {
      // place the sticker in initial position
      setReviewSticker({
        ...reviewSticker,
        x: -40,
        y: 100
      })
      return
    }
    setReviewSticker(sticker)
  }

  // const onUserLogin = (user) => {
  //   login(user.token, reviewData.id)
  //     .then(() => {
  //       _onClose()
  //       navigate('/home', {
  //         state: {
  //           newReviewCreated: true,
  //           file: videoSrc
  //         }
  //       })
  //     })
  //     .catch((err) => {
  //       console.log(err)
  //       enqueueSnackbar('We ran into an error trying to link your review with this account!', { variant: 'error' })
  //     })
  // }

  const onNewUserSignUp = (user) => {
    if (user.type === 'signUp') {
      // analytics
      recordGaEvent('createReviewModal-userAcquired', {
        abstractAccountId: user.allUserData.user.ID,
        neighborhoodId: user.allUserData.personal_accounts[0].lives_in_id,
        businessId: offer?.business_id || business?.id,
        businessName: offer?.business_name || business?.name,
        offerId: offer?.offer_id,
        couponCode: offer?.redemption_code,
        rating: reviewSticker.rating,
        offerTitle: offer?.title,
        createdAt: new Date().toISOString()
      })
      feedData('user_acquired', {
        abstract_account_id: user.allUserData.user.ID,
        neighbourhood_id: user.allUserData.personal_accounts[0].lives_in_id,
        business_id: offer?.business_id || business?.id,
        offer_id: offer?.offer_id,
        created_at: new Date().toISOString()
      })
    }
    login(user.token, reviewData.id)
      .then(() => {
        _onClose()
        navigate('/home', {
          state: {
            newReviewCreated: true,
            file: videoSrc
          }
        })
      })
      .catch((err) => {
        console.log(err)
        enqueueSnackbar('We ran into an error trying to link your review with this account!', { variant: 'error' })
      })
  }

  React.useEffect(() => {
    if (isOpen) {
      if (isFor === 'business') {
        setReviewSticker({
          ...reviewSticker,
          business_name: business.name
        })
      } else {
        setReviewSticker({
          ...reviewSticker,
          business_name: offer.business_name
        })
      }
    }
  }, [offer, business, isFor])

  React.useEffect(() => {
    if (type === 'post') {
      setTimeout(() => {
        document.querySelector<HTMLElement>('#ratingFormControl').focus()
      }, 500)
    }
  }, [type])

  const renderRecordVideo = () => {
    if (window.innerWidth < 600) {
      return (
        <div
          style={{
            zIndex: 100,
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0
          }}
        >
            <div
              style={{
                width: '100%',
                height: '100%'
              }}
            >
              <CreateVideo
                setVideoSrc={(src, rawFile) => {
                  setVideoFile(rawFile)
                  setVideoSrc(src)
                  setType('post')
                }}
              />
            </div>
        </div>
      )
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 20,
          marginBottom: 20,
          height: screenDimensionsPortrait.height
        }}
      >
        <div
          style={{
            height: screenDimensionsPortrait.height,
            width: screenDimensionsPortrait.width
          }}
        >
          <CreateVideo
            setVideoSrc={(src, rawFile) => {
              setVideoFile(rawFile)
              setVideoSrc(src)
              setType('post')
            }}
          />
        </div>
      </div>
    )
  }

  // if user is at record type then show them the Camera component
  if (type === 'record') {
    if (window.innerWidth < 600 && isOpen) {
      return renderRecordVideo()
    }

    return (
      <WideModal
        title="Record Review"
        isOpen={isOpen}
        onClose={_onClose}
      >
        <Divider />
        {renderRecordVideo()}
        <Divider />
      </WideModal>
    )
  }

  if (type === 'post') {
    return (
      <WideModal
        title="Share Your Experience"
        isOpen={isOpen}
        onClose={_onClose}
        buttons={[
          {
            title: 'Cancel',
            color: 'ghost',
            onPress: _onClose,
            variant: 'outlined'
          },
          {
            title: posting ? `Posting: ${postingProgress}%` : 'Post',
            color: posting ? 'ghost' : 'primary',
            onPress: onClickPostHandler
          }
        ]}
      >
        <Divider />
        <CreateNearcastPostWrapper
          style={{
            paddingTop: 8,
            paddingBottom: 8,
            overflowX: 'hidden'
          }}
        >
          <div
            style={styles.gridVideoWrapper}
          >
            <NearcastVideoPlayer
              type='preview'
              video={{
                source: videoSrc,
                muted: userPreferences.videoMuted,
                toggleMuted: toggleVideoMuted
              }}
              stickers={[reviewSticker]}
              updateSticker={updateSticker}
              screenDimensions={screenDimensionsPortrait}
            />
            <Stack direction="row" justifyContent="flex-end" style={{ width: '100%' }}>
              <Typography variant="caption" >
                File size: {getFileSizeInMB(videoFile, 2)}
              </Typography>
            </Stack>
          </div>
          <div
            style={{
              ...styles.mainDivPostContainers,
              paddingRight: '10px'
            }}
          >
            <FormControl
              fullWidth
              margin="none"
            >
              <Stack
                direction="column"
                style={{
                  border: inputErrors.mobile ? '1px solid red' : 'none',
                  borderRadius: 8
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    width: '100%'
                  }}
                >
                  <TextLabel style={{ marginLeft: 4 }}>
                    Phone Number (optional)
                  </TextLabel>
                  <Button
                    variant="text"
                    color="error"
                    onClick={() => {
                      setMobile('')
                      setInputErrors({
                        ...inputErrors,
                        mobile: false
                      })
                    }}
                    disabled={mobile.length === 0}
                    sx={{
                      padding: 0,
                      margin: 0,
                      height: 'auto'
                    }}
                  >
                    Remove
                  </Button>
                </Stack>
                <MuiPhoneNumber
                  defaultCountry="us"
                  id="phone"
                  variant="filled"
                  size="small"
                  value={mobile}
                  InputProps={{ hiddenLabel: true, disableUnderline: true }}
                  onChange={(value) => {
                    setMobile(value.replace(/\s|-|\(|\)/g, ''))
                  }}
                  style={{
                    borderRadius: '8px'
                  }}
                  disableAreaCodes={true}
                />
              </Stack>
              {inputErrors.mobile && (
                <Typography color="error" variant="caption">
                  {inputErrors.mobile}
                </Typography>
              )}
            </FormControl>
            <FormControl
              id="ratingFormControl"
              fullWidth
              margin="normal"
            >
              <Stack
                direction="column"
                style={{
                  border: inputErrors.rating ? '1px solid red' : 'none',
                  borderRadius: 8
                }}
              >
                <TextLabel style={{ marginLeft: 4 }}>
                  Give Your Experience a Rating*
                </TextLabel>
                <Rating
                  style={{
                    marginTop: 4
                  }}
                  name="simple-controlled"
                  value={reviewSticker.rating}
                  onChange={(event, newValue) => {
                    setReviewSticker({ ...reviewSticker, rating: newValue })
                    setInputErrors({
                      ...inputErrors,
                      rating: false
                    })
                  }}
                  data-testid="giveReviewRating"
                />
              </Stack>
              {inputErrors.rating && (
                <Typography color="error" variant="caption">
                  {inputErrors.rating}
                </Typography>
              )}
            </FormControl>
            <FormControl
              fullWidth
              margin="normal"
            >
              <CustomTextArea
                id="reviewDescription"
                multiline
                rows={6}
                label="Describe Your Experience*"
                placeholder="How was the food? 🍔"
                value={reviewSticker.rating_description}
                inputProps={{ maxLength: '255' }}
                onChange={(e) => {
                  setReviewSticker({ ...reviewSticker, rating_description: e.target.value })
                  setInputErrors({
                    ...inputErrors,
                    description: false
                  })
                }}
                style={{
                  backgroundColor: '#f2f2f2',
                  borderRadius: 10
                }}
                data-testid="giveReviewDescription"
                error={inputErrors.description}
              />
              {inputErrors.description && (
                <Typography color="error" variant="caption">
                  {inputErrors.description}
                </Typography>
              )}
            </FormControl>
          </div>
        </CreateNearcastPostWrapper>
        <Divider />
      </WideModal>
    )
  }

  if (type === 'posted') {
    return (
      <StandardModal
        isOpen={isOpen}
        onClose={_onClose}
      >
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            margin: 2
          }}
          spacing={1}
        >
          <img
            src={require('../../../assets/success.svg').default}
            style={{
              width: 140,
              marginTop: 18,
              marginBottom: 20
            }}
          />
          <Typography variant="h3" color="primary">
            Thanks for your review!
          </Typography>
          <Typography variant="body2" textAlign="center">
            We appreciate your time. We'll use this feedback to help improve our business!
          </Typography>
          {/* <img
            src={require('../../../assets/nearcoin.gif')}
            style={{
              width: 140,
              marginTop: 18,
              marginBottom: 20
            }}
          /> */}
          {/* <Stack direction="row" justifyContent="center" alignItems="center">
            <Typography variant='h4' component="span" sx={{ marginRight: 0.5 }}>
              You've earned
            </Typography>
            <Typography variant='h4' component="span" color="primary">
              100 Nearcoins
            </Typography>
          </Stack> */}
          <Stack
            direction="column"
            // alignItems="center"
            spacing={1}
            style={{ width: '100%' }}
            sx={{ marginTop: '32px !important' }}
          >
            {/* <Typography variant="h5" textAlign="center" data-testid="primaryText" sx={{ paddingBottom: 2 }}>
              Continue to redeem your reward
            </Typography> */}
            <Button
              variant="contained"
              color="secondary"
              disableElevation
              onClick={() => {
                recordGaEvent('createReviewModal-reviewPosted-signUp', {
                  couponCode: offer?.redemption_code,
                  offerTitle: offer?.title,
                  businessName: offer?.business_name || business?.name,
                  businessId: offer?.business_id || business.id
                })
                openAuthModal(
                  'review',
                  onNewUserSignUp
                )
              }}
              data-testid="signUp"
            >
              Find your next destination
            </Button>
            {/* <Button
              variant="contained"
              color="primary"
              onClick={() => {
                recordGaEvent('createReviewModal-reviewPosted-login', {
                  couponCode: offer.redemption_code,
                  offerTitle: offer.title
                })
                openAuthModal(
                  'offer',
                  onUserLogin
                )
              }}
              data-testid="login"
            >
              Login
            </Button> */}
          </Stack>
          <Typography variant="caption" sx={{ marginTop: '32px !important' }}>
            {isFor === 'business' ? business.name : offer.business_name} | Powered by Nearcast
          </Typography>
        </Stack>
      </StandardModal>
    )
  }
}

const styles = {
  mainDivPostContainers: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingLeft: 8,
    width: '100%',
    height: '100%',
    marginTop: 20
  },
  gridVideoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  shareWithElementWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: 10,
    padding: 10,
    cursor: 'pointer',
    width: 80,
    wordWrap: 'anywhere',
    textAlign: 'center',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: 'black'
  }
} as const

export default CreateReviewModal
