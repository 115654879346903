import React, { useContext } from 'react'
import Features from '../../components/Landing/Features/Features'
import FooterNew from '../../components/Landing/FooterNew/Footer'
import LandingHeader from '../../components/Landing/LandingHeader/LandingHeader'
import SubpagesHead from '../../components/Landing/SubpagesHead/SubpagesHead'
import InfluencerBg from './HeadBackgrounds/influencer-bg.png'
import features from './FeatureLists/InfluencersList'
import InfluencerBanner from './Banners/influencers.png'
import { GlobalFuncContext } from '../../context/GlobalFuncHOC'

function LandingInfluencers () {
  const { openAuthModal } = useContext(GlobalFuncContext)

  const featureBanners = [InfluencerBanner]
  const description =
		'Become famous in your neighborhood and get perks at local businesses. Showcase your passion and support local businesses that you enjoy.'

  return (
		<>
			<LandingHeader light onActionClick={() => openAuthModal('landing')} />
			<SubpagesHead
				title="Nearcast for Neighborhood Influencers"
				description={description}
				bg={InfluencerBg}
				onActionClick={() => openAuthModal('landing')}
			/>
			<Features
				title="Neighborhood Influencers use Nearcast to"
				features={features}
				featureBanners={featureBanners}
				scrollEnabled={false}
				onActionClick={() => openAuthModal('landing')}
			/>
			<FooterNew />
		</>
  )
}

export default LandingInfluencers
