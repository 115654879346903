import axios from 'axios'

/**
 * @function profileFollowings Used to retrieve the user's following list
 * @param {number} page - the page to retrieve
 * @param {number} size - the size of the page
 * @param {string} userId - user id to retrieve from
 * @param {string} nrctUID - personal[active_neighborhood].ID from user context
 * @param {string} jwt - jwt from the user context
 * @returns {Promise} Promise object represents array of following user object
 */
export const profileFollowings = (page, size, userId, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/profile/followings/${userId}`, {
      params: {
        page,
        distance: size
      },
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(res.data.body))
      .catch((err) => reject(err))
  })
}
