
/**
 * Action types
 * All the actions defined here will be implemented in reducer,
 * this way it's easier to manage action type strings
 */
const userReducerActionTypes = {
  login: 'LOGIN',
  logout: 'LOGOUT',
  refreshTokens: 'REFRESH_TOKENS',
  refreshProfiles: 'REFRESH_PROFILES',
  switchActiveNeighborhood: 'SWITCH_ACTIVE_NEIGHBORHOOD'
}

export default userReducerActionTypes

export const logout = () => {
  return (dispatch, getState) => {
    dispatch({
      type: userReducerActionTypes.logout
    })
  }
}

export interface loginParams {
  refToken: string;
  jwtToken: string;
  user: any;
  accounts: any[];
  userProfiles: any[];
}

export const login = (data: loginParams) => {
  return (dispatch, getState) => {
    const defaultAccountIndex = data.accounts.findIndex((account) => account.is_default)
    const newUser = {
      refreshToken: data.refToken,
      jwtToken: data.jwtToken,
      user: data.user,
      userProfiles: data.userProfiles,
      business: data.accounts.filter((p) => p.is_business === true),
      personal: data.accounts,
      activeNeighborhood: defaultAccountIndex !== -1 ? defaultAccountIndex : 0
    }
    dispatch({
      type: userReducerActionTypes.login,
      payload: newUser
    })
  }
}

export interface refreshUserTokensParams {
  jwtToken: string;
  refreshToken: string;
}

export const refreshUserTokens = (data: refreshUserTokensParams) => {
  return (dispatch, getState) => {
    dispatch({
      type: userReducerActionTypes.refreshTokens,
      payload: data
    })
  }
}

export interface refreshUserProfilesParams {
  accounts: any[],
  userProfiles: any[],
}

export const refreshUserProfiles = (data: refreshUserProfilesParams) => {
  return (dispatch, getState) => {
    const update = {
      userProfiles: data.userProfiles,
      business: data.accounts.filter((p) => p.is_business === true),
      personal: data.accounts
    }
    dispatch({
      type: userReducerActionTypes.refreshProfiles,
      payload: update
    })
  }
}

export interface changeActiveNeighborhoodParams {
  index: number;
}

export const changeActiveNeighborhood = (data: changeActiveNeighborhoodParams) => {
  return (dispatch, getState) => {
    const state = getState()
    const userState = state.root.userState
    if (userState.personal.length <= data.index) {
      return
    }
    dispatch({
      type: userReducerActionTypes.switchActiveNeighborhood,
      payload: data.index
    })
  }
}
