import React, { useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FormControl, IconButton, Menu, MenuItem, Tab, TextField, Typography, useTheme } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import {
  Close,
  MoreVert,
  PeopleAlt,
  Send,
  Chat,
  PanTool,
  PersonAdd
} from '@mui/icons-material'
import { TabContext, TabList, TabPanel } from '@mui/lab'

// importing contexts
import { UserContext } from '../../../context/UserContext'

// importing apis
import { fetchProfile } from 'api/fetch/profile'
import { gatheringPromote } from 'api/gatherings/promote'
import { gatheringDepromote } from 'api/gatherings/depromote'
import { gatheringKick } from 'api/gatherings/kick'
import { personalAccountAvatar } from 'api/personal_account/avatar'

const TabsStyled = withStyles((theme) => {
  return {
    labelIcon: {
      borderRadius: 8,
      margin: 8,
      color: '#828282',
      fontWeight: 600,
      minHeight: 48
    },
    selected: {
      background: theme.palette.primary.main
    },
    wrapper: {
      flexDirection: 'row',
      textTransform: 'none',
      '& > *:first-child': {
        marginBottom: '0px !important',
        marginRight: 6
      }
    }
  }
})(Tab)

function ChatPanel ({ messages, sendMessage }) {
  const [txt, setTxt] = useState('')
  const messageEndRef = useRef(null)

  const send = () => {
    if (txt === '') {
      return
    }
    sendMessage(txt)
    setTxt('')
  }

  useEffect(() => {
    messageEndRef.current.scrollIntoView({ behavior: 'smooth' })
  }, [messages])

  return (
    <>
      <div
        style={{
          height: 'calc(100% - (50px + 24px))',
          width: '100%',
          overflow: 'hidden',
          overflowY: 'scroll',
          scrollbarWidth: 'none'
        }}
      >
        {messages.map((item, i) => {
          return (
            <div style={{ padding: '10px 14px' }} key={i}>
              <div
                style={{
                  fontSize: 14,
                  color: '#232323',
                  fontWeight: 'bold'
                }}
              >
                {item.username}
              </div>
              <div style={{ fontSize: 14, marginTop: 5 }}>{item.message}</div>
              <div style={{ fontSize: 8, marginTop: 5 }}>{item.time}</div>
            </div>
          )
        })}
        <div ref={messageEndRef}></div>
      </div>
      <div
        style={{
          marginTop: 0,
          height: '50px'
        }}
      >
        <FormControl fullWidth>
          <TextField
            size="small"
            variant="outlined"
            value={txt}
            placeholder="Send Chat"
            onChange={(e) => {
              setTxt(e.target.value)
            }}
            onKeyPress={(e) => {
              if (e.charCode === 13) {
                send()
              }
            }}
            InputProps={{
              endAdornment: (
                <IconButton size="small" onClick={send}>
                  <Send color="primary" />
                </IconButton>
              )
            }}
          />
        </FormControl>
      </div>
    </>
  )
}

function PeopleTile ({
  item,
  localUser,
  requestTokenChange,
  forceUpdate,
  sendKickMessage
}) {
  const [open, setOpen] = useState(null)
  const { id } = useParams()
  const { personal, jwt_token, active_neighbourhood } = useContext(UserContext)
  const isGuest = useRef(item.userId.includes('guest'))
  useEffect(() => { }, [forceUpdate])

  useEffect(() => {
    // BAD CODE: any operations should not directly assign values to component props or states
    fetchProfile(item.userId, personal[active_neighbourhood].ID, jwt_token)
      .then((resBody) => item.location = `${resBody.name}, ${resBody.metro}`)
      .catch((err) => console.log(err))
      // we only need to fetch profile again if the item's userId changes
  }, [active_neighbourhood, item, jwt_token, personal])

  const promote = () => {
    gatheringPromote(id, item.userId, personal[active_neighbourhood].ID, jwt_token)
      .then((resData) => requestTokenChange(item.userId, resData.token, 'host'))
      .catch((err) => console.log(err))
  }

  const demote = () => {
    gatheringDepromote(id, item.userId, personal[active_neighbourhood].ID, jwt_token)
      .then((resData) => requestTokenChange(item.userId, resData.token, 'audience'))
      .catch((err) => console.log(err))
  }

  const kick = () => {
    gatheringKick(id, item.userId, personal[active_neighbourhood].ID, jwt_token)
      .then(() => sendKickMessage(item.userId))
      .catch((err) => console.log(err))
  }

  return (
    <div
      style={{
        margin: '10px 8px',
        display: 'flex',
        alignItems: 'center',
        position: 'relative'
      }}
    >
      <img
        src={
          isGuest.current
            ? 'https://s3.us-west-1.amazonaws.com/static.nearcast.com/photos/default-img.png'
            : personalAccountAvatar(item.userId)
        }
        alt=""
        style={{
          width: 50,
          height: 50,
          borderRadius: 25,
          backgroundColor: '#c4c4c4',
          verticalAlign: 'middle'
        }}
      />
      <div>
        <div
          style={{
            fontSize: 16,
            color: '#232323',
            fontWeight: 'bold',
            marginLeft: 10
          }}
        >
          {item.name}
          {personal
            ? (
                personal[active_neighbourhood].ID === item.userId
                  ? (
              <>{' (You)'}</>
                    )
                  : (
              <></>
                    )
              )
            : (
            <></>
              )}
        </div>
        <div
          style={{
            fontSize: 12,
            color: '#232323',
            marginLeft: 10
          }}
        >
          {item.location ? item.location : 'Fetching location'}
        </div>
        <div
          style={{
            fontSize: 12,
            color: '#323232',
            fontWeight: 'bold',
            margin: '0px 0 0 10px'
          }}
        >
          {item.role === 1
            ? 'Host'
            : item.role === 2
              ? 'Co-Host'
              : item.role === 3
                ? 'Co-Host'
                : 'Attendee'}
        </div>
      </div>
      {item.isRaised
        ? (
        <IconButton style={{ marginLeft: 'auto' }} disabled size="large">
          <PanTool />
        </IconButton>
          )
        : (
        <></>
          )}
      {localUser.userId === item.userId ||
        localUser.role === 4 ||
        localUser.role === 3 ||
        (localUser.role === 2 && item.role === 1)
        ? (
        <></>
          )
        : (
        <>
          <IconButton
            style={{ marginLeft: item.isRaised ? '5px' : 'auto' }}
            onClick={(e) => {
              setOpen(e.currentTarget)
            }}
            aria-controls="people-menu"
            size="large">
            <MoreVert />
          </IconButton>
          <Menu
            id="people-menu"
            anchorEl={open}
            open={Boolean(open)}
            keepMounted
            onClose={() => {
              setOpen(null)
            }}
          >
            {!isGuest.current &&
              (item.isHost
                ? (
                <MenuItem
                  onClick={() => {
                    demote()
                    setOpen(false)
                  }}
                >
                  Demote
                </MenuItem>
                  )
                : (
                <MenuItem
                  onClick={() => {
                    promote()
                    setOpen(false)
                  }}
                >
                  Promote
                </MenuItem>
                  ))}
            <MenuItem
              onClick={() => {
                kick()
                setOpen(false)
              }}
            >
              Kick
            </MenuItem>
          </Menu>
        </>
          )}
    </div>
  )
}

function PeoplePanel ({
  users,
  localUser,
  requestTokenChange,
  forceUpdate,
  inviteMessage,
  sendKickMessage
}) {
  const [userList, setUserList] = useState(users.current)
  const [txt, setTxt] = useState('')
  const theme = useTheme()

  useEffect(() => {
    if (txt === '') setUserList(users.current)
    else setUserList(users.current.filter((item) => item.name.includes(txt)))
    // eslint-disable-next-line
  }, [forceUpdate]);

  return (
    <>
      <div
        style={{
          width: 'calc(100%)'
        }}
      >
        <a
          href={`mailto:?body=${inviteMessage.current.body}&subject=${inviteMessage.current.subject}`}
          target="_blank"
          rel="noreferrer"
          style={{
            display: 'inline-flex',
            textDecoration: 'none',
            padding: 8,
            color: theme.palette.primary.light,
            width: 'fit-content',
            marginLeft: 'auto',
            fontWeight: 500
          }}
        >
          <PersonAdd />
          &nbsp;Invite via Email
        </a>
        <FormControl fullWidth>
          <TextField
            size="small"
            variant="outlined"
            value={txt}
            placeholder="Search"
            onChange={(e) => setTxt(e.target.value)}
          />
        </FormControl>
      </div>
      <div
        style={{
          height: 'calc(100% - 50px)',
          overflow: 'auto'
        }}
      >
        <PeopleTile
          item={localUser.current}
          localUser={localUser.current}
          requestTokenChange={requestTokenChange}
          sendKickMessage={sendKickMessage}
          forceUpdate={forceUpdate}
        />
        <>
          {userList
            ? (
                userList.map((item, i) => {
                  return (
                <PeopleTile
                  item={item}
                  localUser={localUser.current}
                  requestTokenChange={requestTokenChange}
                  forceUpdate={forceUpdate}
                  sendKickMessage={sendKickMessage}
                  key={i}
                />
                  )
                })
              )
            : (
            <>No people in the chat</>
              )}
        </>
      </div>
    </>
  )
}

function Sidebar ({
  open,
  messages,
  sendMessage,
  users,
  localUser,
  requestTokenChange,
  forceUpdate,
  media,
  setSidebar,
  inviteMessage,
  sendKickMessage
}) {
  const [value, setValue] = useState('1')

  useEffect(() => { }, [forceUpdate])

  const theme = useTheme()

  return (
    <div
      style={{
        position: 'fixed',
        padding: media.small ? 0 : 10,
        zIndex: 20,
        top: media.small ? 'auto' : 70,
        width: media.small ? 'calc(100%)' : 400,
        height: media.small
          ? '100%'
          : media.medium
            ? 'calc(100% - 155px)'
            : 'calc(100% - 90px)',
        bottom: media.small ? (open ? 0 : '-100%') : 'auto',
        borderRadius: 10,
        transition: '0.3s all ease-in',
        right: media.small ? 0 : open ? (media.medium ? 3 : 0) : '-420px',
        backgroundColor: media.small ? '#FFFFFF' : theme.palette.primary.main
      }}
    >
      <div
        style={{
          position: 'relative',
          height: '100%',
          background: '#FFF',
          borderRadius: media.small ? '30px 30px 0px 0px' : 10,
          boxShadow: '0 3px 15px #DDDDDD'
        }}
      >
        {media.small
          ? (
          <div style={{ textAlign: 'right', padding: '10px 15px 0 0' }}>
            <button
              onClick={() => setSidebar(false)}
              style={{
                outline: 'none',
                border: 'none',
                padding: '8px 20px',
                background: theme.palette.primary.main,
                color: 'white',
                borderRadius: 18,
                cursor: 'pointer'
              }}
            >
              <Close
                style={{
                  fontSize: 16,
                  verticalAlign: 'middle'
                }}
              />{' '}
              Close
            </button>
          </div>
            )
          : (
          <></>
            )}
        <TabContext value={value}>
          <TabList
            value={value}
            onChange={(e, newVal) => {
              setValue(newVal)
            }}
            variant="fullWidth"
            TabIndicatorProps={{ style: { display: 'none' } }}
          >
            <TabsStyled
              icon={<Chat style={{ color: value === '1' ? 'white' : 'grey' }} />}
              label={<Typography variant="h6" style={{ color: value === '1' ? 'white' : 'grey' }}>Chat</Typography>}
              value={'1'}
            />
            <TabsStyled
              icon={<PeopleAlt style={{ color: value === '2' ? 'white' : 'grey' }} />}
              label={<Typography variant="h6" style={{ color: value === '2' ? 'white' : 'grey' }}>People</Typography>}
              value={'2'}
            />
          </TabList>
          <TabPanel
            value={'1'}
            style={{
              height: media.small ? 'calc(100% - 134px)' : 'calc(100% - 90px)',
              padding: '12px'
            }}
          >
            <ChatPanel messages={messages} sendMessage={sendMessage} />
          </TabPanel>
          <TabPanel
            value={'2'}
            style={{
              height: media.small ? 'calc(100% - 134px)' : 'calc(100% - 90px)',
              padding: '12px'
            }}
          >
            <PeoplePanel
              users={users}
              localUser={localUser}
              requestTokenChange={requestTokenChange}
              forceUpdate={forceUpdate}
              inviteMessage={inviteMessage}
              sendKickMessage={sendKickMessage}
            />
          </TabPanel>
        </TabContext>
      </div>
    </div>
  )
}

export default Sidebar
