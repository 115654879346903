import axios from 'axios'

/**
 * @function userHomeNeighbourhood Used to retrive the user's nearby videos
 * @param {number} page - the page number of videos to retrive (default 1)
 * @param {number} size - the size of the page (default 18)
 * @param {string} nrctUID - personal[active_neighbourhood].ID from user context
 * @param {string} jwt - the jwt token from user context
 * @returns {Promise} Promise object represents the array of videos retrived
 */
export const userHomeNeighbourhood = (page = 1, size = 18, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/home/neighbourhood`, {
      ts: new Date().toISOString(),
      page,
      page_size: size
    }, {
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => {
        if (res.data.body === null) {
          resolve([])
        } else {
          resolve(res.data.body)
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

/**
 * @function userHomeIndex Used to retrive the user's neighbour videos
 * @param {number} page - the page number of videos to retrive (default 1)
 * @param {number} size - the size of the page (default 18)
 * @param {string} nrctUID - personal[active_neighbourhood].ID from user context
 * @param {string} jwt - the jwt token from user context
 * @returns {Promise} Promise object represents the array of videos retrived
 */
export const userHomeIndex = (page = 1, size = 18, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/home`, {
      ts: new Date().toISOString(),
      page: page,
      page_size: size
    }, {
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => {
        if (res.data.body === null) {
          resolve([])
        } else {
          resolve(res.data.body)
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}
