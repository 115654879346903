import { makeStyles } from '@mui/styles'

export default makeStyles(({ palette, breakpoints }) => {
  return {
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 'calc(90vh - 64px)',
      marginTop: 64,

      [breakpoints.down('sm')]: {
        minHeight: 'calc(90vh - 64px)'
      }
    },
    bannerTextContainer: {
      backgroundColor: 'white',
      borderRadius: 10,
      padding: '40px 20px',

      width: '70%'
    },
    text: {
      color: 'black',
      fontSize: '3rem',
      lineHeight: 1.2,
      fontWeight: 500,
      // eslint-disable-next-line no-useless-computed-key
      ['@media (max-width: 600px)']: {
        fontSize: '2.2rem'
      }
    },

    bold: {
      fontWeight: 700,
      marginBottom: 20,
      color: '#0C3A2D'
    },
    smallText: {
      fontSize: '1rem'
    },
    btnContainer: {
      marginTop: 30,
      width: '100%',
      textAlign: 'center',
      [breakpoints.down('sm')]: {
        width: '100%',
        fontSize: 20
      }
    },
    joinBtn: {
      padding: '12px 18px',
      fontWeight: 600,
      fontSize: 20,

      width: '60%',
      height: '55px',

      [breakpoints.down('sm')]: {
        width: '90%',
        fontSize: 15
      }
    },
    bannerContainer: {
      [breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    bannerImg: {
      height: '100%',
      width: 'auto',
      maxHeight: '590px'
    },

    offsetDown: {
      marginBottom: '-35%'
    }
  }
})
