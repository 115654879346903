import axios from 'axios'

/**
 * @function groupAnnouncementFetch Used to get all announcements regarding the group
 * @param {string} id - the group's id
 * @param {string} nrctUID - user's current neighborhood id
 * @param {string} jwt - user's jwt token
 * @returns {Promise} Promise object represents array of announcements
 */
export const groupAnnouncementFetch = (id, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/group/announcements/fetch`, {
      params: {
        group: id
      },
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(res.data.data))
      .catch((err) => reject(err))
  })
}

/**
 * @function groupAnnouncementCreate Used to create a group announcement
 * @param {string} groupId - the group's id
 * @param {string} nrctUID - user's current neighborhood id
 * @param {string} jwt - user's jwt token
 * @returns {Promise} Promise object represents announcement
 */
export const groupAnnouncementCreate = (
  title,
  description,
  validTill,
  groupId,
  stickers,
  nrctUID,
  jwt
) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/group/announcements/create`, {
      title,
      description,
      valid_till: validTill,
      group_id: groupId,
      stickers: stickers
    }, {
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(res.data.data))
      .catch((err) => reject(err))
  })
}
