import React, { useContext, useState } from 'react'
import StickerButton from 'ui/Buttons/StickerButton/StickerButton'
import StandardModal from 'ui/Modals/StandardModal'
import { Grid } from '@mui/material'
import { UserContext } from '../../context/UserContext'
import PriceSticker from './StickerInputModals/PriceSticker'
import CustomUrlSticker from './StickerInputModals/CustomUrlSticker'
import DateSticker from './StickerInputModals/DateSticker'
import LocationModal from './StickerInputModals/LocationModal'
import { useSnackbar } from 'notistack'
import TextInputStickers from './StickerInputModals/TextInputSticker'

export const sTypes = {
  call: 0,
  message: 1,
  cost: 2,
  email: 3,
  location: 4,
  custom: 5,
  rating: 6,
  donate: 7,
  date: 8
}

function AddStickers ({ open, onClose, currentStickers, setStickers }) {
  const { enqueueSnackbar } = useSnackbar()

  const [priceStickerModal, setPriceSticker] = useState(false)
  const [customLinkModal, setCustomLinkModal] = useState(false)
  const [dateStickerModal, setDateSticker] = useState(false)
  const [locationModal, setLocationModal] = useState(false)
  const [textInputModal, setTextInputModal] = useState(false)

  const { user } = useContext(UserContext)

  const featuredStickers = [
    { type: sTypes.call },
    { type: sTypes.location, location_name: 'Add Location' },
    { type: sTypes.email }
  ]

  const otherStickers = [
    { type: sTypes.custom, custom_name: 'Custom URL' },
    { type: sTypes.date, date: 'Add Date' }
  ]

  const handleStickerAdd = (sticker) => {
    sticker.x = 100
    sticker.y = 200
    sticker.id = Date.now()
    setStickers((prev) => [...prev, sticker])
    onClose()
  }

  const handleClick = (sticker) => {
    console.log(sticker)
    if (currentStickers.findIndex((item) => item.type === sticker.type) !== -1) {
      enqueueSnackbar('Already added this sticker.', { variant: 'error', autoHideDuration: 2000 })
      return
    }

    if (sticker.type === sTypes.cost) {
      setPriceSticker(true)
    } else if (sticker.type === sTypes.custom) {
      setCustomLinkModal(true)
    } else if (sticker.type === sTypes.date) {
      setDateSticker(true)
    } else if (sticker.type === sTypes.location) {
      setLocationModal(true)
    } else if (sticker.type === sTypes.email) {
      setTextInputModal({
        title: 'Add Email Address',
        input: {
          title: 'Email',
          type: 'email',
          value: user.email
        },
        sticker: sticker
      })
    } else if (sticker.type === sTypes.call) {
      setTextInputModal({
        title: 'Add Mobile Number',
        input: {
          title: 'Phone Number',
          type: 'tel',
          value: user.phone
        },
        sticker: sticker
      })
    } else if (sticker.type === sTypes.rating) {
      setTextInputModal({
        title: 'Add Rating',
        input: {
          title: 'Rating to use',
          type: 'number',
          value: '5',
          textInputProps: {
            inputProps: {
              min: 1,
              max: 5
            }
          }
        },
        sticker: sticker
      })
    } else {
      handleStickerAdd(sticker)
    }
  }

  return (
    <StandardModal
      isOpen={open}
      onClose={onClose}
      title="Add Stickers"
    >
      <div style={{ marginTop: 20, width: '100%' }}>
        <div style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>Featured</div>
        <div style={{ paddingTop: 20, paddingBottom: 20 }}>
          <Grid container spacing={2}>
            {featuredStickers.map((sticker, i) => (
              <Grid item xs={6} key={`s-${i}`}>
                <StickerButton
                  sticker={sticker}
                  overRideOnClick={() => handleClick(sticker)}
                />
              </Grid>
            ))}
          </Grid>
        </div>
        <div style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>Others</div>
        <div style={{ paddingTop: 20, paddingBottom: 20 }}>
          <Grid container spacing={2}>
            {otherStickers.map((sticker, i) => (
              <Grid item xs={6} key={`s-${i}`}>
                <StickerButton
                  sticker={sticker}
                  overRideOnClick={() => handleClick(sticker)}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
      <PriceSticker
        open={priceStickerModal}
        onClose={() => setPriceSticker(false)}
        handleStickerAdd={handleStickerAdd}
      />
      <CustomUrlSticker
        open={customLinkModal}
        onClose={() => setCustomLinkModal(false)}
        handleStickerAdd={handleStickerAdd}
      />
      <DateSticker open={dateStickerModal} onClose={() => setDateSticker(false)} handleStickerAdd={handleStickerAdd} />
      <LocationModal open={locationModal} onClose={() => setLocationModal(false)} handleStickerAdd={handleStickerAdd} />
      {textInputModal && (
        <TextInputStickers
          open={Boolean(textInputModal)}
          onClose={() => setTextInputModal(false)}
          title={textInputModal.title}
          input={textInputModal.input}
          handleStickerAdd={(value) => {
            if (textInputModal.sticker.type === sTypes.email) {
              handleStickerAdd({
                ...textInputModal.sticker,
                email: value
              })
            } else if (textInputModal.sticker.type === sTypes.call) {
              handleStickerAdd({
                ...textInputModal.sticker,
                phone_number: value
              })
            } else if (textInputModal.sticker.type === sTypes.rating) {
              handleStickerAdd({
                ...textInputModal.sticker,
                rating: value
              })
            }
            setTextInputModal(false)
          }}
        />
      )}
    </StandardModal>
  )
}

export default AddStickers
