import React, { useState } from 'react'
import { IconButton, Menu, MenuItem, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  DeleteRounded,
  ErrorRounded,
  MoreHoriz,
  Share
} from '@mui/icons-material'
import { getFormattedDate } from '../../../utils/dateTimeParser'
import { useNavigate } from 'react-router-dom'

const GatheringCard = ({
  gathering,
  own,
  setDeleteOpen,
  setReportOpen,
  setShowShare,
  disable
}) => {
  const classes = styles()
  const [hover, setHover] = useState(false)
  const [open, setOpen] = useState()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (window.innerWidth < 500) {
      setHover(true)
    }
  }, [])

  return (
    <div
      className={classes.gatheringCard}
      style={{
        backgroundImage: true
          ? `url(${process.env.REACT_APP_BACKEND_URL}/gatherings/avatar?gathering=${gathering.id})`
          : 'url(https://s3.envato.com/files/351275585/FMZ_6870-1.jpg)',
        backgroundSize: 'cover'
      }}
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        if (window.innerWidth < 500) {
          return
        }
        setHover(false)
        setOpen(null)
      }}
      onClick={() => {
        !disable && !open && navigate(`/gathering/${gathering.id}`)
      }}
    >
      <div className={classes.gc_overlay}>
        {hover && (
          <>
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                setOpen(e.currentTarget)
              }}
              aria-controls="post-menu"
              className={classes.gc_menuIcon}
              color="inherit"
              size="small"
              style={{ backgroundColor: 'rgba(80, 80, 80, 0.8)' }}
            >
              <MoreHoriz htmlColor="#fff" />
            </IconButton>
            <Menu
              id="people-menu"
              anchorEl={open}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={Boolean(open)}
              keepMounted
              onClose={() => {
                setOpen(null)
              }}
            >
              {!disable && (
                <MenuItem
                  onClick={() => {
                    setShowShare({
                      prefix: 'gathering',
                      id: gathering.id,
                      type: 'gathering'
                    })
                    setOpen()
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Share style={{ marginRight: '8px' }} />
                    <Typography>Share</Typography>
                  </div>
                </MenuItem>
              )}
              {own
                ? (
                <MenuItem
                  onClick={() => {
                    setDeleteOpen(gathering.id)
                    setOpen()
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <DeleteRounded
                      color="error"
                      style={{ marginRight: '8px' }}
                    />
                    <Typography color="error">Delete gathering</Typography>
                  </div>
                </MenuItem>
                  )
                : (
                <MenuItem
                  onClick={() => {
                    setReportOpen(gathering.id)
                    setOpen()
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ErrorRounded
                      color="secondary"
                      style={{ marginRight: '8px' }}
                    />
                    <Typography color="secondary">Report gathering</Typography>
                  </div>
                </MenuItem>
                  )}
            </Menu>
          </>
        )}
        <div className={classes.gc_gatheringInfo}>
          <Typography className={classes.gc_gatheringName}>
            {gathering.name.length > 30 ? (gathering.name.match(/(.{1,30}\w)\s/) + '...') : gathering.name}
          </Typography>
          <Typography className={classes.gc_gatheringDescription}>
            happened on {getFormattedDate(gathering.starts_at)}
          </Typography>
        </div>
      </div>
    </div>
  )
}

const styles = makeStyles(({ breakpoints }) => ({
  gatheringCard: {
    position: 'relative',
    height: '234px',
    width: '380px',
    borderRadius: '10px',
    [breakpoints.down('md')]: {
      height: '234px',
      width: '300px'
    },
    [breakpoints.down('sm')]: {
      width: '92vw'
    }
  },
  gc_overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: 'linear-gradient(to top, #000 -20%, rgba(0, 0, 0, 0) 40%)'
  },
  gc_gatheringInfo: {
    color: 'white',
    marginTop: 'auto',
    marginBottom: '10px',
    padding: '10px'
  },
  gc_gatheringDescription: {
    fontSize: '12px'
  },
  gc_gatheringName: {
    fontWeight: 'bold',
    fontSize: '24px',
    marginBottom: '-4px',
    textOverflow: 'ellipsis'
  },
  gc_menuIcon: {
    marginLeft: 'auto',
    marginRight: '10px',
    marginTop: '10px'
  }
}))

export default GatheringCard
