import firebase from 'firebase'

export const getProviderForProviderId = (id) => {
  if (id === 'google.com') {
    return new firebase.auth.GoogleAuthProvider()
  } else if (id === 'apple.com') {
    return new firebase.auth.OAuthProvider('apple.com')
  } else if (id === 'facebook.com') {
    return new firebase.auth.FacebookAuthProvider()
  }
  return new firebase.auth.GoogleAuthProvider()
}
