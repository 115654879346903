import { Typography, Stack } from '@mui/material'
import React from 'react'

import StandardModal from 'ui/Modals/StandardModal'

export interface NearcoinRewardModalProps {
  videoFile: string;
  isOpen: boolean;
  onClose: () => void;
}

const NearcoinRewardModal: React.FC<NearcoinRewardModalProps> = (props) => {
  return (
    <StandardModal
      title=""
      isOpen={props.isOpen}
      onClose={props.onClose}
      buttons={[
        {
          title: 'Download our App',
          color: 'primary',
          onPress: () => {
            window.open('https://app.nearcast.com/download/landingpage', '_blank')
          }
        }
      ]}
    >
      <Stack direction="column" alignItems="center" spacing={1}>
        <img
          src={require('../../../assets/nearcoin.gif')}
          style={{
            width: 140,
            marginTop: 10,
            marginBottom: 10
          }}
        />
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Typography component="span" variant="h5" color="black" sx={{ marginRight: 0.5 }} data-testid="recordedText">
            You have been rewarded
          </Typography>
          <Typography component="span" variant="h5" color="primary">
            100 Nearcoins
          </Typography>
        </Stack>
        <Stack
          direction="column"
          alignSelf="center"
          alignItems="flex-start"
          justifyContent="flex-start"
          sx={{
            paddingTop: 2
          }}
        >
          <Typography component="span" variant="h5">
            Earn more Nearcoins by creating:
          </Typography>
          <ul style={{ marginTop: 10, paddingLeft: 14 }}>
            <li>
              <Typography component="span" variant="h5" color="primary">
                Short Videos
              </Typography>
            </li>
            <li>
              <Typography component="span" variant="h5" color="primary">
                Create Gatherings
              </Typography>
            </li>
            <li>
              <Typography component="span" variant="h5" color="primary">
                Engaging with Nearcast
              </Typography>
            </li>
          </ul>
        </Stack>
      </Stack>
    </StandardModal>
  )
}

export default NearcoinRewardModal
