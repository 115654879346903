import React, { useEffect, useState } from 'react'
import {
	AppBar,
	Button,
	IconButton,
	Toolbar,
	useMediaQuery,
	useTheme,
	SwipeableDrawer
} from '@mui/material'
import {
	MenuRounded
} from '@mui/icons-material'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import Nearcast from 'ui/Icons/Nearcast'
import styles from './LandingHeaderStyles'

import { SidebarElement } from '../../SidebarDrawer/SidebarDrawer'

// importing icons
import GroupIcon from '../../../assets/SidebarIcons/groups-icon.svg'
import BusinessIcon from '../../../assets/SidebarIcons/rocket-icon.svg'
import CoinIcon from '../../../assets/SidebarIcons/nearcoin-logo-gold.png'
import CivicIcon from '../../../assets/SidebarIcons/transmit-icon.svg'
import StarIcon from '../../../assets/SidebarIcons/star-icon.svg'
import MusicIcon from '../../../assets/SidebarIcons/music-note-icon.svg'

function LandingHeader({ light = false, onActionClick }) {
	const theme = useTheme()
	const small = useMediaQuery(theme.breakpoints.down('md'))
	const [drawerOpen, setDrawerOpen] = useState(false)
	const [scrollY, setScrollY] = useState(0)

	const navigate = useNavigate()
	const location = useLocation()

	const classes = styles()

	const handleScroll = () => {
		setScrollY(window.scrollY)
	}

	useEffect(() => {
		window.addEventListener('scroll', handleScroll, { passive: true })

		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])

	const checkIfStaging = () => {
		const origin = window.location.origin
		if (origin.includes('staging') || origin.includes('localhost')) {
			return true
		} else {
			return false
		}
	}

	return (
		<AppBar
			className={classes.root}
			elevation={scrollY > 1 ? 3 : 1}
			style={{
				backgroundColor: light ? 'white' : '#2C8D71'
			}}
		>
			<Toolbar className={classes.toolbar}>
				{small && (
					<div>
						<IconButton
							edge="start"
							onClick={() => {
								setDrawerOpen(true)
							}}
							style={{ color: 'black', borderColor: 'black' }}
						>
							<MenuRounded
								htmlColor={light ? 'black' : 'white'}
							/>
						</IconButton>
					</div>
				)}
				<Link
					to={'/'}
					style={
						small
							? {
								display: 'contents',
								justifySelf: 'center'
							}
							: {
								display: 'flex',
								alignItems: 'center'
							}
					}
				>
					<Nearcast
						height={small ? 27 : 34}
						className={classes.nearcastLogo}
						htmlColor={light ? theme.palette.primary.main : '#fff'}
					/>
				</Link>
				<div className={classes.cta}>
					{!small && (
						<>
							<NavLink to="/" text="Neighbors" light={light} />
							<NavLink
								to=""
								text="Business"
								light={light}
								onClick={() => {
									if (checkIfStaging()) {
										window.open('https://stagingbusiness.nearcast.com', '_self')
									} else {
										window.open('https://business.nearcast.com', '_self')
									}
								}}
							/>
							<NavLink to="" text="Whitepaper" light={light} onClick={() => {
								window.open('https://docs.google.com/document/d/1nBrpdkouZPPsOpdpEFQdNf_My9JT2YBzXltMDad6KGk/edit', '_blank')
							}} />
							{/* <NavLink to="/civic" text="Civic" light={light} /> */}
							{/* <NavLink to="/bands" text="Bands" light={light} /> */}
							{/* <NavLink
								to="/influencers"
								text="Influencers"
								light={light}
								style={{ marginRight: 50 }}
							/> */}

							{false && (
								<Button
									variant="contained"
									color="secondary"
									style={{
										marginRight: 10,
										boxShadow: 'none'
									}}
								>
									Start a Gathering
								</Button>
							)}
						</>
					)}
					<Button
						onClick={onActionClick}
						variant="outlined"
						color={light ? 'primary' : ''}
						className={!light ? classes.loginBtn : ''}
					>
						Login
					</Button>
				</div>
			</Toolbar>
			<SwipeableDrawer
				anchor="left"
				variant="temporary"
				open={drawerOpen}
				onOpen={() => {
					setDrawerOpen(true)
				}}
				onClose={() => {
					setDrawerOpen(false)
				}}
			>
				<Nearcast
					height={27}
					style={{
						display: 'block',
						alignSelf: 'center',
						marginInline: 'auto',
						marginTop: '30px',
						marginBottom: '30px'
					}}
					htmlColor={'#0C3A2D'}
				/>
				<SidebarElement
					onClick={() => navigate('/')}
					pathCheck={location.pathname === '/'}
					icon={GroupIcon}
				>
					For Neighbors
				</SidebarElement>
				<SidebarElement
					onClick={() => {
						if (checkIfStaging()) {
							window.open('https://stagingbusiness.nearcast.com', '_self')
						} else {
							window.open('https://business.nearcast.com', '_self')
						}
					}}
					pathCheck={location.pathname === '/businesses'}
					icon={BusinessIcon}
				>
					For Businesses
				</SidebarElement>
				<SidebarElement
					onClick={() => {
							window.open('https://docs.google.com/document/d/1nBrpdkouZPPsOpdpEFQdNf_My9JT2YBzXltMDad6KGk/edit', '_self')
					}}
					icon={CoinIcon}
				>
					Nearcoin
				</SidebarElement>
				{/* <SidebarElement
					onClick={() => navigate('/civic')}
					pathCheck={location.pathname === '/civic'}
					icon={CivicIcon}
				>
					For Civic Orgs
				</SidebarElement>
				<SidebarElement
					onClick={() => navigate('/bands')}
					pathCheck={location.pathname === '/bands'}
					icon={MusicIcon}
				>
					For Local Bands
				</SidebarElement>
				<SidebarElement
					onClick={() => navigate('/influencers')}
					pathCheck={location.pathname === '/influencers'}
					icon={StarIcon}
				>
					For Influencers
				</SidebarElement> */}
			</SwipeableDrawer>
		</AppBar>
	)
}

LandingHeader.defaultProps = {
	onActionClick: () => { }
}

export default LandingHeader

function NavLink({ to, text, light, ...props }) {
	const classes = styles()
	const { pathname } = useLocation()
	return (
		<Link
			to={to}
			className={
				classes.navLink +
				(light ? ' ' + classes.light : '') +
				(pathname === to && light ? ' ' + classes.navActive : ' ')
			}
			{...props}
		>
			{text}
		</Link>
	)
}
