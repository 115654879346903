import makeStyles from '@mui/styles/makeStyles'

export default makeStyles(({ breakpoints, palette }) => {
  return {
    toolbar: {
      minHeight: 54,
      width: '100%',
      maxWidth: '1800px',
      display: 'flex'
    },
    userDetailsContainer: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      marginRight: '30px',
      [breakpoints.down('sm')]: {
        marginRight: '0px'
      }
    },
    userName: {
      color: 'black',
      marginLeft: '15px',
      marginTop: '2px'
    },
    root: {
      backgroundColor: 'white',
      position: 'fixed',
      height: 'auto',
      padding: '5px 20px',
      display: 'flex',
      alignItems: ' center',
      boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
      // eslint-disable-next-line no-useless-computed-key
      [breakpoints.down('md')]: {
        padding: '5px 10px'
      }
    },
    menuDivider: {
      paddingBlock: '6px',
      paddingInline: '16px'
    },
    neighborhoods: {
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      cursor: 'pointer',
      marginLeft: 84,
      marginRight: 20
    },
    neighborhoodsListItem: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '12px',
      padding: '6px 10.5px',
      border: '2px solid #a9a9a9',
      borderRadius: '5px',
      width: 'calc(100% - 16px)',
      justifyContent: 'space-between'
    },
    nearcastLogo: {
      display: 'block',
      marginLeft: '20px',
      alignSelf: 'center',
      [breakpoints.down('sm')]: {
        marginInline: 'auto'
      }
    },
    cta: {
      marginLeft: 'auto',
      [breakpoints.down('sm')]: {
        marginLeft: '0px'
      },
      // minWidth: 210,
      '& .host': {
        backgroundColor: `${palette.secondary.main}`,
        textAlign: 'center',
        height: '45px',
        color: `${palette.grey[50]}`,
        margin: '0 3px',
        border: `2px solid ${palette.secondary.main}`,
        borderRadius: '10px',
        textTransform: 'none',
        '& .MuiButton-label': {
          display: 'inline',
          lineHeight: 'normal'
        },
        '&:hover': {
          backgroundColor: `${palette.grey[50]}`,
          color: `${palette.secondary.main}`
        }
      },
      '& .login': {
        backgroundColor: '#FFFFFF',
        color: `${palette.primary.main}`,
        textAlign: 'center',
        height: '45px',
        margin: '0 3px',
        border: `2px solid ${palette.primary.main}`,
        borderRadius: '10px',
        textTransform: 'none',
        '& .MuiButton-label': {
          display: 'inline',
          lineHeight: 'normal'
        },
        '&:hover': {
          backgroundColor: `${palette.primary.main}`,
          color: '#FFFFFF'
        }
      }
    }
  }
})
