import { Grid, Button, Hidden, useTheme } from '@mui/material'
import React from 'react'
import styles from './SubpagesHeadStyles'

function SubpagesHead ({
  title,
  description,
  actionText = 'Get Started',
  bg,
  onActionClick
}) {
  const classes = styles()

  const theme = useTheme()

  return (
		<Grid
			container
			justify="center"
			className={classes.root}
			style={{
			  background: `url(${bg}), ${theme.palette.primary.main}`,
			  backgroundSize: 'cover',
			  backgroundPosition: 'center'
			}}
			alignItems="center"
			spacing={2}
		>
			<Grid
				item
				xs={12}
				md={6}
				style={{
				  display: 'flex',
				  flexDirection: 'column',
				  justifyContent: 'center',
				  alignItems: 'center'
				}}
			>
				<div className={classes.bannerTextContainer}>
					<div className={`${classes.text} ${classes.bold}`}>
						{title}
					</div>
					<div className={`${classes.text} ${classes.smallText}`}>
						{description}
					</div>
					<div className={classes.btnContainer}>
						<Button
							variant="contained"
							disableElevation
							color="primary"
							className={classes.joinBtn}
							onClick={() => onActionClick()}
						>
							{actionText}
						</Button>
					</div>
				</div>
			</Grid>
			<Hidden smDown>
				<Grid item xs={0} md={6}></Grid>
			</Hidden>
		</Grid>
  )
}

export default SubpagesHead
