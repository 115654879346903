import * as React from 'react'
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  IconButton
} from '@mui/material'
import { MoreHoriz } from '@mui/icons-material'

// importing ui
import CardMenu, { MenuItemProps } from '../../Menu/CardMenu'

export interface ImageContentCardProps {
  id: string;
  cardCover: string;
  cardContent: JSX.Element;
  cardMenuItems: MenuItemProps[];
  onCardPress?: () => void;
}

const ImageContentCard: React.FC<ImageContentCardProps> = ({
  id,
  cardCover,
  cardContent,
  cardMenuItems,
  onCardPress
}) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState<null | Element>(null)

  return (
    <Card
      style={{
        ...styles.allOffersOfferCard,
        cursor: onCardPress ? 'pointer' : 'default'
      }}
      sx={{
        marginLeft: {
          xs: 0,
          md: 2
        },
        marginBottom: 2
      }}
      elevation={0}
    >
      {cardMenuItems.length > 0 && (
        <>
          <IconButton
            style={styles.allOffersOfferCardMenuButton}
            onClick={(e) => setIsMenuOpen(e.currentTarget)}
          >
            <MoreHoriz htmlColor='white' />
          </IconButton>
          <CardMenu
            id={id}
            isOpen={isMenuOpen}
            onClose={() => setIsMenuOpen(null)}
            menuItems={cardMenuItems}
          />
        </>
      )}
      {onCardPress
        ? (
        <CardActionArea onClick={onCardPress}>
          <CardMedia
            component='img'
            height='160'
            image={cardCover}
            alt=''
          />
          <CardContent>
            {cardContent}
          </CardContent>
        </CardActionArea>
          )
        : (
        <>
          <CardMedia
            component='img'
            height='160'
            image={cardCover}
            alt=''
          />
          <CardContent>
            {cardContent}
          </CardContent>
        </>
          )}

    </Card>
  )
}

const styles = {
  allOffersOfferCard: {
    position: 'relative',
    width: 300,
    borderRadius: 10,
    zIndex: 8,
    border: '1px solid #bdbdbd'
  },
  allOffersOfferCardMenuButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 10,
    backgroundColor: '#505050',
    cursor: 'pointer'
  }
} as const

export default ImageContentCard
