import React from 'react'
import styles from './FeaturesStyle'

function FeatureCard ({ iconSrc, children, active, onClick }) {
  const classes = styles()

  return (
		<div
			onClick={onClick}
			className={`${classes.cardRoot} ${!active ? classes.inactive : ''}`}
		>
			<div style={{ marginRight: 25 }}>
				<img src={iconSrc} alt="" className={classes.featureCardIcon} />
			</div>
			<div>{children}</div>
		</div>
  )
}

export default FeatureCard
