import * as React from 'react'

// importing ui
import StandardModal from 'ui/Modals/StandardModal'
import NearcastVideoPlayer from 'ui/Custom/NearcastVideoPlayer/NearcastVideoPlayer'
import { Stack } from '@mui/material'

export interface AnonymousReviewModalProps {
  videoToken: string;
  isOpen: boolean;
  onClose: () => void;
}

const AnonymousReviewModal: React.FC<AnonymousReviewModalProps> = (props) => {
  return (
    <StandardModal
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <NearcastVideoPlayer
            type="post"
            video={{
              source: props.videoToken,
              muted: false,
              toggleMuted: () => {}
            }}
        />
      </Stack>
    </StandardModal>
  )
}

export default AnonymousReviewModal
