const dayjs = require('dayjs')

// some helpful constants
// dayjs().day() gives values from 0 (Sunday) to 6 (Saturday), this array can be used for mapping
const dayjsDayArray = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export const getBusinessOpenScheduleForToday = (schedule) => {
  if (schedule) {
    const todaysDayName = dayjsDayArray[dayjs().day()]
    const todaysSchedule = schedule.find((day) => day.day.toLowerCase() === todaysDayName.toLowerCase())
    if (todaysSchedule.is_active) {
      return `${todaysSchedule.day}: ${dayjs(todaysSchedule.start_time).format('hh:mm A')} - ${dayjs(todaysSchedule.end_time).format('hh:mm A')}`
    } else {
      return 'Closed today 😞'
    }
  }
  return 'Call the restaurant before going to confirm timings ⏰'
}

export const getBusinessOpenScheduleForALl = (schedule) => {
  const timimgsCon = []
  if (schedule) {
    const allDays = []
    // const todaysDayName = dayjsDayArray[dayjs().day()]
    schedule.map((eachDay) => {
      if (eachDay.is_active) {
        allDays.push({
          day: `${eachDay.day}:`,
          time: `${dayjs(eachDay.start_time).format('hh:mm A')} - ${dayjs(eachDay.end_time).format('hh:mm A')}`
        })
      } else if (!eachDay.is_active) {
        allDays.push({
          day: 'Closed on ',
          time: `${eachDay.day}`
        })
      }
    })
    return allDays
  }
  timimgsCon.push({
    day: 'Call the restaurant before going to confirm timings',
    time: '⏰'
  })
  return timimgsCon
}
