import React, { useContext } from 'react'
import Features from '../../components/Landing/Features/Features'
import FooterNew from '../../components/Landing/FooterNew/Footer'
import LandingHeader from '../../components/Landing/LandingHeader/LandingHeader'
import SubpagesHead from '../../components/Landing/SubpagesHead/SubpagesHead'
import BandsBg from './HeadBackgrounds/bands-bg.png'
import features from './FeatureLists/BandsList'
import BandsBanner from './Banners/bands.png'
import { GlobalFuncContext } from '../../context/GlobalFuncHOC'

function LandingBands () {
  const { openAuthModal } = useContext(GlobalFuncContext)

  const featureBanners = [BandsBanner]
  const description =
		'License your original music to Nearcast and enable users living nearby to create amazing content incorporating your music. Get statistics, be signed for gigs, sell merchandise & grow a fanbase locally.'

  return (
		<>
			<LandingHeader light onActionClick={() => openAuthModal('landing')} />
			<SubpagesHead
				title="Nearcast for Local Bands"
				description={description}
				bg={BandsBg}
				onActionClick={() => openAuthModal('landing')}
			/>
			<Features
				title="Local Bands use Nearcast to"
				features={features}
				featureBanners={featureBanners}
				scrollEnabled={false}
				onActionClick={() => openAuthModal('landing')}
			/>
			<FooterNew />
		</>
  )
}

export default LandingBands
