import { makeStyles } from '@mui/styles'

export default makeStyles(({ palette, breakpoints }) => {
  return {
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 700,
      marginTop: '64px !important',
      marginBottom: 40,
      background: 'linear-gradient(151.08deg, #30987A 3.85%, #1A6751 96.19%)',
      [breakpoints.down('sm')]: {
        minHeight: 'calc(100vh - 64px)',
        marginBottom: 10
      }
    },
    text: {
      color: 'white',
      fontSize: '3rem',
      lineHeight: 1.2,
      textAlign: 'center',
      // eslint-disable-next-line no-useless-computed-key
      ['@media (max-width: 600px)']: {
        fontSize: '2.2rem'
      }
    },
    bold: {
      fontWeight: 'bold',
      marginBottom: 40
    },
    btnContainer: {
      width: '350px',
      [breakpoints.down('sm')]: {
        width: '300px',
        fontSize: 20
      }
    },
    joinBtn: {
      padding: '12px 18px',
      fontWeight: 600,
      fontSize: 20,

      width: '100%',
      height: '55px'
    },
    bannerContainer: {
      [breakpoints.down('md')]: {
        display: 'none'
      }
    },
    bannerImg: {
      height: '100%',
      //   width: "auto",
      maxHeight: 580
    },

    offsetDown: {
      marginBottom: '-35%'
    },
    phone: {
      margin: 0
    },
    two: {
      marginTop: 300
    }
  }
})
