import React from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { ArrowRight as Arrow } from "@mui/icons-material";

// importing components
import play from "../../../assets/play.svg";
import app from "../../../assets/apple.svg";

const FooterNew = () => {
  return (
    <Stack
      sx={{
        background: "#1A6751",
        backgroundSize: "cover",
        width: "100%",
        minHeight: "30vh",
        "& ul": {
          listStyle: "outside none none",
          padding: 0,
          margin: 0,
          marginLeft: -1,
        },
        "& li": {
          padding: 0,
          fontSize: "1.0rem",
          fontWeight: "normal",
          lineHeight: "1.6",
          "& a": {
            textDecoration: "none",
            color: "inherit",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          },
        },
        color: "white",
      }}
    >
      <Grid
        container
        spacing={0}
        justify="center"
        sx={{
          padding: "60px 0px",
          paddingBottom: "40px 0px",
        }}
      >
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column !important",
          }}
        >
          <Stack direction="column" alignItems="center">
            <Stack
              sx={{
                marginBottom: 1,
                display: {
                  xs: "none",
                  md: "flex",
                },
              }}
            >
              <img
                src={require("../../../assets/landingQrcode.png")}
                style={{
                  width: 160,
                  borderRadius: 8,
                }}
              />
            </Stack>
            <Typography variant="h2" sx={{ color: "white" }}>
              Broadcast to your
            </Typography>
            <Typography variant="h2" sx={{ color: "white" }}>
              Neighborhood
            </Typography>
            <Stack
              sx={{
                marginTop: 3,
                marginBottom: 3,
                display: {
                  xs: "flex",
                  lg: "none",
                },
              }}
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={1}
            >
              <a href="https://apps.apple.com/app/id1586786030">
                <img src={app} alt="App Store" style={{ maxWidth: "165px" }} />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.nearcast.app">
                <img
                  src={play}
                  alt="Play Store"
                  style={{ maxWidth: "165px" }}
                />
              </a>
            </Stack>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            padding: 2,
          }}
        >
          <Stack
            direction="column"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Typography
              variant="body2"
              textAlign={{
                xs: "justify",
                md: "left",
              }}
              sx={{
                width: {
                  xs: "100%",
                  md: "90%",
                },
              }}
            >
              Nearcast is a neighborhood social networking platform leveraging
              audio-visual media where residents can interact and get to know
              what's happening around them. Whether you're a local business, a
              sports enthusiast, or a helpful neighbor- there is something for
              everyone on Nearcast.
            </Typography>
          </Stack>
          <Stack
            direction={{
              xs: "column",
              md: "row",
            }}
            alignItems="flex-start"
            justifyContent="flex-start"
            spacing={{
              xs: 0,
              md: 4,
            }}
            sx={{
              marginTop: 2,
            }}
          >
            <ul>
              <li>
                <a
                  href="https://www.nearcast.com/privacy"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Arrow sx={{ fontSize: "36px" }} /> Privacy Policy
                </a>
              </li>
              <li>
                <a
                  href="https://www.nearcast.com/terms"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Arrow sx={{ fontSize: "36px" }} /> Terms and Conditions
                </a>
              </li>
              <li>
                <a
                  href="https://www.nearcast.com/content-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Arrow sx={{ fontSize: "36px" }} /> Content Policy
                </a>
              </li>
            </ul>
            <ul style={window.innerWidth < 900 ? { marginLeft: -8 } : {}}>
              <li>
                <a
                  href="https://www.nearcast.com/report/copyright"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Arrow sx={{ fontSize: "36px" }} /> Report Copyright
                  Infrigement
                </a>
              </li>
              <li>
                <a
                  href="https://www.nearcast.com/community-guidelines"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Arrow sx={{ fontSize: "36px" }} /> Community Guidelines
                </a>
              </li>
            </ul>
          </Stack>
        </Grid>
      </Grid>
      <div
        style={{
          borderTop: "1px solid white",
          padding: "20px 0",
          textAlign: "center",
        }}
      >
        Copyright © 2022 NearCast, Inc.
      </div>
    </Stack>
  );
};

export default FooterNew;
