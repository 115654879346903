import axios from 'axios'

/**
 * @function signupOtpSend Used to send otp to new user's mobile number
 * @param {string} mobile - mobile number of the new user
 * @param {string} token - token of the new user profile
 * @returns {Promise} Promise object represents true for successful opt send
 */
export const signupOtpSend = (mobile, token) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/signup/otp/send`, {
      phone: mobile,
      id_token: token
    })
      .then((res) => {
        resolve(true)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

/**
 * @function signupOtpVerify Used to verity the otp received by the new user during signup
 * @param {string} mobile - mobile number of the new user
 * @param {string} otp - the otp received by the user
 * @param {string} token - token of the new user profile
 * @returns {Promise} Promise object represents true for successful otp verification
 */
export const signupOtpVerify = (mobile, otp, token) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/signup/otp/verify`, {
      phone: mobile,
      id_token: token,
      otp: otp
    })
      .then((res) => {
        resolve(true)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
