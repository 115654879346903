import RocketIcon from '../FeatureIcons/rocket-icon.svg'
import MusicIcon from '../FeatureIcons/music-note-icon.svg'
import StarIcon from '../FeatureIcons/star-icon.svg'
import SellIcon from '../FeatureIcons/neighbours-icon.svg'
import TransmitIcon from '../FeatureIcons/transmit-icon.svg'

const features = [
  {
    iconSrc: StarIcon,
    head: 'Become a local superstar.',
    headHighlight: '',
    description:
			'Create a fan page for your band, through which nearby residents can discover your music and learn about you and your bandmates. Be heard by thousands of fans locally.'
  },
  {
    iconSrc: SellIcon,
    head: 'Promote local concerts and sell merchandise.',
    headHighlight: '',
    description:
			'Invite local residents to your concerts and speak to them through drop-in video conversations. Sell them merchandise and have them create a video wearing it.'
  },
  {
    iconSrc: MusicIcon,
    head: 'Share your music with nearby residents.',
    headHighlight: '',
    description:
			'Allow nearby residents to discover your music in new ways. Empower them to incorporate your music into short-videos sharing their experiences.'
  },
  // {
  //   iconSrc: TransmitIcon,
  //   head: 'Livestream to your local fans.',
  //   headHighlight: '',
  //   description:
	// 		'Allow your fans to experience your music whenever you feel like sharing it. Let them hear a new riff, give them a live performance when you feel like without worrying about COVID restrictions.'
  // },
  {
    iconSrc: RocketIcon,
    head: 'Get hired for local gigs.',
    headHighlight: '',
    description:
			'List yourself on Nearcast and allow local businesses and families to hire your band for special events.'
  }
]

export default features
