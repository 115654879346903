import { FormControl } from '@mui/material'
import React, { useState } from 'react'
import CustomPriceFields from 'ui/TextInputs/PriceFieldsInput'
import { sTypes } from '../AddStickers'
import StandardModal from 'ui/Modals/StandardModal'
import { useSnackbar } from 'notistack'

function PriceSticker ({ open, onClose, handleStickerAdd }) {
  const [price, setPrice] = useState()
  const { enqueueSnackbar } = useSnackbar()

  const handleClick = () => {
    if(!price){
      enqueueSnackbar('Enter a Price 💸', {
        variant: 'error',
        autoHideDuration: 2000
      })
	  return
    } if (price) {
      enqueueSnackbar('Your sticker has been added ✅', {
        variant: 'success',
        autoHideDuration: 2000
	   })
	  }
    handleStickerAdd({ type: sTypes.cost, price: Number(price) })
    onClose()
  }

  return (
    <StandardModal
      isOpen={open}
      onClose={onClose}
      title="Price Sticker"
      buttons={[
        {
          title: 'Add Sticker',
          color: 'primary',
          onPress: handleClick
        }
      ]}
    >
      <div style={{ marginTop: 20, marginBottom: 20, width: '100%' }}>
        <FormControl fullWidth margin="none">
          <CustomPriceFields
            label="Price"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            type="number"
          />
        </FormControl>
      </div>
    </StandardModal>
  )
}

export default PriceSticker
