import * as React from 'react'
import {
  Button,
  CircularProgress,
  Stack, Typography
} from '@mui/material'
import { AddCircle } from '@mui/icons-material'
import useDimensions from 'react-use-dimensions'
import { useNavigate } from 'react-router-dom'

// importing components
import { UserContext } from '../../../context/UserContext'
import { GlobalFuncContext } from '../../../context/GlobalFuncHOC'
import GGBTabButtonGroup from '../GGBTabButtonGroup'

// importing ui
import ImageContentCard from 'ui/Cards/ImageContentCard/ImageContentCard'

// importing api
import { businessNearby, offersNearby } from 'api/business/nearby'

// importing helpers
import { getBusinessOpenScheduleForToday } from 'helpers/business'
import { maxStringEllipses } from 'helpers/string'
import { getBusinessDomainURL, getUserDomainURL } from 'helpers/url'
import { recordGaEvent } from 'helpers/ga'

const Business = () => {
  const [ref, { width }] = useDimensions()
  const navigate = useNavigate()
  const {
    personal,
    jwt_token,
    active_neighbourhood,
    userProfiles
  } = React.useContext(UserContext)
  const { openClaimOfferModal } = React.useContext(GlobalFuncContext)
  // component states
  const [isAllOffers, setIsAllOffers] = React.useState(false)
  const offerStateInitial = {
    loading: true,
    page: 0,
    hasMore: true,
    offers: []
  }
  const [offersState, setOffersState] = React.useState(offerStateInitial)
  const businessStateInitial = {
    loading: true,
    page: 0,
    hasMore: true,
    businesses: []
  }
  const [businessState, setBusinessState] = React.useState(businessStateInitial)

  // load more on scroll end
  const observer = React.useRef()
  const lastVideoElementRef = React.useCallback(
    (node) => {
      if (isAllOffers) {
        if (offersState.loading) {
          return
        }
      } else {
        if (businessState.loading) {
          return
        }
      }
      if (observer.current) {
        observer.current.disconnect()
      }
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && offersState.hasMore) {
          if (isAllOffers) {
            getNearbyOffers(offersState.page + 1)
          } else {
            getNearbyBusinesses(businessState.page + 1)
          }
        }
      })
      if (node) observer.current.observe(node)
    },
    [offersState, businessState, isAllOffers]
  )

  const getNearbyOffers = (pageNumber = 1) => {
    if (pageNumber === offersState.page || offersState.hasMore === false) {
      return
    }
    offersNearby(
      personal[active_neighbourhood].lives_in_id,
      pageNumber,
      personal[active_neighbourhood].ID,
      jwt_token
    )
      .then((resBody) => {
        const newOffersState = {
          loading: false,
          page: pageNumber,
          hasMore: resBody.length !== 0,
          offers: [...offersState.offers, ...resBody]
        }
        setOffersState(newOffersState)
      })
      .catch((err) => console.log(err))
  }

  const getNearbyBusinesses = (pageNumber = 1) => {
    if (pageNumber === businessState.page || businessState.hasMore === false) {
      return
    }
    businessNearby(
      personal[active_neighbourhood].lives_in_id,
      pageNumber,
      personal[active_neighbourhood].ID,
      jwt_token
    )
      .then((resBody) => {
        const newBusinessState = {
          loading: false,
          page: pageNumber,
          hasMore: resBody.length !== 0,
          businesses: [...businessState.businesses, ...resBody]
        }
        setBusinessState(newBusinessState)
      })
      .catch((err) => console.log(err))
  }

  React.useEffect(() => {
    getNearbyOffers()
    getNearbyBusinesses()
  }, [])

  const renderBusinessContent = () => {
    if (businessState.loading) {
      return (
        <Stack
          alignItems="center"
          justifyContent="center"
          style={{ width: '100%', height: '70vh' }}
        >
          <CircularProgress />
        </Stack>
      )
    }

    if (businessState.businesses.length === 0) {
      return (
        <Stack
          alignItems="center"
          justifyContent="center"
          style={{ width: '100%', height: '70vh' }}
        >
          <img
            src={require('./shop.png')}
            style={{
              width: 300
            }}
          />
          <Typography variant="body2" style={{ textAlign: 'center' }}>
            Turn people who find you on Nearcast into <br /> new customers with a Business Profile 💼
          </Typography>
          <Button
            variant='contained'
            style={{
              marginTop: 16
            }}
            disableElevation
            onClick={() => {
              window.open(getBusinessDomainURL('/'))
            }}
          >
            Add my Business
          </Button>
        </Stack>
      )
    }

    return (
      <>
        {businessState.businesses.map((business, index) => {
          return (
            <div
              key={business.id}
              ref={businessState.businesses.length - 1 === index ? lastVideoElementRef : null}
              data-testid={`business-${index}`}
            >
              <ImageContentCard
                key={index}
                id={business.id}
                cardCover={business.business_cover}
                cardContent={
                  <Stack
                    direction="column"
                  >
                    <Typography variant="h6">
                      {business.name}
                    </Typography>
                    <Typography style={{ marginTop: 2, marginBottom: 2 }} variant="caption" color={getBusinessOpenScheduleForToday(business.schedules) != 'Closed today 😞' && 'Call the restaurant before going to confirm timings ⏰' ? 'primary' : 'secondary'}>
                      {getBusinessOpenScheduleForToday(business.schedules)}
                    </Typography>
                    <Typography variant="caption">
                      {maxStringEllipses(business.address_line, 64)}
                    </Typography>
                  </Stack>
                }
                cardMenuItems={[]}
                onCardPress={() => {
                  navigate(`/b/${business.ID}`)

                  // analytics
                  recordGaEvent('allBusinesses&OffersPage-businessCard-clicked', {
                    personalAccountId: personal[active_neighbourhood].ID,
                    businessName: business.name,
                    businessId: business.ID
                  })
                }}
              />
            </div>
          )
        })}
      </>
    )
  }

  const renderOfferContent = () => {
    if (offersState.loading) {
      return (
        <Stack
          alignItems="center"
          justifyContent="center"
          style={{ width: '100%', height: '70vh' }}
        >
          <CircularProgress />
        </Stack>
      )
    }

    if (offersState.offers.length === 0) {
      return (
        <Stack
          alignItems="center"
          justifyContent="center"
          style={{ width: '100%', height: '70vh' }}
        >
          <img
            src={require('./offers.png')}
            style={{
              width: 300
            }}
          />
          <Typography variant="body2" style={{ marginTop: 20, textAlign: 'center' }}>
            No offers available. Come back later<br />to check for more offers ⌛
          </Typography>
        </Stack>
      )
    }

    return (
      <>
        {offersState.offers.map((offer, i) => {
          return (
            <div
              key={offer.id}
              ref={offersState.offers.length - 1 === i ? lastVideoElementRef : null}
            >
              <ImageContentCard
                id={offer.id}
                cardCover={offer.cover}
                cardContent={
                  <Stack
                    direction="column"
                    style={{
                      minHeight: 110
                    }}
                  >
                    <Typography variant="h4">
                      {offer.title}
                    </Typography>
                    <Typography style={{ marginTop: 2, marginBottom: 2 }} variant="caption">
                      {maxStringEllipses(offer.description, 88)}
                    </Typography>
                    <Typography variant="caption" color="primary" style={{ marginTop: 10 }}>
                      <span style={{ color: 'black' }}>Coupon Code: </span>{offer?.in_store_offer?.redemption_code}
                    </Typography>
                    <Typography
                      style={{
                        marginTop: 10,
                        marginBottom: 2,
                        alignSelf: 'flex-end'
                      }}
                      variant="body2"
                      color="secondary"
                    >
                      {offer?.name}
                    </Typography>
                  </Stack>
                }
                cardMenuItems={[]}
                onCardPress={() => {
                  openClaimOfferModal({
                    cover: offer.cover,
                    title: offer.title,
                    description: offer.description,
                    redemption: offer?.in_store_offer?.redemption_code,
                    nearcoins: offer.nearcoins,
                    link: getUserDomainURL(`/offer/${offer?.in_store_offer?.redemption_code}`)
                  })

                  // analytics
                  recordGaEvent('allBusinesses&OffersPage-offerCard-clicked', {
                    personalAccountId: personal[active_neighbourhood].ID,
                    offerTitle: offer.title,
                    couponCode: offer?.in_store_offer?.redemption_code
                  })
                }}
              />
            </div>
          )
        })
        }
      </>
    )
  }

  return (
    <Stack
      ref={ref}
      direction="column"
      alignItems={{
        xs: 'stretch',
        md: 'flex-start'
      }}
      justifyContent="flex-start"
      spacing={0}
    >
      {width < 600 && (
        <div
          style={{
            display: 'flex',
            marginBottom: '20px',
            marginRight: 20,
            justifyContent: 'center',
            marginLeft: 20
          }}
        >
          <GGBTabButtonGroup />
        </div>
      )}
      <Stack
        direction={{
          xs: 'column',
          md: 'row'
        }}
        alignItems="center"
        justifyContent="space-between"
        style={{
          width: '100%'
        }}
      >
        <Typography
          sx={{
            marginLeft: {
              xs: 0,
              md: 2
            }
          }}
          textAlign="center"
          variant="h3"
          color="primary"
        >
          {isAllOffers ? 'Offers' : 'Businesses'} in {userProfiles[active_neighbourhood].name}
        </Typography>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            marginTop: {
              xs: 1,
              md: 0
            }
          }}
        >
          <Button
            variant={isAllOffers ? 'contained' : 'outlined'}
            onClick={() => setIsAllOffers(!isAllOffers)}
            color={isAllOffers ? 'secondary' : 'primary'}
            disableElevation
            data-testid="viewAllOfferToggle"
          >
            View Offers
          </Button>
          <Button
            variant="contained"
            startIcon={<AddCircle />}
            onClick={() => {
              window.open(getBusinessDomainURL('/'))

              // analytics
              recordGaEvent('allBusinesses&OffersPage-createBusiness-clicked', {
                personalAccountId: personal[active_neighbourhood].ID
              })
            }}
            disableElevation
          >
            Create Business Profile
          </Button>
        </Stack>
      </Stack>
      <Stack
        direction="row"
        alignItems='flex-start'
        justifyContent={{
          xs: 'center',
          md: 'flex-start'
        }}
        flexWrap="wrap"
        sx={{
          marginTop: 2,
          marginBottom: 2
        }}
        style={{
          width: '100%'
        }}
      >
        {isAllOffers ? renderOfferContent() : renderBusinessContent()}
      </Stack>
    </Stack>
  )
}

export default Business
