import axios from 'axios'

/**
 * @function postBuy Used to buy something from post buy sticker
 * @param {string} postId - post's id, eg: "qwe....948ijg"
 * @param {string} nrctUID - personal[active_neighbourhood].ID from user context
 * @param {string} jwt - jwt token from user context
 * @returns {Promise} Promise object represents array of comments
 */
export const postBuy = (postId, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/post/buy`, {
      params: {
        post: postId
      },
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(res.data.body))
      .catch((err) => reject(err))
  })
}
